import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";

const styles = {
  root: {
    pointerEvents: "none",
    width: "auto",
    maxWidth: "600px",
    height: 46,

    position: "absolute",
    top: 10,
    left: 5,
    color: "#ffffff",

    padding: 0,
    "& :active": {
      outline: "",
    },
    background: "#000000AA",
    border: "2px solid rgb(85, 85, 85)",
    borderRadius: 10,
    zIndex: 9999,
  },
  barIcon: {
    position: "absolute",
    top: -1,
    left: 2,
    height: 42,
    width: "36px!important",
    padding: 8,
    color: "#ffffff",
  },
  infoArea: {
    paddingLeft: "40px",
    paddingRight: "10px",
    right: 0,
    top: 0,
    bottom: 0,
    color: "#fff",
  },
};

class ImageInfo extends Component {
  render() {
    const { classes, ome } = this.props;

    return (
      <div className={classes.root}>
        <FontAwesomeIcon className={classes.barIcon} icon={faInfo} />
        {ome && (
          <div className={classes.infoArea}>
            <Typography color="inherit" noWrap={true}>
              {ome.fileName}{" "}
              {ome.sizeS > 1 && <span>, Scene {ome.scene + 1}</span>}
            </Typography>
            <Typography color="inherit" noWrap={true} style={{ fontSize: 12 }}>
              Size: {ome.sizeX}x{ome.sizeY}
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

// define the component's interface
ImageInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  ome: PropTypes.object,
};

export default withStyles(styles)(ImageInfo);

import { findSameLayer } from "../../utils/PolygonUtil";
import { Typography, FormControl, FormLabel, Slider } from "@mui/material";
import Tool from "./Tool";
import React from "react";
import KDBush from "kdbush";

class HeatmapTool extends Tool {
  toolName = "Heatmap Tool";
  noConfig = false;
  removeOverlap = null;
  removeOverlapSame = null;
  tempRemoveOverlap = null;
  tempRemoveOverlapSame = null;
  threshold = 30;
  init = false;
  maxValue = 1;
  searchRadius = 20;
  labels = [];
  prevSelectedLayer = 0;
  rois = [];
  activeGrid = 0;

  setLayer(obj) {
    this.ome = obj.ome;
    this.isBrightfield = obj.ome.channels[0].type === "brightfield";
    this.ctx = obj.ctx; //context
    this.canvas = obj.ctx.canvas; //canvas
    this.canvasWidth = this.canvas.width;
    this.canvasHeight = this.canvas.height;
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    let layerResults = findSameLayer(obj.structures, obj.selectedLayer);
    this.selectedLayer = layerResults[0];
    this.parentLayer = layerResults[1];
    this.originalSelectedLayer = obj.selectedLayer;
    this.structures = obj.structures;
    this.drawLayer = obj.drawLayer;

    this.fileId = obj.fileId;
    this.rendererDict = obj.rendererDict;
    this.rendererRef = this.rendererDict[this.fileId];

    const project = obj.viewerConfig_project;
    if (this.removeOverlap === null) {
      this.removeOverlap = project.projectProperties["PreventOverlap"];
    }
    if (this.tempRemoveOverlap === null) {
      this.tempRemoveOverlap = project.projectProperties["PreventOverlap"];
    }
    if (this.removeOverlapSame === null) {
      this.removeOverlapSame = project.projectProperties["PreventOverlapSame"];
    }

    // set removeOverlap
    // save current settings (in tempRemoveOverlap) if structure gets changed
    if (obj.selectedLayer === 0) {
      if (!this.noConfig) {
        this.noConfig = true;
        this.tempRemoveOverlap = this.removeOverlap;
        this.tempRemoveOverlapSame = this.removeOverlapSame;
        this.removeOverlap = false;
        this.removeOverlapSame = false;
        window.forceSidebarUpdate();
      }
    } else {
      if (this.noConfig) {
        this.noConfig = false;
        this.removeOverlap = this.tempRemoveOverlap;
        this.removeOverlapSame = this.tempRemoveOverlapSame;
        window.forceSidebarUpdate();
      }
    }

    this.initTool();
  }

  initTool = () => {
    if (!this.init) {
      this.visibilityState = [];
      for (let s of this.structures) {
        this.visibilityState.push({
          id: s.id,
          visible: s.visible,
        });
        s.visible = false;
      }
      this.init = true;
    }
  };

  getPointInCanvas = (point) => {
    let pt = {
      x: Math.round(
        point.center.x * this.ctx.getTransform().a + this.ctx.getTransform().e
      ),
      y: Math.round(
        point.center.y * this.ctx.getTransform().d + this.ctx.getTransform().f
      ),
    };
    return pt;
  };

  getSubtypeLabels = (idx) => {
    if (!this.labels.includes(this.structures[idx].label)) {
      if (
        this.structures[idx].parentId === 0 ||
        !this.structures[idx].classificationSubtype
      ) {
        this.labels.push("");
      } else {
        this.labels.push(this.structures[idx].label);
      }
    }
    if (this.structures[idx].hasChild) {
      let structs = this.structures.filter(
        (s) => this.structures[idx].id === s.parentId
      );
      for (let s of structs) {
        let index = this.structures.indexOf(s);
        if (index > -1) this.getSubtypeLabels(index);
      }
    }
  };

  getRois = () => {
    if (this.originalSelectedLayer !== 0) {
      if (this.prevSelectedLayer !== this.originalSelectedLayer) {
        this.rois = [];
        this.prevSelectedLayer = this.originalSelectedLayer;
        this.labels = [];
        let id = this.roiLayers[this.originalSelectedLayer].id;
        let idx = this.structures.findIndex((s) => s.id === id);
        this.getSubtypeLabels(idx);
        let rois = this.roiLayers[this.selectedLayer].layer.regionRois;
        if (rois.length > 0) {
          this.rois = rois.filter((roi) =>
            this.labels.includes(roi.subtypeName)
          );
        }
      }
    }
  };

  checkRTree = () => {
    this.getRois();
    if (!this.rtree) {
      this.rtree = new KDBush([]);
    }
    if (this.rtree && this.rtree.points.length !== this.rois.length) {
      this.treePoints = [];
      for (let point of this.rois) {
        this.treePoints.push([point.center.x, point.center.y]);
      }
      this.rtree = new KDBush(this.treePoints);
    }
  };

  worldToCanvas = (x, y) => {
    let pt = {
      x: Math.round(x * this.ctx.getTransform().a + this.ctx.getTransform().e),
      y: Math.round(y * this.ctx.getTransform().d + this.ctx.getTransform().f),
    };
    return pt;
  };

  yToWorld = (y) => {
    let p1 = this.rendererRef.getPointInCanvas({
      x: 0,
      y: y,
    });
    let p2 = this.rendererRef.getPointInCanvas({ x: 0, y: 0 });
    let p3 = this.rendererRef.getPosition();
    p1.y += p2.y - p3.y / this.rendererRef.getScale();
    return p1.y;
  };

  xToWorld = (x) => {
    let p1 = this.rendererRef.getPointInCanvas({
      x: x,
      y: 0,
    });
    let p2 = this.rendererRef.getPointInCanvas({ x: 0, y: 0 });
    let p3 = this.rendererRef.getPosition();
    p1.x += p2.x - p3.x / this.rendererRef.getScale();
    return p1.x;
  };

  worldDistance = (d) => {
    let dStart = 0;
    let dEnd = Math.abs(d);
    dStart = this.xToWorld(dStart);
    dEnd = this.xToWorld(dEnd);
    return parseInt(Math.abs(dEnd - dStart));
  };

  makeTiles = () => {
    let tileSizeX = 20;
    let tileSizeY = 20;
    let tiles = [];

    for (let x = 0; x < this.canvasWidth; x = x + tileSizeX) {
      for (let y = 0; y < this.canvasHeight; y = y + tileSizeY) {
        if (
          y > this.canvasHeight - tileSizeY &&
          x > this.canvasWidth - tileSizeX
        ) {
          tiles.push({
            TLx: this.xToWorld(x),
            TLy: this.yToWorld(y),
            BRx: this.xToWorld(this.canvasWidth),
            BRy: this.yToWorld(this.canvasHeight),
          });
        } else if (
          y > this.canvasHeight - tileSizeY &&
          x < this.canvasWidth - tileSizeX
        ) {
          tiles.push({
            TLx: this.xToWorld(x),
            TLy: this.yToWorld(y),
            BRx: this.xToWorld(x + tileSizeX),
            BRy: this.yToWorld(this.canvasHeight),
          });
        } else if (
          y < this.canvasHeight - tileSizeY &&
          x > this.canvasWidth - tileSizeX
        ) {
          tiles.push({
            TLx: this.xToWorld(x),
            TLy: this.yToWorld(y),
            BRx: this.xToWorld(this.canvasWidth),
            BRy: this.yToWorld(y + tileSizeY),
          });
        } else {
          tiles.push({
            TLx: this.xToWorld(x),
            TLy: this.yToWorld(y),
            BRx: this.xToWorld(x + tileSizeX),
            BRy: this.yToWorld(y + tileSizeY),
          });
        }
      }
    }
    this.searchRadius = tileSizeX;
    return tiles;
  };

  setHeatmapWithGrid = () => {
    let tiles = this.makeTiles();
    let results = [];
    let gridMaxValue = 0;

    for (let tile of tiles) {
      let result = this.rtree
        .range(tile.TLx, tile.TLy, tile.BRx, tile.BRy)
        .map((id) => this.treePoints[id]);
      if (result.length > gridMaxValue) gridMaxValue = result.length;
      if (result.length > 0) {
        let avgX = 0;
        let avgY = 0;
        for (let point of result) {
          avgX += point[0];
          avgY += point[1];
        }
        avgX = parseInt(avgX / result.length);
        avgY = parseInt(avgY / result.length);
        let center = this.worldToCanvas(avgX, avgY);
        results.push({
          x: center.x,
          y: center.y,
          value: result.length,
          radius: this.threshold, // this.rendererRef.getScale();,
        });
      }
    }
    this.maxValue = gridMaxValue * parseInt(this.threshold / 12);
    this.rendererRef.setHeatmapData(results, gridMaxValue);
    window.forceSidebarUpdate();
  };

  setHeatmapWithoutGrid = (res) => {
    let data = res;
    let noGridMaxValue = 0;
    let searchRadius = this.threshold; /// this.rendererRef.getScale();
    this.searchRadius = parseInt(searchRadius);
    let results = [];
    for (let i = 0; i < data.length; i++) {
      const result = this.rtree
        .within(data[i][0], data[i][1], searchRadius)
        .map((id) => this.treePoints[id]);
      let center = this.worldToCanvas(data[i][0], data[i][1]);

      results.push({
        x: center.x,
        y: center.y,
        value: result.length,
        radius: this.threshold * this.rendererRef.getScale(),
      });
      if (result.length > noGridMaxValue) noGridMaxValue = result.length;
    }
    this.maxValue = noGridMaxValue;
    this.rendererRef.setHeatmapData(results, noGridMaxValue);
    window.forceSidebarUpdate();
  };

  checkVisibleObjects = () => {
    this.checkRTree();
    let TLx = this.xToWorld(0);
    let TLy = this.yToWorld(0);
    let BRx = this.xToWorld(this.canvasWidth);
    let BRy = this.yToWorld(this.canvasHeight);
    let result = this.rtree
      .range(TLx, TLy, BRx, BRy)
      .map((id) => this.treePoints[id]);
    return result;
  };

  drawCustomCursor(ctx, mousePosition) {
    this.mousePosition = mousePosition;
    if (ctx) {
      let result = this.checkVisibleObjects();
      let visiblePoints = 2500;
      if (result.length > 0 && result.length < visiblePoints) {
        this.activeGrid = 0;
        this.setHeatmapWithoutGrid(result);
      } else if (result.length > 0 && result.length > visiblePoints) {
        this.activeGrid = 1;
        this.setHeatmapWithGrid();
      } else if (result.length === 0) {
        this.rendererRef.setHeatmapData([], 100);
      }
    }
  }

  exit() {
    for (let s of this.visibilityState) {
      let i = this.structures.findIndex((item) => item.id === s.id);
      this.structures[i].visible = s.visible;
    }
    this.init = false;
    this.rois = [];
    this.prevSelectedLayer = 0;
  }

  renderConfiguration() {
    const pxlValue =
      this.activeGrid === 0
        ? this.searchRadius
        : this.worldDistance(this.threshold);
    return (
      <div style={{ margin: "12px" }}>
        {/* canvas for debugging */}
        {/* <canvas id="outputCanvas"></canvas> */}
        <Typography variant="h6">{this.toolName}:</Typography>
        <div
          style={{
            height: "20px",
            width: "50%",
            float: "right",
            textAlignLast: "end",
          }}
        >
          {this.maxValue}
        </div>
        <div
          style={{
            height: "20px",
            width: "50%",
          }}
        >
          1
        </div>
        <div
          style={{
            height: "20px",
            backgroundImage:
              "linear-gradient(to right,#0000ff, #00ff00, #ffff00, #ff0000)",
          }}
        ></div>
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          Objects within {pxlValue}px
        </div>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">
            {"Color Distribution Radius: " + pxlValue + "px"}
          </FormLabel>
          <Slider
            min={1}
            max={100}
            value={this.threshold}
            onChange={(e, v) => {
              this.threshold = v;
              window.forceSidebarUpdate();
            }}
            onChangeCommitted={() => {
              this.updateDrawing();
            }}
          />
        </FormControl>
      </div>
    );
  }
}

export default HeatmapTool;

import React, { Component } from "react";
import PropTypes from "prop-types";
import CheckIcon from "@mui/icons-material/Check";

import withStyles from "@mui/styles/withStyles";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import Backend from "../../common/utils/Backend";

const styles = () => ({
  root: {},
  dialogContent: {
    paddingTop: 0,
    maxWidth: 900,
    minWidth: 750,
  },
  tileImage: {},
  tileBar: {
    position: "absolute",
    left: "0",
    bottom: "0",
    width: "100%",
    height: "auto",
    padding: "14px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "1em",
    color: "white",
    background: "rgba(0,0,0,0.5)",
  },
  projectTile: {
    cursor: "pointer",
    "&:hover": {
      background: "#AAA",
    },
  },
  projectTileSelected: {
    background: "#1BA1E2!important",
  },
  projectTilesList: {
    maxHeight: "500px",
    padding: 3,
  },
});

class ImportProjectsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: props.formData
        ? props.formData
        : {
            filesToMap: [],
          },
      open: Boolean(props.open),
    };
  }

  /**
   * Open/Show ImportProjectsDialog.
   * Main way to open this dialog.
   * @param {JSON} formData File mappings, mapping previous to new files.
   */
  show(formData) {
    this.setState({ open: true, formData });
  }

  /**
   * Close ImportProjectsDialog. Resets state.
   */
  handleClose = () => {
    this.setState({
      open: false,
      formData: {
        filesToMap: [],
      },
    });
  };

  /**
   * Submit all updated filepaths to backend for project completion.
   */
  handleSubmit = () => {
    let { formData } = this.state;
    // Ensure all files with the same origin file get the updated new path
    formData.filesToMap
      // Find all duplicate items, except the first one, i.e. the ones that have not been updated.
      .filter(
        (mapping, index, array) =>
          array.findIndex(
            (el) => el.file.sourcePath === mapping.file.sourcePath
          ) !== index
      )
      // Equalize settings for all files with the same sourcePath
      .forEach((dupeObject) => {
        dupeObject.newPath = formData.filesToMap.find(
          (mapping) => mapping.file.sourcePath === dupeObject.file.sourcePath
        ).newPath;
      });
    this.setState({ formData });

    // No duplicate paths per file allowed
    if (
      formData.filesToMap
        // All files with potential duplicates, could be same file, different scene
        .filter(
          (mapping, index, array) =>
            array.findIndex(
              (el) =>
                el.newPath === mapping.newPath &&
                el.file.sourcePath !== mapping.file.sourcePath
            ) !== index
        )
        // Exclude same file, different scene from duplicates
        .some((dup) =>
          formData.filesToMap
            .filter(
              (el) =>
                el.newPath === dup.newPath &&
                el.file.sourcePath !== dup.file.sourcePath
            )
            .some((el) => el.file.scene === dup.file.scene)
        )
    ) {
      // Assign all duplicates as such
      formData.filesToMap.forEach((mapping) => {
        if (
          formData.filesToMap.some(
            (potDupe) =>
              potDupe.newPath === mapping.newPath &&
              potDupe.file.sourcePath !== mapping.file.sourcePath
          )
        ) {
          mapping.found = false;
        } else {
          // Note: potentially marks "not-found" items as found, as they are no duplicates
          mapping.found = true;
        }
      });

      window.showErrorSnackbar(
        "Please remove duplicate file assignments. Each filepath must be unique."
      );
      this.setState({ formData });
      return;
    }

    Backend.importProjectsFinalize(
      formData,
      // Success
      () => {
        this.handleClose();
      },
      // Error
      (err) => {
        console.warn(err);
        window.openWarningDialog(err);
      }
    );
  };

  render() {
    const { open, formData } = this.state;

    return (
      <Dialog onClose={this.handleClose} open={open} maxWidth="lg">
        <DialogTitle>Map Files of Imported Project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Import file doesn&apos;t contain image files. Please select the
            corresponding files from your hard drive.
          </DialogContentText>
          {formData.filesToMap
            // Only show unique source files, not one per scene of file.
            .filter(
              (mapping, index, array) =>
                array.findIndex(
                  (el) => el.file.sourcePath === mapping.file.sourcePath
                ) === index
            )
            .map((c) => (
              <div key={c.file.id} style={{ marginBottom: 10 }}>
                <TextField
                  value={c.file.sourcePath}
                  InputProps={{
                    endAdornment: c.found && (
                      <InputAdornment position="end">
                        <Tooltip
                          disableInteractive
                          title="File automatically found on your disk!"
                        >
                          <CheckIcon
                            style={{ color: "#0673C1" }}
                            color="inherit"
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  disabled
                  autoFocus
                  margin="dense"
                  label="Old Path"
                  fullWidth
                  variant="filled"
                />
                <TextField
                  value={c.newPath}
                  onChange={(e) => {
                    c.newPath = e.target.value;
                    this.forceUpdate();
                  }}
                  autoFocus
                  margin="dense"
                  label="New Path"
                  fullWidth
                  error={!c.found}
                />
              </div>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleSubmit} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ImportProjectsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  formData: PropTypes.object,
  open: PropTypes.bool,
};

export default withStyles(styles)(ImportProjectsDialog);

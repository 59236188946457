import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
// define the component's styling
const styles = () => ({
  root: {
    height: 300,
    width: 50,
    position: "absolute",
    bottom: 50,
    right: 5,
    opacity: 0.8,
  },
  verticalBar: {
    position: "absolute",
    top: -15,
    left: "50%",
    marginLeft: -2,
    width: 4,
    height: "100%",
    background: "#0673C1",
  },
  fab: {
    position: "absolute",
    marginTop: -15,
    marginLeft: -15,
    left: "50%",
    width: 30,
    height: 30,
    background: "white",
    color: "#0673C1",
    border: "2px solid #0673C1",
    borderRadius: "50%",
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: "25px",
    textAlign: "center",
    cursor: "pointer",
  },
});
const marks = [
  {
    value: 100,
    level: 40,
  },
  {
    value: 75,
    level: 20,
  },
  {
    value: 50,
    level: 10,
  },
  {
    value: 30,
    level: 4,
  },
  {
    value: 15,
    level: 2,
  },
  {
    value: 0,
    level: 1,
  },
];

class ZoomBar extends Component {
  isSelected = (idx) => {
    let zoom = this.props.zoom;
    let result = marks.length - 1;
    let max = 0;
    for (let i = 0; i < marks.length; i++) {
      let mark = marks[i];
      if (mark.level <= zoom && mark.level >= max) {
        max = mark.level;
        result = i;
      }
    }
    return result === idx;
  };

  render() {
    const { classes, zoom, clickable } = this.props;
    return (
      <React.Fragment>
        <div
          className={classes.root}
          style={{ pointerEvents: clickable ? "all" : "none", zIndex: 9999 }}
        >
          <div className={classes.verticalBar} />

          {marks.map((mark, idx) => {
            return (
              <div key={idx} className={classes.markButton}>
                <div
                  style={{
                    background: this.isSelected(idx) ? "#0673C1" : "white",
                    color: this.isSelected(idx) ? "white" : "#0673C1",
                    bottom: mark.value + "%",
                  }}
                  className={classes.fab}
                  onClick={() => this.props.changeValue(mark.level)}
                >
                  {(this.isSelected(idx) ? Math.round(zoom) : mark.level) + "x"}
                </div>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }
}

// define the component's interface
ZoomBar.propTypes = {
  classes: PropTypes.object.isRequired,
  zoom: PropTypes.number,
  clickable: PropTypes.bool,
  changeValue: PropTypes.func,
};

export default withStyles(styles)(ZoomBar);

import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Input,
  Button,
} from "@mui/material";

import { withSpectraViewer } from "../contexts/SpectraViewerContext";

const styles = () => ({
  root: {
    position: "relative",
    height: "100%",
    overflow: "auto",
  },
  paperContainer: {
    margin: 10,
  },
  table: {},
  applyButton: {
    margin: 10,
    width: "calc(100% - 20px)",
  },
});

class FileParamForm extends Component {
  /**
   * Updates the input fields of the file parameters.
   * @param {int} index Position of spectrum in array of spectra.
   * @param {string} paramName Key of value to edit.
   * @param {string} value New value.
   */
  onFileParamChange = (index, paramName, value) => {
    let fileParams = this.props.fileParams.map((fileParam, idx) => {
      if (idx === index) {
        fileParam = JSON.parse(JSON.stringify(fileParam));
        fileParam[paramName] = value
          .trim()
          .split(";")
          .map((e) => e.trim());
        return fileParam;
      } else {
        return fileParam;
      }
    });

    this.props.spectraViewer.setState({ useSavedCalcs: false });
    this.props.onUpdateFileParams(fileParams);
  };

  /**
   * Validate input data, save project and continue to main window.
   * @returns {} Stay in screen on unvalidated input data
   */
  onApply = () => {
    let error = false;
    let fileParams = this.props.fileParams.map((fp) => {
      // Clean up input
      if (typeof fp.subName === "string") {
        fp.subName = fp.subName
          .trim()
          .split(";")
          .map((e) => e.trim());
      }
      if (typeof fp.subName === "string") {
        fp.concentration = fp.concentration
          .trim()
          .split(";")
          .map((e) => e.trim());
      }

      // Remove empty entries
      fp.subName = fp.subName.filter((e) => e === 0 || e);
      fp.concentration = fp.concentration.filter((e) => e === 0 || e);

      // Each substance must have exactly one concentration
      if (fp.subName.length !== fp.concentration.length) {
        error = true;
        window.showErrorSnackbar(
          fp.fileName + ": Mismatch of substance and concentration count."
        );
        // Highlight errorneous entries
        fp.error = error;
        return fp;
      }

      // All spectra must have an assigend name
      if (!fp.subName || fp.subName.length === 0) {
        error = true;
        // Highlight errorneous entries
        fp.error = error;
        window.showErrorSnackbar(fp.fileName + ": missing substance name.");
        return fp;
      }

      // All spectra must have an assigend concentration for each substance
      let concentrationsOk = true;
      fp.concentration = fp.concentration.map((concentr) => {
        // Allow for 0.0 and 0,0 input values
        let tmp = isNaN(concentr)
          ? parseFloat(concentr.replace(",", ".").replace(" ", ""))
          : concentr;

        if (isNaN(tmp)) {
          concentrationsOk = false;
          window.showErrorSnackbar(
            `${fp.fileName}: Invalid input value, concentration "${concentr}" must be a number between 0 and 100.`
          );
          return concentr;
        }
        concentr = tmp;

        if (concentr < 0 || concentr > 100) {
          concentrationsOk = false;
          window.showErrorSnackbar(
            `${fp.fileName}: Concentration must be between 0 and 100 %, was ${concentr}.`
          );
          return concentr;
        }

        return concentr;
      });
      if (!concentrationsOk) {
        error = true;
        fp.error = error;
        return fp;
      }
      // Passed all tests
      fp.error = false;
      return fp;
    });

    this.props.onUpdateFileParams(fileParams);
    if (error) {
      return;
    }

    this.props.save();
    this.props.setInitialized(true);
  };

  render() {
    const { classes, fileParams } = this.props;
    return (
      <div className={classes.root}>
        <Paper className={classes.paperContainer}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>File</TableCell>
                  <TableCell>
                    Substance Name <br /> Multiple semicolon-separated entries
                    possible
                  </TableCell>
                  <TableCell>
                    Concentration (%)
                    <br /> Multiple semicolon-separated entries possible
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fileParams.map((fileParam, idx) => (
                  <TableRow key={idx}>
                    <TableCell component="th" scope="row">
                      {fileParam.fileName}
                    </TableCell>
                    <TableCell>
                      <Input
                        placeholder="Substance Name(s)"
                        style={{ width: "100%" }}
                        value={fileParam.subName.join("; ")}
                        onChange={(e) => {
                          this.onFileParamChange(
                            idx,
                            "subName",
                            e.target.value
                          );
                        }}
                        error={fileParam.error}
                      />
                    </TableCell>
                    <TableCell>
                      <Input
                        placeholder="Concentration(s) (%)"
                        style={{ width: "100%" }}
                        value={fileParam.concentration.join("; ")}
                        onChange={(e) => {
                          this.onFileParamChange(
                            idx,
                            "concentration",
                            e.target.value
                          );
                        }}
                        error={fileParam.error}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Button
              variant="contained"
              color="primary"
              className={classes.applyButton}
              onClick={() => this.onApply()}
            >
              Apply
            </Button>
          </TableContainer>
        </Paper>
      </div>
    );
  }
}

FileParamForm.propTypes = {
  classes: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  spectraViewer: PropTypes.object.isRequired,
  fileParams: PropTypes.array,
  onUpdateFileParams: PropTypes.func,
  setInitialized: PropTypes.func,
  save: PropTypes.func,
};

export default withSpectraViewer(withStyles(styles)(FileParamForm));

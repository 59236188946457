import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import CustomResultTable from "./CustomResultTable";

import { withSpectraViewer } from "../contexts/SpectraViewerContext";

const styles = {
  root: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
    backgroundColor: "#333333",
    color: "#ffffff",
  },
  mainContent: {
    overflow: "hidden",
    height: "100%",
  },
};

const headCells = [
  { id: "rmse", numeric: true, disablePadding: true, label: "RMSECV" },
  { id: "r2", numeric: true, disablePadding: false, label: "R²" },
  { id: "scores", numeric: true, disablePadding: false, label: "Scores" },
  { id: "areas", numeric: false, disablePadding: false, label: "Areas" },
  { id: "settings", numeric: false, disablePadding: false, label: "Settings" },
];

class SpectraBottomTableResults extends Component {
  render() {
    const { classes } = this.props;
    const rows = this.props.spectraViewer.pcas.map((result) => {
      return {
        r2: result.result.r2,
        rmse: result.result.rmse,
        scores: result.used_scores,
        areas: result.areas.toString(),
        settings: result.preprocessing.toString(),
      };
    });
    return (
      <div className={classes.root}>
        <div className={classes.mainContent}>
          <CustomResultTable
            headCells={headCells}
            rows={rows}
            tableHeight={this.props.tableHeight}
          />
        </div>
      </div>
    );
  }
}

SpectraBottomTableResults.propTypes = {
  classes: PropTypes.object.isRequired,
  spectraViewer: PropTypes.object.isRequired,
  tableHeight: PropTypes.number,
  setParentState: PropTypes.func,
};

export default withSpectraViewer(withStyles(styles)(SpectraBottomTableResults));

import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  Checkbox,
  IconButton,
  FormGroup,
} from "@mui/material";

import {
  Keyboard,
  Mouse,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material";

import { pointInside } from "../../utils/PolygonUtil";
import Tool from "./Tool";

import {
  getContainedRegionRois,
  getParentIndexLayer,
} from "../../utils/StructuresUtils";

class SelectionTool extends Tool {
  name = "Selection";
  selection = null;
  foundLayer = -1;
  classify = true;
  borderColor = ["#FFFFFF", "", ""];
  classificationId = [0, 0, 0];
  automaticFileChange = true;

  setLayer(obj) {
    this.ome = obj.ome;
    this.structures = obj.structures;
    this.structure = obj.structures[obj.selectedLayer];
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    this.selectedLayer = obj.selectedLayer;
    this.classifyFullSceneImage =
      obj.viewerConfig_project.projectProperties.ClassifySceneAsOneImage;
    this.project = obj.project;
    this.fileId = obj.fileId;
    if (this.borderColor[1] === "") {
      this.setCol();
    }
    if (this.classificationId[1] === 0) {
      this.setIds();
    }
    this.regionRois = getContainedRegionRois(
      this.structure,
      this.structures,
      this.roiLayers
    );
    this.parentIndex = getParentIndexLayer(this.structure, this.structures);
  }

  setCol = () => {
    let childs = this.findChilds(this.structures[this.selectedLayer]);

    if (childs.length > 1) {
      if (this.borderColor[1] === "") {
        this.borderColor[1] = childs[0].color;
      }

      if (this.borderColor[2] === "") {
        this.borderColor[2] = childs[1].color;
      }
    }
  };

  setIds = () => {
    let childs = this.findChilds(this.structures[this.selectedLayer]);

    if (childs.length > 1) {
      if (this.classificationId[1] === 0) {
        this.classificationId[1] = childs[0].id;
      }

      if (this.classificationId[2] === 0) {
        this.classificationId[2] = childs[1].id;
      }
    }
  };

  findChilds = (subType) => {
    return this.structures.filter(
      (element) =>
        element.subtypeLevel === subType.subtypeLevel + 1 &&
        element.parentId === subType.id &&
        element.classificationSubtype
    );
  };

  setBorderColor = (c) => {
    this.borderColor = c;
    window.forceSidebarUpdate();
  };

  setClassificationId = (c) => {
    this.classificationId = c;
    window.forceSidebarUpdate();
  };

  setClassification = (e) => {
    this.classify = e.target.checked ? true : false;
    window.forceSidebarUpdate();
  };

  setAutomaticFileChange = (e) => {
    this.automaticFileChange = e.target.checked ? true : false;
    window.forceSidebarUpdate();
  };

  setPreviewRect() {}

  findSmallestTreeItem(p) {
    let minArea = Number.MAX_SAFE_INTEGER;
    let resultItem;
    for (let i = 0; i < this.roiLayers.length; i++) {
      if (this.structures[i].visible) {
        let treeItems = this.roiLayers[i].tree.search({
          minX: p.x,
          minY: p.y,
          maxX: p.x,
          maxY: p.y,
        });
        for (let treeItem of treeItems) {
          let b = treeItem.roi.bounds;

          if (pointInside(p, treeItem.roi)) {
            let bArea = (b.right - b.left) * (b.bottom - b.top);
            if (bArea < minArea) {
              minArea = bArea;
              resultItem = treeItem;
              this.selectedLayer = i;
            }
          }
        }
      }
    }
    return resultItem;
  }

  changeItem = (e, fromButton, dir) => {
    if (this.selection) {
      let p = {
        x: 0,
        y: 0,
      };

      let direction = 0;
      if (fromButton) {
        if (dir === "next") {
          direction = 1;
        }
        if (dir === "before") {
          direction = 2;
        }
      } else {
        if (e.key === "ArrowRight") {
          direction = 1;
        }
        if (e.key === "ArrowLeft") {
          direction = 2;
        }
      }

      // if not classification structure
      if (!this.structures[this.selectedLayer].classificationSubtype) {
        let idx = this.roiLayers[this.selectedLayer].layer.regionRois.findIndex(
          (element) =>
            element.bounds.left === this.selection.roi.bounds.left &&
            element.bounds.top === this.selection.roi.bounds.top &&
            element.bounds.bottom === this.selection.roi.bounds.bottom &&
            element.bounds.right === this.selection.roi.bounds.right
        );

        if (
          typeof this.roiLayers[this.selectedLayer].layer.regionRois[idx] ===
          "undefined"
        )
          return;
        // get middlepoint of selected roi
        let boundsSelection =
          this.roiLayers[this.selectedLayer].layer.regionRois[idx].bounds;
        p = {
          x:
            boundsSelection.right -
            (boundsSelection.right - boundsSelection.left) * 0.5,
          y:
            boundsSelection.bottom -
            (boundsSelection.bottom - boundsSelection.top) * 0.5,
        };

        switch (direction) {
          case 1: //right
            if (this.roiLayers[this.selectedLayer].layer.regionRois[idx + 1]) {
              let bounds =
                this.roiLayers[this.selectedLayer].layer.regionRois[idx + 1]
                  .bounds;
              p.x = bounds.right - (bounds.right - bounds.left) * 0.5;
              p.y = bounds.bottom - (bounds.bottom - bounds.top) * 0.5;
            }
            break;
          case 2: // left
            if (this.roiLayers[this.selectedLayer].layer.regionRois[idx - 1]) {
              let bounds =
                this.roiLayers[this.selectedLayer].layer.regionRois[idx - 1]
                  .bounds;
              p.x = bounds.right - (bounds.right - bounds.left) * 0.5;
              p.y = bounds.bottom - (bounds.bottom - bounds.top) * 0.5;
            }
            break;
          default:
            break;
        }
      }

      this.selection = this.findSmallestTreeItem(p);

      if (this.selection !== null && this.selection) {
        window.moveToRect(this.selection.roi.bounds);
        let obj = {
          r: this.selection.roi,
          colors: "",
          classify: this.classify,
        };
        if (fromButton) {
          window.setNewSelRoi(obj);
        }

        return obj;
      }
    }
  };

  changeTile = (e) => {
    if (this.selection) {
      let p = {
        x:
          this.selection.roi.bounds.left +
          (this.selection.roi.bounds.right - this.selection.roi.bounds.left) /
            2,
        y:
          this.selection.roi.bounds.top +
          (this.selection.roi.bounds.bottom - this.selection.roi.bounds.top) /
            2,
      };

      // if no regions in this layer return
      if (this.roiLayers[this.selectedLayer].layer.regionRois.length === 0) {
        return;
      }

      // width ist width of roi in top left because top left is always a square
      let width =
        this.roiLayers[this.selectedLayer].layer.regionRois[0].bounds.right;

      switch (e.key) {
        case "ArrowRight":
          if (p.x + width <= this.ome.sizeX) {
            p.x = p.x + width;
          } else {
            p.x = this.ome.sizeX - 1;
          }
          e.preventDefault();
          break;
        case "ArrowLeft":
          if (p.x - width >= 0) {
            p.x = p.x - width;
          }
          e.preventDefault();
          break;
        case "ArrowUp":
          if (p.y - width >= 0) {
            p.y = p.y - width;
          }
          e.preventDefault();
          break;
        case "ArrowDown":
          if (p.y + width <= this.ome.sizeY) {
            p.y = p.y + width;
          } else {
            p.y = this.ome.sizeY - 1;
          }
          e.preventDefault();
          break;
        default:
          break;
      }

      this.selection = this.findSmallestTreeItem(p);

      if (this.selection !== null && this.selection) {
        window.moveToRect(this.selection.roi.bounds);
        let obj = {
          r: this.selection.roi,
          colors: "",
          classify: this.classify,
        };
        return obj;
      }
    }
  };

  deleteSelectedRoi = () => {
    let historyItem = [];
    let histId = this.structures[this.parentIndex].id;

    historyItem.push({ add: false, id: histId, roi: this.selection });
    this.roiLayers[this.parentIndex].tree.remove(this.selection);
    this.roiLayers[this.parentIndex].layer.regionRois = this.roiLayers[
      this.parentIndex
    ].tree
      .all()
      .map((treeItem) => treeItem.roi);
    this.selection = null;
    window.projectHistory.add(historyItem);
    window.forceSidebarUpdate();
  };

  onKeyDown(e, fromRenderer, tilesProject) {
    // delete item
    if (!tilesProject && e.key === "Delete") {
      this.deleteSelectedRoi();
    }

    if (!fromRenderer) {
      return;
    }
    if (!tilesProject) {
      return this.changeItem(e);
    } else {
      return this.changeTile(e);
    }
  }

  mouse(params) {
    let { event, p } = params;
    if (!p) {
      return;
    }

    this.curX = p.x;
    this.curY = p.y;

    let color = "";
    let id = 0;

    // mouse middle button
    if (event.button === 1) {
      return;
    }
    // left button
    if (event.button === 0) {
      color = this.borderColor[1];
      id = this.classificationId[1];
    }
    // right button
    if (event.button === 2) {
      color = this.borderColor[2];
      id = this.classificationId[2];
    }

    if (event.type === "mousedown") {
      this.selection = this.findSmallestTreeItem(p);
    }

    if (this.selection !== null && this.selection) {
      let obj = {
        r: this.selection.roi,
        colors: color,
        classify: this.classify,
        id: id,
        changeFile: this.classifyFullSceneImage,
        automaticFileChange: this.automaticFileChange,
      };
      return obj;
    }
  }

  /**
   * Draw a custom cursor
   */
  drawCustomCursor(ctx, mousePosition, fkt) {
    if (this.selection && !this.classifyFullSceneImage) {
      const dashLength = 15 / fkt;
      ctx.beginPath();
      ctx.setLineDash([dashLength, dashLength]);
      ctx.globalAlpha = 1.0;
      ctx.strokeStyle = "#FF0000";
      ctx.lineWidth = 2 / fkt;
      let b = this.selection.roi.bounds;
      // no custom cursor
      ctx.rect(b.left, b.top, b.right - b.left, b.bottom - b.top);
      ctx.stroke();
      ctx.closePath();
      ctx.setLineDash([]);
    }
  }

  exit() {}

  renderConfiguration() {
    return (
      <div>
        <Typography variant="h6">{this.name}:</Typography>
        <ConfigForm
          borderColor={this.borderColor}
          classificationId={this.classificationId}
          selection={this.selection}
          structures={this.structures}
          selectedLayer={this.selectedLayer}
          classifyFullSceneImage={this.classifyFullSceneImage}
          project={this.project}
          fileId={this.fileId}
          setBorderColor={this.setBorderColor}
          setClassificationId={this.setClassificationId}
          setClassification={this.setClassification}
          classify={this.classify}
          setAutomaticFileChange={this.setAutomaticFileChange}
          automaticFileChange={this.automaticFileChange}
          changeSelRoi={this.changeItem}
        />
      </div>
    );
  }
}

class ConfigForm extends Component {
  findChilds = (subType) => {
    return this.props.structures.filter(
      (element) =>
        element.subtypeLevel === subType.subtypeLevel + 1 &&
        element.parentId === subType.id &&
        element.classificationSubtype
    );
  };

  handleChangeClassification = (e) => {
    this.props.setClassification(e);
  };

  handleAutomaticFileChange = (e) => {
    this.props.setAutomaticFileChange(e);
  };

  getNextFileId = () => {
    const { project, fileId } = this.props;
    let inxCurrentFile = project.files.findIndex(
      (element) => element.id === fileId
    );

    if (project.files[inxCurrentFile + 1]) {
      return project.files[inxCurrentFile + 1].id;
    } else {
      // if last file
      window.showWarningSnackbar("Last file.");
      return null;
    }
  };

  getPrevFileId = () => {
    const { project, fileId } = this.props;
    let inxCurrentFile = project.files.findIndex(
      (element) => element.id === fileId
    );

    if (project.files[inxCurrentFile - 1]) {
      return project.files[inxCurrentFile - 1].id;
    } else {
      // if last file
      window.showWarningSnackbar("First file.");
      return null;
    }
  };

  getCurrentFileInx = () => {
    const { project, fileId } = this.props;
    return project.files.findIndex((element) => element.id === fileId) + 1;
  };

  render() {
    let childs = this.findChilds(
      this.props.structures[this.props.selectedLayer]
    );
    childs.unshift(this.props.structures[this.props.selectedLayer]);

    return (
      <div>
        <FormControl component="fieldset" fullWidth>
          <FormControlLabel
            label="Classify with mouse"
            style={{ marginLeft: "5px", marginTop: "10px" }}
            control={
              <Checkbox
                checked={this.props.classify}
                onChange={this.handleChangeClassification}
              />
            }
          />
          <List
            style={{
              marginBottom: "-20px",
            }}
          >
            <ListItem
              onContextMenu={(e) => {
                e.preventDefault();
              }}
            >
              <ListItemText primary="Subtype name" />
              <Tooltip
                disableInteractive
                title="Left (L) or right (R) click./nLeft or right click on item to set subtypes."
              >
                <Mouse style={{ marginRight: "50px" }} />
              </Tooltip>
              <Tooltip disableInteractive title="Shortcut number on keyboard">
                <Keyboard />
              </Tooltip>
            </ListItem>
          </List>
          <div
            style={{
              overflow: "auto",
            }}
          >
            <List>
              {childs.map((child, index) => (
                <ListItem
                  key={index}
                  onContextMenu={(e) => {
                    e.preventDefault();
                  }}
                  onMouseUp={(e) => {
                    if (
                      e.nativeEvent.which === 3 &&
                      this.props.borderColor[1] !== child.color &&
                      this.props.classificationId[1] !== child.id
                    ) {
                      // right click
                      this.props.borderColor[2] = child.color;
                      this.props.classificationId[2] = child.id;
                    } else if (
                      e.nativeEvent.which === 1 &&
                      this.props.borderColor[2] !== child.color &&
                      this.props.classificationId[2] !== child.id
                    ) {
                      // left click
                      this.props.borderColor[1] = child.color;
                      this.props.classificationId[1] = child.id;
                    }
                    this.props.setBorderColor(this.props.borderColor);
                    this.props.setClassificationId(this.props.classificationId);
                    e.preventDefault();
                    this.forceUpdate();
                  }}
                >
                  <ListItemText
                    style={{
                      color:
                        child.color === "rgba(0, 0, 0, 0.0)"
                          ? "#000000"
                          : child.color,
                    }}
                    primary={child.label}
                  />
                  {child.color === this.props.borderColor[1] &&
                    child.id === this.props.classificationId[1] &&
                    this.props.classify && (
                      <Typography style={{ marginRight: "62px" }}>L</Typography>
                    )}
                  {child.color === this.props.borderColor[2] &&
                    child.id === this.props.classificationId[2] &&
                    this.props.classify && (
                      <Typography style={{ marginRight: "62px" }}>R</Typography>
                    )}
                  <Typography style={{ marginRight: "8px" }}>
                    {index}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </FormControl>
        {!this.props.classifyFullSceneImage && (
          <div>
            <Typography style={{ marginTop: "30px" }}>
              Change selected item:
            </Typography>
            <FormGroup row>
              <Tooltip disableInteractive title="Go to previous item">
                <IconButton
                  onClick={() => {
                    this.props.changeSelRoi(null, true, "before");
                  }}
                  size="large"
                >
                  <NavigateBefore />
                </IconButton>
              </Tooltip>
              <Tooltip disableInteractive title="Go to next item">
                <IconButton
                  onClick={() => {
                    this.props.changeSelRoi(null, true, "next");
                  }}
                  size="large"
                >
                  <NavigateNext />
                </IconButton>
              </Tooltip>
            </FormGroup>
          </div>
        )}
        {this.props.classifyFullSceneImage && (
          <div>
            <FormControlLabel
              label="Automatic file change"
              style={{ marginLeft: "5px", marginTop: "10px" }}
              control={
                <Checkbox
                  checked={this.props.automaticFileChange}
                  onChange={this.handleAutomaticFileChange}
                />
              }
            />
            <Typography style={{ marginTop: "10px" }}>Change file:</Typography>
            <FormGroup row>
              <Tooltip disableInteractive title="Go to previous file">
                <IconButton
                  onClick={() => {
                    let fileId = this.getPrevFileId();
                    if (fileId !== null) {
                      window.onSelectFile(fileId);
                    }
                  }}
                  size="large"
                >
                  <NavigateBefore />
                </IconButton>
              </Tooltip>
              <Tooltip disableInteractive title="Go to next file">
                <IconButton
                  onClick={() => {
                    let fileId = this.getNextFileId();
                    if (fileId !== null) {
                      window.onSelectFile(fileId);
                    }
                  }}
                  size="large"
                >
                  <NavigateNext />
                </IconButton>
              </Tooltip>
              <Typography variant="h6" style={{ marginTop: "7px" }}>
                File {this.getCurrentFileInx()} /{" "}
                {this.props.project.files.length}
              </Typography>
            </FormGroup>
          </div>
        )}
      </div>
    );
  }
}

ConfigForm.propTypes = {
  structures: PropTypes.array,
  setClassification: PropTypes.func,
  setAutomaticFileChange: PropTypes.func,
  selectedLayer: PropTypes.number,
  classify: PropTypes.bool,
  automaticFileChange: PropTypes.bool,
  classifyFullSceneImage: PropTypes.bool,
  borderColor: PropTypes.array,
  classificationId: PropTypes.array,
  setBorderColor: PropTypes.func,
  setClassificationId: PropTypes.func,
  changeSelRoi: PropTypes.func,
  project: PropTypes.object,
  fileId: PropTypes.string,
};

export default SelectionTool;

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import withStyles from "@mui/styles/withStyles";
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  ImageListItem,
  ImageList,
  DialogActions,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import Backend from "../../common/utils/Backend";

const styles = () => ({
  dialogContent: {
    paddingTop: 0,
    maxWidth: 900,
    minWidth: 750,
  },
  tileBar: {
    position: "absolute",
    left: "0",
    bottom: "0",
    width: "100%",
    height: "auto",
    padding: "14px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "1em",
    color: "white",
    background: "rgba(0,0,0,0.5)",
  },
  projectTile: {
    cursor: "pointer",
    "&:hover": {
      background: "#AAA",
    },
  },
  projectTileSelected: {
    background: "#1BA1E2!important",
  },
  projectTilesList: {
    maxHeight: "500px",
    padding: 3,
  },
  deleteButton: {
    position: "absolute",
    bottom: 0,
    right: 0,
    zIndex: 10,
  },
  test: {
    background: "green",
  },
});

const ProjectModuleTiles = (props) => {
  const {
    classes,
    availableTypes,
    projectType,
    onProjectType,
    onProjectTypeChange,
  } = props;

  return (
    <DialogContent className={classes.dialogContent}>
      <ImageList
        rowHeight={215}
        className={classes.projectTilesList}
        spacing={8}
        cols={3}
      >
        {availableTypes.map((tile) => (
          <ImageListItem
            key={tile.name}
            onClick={() => onProjectType(tile.name)}
            className={classNames(
              classes.projectTile,
              projectType === tile.name && classes.projectTileSelected
            )}
          >
            {tile.isUserModule && (
              <IconButton
                className={classes.deleteButton}
                onClick={() => {
                  Backend.deleteProjectModuleType(tile.name, () => {
                    window.showSuccessSnackbar(
                      "Project Type " + tile.name + " sucessfully deleted!"
                    );
                    onProjectTypeChange();
                  });
                }}
                size="large"
              >
                <DeleteIcon color="error" fontSize="medium" />
              </IconButton>
            )}

            <img
              src={`data:image/jpeg;base64,${tile.image}`}
              alt={tile.name}
              className={classes.tileImage}
            />
            <div className={classes.tileBar}>{tile.label}</div>
          </ImageListItem>
        ))}
      </ImageList>
    </DialogContent>
  );
};

ProjectModuleTiles.propTypes = {
  classes: PropTypes.object.isRequired,
  availableTypes: PropTypes.array,
  projectType: PropTypes.string,
  onProjectType: PropTypes.func,
  onProjectTypeChange: PropTypes.func,
};
class ModuleSelectionDialog extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      submitted: false,
      projectTypes: [],
      selectedProjectType: null,
      projectType: "",
    };
    this.updateProjectTypes();
  }

  setMountedState = (stateObject, callback) => {
    if (this._isMounted) {
      this.setState(stateObject, callback);
    }
  };

  updateProjectTypes = () => {
    Backend.loadAvailableProjectTypes((projectTypes) => {
      // order project types by order attribute
      projectTypes = projectTypes.sort((a, b) => a.order - b.order);

      // write received info into state
      this.setMountedState({
        projectTypes: projectTypes,
      });
    });
  };

  componentDidUpdate(prevProps) {
    // on open dialog
    if (prevProps.open !== this.props.open && this.props.open) {
      this.updateProjectTypes();
    }
  }

  onProjectType = (e) => {
    if (this.props.project) {
      window.openWarningDialog(
        "Project already created and the Project Type can't be changed afterwards.\nPlease create a new Project instead."
      );
    } else {
      this.setMountedState({ projectType: e });
    }
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleOK = () => {
    let selectedProjectType = this.state.projectTypes.find(
      (item) => item.name === this.state.projectType
    );
    this.props.onSelectProject(selectedProjectType);
  };

  render() {
    const { classes, ...other } = this.props;
    const { projectType, projectTypes } = this.state;

    return (
      <Dialog onClose={this.handleClose} {...other} maxWidth="lg">
        <DialogTitle>Project Module</DialogTitle>
        <ProjectModuleTiles
          classes={classes}
          key="0"
          projectType={projectType}
          availableTypes={projectTypes}
          onProjectType={this.onProjectType}
          onProjectTypeChange={this.updateProjectTypes}
        />
        <DialogActions>
          <Button autoFocus onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleOK} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ModuleSelectionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onSelectProject: PropTypes.func,
  open: PropTypes.bool,
  project: PropTypes.object,
};

export default withStyles(styles)(ModuleSelectionDialog);

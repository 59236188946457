import React from "react";

import { RegionROI } from "../../utils/ROI";
import RBush from "rbush";

import { Tooltip, IconButton, Typography } from "@mui/material";

import Tool from "./Tool";

class GridTool extends Tool {
  name = "GridTool";

  setLayer(obj) {
    this.ome = obj.ome;
    this.structures = obj.structures;
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    this.selectedLayer = obj.selectedLayer;
    this.allRoiLayers = obj.allRoiLayers;
    this.project = obj.viewerConfig_project;
    this.projectNoViewConfig = obj.project;
  }

  findChilds = (subType) => {
    return this.structures.filter(
      (element) =>
        element.subtypeLevel === subType.subtypeLevel + 1 &&
        element.parentId === subType.id
    );
  };

  createGrid = (x, noGridForChilds, fromFileChange) => {
    // if Tobacco Analysis set BaseRoi over hole scene if not set yet
    if (
      this.projectNoViewConfig.type.includes("TobaccoAnalysis") ||
      this.projectNoViewConfig.type.includes("BrainIpsc")
    ) {
      this.selectedLayer = 0;
      if (this.roiLayers[this.selectedLayer].layer.regionRois.length !== 0) {
        return;
      }
    }
    // if fileChange and grid already exists in this file do not draw new grid
    if (
      fromFileChange &&
      this.roiLayers[this.selectedLayer].layer.regionRois.length !== 0
    ) {
      return;
    }
    // make all tiles
    let regions = [];
    let tileWidth = this.ome.sizeX / x;
    let tileHeight = this.ome.sizeY / x;

    // make tiles for each structure
    let lenChilds = this.findChilds(this.structures[this.selectedLayer]).length;
    if (noGridForChilds) {
      lenChilds = 0;
    }

    // check if new gridSize
    let tiles = this.roiLayers[this.selectedLayer].layer.regionRois.length;
    let numberTilesNewGrid = x * x;
    let newGridSize = tiles !== numberTilesNewGrid && tiles !== 0;

    for (
      let str = this.selectedLayer;
      str <= this.selectedLayer + lenChilds;
      str++
    ) {
      // reset structure if new grid with different grid size
      if (newGridSize) {
        this.resetStructure(str);
      }

      // delete all rois
      this.roiLayers[str].layer.regionRois = [];
      this.roiLayers[str].tree = new RBush();

      // make x*x tiles
      for (let i = 0; i < x * x; i++) {
        regions = [];
        // make four corners of tile
        let c_1 = [(i % x) * tileWidth, Math.floor(i / x) * tileHeight];
        regions.push(c_1);
        let c_2 = [((i % x) + 1) * tileWidth, Math.floor(i / x) * tileHeight];
        regions.push(c_2);
        let c_3 = [
          ((i % x) + 1) * tileWidth,
          (Math.floor(i / x) + 1) * tileHeight,
        ];
        regions.push(c_3);
        let c_4 = [(i % x) * tileWidth, (Math.floor(i / x) + 1) * tileHeight];
        regions.push(c_4);
        let roi = new RegionROI({ regions: [regions] });
        this.roiLayers[str].layer.regionRois.push(roi);
        this.roiLayers[str].tree.insert(roi.treeItem);
      }
    }
    window.setGridSize(x);
  };

  resetStructure = (str) => {
    // reset properties of structure
    this.structures[str].avgClassFrequency = 0;
    for (const key of Object.keys(this.structures[str].classFrequencies)) {
      this.structures[str].classFrequencies[key] = 0;
    }
  };

  create1mmGrid = () => {};

  exit() {}

  renderConfiguration = () => {
    return (
      <div>
        <Typography variant="h6">{this.name}:</Typography>
        <Typography variant="h6" style={{ fontSize: "18px" }}>
          Create grid:
        </Typography>

        {!this.projectNoViewConfig.type.includes("HistoClassification") &&
          !this.projectNoViewConfig.type.includes("HistoPointCounting") && (
            <div>
              <Tooltip disableInteractive title="2x2 grid">
                <IconButton
                  style={{ marginBottom: "20px" }}
                  onClick={() => this.createGrid(2, true)}
                  size="large"
                >
                  <div style={{ fontSize: 18, fontWeight: "bold" }}>2x2</div>
                </IconButton>
              </Tooltip>

              <Tooltip disableInteractive title="5x5 grid">
                <IconButton
                  style={{ marginBottom: "20px" }}
                  onClick={() => this.createGrid(5, true)}
                  size="large"
                >
                  <div style={{ fontSize: 18, fontWeight: "bold" }}>5x5</div>
                </IconButton>
              </Tooltip>

              <Tooltip disableInteractive title="20x20 grid">
                <IconButton
                  style={{ marginBottom: "20px" }}
                  onClick={() => this.createGrid(20, true)}
                  size="large"
                >
                  <div style={{ fontSize: 18, fontWeight: "bold" }}>20x20</div>
                </IconButton>
              </Tooltip>
            </div>
          )}

        {this.projectNoViewConfig.type.includes("HistoClassification") && (
          <Tooltip disableInteractive title="1x1 grid">
            <IconButton onClick={() => this.createGrid(1)} size="large">
              <div style={{ fontSize: 18, fontWeight: "bold" }}>(1x1)</div>
            </IconButton>
          </Tooltip>
        )}

        {this.projectNoViewConfig.type.includes("HistoClassification") && (
          <Tooltip disableInteractive title="2x2 grid">
            <IconButton onClick={() => this.createGrid(2)} size="large">
              <div style={{ fontSize: 18, fontWeight: "bold" }}>1:10 (2x2)</div>
            </IconButton>
          </Tooltip>
        )}

        {this.projectNoViewConfig.type.includes("HistoClassification") && (
          <Tooltip disableInteractive title="5x5 grid">
            <IconButton onClick={() => this.createGrid(5)} size="large">
              <div style={{ fontSize: 18, fontWeight: "bold" }}>1:4 (5x5)</div>
            </IconButton>
          </Tooltip>
        )}

        {this.projectNoViewConfig.type.includes("HistoPointCounting") && (
          <Tooltip disableInteractive title="20 x 20 grid">
            <IconButton
              style={{ marginBottom: "20px" }}
              onClick={() => this.createGrid(20, true)}
              size="large"
            >
              <div style={{ fontSize: 18, fontWeight: "bold" }}>20 x 20</div>
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  };
}

export default GridTool;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import { Fab, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Backend from "../common/utils/Backend";
import AddIcon from "@mui/icons-material/Add";

import CreateProjectDialog from "./dialogs/CreateProjectDialog";
import ProjectsTable from "./components/ProjectsTable";

import { authenticationService } from "../common/services";

/**
 * Adds leading zeros to a number
 * @param {Number} num Number
 * @param {Number} size length that the number should have
 * @returns {String} String of number with zero padding
 */
function pad(num, size) {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

/**
 * Generates a default name for new Projects like "Project 001"
 * @param {Object[]} data List of Projects
 * @returns {String} Smart Default name for a new project
 */
function incrementDefaultName(data) {
  let newDefaultName = "Project 001";
  let maxProjNum = 0;
  for (let proj of data) {
    if (proj.name.match("Project [0-9][0-9][0-9]")) {
      const projNum = parseInt(proj.name.replace("Project ", ""), 10);
      let newMaxProjNum = Math.max(maxProjNum, projNum);
      if (newMaxProjNum) maxProjNum = newMaxProjNum;
    }
  }
  newDefaultName = `Project ${pad(maxProjNum + 1, 3)}`;
  return newDefaultName;
}

const styles = (theme) => ({
  root: {},
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
});

class HomePage extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      editableProject: null,
      projects: [],
      open: false,
      newDefaultName: "Project 001",
      activeTab: 0,
      serverIsRunning: false,
    };
    this.refreshInterval = setInterval(() => this.refreshProjects(), 1000);

    Backend.getCurrentUser((user) => {
      if (user.fullName === null) {
        authenticationService.logout();
        window.location.reload(true); //clear cache
      }
    });
    this.checkPythonServer();
  }
  checkPythonServer = () => {
    Backend.isLocalServerReady((result) => {
      if (this.state.serverIsRunning !== result) {
        this.setMountedState({ serverIsRunning: result });
      }
      if (!result) {
        setTimeout(this.checkPythonServer, 2000);
      }
    });
  };

  setMountedState = (stateObject, callback) => {
    if (this._isMounted) {
      this.setState(stateObject, callback);
    }
  };

  handleClickOpen = () => {
    Backend.getLicensingInfo((license) => {
      if (license.licenseStatus === "VALID") {
        this.setMountedState({
          editableProject: null,
          open: true,
        });
      } else {
        authenticationService.logout();
        this.props.history.push("/licensing");
      }
    });
  };

  handleEditOpen = (project) => {
    this.setMountedState({
      editableProject: project,
      open: true,
    });
  };

  handleClose = () => {
    this.setMountedState({
      editableProject: null,
      open: false,
    });
    this.refreshProjects();
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.refreshInterval);
  }

  refreshProjects() {
    Backend.listProjects((data) => {
      let projectsChanged =
        JSON.stringify(this.state.projects) !== JSON.stringify(data);
      if (projectsChanged) {
        this.setMountedState({
          projects: data,
          newDefaultName: incrementDefaultName(data),
        });
      }
    });
  }
  handle_active_tab_change = (activeTabIndex) => {
    if (this.state.activeTab !== activeTabIndex) {
      this.setMountedState({ activeTab: activeTabIndex });
    }
  };

  render() {
    const { classes } = this.props;
    const { serverIsRunning } = this.state;
    return (
      <div className={classes.root}>
        <ProjectsTable
          onEditProject={this.handleEditOpen}
          projects={this.state.projects}
          activeTab={this.state.activeTab}
          handle_active_tab_change={this.handle_active_tab_change}
          serverIsRunning={serverIsRunning}
        />
        <Tooltip
          disableInteractive
          title={
            serverIsRunning ? "Add new project" : "Local API Server not ready!"
          }
        >
          <Fab
            className={classes.fab}
            color="primary"
            onClick={() => {
              if (serverIsRunning) {
                this.handleClickOpen();
              } else {
                window.showWarningSnackbar("Local API Server not ready!");
              }
            }}
          >
            {serverIsRunning ? (
              <AddIcon />
            ) : (
              <CircularProgress
                className={classes.serverSpinLoader}
                color="inherit"
              />
            )}
          </Fab>
        </Tooltip>
        <CreateProjectDialog
          project={this.state.editableProject}
          open={this.state.open}
          onClose={this.handleClose}
          newdefaultname={this.state.newDefaultName}
          handle_active_tab_change={this.handle_active_tab_change}
        />
      </div>
    );
  }
}

HomePage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
};

export default withRouter(withStyles(styles)(HomePage));

import React from "react";
import {
  updateDrawLayer,
  updateLayer,
  findSiblingRoiLayers,
  findSameLayer,
  findClickedRoi,
  getExterior,
  checkIfStructureHidden,
  distance,
} from "../../utils/PolygonUtil";
import { Typography } from "@mui/material";
import Tool from "./Tool";
import OverlapConfigForm from "./ConfigForms/OverlapConfigForm";

class RegionTool extends Tool {
  name = "Region";
  noConfig = false;
  flag = false;
  downScale = 2;
  points = [];
  removeOverlap = null;
  removeOverlapSame = null;
  tempRemoveOverlap = null;
  tempRemoveOverlapSame = null;
  useNodeDrawingMode = null;
  tempUseNodeDrawingMode = null;

  setLayer(obj) {
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    let layerResults = findSameLayer(obj.structures, obj.selectedLayer);
    this.selectedLayer = layerResults[0];
    this.parentLayer = layerResults[1];
    this.originalSelectedLayer = obj.selectedLayer;
    this.structures = obj.structures;
    this.drawLayer = obj.drawLayer;

    const project = obj.viewerConfig_project;
    if (this.removeOverlap === null) {
      this.removeOverlap = project.projectProperties["PreventOverlap"];
    }
    if (this.tempRemoveOverlap === null) {
      this.tempRemoveOverlap = project.projectProperties["PreventOverlap"];
    }
    if (this.removeOverlapSame === null) {
      this.removeOverlapSame = project.projectProperties["PreventOverlapSame"];
    }
    if (this.useNodeDrawingMode === null) {
      this.useNodeDrawingMode = project.projectProperties["NodeDrawingMode"]
        ? true
        : false;
    }

    // set removeOverlap
    // save current settings (in tempRemoveOverlap) if structure gets changed
    if (obj.selectedLayer === 0) {
      if (!this.noConfig) {
        this.noConfig = true;
        this.tempRemoveOverlap = this.removeOverlap;
        this.tempRemoveOverlapSame = this.removeOverlapSame;
        this.removeOverlap = false;
        this.removeOverlapSame = false;
        this.tempUseNodeDrawingMode = this.useNodeDrawingMode;
        this.useNodeDrawingMode = false;
        window.forceSidebarUpdate();
      }
    } else {
      if (this.noConfig) {
        this.noConfig = false;
        this.removeOverlap = this.tempRemoveOverlap;
        this.removeOverlapSame = this.tempRemoveOverlapSame;
        this.useNodeDrawingMode = this.tempUseNodeDrawingMode;
        window.forceSidebarUpdate();
      }
    }
  }

  setPreviewRect() {}

  updateDrawing(color, subtype, name, positionInRoiLayer, fullyLoaded) {
    let drawRegion = {
      regions: [],
      inverted: false,
    };
    if (this.drawLayer.regionRois.length > 0) {
      drawRegion.regions = this.drawLayer.regionRois;

      // soll der selected layer sein
      let overlapRoiLayers = [];
      if (this.removeOverlapSame) {
        overlapRoiLayers.push(this.roiLayers[this.selectedLayer]);
      }
      if (this.removeOverlap) {
        let siblingRoiLayers = findSiblingRoiLayers(
          this.structures,
          this.selectedLayer,
          this.roiLayers
        );
        siblingRoiLayers.map((layer) => overlapRoiLayers.push(layer));
      }

      updateLayer(
        this.layer,
        drawRegion,
        this.clear,
        color,
        subtype,
        name,
        this.roiLayers[this.selectedLayer].tree,
        positionInRoiLayer,
        fullyLoaded,
        false,
        this.roiLayers[this.parentLayer],
        this.structures[this.originalSelectedLayer].id,
        overlapRoiLayers,
        this.clickedOnRoi
      );
      this.drawLayer.regionRois = [];
    }
  }

  addPoint = (event, p) => {
    let eventPoint = { x: event.clientX, y: event.clientY };
    if (this.points.length < 3 || this.useNodeDrawingMode) {
      this.points.push([p.x, p.y]);
      this.lastEventPoint = eventPoint;
    } else {
      const dist = distance(this.lastEventPoint, eventPoint);
      if (dist < 10) {
        this.points[this.points.length - 1] = [p.x, p.y];
      } else {
        this.points.push([p.x, p.y]);
        this.lastEventPoint = eventPoint;
      }
    }
  };

  mouse(params) {
    let {
      event,
      p,
      color,
      subtype,
      name,
      positionInRoiLayer,
      fullyLoaded,
      parentIdx,
    } = params;
    let parentLayer = parentIdx;
    if (this.useNodeDrawingMode) {
      if (
        event.type === "mousedown" &&
        (event.button === 0 || event.button === 2)
      ) {
        if (this.flag) {
          if (
            (this.clear && event.button === 2) ||
            (!this.clear && event.button === 0)
          ) {
            this.addPointToDrawLayer(event, p, color, subtype);
          } else {
            this.applyDrawLayer(
              color,
              subtype,
              name,
              positionInRoiLayer,
              fullyLoaded,
              parentLayer
            );
          }
        } else {
          this.initDrawing(subtype, name, color, event, p);
        }
      }
      return;
    }
    if (
      event.type === "mousedown" &&
      (event.button === 0 || event.button === 2)
    ) {
      this.initDrawing(subtype, name, color, event, p);
      return;
    }

    if (event.type === "mouseup") {
      this.applyDrawLayer(
        color,
        subtype,
        name,
        positionInRoiLayer,
        fullyLoaded,
        parentLayer
      );
      return;
    }
    if (this.flag && event.buttons !== 1 && event.buttons !== 2) {
      this.flag = false;
      this.drawLayer.regionRois = [];
      // this.drawLayer.coordinates = [];
      return;
    }
    if (event.type === "mousemove") {
      if (this.flag) {
        this.addPointToDrawLayer(event, p, color, subtype);
      }
    }
  }

  initDrawing = (subtype, name, color, event, p) => {
    checkIfStructureHidden(
      this.structures,
      this.selectedLayer,
      subtype,
      name,
      color
    );
    this.drawLayer.regionRois = [];
    this.drawLayer.inverted = false;
    // right mouse button -> clear
    this.clear = event.button === 2;
    this.drawLayer.clear = this.clear;

    // update position history
    this.points = [];
    this.points.push([p.x, p.y]);

    // set drawing flag
    this.flag = true;

    // check if mouse down is inside region? => exapand region
    if (this.removeOverlapSame) {
      this.clickedOnRoi = findClickedRoi(
        p,
        this.selectedLayer,
        this.structures,
        this.roiLayers,
        this.includeBaseROI
      );
    }
  };

  addPointToDrawLayer = (event, p, color, subtype) => {
    this.addPoint(event, p);

    let drawRegion = {
      regions: [this.points],
      inverted: false,
    };
    drawRegion = getExterior(drawRegion);

    this.drawLayer.regionRois = []; //only one region will be drawn to the draw layer
    updateDrawLayer(this.drawLayer, drawRegion, false, color, subtype, name);
  };

  replaceLastPointInDrawLayer = (event, p, color, subtype) => {
    if (this.points.length === 1) {
      this.addPointToDrawLayer(event, p, color, subtype);
      return;
    }
    if (this.points.length > 1) {
      this.points.pop();
      this.addPointToDrawLayer(event, p, color, subtype);
    }
  };

  applyDrawLayer = (
    color,
    subtype,
    name,
    positionInRoiLayer,
    fullyLoaded,
    parentLayer
  ) => {
    // release drawing flag
    this.flag = false;
    this.points = [];
    this.updateDrawing(
      color,
      subtype,
      name,
      positionInRoiLayer,
      fullyLoaded,
      parentLayer
    );
  };

  drawCustomCursor(ctx, mousePosition, scale) {
    // no custom cursor
    if (ctx) {
      if (this.useNodeDrawingMode && this.points.length > 0) {
        const p1 = this.points[this.points.length - 1];
        const p2 = [mousePosition.x, mousePosition.y];
        const p3 = this.points[0];
        ctx.lineWidth = 2 / scale;
        ctx.beginPath();
        ctx.globalAlpha = 1.0;
        ctx.strokeStyle = "#000000";
        ctx.moveTo(p1[0], p1[1]);
        ctx.lineTo(p2[0], p2[1]);
        ctx.lineTo(p3[0], p3[1]);
        if (this.points.length == 2) {
          ctx.lineTo(p1[0], p1[1]);
        }
        ctx.stroke();
        ctx.closePath();
      }
    }
  }

  exit() {}

  renderConfiguration() {
    return (
      <div>
        <Typography variant="h6">{this.name}:</Typography>
        <OverlapConfigForm
          removeOverlap={this.removeOverlap}
          removeOverlapSame={this.removeOverlapSame}
          useNodeDrawingMode={this.useNodeDrawingMode}
          onChangeUseNodeDrawingMode={(e) => (this.useNodeDrawingMode = e)}
          onChangeRemoveOverlap={(e) => (this.removeOverlap = e)}
          onChangeRemoveOverlapSame={(e) => (this.removeOverlapSame = e)}
        />
      </div>
    );
  }
}

export default RegionTool;

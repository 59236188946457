import React, { Component } from "react";
import PropTypes from "prop-types";

import RBush from "rbush";
import { knn } from "../../utils/rbush-knn";
import Tool from "./Tool";

import {
  FormControl,
  MenuItem,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import SketchColorPicker from "../SketchColorPicker";

class PlotNearestRoiTool extends Tool {
  name = "Plot nearest objects";
  nearestLayer = null;
  colors = {
    main: "red",
    nearest: "green",
    connection: "blue",
    maxDistance: "red",
  };
  config = {
    mainRadius: 80,
    nearestRadius: 50,
    connectionLineWidth: 1,
    maxDistance: 1000,
    mainVisibility: true,
    nearestVisibility: true,
    connectionVisibility: true,
    maxDistanceVisibility: true,
    nearestLayerId: null,
  };

  setLayer(obj) {
    this.structures = obj.structures;
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    this.selectedLayer = obj.selectedLayer;
  }

  setPreviewRect() {}

  mouse(params) {
    let { event, p } = params;
    this.curX = p.x;
    this.curY = p.y;

    // mouse middle button
    if (event.button === 1) {
      return;
    }
  }

  drawDistanceCircles(ctx, regionRois, color, radius) {
    for (let regionRoi of regionRois) {
      let center = regionRoi.center;
      ctx.beginPath();
      ctx.globalAlpha = 1.0;
      ctx.strokeStyle = color;
      ctx.arc(center.x, center.y, radius, 0, 2 * Math.PI);
      ctx.stroke();
      ctx.closePath();
    }
  }

  drawLayerCircles(ctx, fkt, regionRois, color, radius) {
    for (let regionRoi of regionRois) {
      let center = regionRoi.center;
      ctx.beginPath();
      ctx.globalAlpha = 1.0;
      ctx.fillStyle = color;
      ctx.arc(center.x, center.y, radius, 0, 2 * Math.PI);
      ctx.fill();
      ctx.closePath();
    }
  }

  drawNearestConnections(ctx, regionRois, tree, color) {
    for (let regionRoi of regionRois) {
      let center = regionRoi.center;
      let nearestCenters = knn(
        tree,
        center.x,
        center.y,
        1,
        null,
        this.config.maxDistance
      ).map((item) => item.center);
      if (nearestCenters.length > 0) {
        let center2 = nearestCenters[0];
        ctx.beginPath();
        ctx.strokeStyle = color;
        ctx.moveTo(center.x, center.y);
        ctx.lineTo(center2.x, center2.y);
        ctx.stroke();
      }
    }
  }

  /**
   * Draw a custom cursor
   */
  drawCustomCursor(ctx, mousePosition, fkt) {
    if (this.config.maxDistanceVisibility) {
      this.drawDistanceCircles(
        ctx,
        this.roiLayers[this.selectedLayer].layer.regionRois,
        this.colors.maxDistance,
        this.config.maxDistance
      );
    }

    ctx.lineWidth = this.config.connectionLineWidth / fkt;
    if (
      this.roiLayers[this.nearestLayer] &&
      this.selectedLayer !== this.nearestLayer
    ) {
      if (this.config.connectionVisibility) {
        let selectedTree = new RBush();
        for (let regionRoi of this.roiLayers[this.selectedLayer].layer
          .regionRois) {
          selectedTree.insert(regionRoi.treeItem);
        }
        this.drawNearestConnections(
          ctx,
          this.roiLayers[this.nearestLayer].layer.regionRois,
          selectedTree,
          this.colors.connection,
          1
        );
      }

      if (this.config.nearestVisibility) {
        this.drawLayerCircles(
          ctx,
          fkt,
          this.roiLayers[this.nearestLayer].layer.regionRois,
          this.colors.nearest,
          this.config.nearestRadius
        );
      }
    }

    if (this.config.mainVisibility) {
      this.drawLayerCircles(
        ctx,
        fkt,
        this.roiLayers[this.selectedLayer].layer.regionRois,
        this.colors.main,
        this.config.mainRadius
      );
    }
  }

  setSelectedRoiLayer = (id) => {
    for (let i = 0; i < this.structures.length; i++) {
      if (this.structures[i].id === id) {
        this.nearestLayer = i;
      }
    }
  };

  onChangeColors = (colors) => {
    this.colors = colors;
    window.forceSidebarUpdate();
  };

  onChangeConfig = (config) => {
    this.config = config;
    window.forceSidebarUpdate();
  };

  exit() {}

  renderConfiguration = () => {
    return (
      <div>
        <Typography variant="h6">{this.name}:</Typography>
        <ConfigForm
          colors={this.colors}
          config={this.config}
          onChangeColors={(colors) => this.onChangeColors(colors)}
          onChangeConfig={(config) => this.onChangeConfig(config)}
          roiLayers={this.roiLayers}
          selectedLayer={this.selectedLayer}
          structures={this.structures}
          onChangeSelection={(id) => {
            this.setSelectedRoiLayer(id);
          }}
        />
      </div>
    );
  };
}

class ConfigForm extends Component {
  onChangeColor = (color, type) => {
    let colorObject = this.props.colors;
    colorObject[type] = color;
    this.props.onChangeColors(colorObject);
  };
  onChangeConfig = (value, type) => {
    let configObject = this.props.config;
    configObject[type] = value;
    this.props.onChangeConfig(configObject);
  };
  render() {
    const { structures, roiLayers, selectedLayer, config, colors } = this.props;
    const styles = {
      numberInput: {
        width: 60,
      },
    };

    const filteredStructures = structures.filter(
      (val, i) =>
        i !== selectedLayer && roiLayers[i].layer.regionRois.length > 0
    );
    if (config.nearestLayerId === null && filteredStructures.length > 0) {
      this.props.onChangeSelection(filteredStructures[0].id);
      this.onChangeConfig(filteredStructures[0].id, "nearestLayerId");
    }

    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Main
            </TableCell>
            <TableCell align="right">
              <TextField
                style={styles.numberInput}
                label="Radius"
                type="number"
                value={config.mainRadius === null ? "" : config.mainRadius}
                onChange={(e) => {
                  let value = Math.max(1, e.target.value);
                  this.onChangeConfig(value, "mainRadius");
                }}
              />
            </TableCell>
            <TableCell padding="checkbox" align="right">
              <Tooltip disableInteractive title="Hide connections">
                <IconButton
                  onClick={() => {
                    this.onChangeConfig(
                      !config.mainVisibility,
                      "mainVisibility"
                    );
                  }}
                  size="large"
                >
                  {config.mainVisibility ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell padding="checkbox" align="right">
              <SketchColorPicker
                color={colors.main}
                handleChange={(color) => {
                  this.onChangeColor(color, "main");
                  this.onChangeColor(color, "maxDistance");
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <FormControl fullWidth>
                <TextField
                  select
                  label="Nearest Structures"
                  value={config.nearestLayerId}
                  onChange={(e) => {
                    this.props.onChangeSelection(e.target.value);
                    this.onChangeConfig(e.target.value, "nearestLayerId");
                  }}
                >
                  {filteredStructures.map((structure, idx) => {
                    return (
                      <MenuItem key={idx} value={structure.id}>
                        {structure.label} {idx}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </FormControl>
            </TableCell>
            <TableCell align="right">
              <TextField
                style={styles.numberInput}
                label="Radius"
                type="number"
                value={config.nearestRadius}
                onChange={(e) => {
                  let value = Math.max(1, e.target.value);
                  this.onChangeConfig(value, "nearestRadius");
                }}
              />
            </TableCell>
            <TableCell padding="checkbox" align="right">
              <Tooltip disableInteractive title="Hide connections">
                <IconButton
                  onClick={() => {
                    this.onChangeConfig(
                      !config.nearestVisibility,
                      "nearestVisibility"
                    );
                  }}
                  size="large"
                >
                  {config.nearestVisibility ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell padding="checkbox" align="right">
              <SketchColorPicker
                color={colors.nearest}
                handleChange={(color) => this.onChangeColor(color, "nearest")}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Connection
            </TableCell>
            <TableCell align="right">
              <TextField
                style={styles.numberInput}
                label="thickness"
                type="number"
                value={config.connectionLineWidth}
                onChange={(e) => {
                  let value = Math.max(1, e.target.value);
                  this.onChangeConfig(value, "connectionLineWidth");
                }}
              />
            </TableCell>
            <TableCell padding="checkbox" align="right">
              <Tooltip disableInteractive title="Hide connections">
                <IconButton
                  onClick={() => {
                    this.onChangeConfig(
                      !config.connectionVisibility,
                      "connectionVisibility"
                    );
                  }}
                  size="large"
                >
                  {config.connectionVisibility ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell padding="checkbox" align="right">
              <SketchColorPicker
                color={colors.connection}
                handleChange={(color) =>
                  this.onChangeColor(color, "connection")
                }
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" scope="row">
              Maximum Distance
            </TableCell>
            <TableCell align="right">
              <TextField
                style={styles.numberInput}
                label="distance"
                type="number"
                value={config.maxDistance}
                onChange={(e) => {
                  let value = Math.max(1, e.target.value);
                  this.onChangeConfig(value, "maxDistance");
                }}
              />
            </TableCell>
            <TableCell padding="checkbox" align="right">
              <Tooltip disableInteractive title="Hide max distance circle">
                <IconButton
                  onClick={() => {
                    this.onChangeConfig(
                      !config.maxDistanceVisibility,
                      "maxDistanceVisibility"
                    );
                  }}
                  size="large"
                >
                  {config.maxDistanceVisibility ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell padding="checkbox" align="right">
              <SketchColorPicker
                color={colors.maxDistance}
                handleChange={(color) =>
                  this.onChangeColor(color, "maxDistance")
                }
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

ConfigForm.propTypes = {
  colors: PropTypes.object,
  onChangeColors: PropTypes.func,
  config: PropTypes.object,
  onChangeConfig: PropTypes.func,
  structures: PropTypes.array,
  roiLayers: PropTypes.array,
  selectedLayer: PropTypes.number,
  onChangeSelection: PropTypes.func,
};

export default PlotNearestRoiTool;

import React, { Component } from "react";

import PropTypes from "prop-types";
import { withTiles } from "../contexts/TilesContext";

class AccuracyGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    // draw graph with accuracy of single training iterations
    // draw horizontal lines with accuracy values (y-axis)
    const max = 255;
    let ctx = this.canvas.getContext("2d");
    let w = this.canvas.width;
    let h = this.canvas.height;
    ctx.clearRect(0, 0, w, h);
    ctx.beginPath();
    let step = (50 * w) / max;
    ctx.font = "10px Arial";
    ctx.fillStyle = "#ddd";
    step = h / 5;
    for (let s = step, i = 1; s < h; s += step, i++) {
      ctx.moveTo(0, s);
      ctx.lineTo(w, s);
      ctx.fillText(100 - i * 20, 2, s - 2);
    }
    ctx.strokeStyle = "#ddd";
    ctx.closePath();
    ctx.stroke();

    // draw graph with accuracy points from training iterations
    if (this.props.tiles.getGraphAcc()[0]) {
      let ctx = this.canvas.getContext("2d");

      let height = this.canvas.height;
      let y = this.props.tiles.getGraphAcc()[0];
      let factor = height / 100;

      ctx.beginPath();
      ctx.moveTo(0, parseInt(-y * factor + height, 10));

      let arr = [];
      if (this.props.tiles.getGraphAcc().length <= 10) {
        arr = this.props.tiles.getGraphAcc();
      } else {
        let start = this.props.tiles.getGraphAcc().length - 10;
        arr = this.props.tiles.getGraphAcc().slice(start - 1);
      }

      // only show last 10 iterations
      let x = this.canvas.width / 10;
      let cWdt = this.canvas.width;
      arr.forEach(function (point) {
        ctx.lineTo(x, parseInt(-point * factor + height, 10));
        x = x + cWdt / 10;
      });

      ctx.strokeStyle = "#FF0000";
      ctx.stroke();
    }
  }

  componentDidUpdate() {}

  UNSAFE_componentWillUpdate() {
    // draw graph with accuracy of single training iterations
    if (this.props.tiles.getGraphAcc()[0]) {
      // draw horizontal lines with accuracy values (y-axis)
      const max = 255;
      let ctx = this.canvas.getContext("2d");
      let w = this.canvas.width;
      let h = this.canvas.height;
      ctx.clearRect(0, 0, w, h);
      ctx.beginPath();
      let step = (50 * w) / max;
      ctx.font = "10px Arial";
      ctx.fillStyle = "#ddd";
      step = h / 5;
      for (let s = step, i = 1; s < h; s += step, i++) {
        ctx.moveTo(0, s);
        ctx.lineTo(w, s);
        ctx.fillText(100 - i * 20, 2, s - 2);
      }
      ctx.strokeStyle = "#ddd";
      ctx.closePath();
      ctx.stroke();

      // draw graph with accuracy points from training iterations
      let height = this.canvas.height;
      let y = this.props.tiles.getGraphAcc()[0];
      let factor = height / 100;

      ctx.beginPath();
      ctx.moveTo(0, parseInt(-y * factor + height, 10));

      let arr = [];
      if (this.props.tiles.getGraphAcc().length <= 10) {
        arr = this.props.tiles.getGraphAcc();
      } else {
        let start = this.props.tiles.getGraphAcc().length - 10;
        arr = this.props.tiles.getGraphAcc().slice(start - 1);
      }

      // only show last 10 iterations
      let x = this.canvas.width / 10;
      let cWdt = this.canvas.width;
      arr.forEach(function (point) {
        ctx.lineTo(x, parseInt(-point * factor + height, 10));
        x = x + cWdt / 10;
      });

      ctx.strokeStyle = "#FF0000";
      ctx.stroke();
    }
  }

  render() {
    return (
      <div ref={(c) => (this.root = c)}>
        <div style={{ marginLeft: "5px", marginTop: "10px" }}>
          Overall model progress:
        </div>
        <canvas
          style={{
            background: "#aaa",
            width: "290px",
            marginLeft: "5px",
            borderRadius: "5px",
          }}
          ref={(c) => (this.canvas = c)}
        />
      </div>
    );
  }
}

// define the component's interface
AccuracyGraph.propTypes = {
  ome: PropTypes.object,
  histogramConfig: PropTypes.object,
  visibleImage: PropTypes.array,
  coloredImages: PropTypes.array,
  bounds: PropTypes.object,
  // from withTiles
  tiles: PropTypes.object,
};

//export default withTheme(withStyles(styles)(AccuracyGraph));
export default withTiles(AccuracyGraph);

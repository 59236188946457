import { pointInside } from "../../utils/PolygonUtil";
import Tool from "./Tool";

class TilesClassificationTool extends Tool {
  name = "TilesClassificationTool";
  selection = null;

  setLayer(obj) {
    this.ome = obj.ome;
    this.structures = obj.structures;
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    this.selectedLayer = obj.selectedLayer;
  }

  setNextTile = (roiItem) => {
    if (roiItem) {
      this.selection = roiItem;
      window.moveToRect(this.selection.roi.bounds);
    }
  };

  findSmallestTreeItem(p) {
    let minArea = Number.MAX_SAFE_INTEGER;
    let resultItem;
    for (let i = 0; i < this.roiLayers.length; i++) {
      if (this.structures[i].visible) {
        let treeItems = this.roiLayers[i].tree.search({
          minX: p.x,
          minY: p.y,
          maxX: p.x,
          maxY: p.y,
        });
        for (let treeItem of treeItems) {
          let b = treeItem.roi.bounds;

          if (pointInside(p, treeItem.roi)) {
            let bArea = (b.right - b.left) * (b.bottom - b.top);
            if (bArea < minArea) {
              minArea = bArea;
              resultItem = treeItem;
              this.selectedLayer = i;
            }
          }
        }
      }
    }
    return resultItem;
  }

  changeItem = (e, fromButton, dir) => {
    if (this.selection) {
      let p = {
        x: 0,
        y: 0,
      };

      let direction = 0;
      if (fromButton) {
        if (dir === "next") {
          direction = 1;
        }
        if (dir === "before") {
          direction = 2;
        }
      } else {
        if (e.key === "ArrowRight") {
          //e.key not tested, because not in use
          direction = 1;
        }
        if (e.key === "ArrowLeft") {
          //e.key not tested, because not in use
          direction = 2;
        }
      }

      // if not classification structure
      if (!this.structures[this.selectedLayer].classificationSubtype) {
        let idx = this.roiLayers[this.selectedLayer].layer.regionRois.findIndex(
          (element) => element === this.selection.roi
        );

        // get middlepoint of selected roi
        let boundsSelection =
          this.roiLayers[this.selectedLayer].layer.regionRois[idx].bounds;
        p = {
          x:
            boundsSelection.right -
            (boundsSelection.right - boundsSelection.left) * 0.5,
          y:
            boundsSelection.bottom -
            (boundsSelection.bottom - boundsSelection.top) * 0.5,
        };

        switch (direction) {
          case 1: //right
            if (this.roiLayers[this.selectedLayer].layer.regionRois[idx + 1]) {
              let bounds =
                this.roiLayers[this.selectedLayer].layer.regionRois[idx + 1]
                  .bounds;
              p.x = bounds.right - (bounds.right - bounds.left) * 0.5;
              p.y = bounds.bottom - (bounds.bottom - bounds.top) * 0.5;
            }
            break;
          case 2: // left
            if (this.roiLayers[this.selectedLayer].layer.regionRois[idx - 1]) {
              let bounds =
                this.roiLayers[this.selectedLayer].layer.regionRois[idx - 1]
                  .bounds;
              p.x = bounds.right - (bounds.right - bounds.left) * 0.5;
              p.y = bounds.bottom - (bounds.bottom - bounds.top) * 0.5;
            }
            break;
          default:
            break;
        }
      }

      this.selection = this.findSmallestTreeItem(p);

      if (this.selection !== null && this.selection) {
        window.moveToRect(this.selection.roi.bounds);
      }
    }
  };

  changeTile = (e) => {
    if (this.selection) {
      let p = {
        x:
          this.selection.roi.bounds.left +
          (this.selection.roi.bounds.right - this.selection.roi.bounds.left) /
            2,
        y:
          this.selection.roi.bounds.top +
          (this.selection.roi.bounds.bottom - this.selection.roi.bounds.top) /
            2,
      };

      // if no regions in this layer return
      if (this.roiLayers[this.selectedLayer].layer.regionRois.length === 0) {
        return;
      }

      // width ist width of roi in top left because top left is always a square
      let width =
        this.roiLayers[this.selectedLayer].layer.regionRois[0].bounds.right;

      switch (e.key) {
        case "ArrowRight": //e.key not tested, because not in use
          if (p.x + width <= this.ome.sizeX) {
            p.x = p.x + width;
          } else {
            p.x = this.ome.sizeX - 1;
          }
          e.preventDefault();
          break;
        case "ArrowLeft": //e.key not tested, because not in use
          if (p.x - width >= 0) {
            p.x = p.x - width;
          }
          e.preventDefault();
          break;
        case "ArrowUp": //e.key not tested, because not in use
          if (p.y - width >= 0) {
            p.y = p.y - width;
          }
          e.preventDefault();
          break;
        case "ArrowDown": //e.key not tested, because not in use
          if (p.y + width <= this.ome.sizeY) {
            p.y = p.y + width;
          } else {
            p.y = this.ome.sizeY - 1;
          }
          e.preventDefault();
          break;
        default:
          break;
      }

      this.selection = this.findSmallestTreeItem(p);

      if (this.selection !== null && this.selection) {
        window.moveToRect(this.selection.roi.bounds);
      }
    }
  };

  /**
   * Draw a custom cursor
   */
  drawCustomCursor(ctx, mousePosition, fkt) {
    if (this.selection) {
      ctx.beginPath();
      ctx.globalAlpha = 1.0;
      ctx.strokeStyle = "#FF0000";
      ctx.lineWidth = 2 / fkt;
      let b = this.selection.roi.bounds;
      ctx.rect(b.left, b.top, b.right - b.left, b.bottom - b.top);
      ctx.stroke();
      ctx.closePath();
    }
  }

  exit() {}

  renderConfiguration() {
    return null;
  }
}

export default TilesClassificationTool;

import React, { Component } from "react";
import PropTypes from "prop-types";

import { FormControl, FormControlLabel, Checkbox } from "@mui/material";

class OverlapConfigForm extends Component {
  state = {
    removeOverlap: this.props.removeOverlap ? true : false,
    removeOverlapSame: this.props.removeOverlapSame ? true : false,
    useNodeDrawingMode: this.props.useNodeDrawingMode ? true : false,
  };

  render() {
    const {
      onChangeRemoveOverlap,
      onChangeRemoveOverlapSame,
      onChangeUseNodeDrawingMode,
    } = this.props;
    const { removeOverlap, removeOverlapSame, useNodeDrawingMode } = this.state;
    return (
      <div style={{ marginRight: "15px" }}>
        <FormControl component="fieldset" fullWidth>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={removeOverlapSame}
                  onChange={(e) => {
                    onChangeRemoveOverlapSame(e.target.checked);
                    this.setState({ removeOverlapSame: e.target.checked });
                  }}
                  value="remove overlaps of same structure"
                />
              }
              label="remove overlaps of same structure"
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={removeOverlap}
                  onChange={(e) => {
                    onChangeRemoveOverlap(e.target.checked);
                    this.setState({ removeOverlap: e.target.checked });
                  }}
                  value="remove overlaps of same level"
                />
              }
              label="remove overlaps of same visible level"
            />
          </div>
          {typeof this.props.useNodeDrawingMode !== "undefined" && (
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={useNodeDrawingMode}
                    onChange={(e) => {
                      onChangeUseNodeDrawingMode(e.target.checked);
                      this.setState({ useNodeDrawingMode: e.target.checked });
                    }}
                    value="Use Node drawing mode!"
                  />
                }
                label="Use Node drawing mode (apply with other mouse button)!"
              />
            </div>
          )}
        </FormControl>
      </div>
    );
  }
}

OverlapConfigForm.propTypes = {
  removeOverlap: PropTypes.bool,
  removeOverlapSame: PropTypes.bool,
  useNodeDrawingMode: PropTypes.bool,
  onChangeUseNodeDrawingMode: PropTypes.func,
  onChangeRemoveOverlap: PropTypes.func,
  onChangeRemoveOverlapSame: PropTypes.func,
};

export default OverlapConfigForm;

import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { unregister as unregisterServiceWorker } from "./registerServiceWorker";
import SpinloaderProvider from "./common/components/Spinloader";

import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import { SnackbarProvider } from "notistack";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
export const theme = createTheme({
  palette: {
    selected: {
      main: "rgba(0, 0, 0, 0.2)",
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#0673C1",
      inactive: "rgba(0, 0, 0, 0.54)",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.primary.main,
      main: "#182A42",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    default: {
      main: "#ffffff",
    },
    error: {
      main: "#d84646",
    },
    success: {
      main: "#4e9a51",
    },
    warning: {
      main: "#f68a1c",
    },
  },
  typography: {
    useNextVariants: true,
  },
});

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SpinloaderProvider>
          <SnackbarProvider maxSnack={3}>
            <App />
          </SnackbarProvider>
        </SpinloaderProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </BrowserRouter>,
  rootElement
);

unregisterServiceWorker();

import React, { Component } from "react";
import { PropTypes } from "prop-types";

import {
  ListItem,
  IconButton,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";

import { withSpectraViewer } from "../../contexts/SpectraViewerContext";

class AnalysisTab extends Component {
  graph_names = [`Original`, `Preprocessed`, `Approximated`, `Residual`];

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.showAnalysisResults();
  }

  /**
   * The button to display for each item, indicating its visibility.
   * @param {boolean} checked Whether the item is checked.
   * @param {Function} func The function to execute with func(name, checked) upon button press.
   * @param {string} name The name of the dataseries the visibility refers to.
   * @param {Object} iconOn The icon to diplay when series is visible.
   * @param {Object} iconOff The icon to diplay when series is hidden.
   * @returns
   */
  visbilityButton = (checked, func, name, iconOn, iconOff) => {
    return (
      <Tooltip
        disableInteractive
        title={checked ? `Hide ${name}` : `Show ${name}`}
      >
        <IconButton
          onClick={(e) => {
            func(name, checked);
            e.stopPropagation();
          }}
          size="large"
        >
          {checked ? iconOn : iconOff}
        </IconButton>
      </Tooltip>
    );
  };

  onApproxToggleVisibility = (name, checked) => {
    // Enables/Disables all series belonging to a single approximation
    this.props.datasets
      .filter((element) => this.graph_names.includes(element.name))
      .forEach((element) => {
        // On initial load, the spectra are set to undefined
        if (element.checked === checked || element.checked === undefined) {
          this.props.toggleLegend(element.name);
        }
      });
  };

  /**
   * Checks if a series calles by name is currently visible.
   * @param {string} name The name of the dataseries in the spectrum.
   * @returns {boolean} Is the series checked, i.e. currently visible?
   */
  checkForChecked = (name) => {
    let element = this.props.datasets.filter((element) =>
      element.name.startsWith(name)
    )[0];
    return element ? element.checked : false;
  };

  /**
   * Checks if any item of a group is checked.
   * @param {object} item Group of series referring to a single starting spectrum. Include original, preprocessed, approximation, residual.
   * @returns {boolean} Are any of the above checked?
   */
  checkForAnyChecked = (item) => {
    let checked = false;
    this.props.datasets
      .filter((element) => this.graph_names.includes(element.name))
      .forEach((element) => {
        if (element.checked) {
          checked = true;
        }
      });

    item.checked = checked;
    return checked;
  };

  render() {
    return this.props.spectraViewer.selectedPca !== null &&
      this.props.spectraViewer.focusedPca.info !== null ? (
      <div style={{ overflow: "auto" }}>
        {/* Spectrum Analysis Information */}
        {this.props.spectraViewer.selectedPca !== null &&
          this.props.spectraViewer.focusedPca.approximations.map(
            (approximation, idx) => {
              let orig = `Original`;
              let pre = `Preprocessed`;
              let approx = `Approximated`;
              let resid = `Residual`;
              return (
                <React.Fragment key={idx}>
                  <ListItem
                    button
                    key={555}
                    selected={
                      this.props.spectraViewer.selectedProcessedSpectrum === idx
                    }
                    style={{
                      paddingBottom: 0,
                      paddingTop: 0,
                    }}
                    onClick={() =>
                      this.props.spectraViewer.setState({
                        selectedProcessedSpectrum: idx,
                      })
                    }
                  >
                    <span style={{ width: "100%", minWidth: 0 }}>
                      <ListItemText
                        style={{
                          padding: "0px",
                        }}
                        primary={
                          <div
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {approximation.name}
                          </div>
                        }
                      />
                    </span>
                    {/* Full visibility toggle */}
                    {this.visbilityButton(
                      this.checkForAnyChecked(approximation),
                      this.onApproxToggleVisibility,
                      approximation.name,
                      <Visibility />,
                      <VisibilityOff />
                    )}
                    {/* Original Spectrum Visibility */}
                    {this.visbilityButton(
                      this.checkForChecked(orig),
                      this.props.toggleLegend,
                      orig,
                      <Visibility />,
                      <VisibilityOff />
                    )}
                    {/* Preprocessed Spectra Visibility */}
                    {this.visbilityButton(
                      this.checkForChecked(pre),
                      this.props.toggleLegend,
                      pre,
                      <Visibility />,
                      <VisibilityOff />
                    )}
                    {/* Approximated Spectra visibility */}
                    {this.visbilityButton(
                      this.checkForChecked(approx),
                      this.props.toggleLegend,
                      approx,
                      <Visibility />,
                      <VisibilityOff />
                    )}
                    {/* Residual Spectra visibilty */}
                    {this.visbilityButton(
                      this.checkForChecked(resid),
                      this.props.toggleLegend,
                      resid,
                      <Visibility />,
                      <VisibilityOff />
                    )}
                  </ListItem>
                </React.Fragment>
              );
            }
          )}
        {/* PCA Analysis Information */}
        {this.props.spectraViewer.focusedPca.scores.map((score, idx) => {
          return (
            <React.Fragment key={idx}>
              <ListItem
                button
                key={555}
                selected={this.props.spectraViewer.selectedPcaScore === idx}
                style={{
                  paddingBottom: 0,
                  paddingTop: 0,
                }}
                onClick={() =>
                  this.props.spectraViewer.setState({ selectedPcaScore: idx })
                }
              >
                <span style={{ width: "100%", minWidth: 0 }}>
                  <ListItemText
                    style={{
                      padding: "0px",
                    }}
                    primary={
                      <div
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {score.name}
                      </div>
                    }
                  />
                </span>
                {this.visbilityButton(
                  this.checkForChecked(score.name),
                  this.props.toggleLegend,
                  score.name,
                  <Visibility />,
                  <VisibilityOff />
                )}
              </ListItem>
            </React.Fragment>
          );
        })}
      </div>
    ) : (
      <div>
        <Typography variant="h5">Please select a PCA</Typography>
        <Typography variant="body1">
          Please select a PCA.Choose a PCA from the PCA-Tab and press the
          analyse button at the bottom of the tab. The spectrum selected in the
          spectra tab will then be analysed using the respective pca.
        </Typography>
      </div>
    );
  }
}

AnalysisTab.propTypes = {
  spectraViewer: PropTypes.object.isRequired,
  datasets: PropTypes.array,
  toggleLegend: PropTypes.func,
  showRawSpectra: PropTypes.func,
  showAnalysisResults: PropTypes.func,
};

export default withSpectraViewer(AnalysisTab);

import React, { Component } from "react";
import PropTypes from "prop-types";
import * as echarts from "echarts";

import withStyles from "@mui/styles/withStyles";

const styles = () => ({
  root: {
    position: "relative",
    height: "100%",
  },
  topContainer: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  toolBarBorder: {
    position: "absolute",
    top: 0,
    right: 40,
    height: "100%",
    width: 3,
    background: "#EBEBEB",
  },
});

class ELineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartRef: React.createRef(),
    };
  }

  updateDimensions = () => {
    if (this.props.chart) this.props.chart.resize();
  };

  componentDidMount = async () => {
    await this.initChart();
    switch (this.props.mainChartDataShown) {
      case "raw_spectra":
        this.props.showRawSpectra();
        break;

      case "analysis_results":
        this.props.showAnalysisResults();
        break;

      default:
        this.props.requestData("init");
    }
  };

  initChart = async () => {
    let chartDom = this.state.chartRef.current;
    //prevent context menu (right click) => so delete can be used
    chartDom.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });

    let myChart = echarts.init(chartDom, { renderer: "svg" });
    this.props.setChart(myChart);
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.topContainer} ref={this.state.chartRef} />
      </div>
    );
  }
}

ELineChart.propTypes = {
  classes: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  chart: PropTypes.object,
  setChart: PropTypes.func,
  onSave: PropTypes.func,
  requestData: PropTypes.func,
  showRawSpectra: PropTypes.func,
  showAnalysisResults: PropTypes.func,
  mainChartDataShown: PropTypes.string,
};

export default withStyles(styles)(ELineChart);

import React, { Component } from "react";
import PropTypes from "prop-types";

import { Paper, Grid } from "@mui/material";

import withStyles from "@mui/styles/withStyles";
import CasesSideBar from "./components/CasesSideBar";
import CasesTable from "./components/CasesTable";

import CasesViewer from "./components/CasesViewer";

const styles = {
  root: {
    background: "#EEEEEE",
    width: "100%",
    height: "calc(100% - 64px)",
    padding: 5,
  },
  casesContainer: {
    height: "100%",
  },
  paper: {
    padding: 0,
    display: "flex",
    justifyContent: "center",
    overflow: "auto",
    height: "100%",
  },
};

class CasesPage extends Component {
  state = {
    selectedCase: 0,
  };

  onChangeSelection = (id) => {
    if (id !== this.state.selectedCase) {
      this.setState({ selectedCase: id });
    }
  };

  render() {
    const { classes } = this.props;
    const { selectedCase } = this.state;
    return (
      <div className={classes.root}>
        <Grid className={classes.casesContainer} container spacing={1}>
          <Grid item xs={2}>
            <Paper className={classes.paper}>
              <CasesSideBar />
            </Paper>
          </Grid>
          <Grid item xs={5}>
            <Paper className={classes.paper}>
              <CasesTable
                selectedCase={selectedCase}
                onChangeSelection={this.onChangeSelection}
              />
            </Paper>
          </Grid>
          <Grid item xs={5}>
            <Paper className={classes.paper}>
              <CasesViewer selectedCase={selectedCase} />
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

CasesPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CasesPage);

import React, { Component } from "react";
import PropTypes from "prop-types";

import { Fab, Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import withStyles from "@mui/styles/withStyles";

import ResultChart from "./ResultChart";
import { withSpectraViewer } from "../contexts/SpectraViewerContext";

const styles = (theme) => ({
  root: {
    position: "relative",
    height: "100%",
    overflow: "hidden",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  excludeButton: {
    position: "absolute",
    top: 0,
    right: 0,
    color: "#666",
  },
});

const CHARTTYPES = [
  { value: "rmsecvScore", name: "RMSECV vs Score" },
  { value: "r2Score", name: "R² vs Score" },
  { value: "predTrue", name: "Prediction vs. True value" },
  { value: "diffTrue", name: "Difference vs. True value" },
];

class SpectraResultCharts extends Component {
  componentDidMount = () => {
    this.props.setCurrentlyShownData("result_chart");
    // Should none be present so far, preselect the first graph
    if (this.props.resultChartTypes.length === 0) {
      this.handleClickOpen();
    }
  };

  handleClickOpen = () => {
    let value = CHARTTYPES[this.props.resultChartTypes.length % 4].value;
    this.props.onUpdateResultChartTypes([
      ...this.props.resultChartTypes,
      value,
    ]);
  };

  onRemoveChart = (index) => {
    this.props.onUpdateResultChartTypes(
      this.props.resultChartTypes.filter((elem, idx) => idx !== index)
    );
  };

  render() {
    const { classes, resultChartTypes } = this.props;
    return (
      <div className={classes.root}>
        <Grid
          container
          style={{
            position: "relative",
            height: "100%",
            flexBasis: "100%",
            overflowY: "inherit",
            overflowX: "inherit",
          }}
        >
          {resultChartTypes.map((initChartType, index) => (
            <Grid
              item
              key={initChartType + index}
              xs={
                resultChartTypes.length === 4
                  ? 6
                  : resultChartTypes.length > 2
                  ? 4
                  : resultChartTypes.length > 1
                  ? 6
                  : 12
              }
              style={{
                border: resultChartTypes.length > 1 && "1px solid",
                position: "relative",
                background: "#EBEBEB",
                height:
                  resultChartTypes.length === 4
                    ? "50%"
                    : resultChartTypes.length > 6
                    ? "33.333333333%"
                    : resultChartTypes.length > 3
                    ? "50%"
                    : "100%",
              }}
            >
              <ResultChart
                initChartType={initChartType}
                chartTypes={CHARTTYPES}
                datasets={this.props.datasets}
              />
              <IconButton
                className={classes.excludeButton}
                size="small"
                onClick={() => this.onRemoveChart(index)}
              >
                <RemoveCircleIcon />
              </IconButton>
            </Grid>
          ))}
        </Grid>

        {resultChartTypes.length < 9 && (
          <Fab
            className={classes.fab}
            color="primary"
            aria-label="add"
            onClick={this.handleClickOpen}
          >
            <AddIcon />
          </Fab>
        )}
      </div>
    );
  }
}

SpectraResultCharts.propTypes = {
  classes: PropTypes.object.isRequired,
  spectraViewer: PropTypes.object.isRequired,
  resultChartTypes: PropTypes.array,
  datasets: PropTypes.array,
  onUpdateResultChartTypes: PropTypes.func,
  setCurrentlyShownData: PropTypes.func,
};

export default withSpectraViewer(withStyles(styles)(SpectraResultCharts));

import React, { Component } from "react";

import { FormLabel, FormControl, Button, Typography } from "@mui/material";
import Tool from "./Tool";

class ActiveLearningTool extends Tool {
  name = "Active Learning";

  setLayer(obj) {
    this.id = obj.fileId;
    this.layer = obj.layer;
    this.ome = obj.ome;
    this.projectId = obj.projectId;
    this.roiLayers = obj.roiLayers;
    this.structures = obj.structures;
    this.selectedLayer = obj.selectedLayer;

    if (obj.structures[obj.selectedLayer]) {
      this.toolLayerConfig = obj.structures[obj.selectedLayer].tools.find(
        (c) => c.name === this.toolConfig.name
      );
    }

    if (typeof this.toolLayerConfig !== "undefined")
      Object.assign(this.state, this.toolLayerConfig.parameters);
  }
  setPreviewRect() {}

  exit() {}

  renderConfiguration() {
    return (
      <div>
        <Typography variant="h6">{this.name}:</Typography>
        <ConfigForm
          ome={this.ome}
          toolConfig={this.toolConfig}
          state={this.state}
          onChange={this.onChange}
          structures={this.structures}
          selectedLayer={this.selectedLayer}
          onApply={(component) => {
            return this.state.preview
              ? this.calcPreview(component)
              : this.calcFull(component);
          }}
        />
      </div>
    );
  }
}

class ConfigForm extends Component {
  render() {
    return (
      <div>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">
            <Button
              style={{ marginTop: "5px" }}
              fullWidth
              variant="contained"
              color="primary" /*onClick={() => onApply(this)}*/
            >
              Start
            </Button>
          </FormLabel>
        </FormControl>
      </div>
    );
  }
}

export default ActiveLearningTool;

import { Component } from "react";
import PropTypes from "prop-types";

import { FormLabel, FormControl, Slider, Typography } from "@mui/material";
import {
  updateLayer,
  findSiblingRoiLayers,
  createRegionRoi,
} from "../../utils/PolygonUtil";
import Tool from "./Tool";
import React from "react";
import MagicWandToolLib from "magic-wand-tool";

let hatchOffset = 0;

class MagicWandTool extends Tool {
  name = "MagicWand";
  noConfig = false;
  flag = false;
  downScale = 2;
  removeOverlap = null;
  tempRemoveOverlap = null;
  threshold = 15; //default threshold value with best result
  shiftflag = false;
  points = [];
  drawRegion = {
    regions: [],
    inverted: false,
  };
  lastp = null;

  setLayer(obj) {
    this.ctx = obj.ctx; //context
    this.canvas = obj.ctx.canvas; //canvas
    this.layer = obj.layer; //layer
    this.roiLayers = obj.roiLayers;
    this.selectedLayer = obj.selectedLayer;
    this.structures = obj.structures;
    this.originalSelectedLayer = obj.selectedLayer;
    this.drawLayer = obj.drawLayer;

    const project = obj.viewerConfig_project;
    if (this.removeOverlap === null) {
      this.removeOverlap = project.projectProperties["PreventOverlap"];
    }
    if (this.tempRemoveOverlap === null) {
      this.tempRemoveOverlap = project.projectProperties["PreventOverlap"];
    }

    // set removeOverlap
    // save current settings (in tempRemoveOverlap) if structure gets changed
    if (obj.selectedLayer === 0) {
      if (!this.noConfig) {
        this.noConfig = true;
        this.tempRemoveOverlap = this.removeOverlap;
        this.removeOverlap = false;
        window.forceSidebarUpdate();
      }
    } else {
      if (this.noConfig) {
        this.noConfig = false;
        this.removeOverlap = this.tempRemoveOverlap;
        window.forceSidebarUpdate();
      }
    }
  }

  setPreviewRect() {}

  getScale = () => {
    return this.ctx ? this.ctx.getTransform().a : 1;
  };

  getPosition = () => {
    return {
      x: -this.ctx.getTransform().e,
      y: -this.ctx.getTransform().f,
    };
  };

  useMagicWand(p, color, subtype, name, positionInRoiLayer, fullyLoaded) {
    this.lastp = p;
    let position = {
      x: parseInt(p.x * this.getScale() - this.getPosition().x, 10),
      y: parseInt(p.y * this.getScale() - this.getPosition().y, 10),
    };

    let ctx = this.canvas.getContext("2d");
    //let points = [];
    //this.startPoint = position;
    let imageData = ctx.getImageData(
      0,
      0,
      this.canvas.width,
      this.canvas.height
    );

    // let len = imageData.data.length;
    // for (j = 0; j < len; j++){
    //   imageData.data[j] = 255 - imageData.data[j];
    // }

    let image = {
      data: imageData.data,
      width: this.canvas.width,
      height: this.canvas.height,
      bytes: 4,
    };

    let wandMask = MagicWandToolLib.floodFill(
      image,
      position.x,
      position.y,
      this.threshold,
      null,
      true
    );

    let len = wandMask.data.length;
    for (let j = 0; j < len; j++) {
      if (wandMask.data[j] === 1) {
        wandMask.data[j] = 0;
      } else {
        wandMask.data[j] = 1;
      }
    }

    if (wandMask) {
      wandMask = MagicWandToolLib.gaussBlurOnlyBorder(wandMask, 2, null);
    }

    //let nonzeromask = wandMask.data.filter(number=>number>0);
    //console.log("nonzeromask:",nonzeromask.length);
    //console.log(this.wandMask.data);

    //wandMask = MagicWandToolLib.gaussBlurOnlyBorder(wandMask, 5, null);
    let cacheInd = MagicWandToolLib.getBorderIndices(wandMask);
    //console.log(cacheInd.length);
    //this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    let w = this.canvas.width;
    let h = this.canvas.height;
    let x,
      j,
      i = null;
    let y = null;
    let hatchLength = 4;
    hatchOffset = (hatchOffset + 1) % (hatchLength * 2);

    ctx.clearRect(0, 0, w, h);

    len = cacheInd.length;
    for (j = 0; j < len; j++) {
      i = cacheInd[j];
      x = i % w; // calc x by index
      y = (i - x) / w; // calc y by index
      //let k = (y * w + x) * 4;
      if ((x + y + hatchOffset) % (hatchLength * 2) < hatchLength) {
        // detect hatch color
        this.points.push([
          (x + this.getPosition().x) / this.getScale(),
          (y + this.getPosition().y) / this.getScale(),
        ]);
      } else {
        this.points.push([
          (x + this.getPosition().x) / this.getScale(),
          (y + this.getPosition().y) / this.getScale(),
        ]);
      }
    }

    this.points = this.trace(wandMask);
    if (this.points.length > 2) {
      let drawRegion = {
        regions: [
          createRegionRoi([this.points], 0, null, null, null, 0, false),
        ],
        inverted: false,
      };
      updateLayer(
        this.layer,
        drawRegion,
        this.clear,
        color,
        subtype,
        name,
        this.roiLayers[this.selectedLayer].tree,
        positionInRoiLayer,
        fullyLoaded,
        false,
        this.roiLayers[this.parentLayer],
        this.structures[this.originalSelectedLayer].id,
        false,
        this.clickedOnRoi,
        this.activeTab === 1 // defines if it should be treated as an object
      );
    }
  }

  trace(wandMask) {
    let simplifyTolerant = 0;
    let simplifyCount = 50;
    let cs = MagicWandToolLib.traceContours(wandMask);
    cs = MagicWandToolLib.simplifyContours(cs, simplifyTolerant, simplifyCount);

    wandMask = null;

    //draw contours
    this.points = [];
    for (var i = 0; i < cs.length; i++) {
      if (!cs[i].inner) continue;
      var ps = cs[i].points;
      this.points.push([
        (ps[0].x + this.getPosition().x) / this.getScale(),
        (ps[0].y + this.getPosition().y) / this.getScale(),
      ]);
      for (var j = 1; j < ps.length; j++) {
        this.points.push([
          (ps[j].x + this.getPosition().x) / this.getScale(),
          (ps[j].y + this.getPosition().y) / this.getScale(),
        ]);
      }
    }
    return this.points;
  }

  mouseWheelEvent = (e) => {
    if (e.shiftKey) {
      if (e.deltaY > 0 && this.threshold > 0) {
        this.threshold--;
        updateLayer(this.layer, this.drawRegion, true, 0);
        this.useMagicWand(this.lastp);
        window.forceSidebarUpdate();
      } else if (e.deltaY < 0 && this.threshold < 250) {
        this.threshold++;
        updateLayer(this.layer, this.drawRegion, true, 0);
        this.useMagicWand(this.lastp);
        window.forceSidebarUpdate();
      }
    }
  };

  mouse(params) {
    let {
      event,
      p,
      color,
      subtype,
      name,
      positionInRoiLayer,
      fullyLoaded,
      parentIdx,
    } = params;
    let parentLayer = parentIdx;
    if (
      event.type === "mousedown" &&
      (event.button === 0 || event.button === 2)
    ) {
      this.drawLayer.regionRois = [];
      this.drawLayer.inverted = false;
      // right mouse button -> clear
      this.clear = event.button === 2;
      this.drawLayer.clear = this.clear;

      // update position history
      this.startPoint = p;
      this.points = [];
      this.points.push([p.x, p.y]);

      // set drawing flag
      this.flag = true;
    } else if (
      event.type === "mouseup" ||
      (this.flag && event.type === "mouseleave")
    ) {
      this.useMagicWand(
        p,
        color,
        subtype,
        name,
        positionInRoiLayer,
        fullyLoaded
      ); //func goes here

      // release drawing flag
      this.flag = false;

      if (this.drawLayer.regionRois.length > 0) {
        //if not empty update drawing
        this.drawRegion.regions = [this.drawLayer.regionRois[0]];
        //updateLayer(this.layer, drawRegion, this.clear);
        let overlapRoiLayers = this.removeOverlap
          ? findSiblingRoiLayers(
              this.structures,
              this.selectedLayer,
              this.roiLayers
            )
          : [];

        updateLayer(
          this.layer,
          this.drawRegion,
          this.clear,
          color,
          subtype,
          name,
          this.roiLayers[this.selectedLayer].tree,
          positionInRoiLayer,
          fullyLoaded,
          false,
          this.roiLayers[parentLayer],
          this.structures[this.selectedLayer].id,
          overlapRoiLayers
        );
        this.drawLayer.regionRois = [];
      }
    } else if (event.type === "mousemove") {
      // if (this.flag) {
      //   let points = [];
      //   points.push([this.startPoint.x, this.startPoint.y]);
      //   points.push([p.x, this.startPoint.y]);
      //   points.push([p.x, p.y]);
      //   points.push([this.startPoint.x, p.y]);
      //   console.log(typeof layer)
      //   let drawRegion = {
      //     regions: [createRegionRoi([points], 0, null, null, null, 0, false)],
      //     inverted: false,
      //   };
      //   this.drawLayer.regionRois = []; //only one region will be drawn to the draw layer
      //   updateLayer(this.drawLayer, drawRegion, false, color, subtype, name);
      // }
      //do nothing
    }
  }

  drawCustomCursor() {
    // no custom cursor
    if (this.ctx) {
      //this.ctx.fillStyle = "red";
      //this.ctx.fillRect(10, 10, 50, 50);
      //let imgData = this.ctx.getImageData(10, 10, 50, 50);
      //this.ctx.putImageData(imgData, 10, 70,0,0,5000,5000);
      //this.useMagicWand(ctx, mousePosition);
      //this.ctx.clearRect(5000, 5000, this.canvas.width, this.canvas.height);
    }
  }

  exit() {}

  renderConfiguration() {
    return (
      <div>
        <Typography variant="h6">{this.name}:</Typography>
        <ConfigForm
          threshold={this.threshold}
          onChangeThreshold={(e) => {
            this.threshold = e;
            window.forceSidebarUpdate();
          }}
        />
      </div>
    );
  }
}

class ConfigForm extends Component {
  render() {
    let { onChangeThreshold, threshold } = this.props;
    return (
      <div style={{ marginRight: "15px" }}>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">{"Threshold = " + threshold}</FormLabel>
          <Slider
            min={0}
            max={255}
            value={threshold}
            onChange={(e, v) => {
              onChangeThreshold(v);
            }}
          />
          <FormLabel component="legend">
            {"use shift + mouse wheel for region growth and decrease"}
          </FormLabel>
        </FormControl>
      </div>
    );
  }
}

ConfigForm.propTypes = {
  onChangeThreshold: PropTypes.func,
  threshold: PropTypes.number,
};

export default MagicWandTool;

import React, { Component } from "react";
import PropTypes from "prop-types";

const SpectraViewerContext = React.createContext();

/**
 * Allow other classes to use the SpectraViewer context with withSpectraViewer().
 * @param {*} Component The class using the context.
 * @returns The Component wrapped inside the SpectraViewer.
 */
export const withSpectraViewer = (Component) => {
  const WrappedComponent = (props) => (
    <SpectraViewerContext.Consumer>
      {(context) => <Component {...props} spectraViewer={context} />}
    </SpectraViewerContext.Consumer>
  );

  WrappedComponent.displayName = `withSpectraViewer(${
    Component.displayName || Component.name || "Component"
  })`;

  return WrappedComponent;
};

class SpectraViewerProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSpectrum: 0,
      areas: [],
      operationSets: [[["scores", 1]]],
      maxScores: 0,
      useSavedCalcs: true,
      rawSpectra: [], // Permanently save spectra in frontend once loaded
      rawSpectraLoaded: false,
      pcas: [], // Permanently save pcas in frontend once loaded, only cahnge on update
      pcaOrder: "asc", // The order in which the PCAs are sorted
      selectedPca: null,
      selectedPcaId: null,
      focusedPca: {
        info: null,
        scores: null,
        approximations: null,
      }, // The pca which is currently being focused in analysis. Includes spectra both before and after trimming and preprocessing.
      analysisResults: [], // Permanently save results of analysis with focussed pca, only change on update
      analysisResultsLoaded: false,
      selectedPcaScore: 0,
      selectedProcessedSpectrum: 0,
      currentlyShownData: null,
      ratedSpectra: [],
      models: [], // AI Models provided
      selectedModelIdx: null,
      selectedModelName: null,
      passingCriteria: [],
      passingCriteria_idx: null,
      passingCriteria_min: 0.99,
      passingCriteria_max: 1.0,
      spectraPredictionSettings: [], // Format: {substance: "", concentration: 0.0}
    };
  }

  /**
   * Allows to set state of context from outside the class
   * @param {*} object Stateobject to be set.
   * @param {function} callback Function to be called after state has been updated.
   */
  setContextState = (object, callback) => {
    this.setState(object, callback);
  };

  /**
   * Saves all areas to state. Also assigns each sub-area a reference.
   * @param {array} areas
   */
  updateAreas = (areas) => {
    this.setState({
      areas: areas.map((area) => {
        area.refs = [React.createRef(), React.createRef()];
        return area;
      }),
    });
  };

  /**
   * Toggles the status of useSavedCalcs on or off.
   */
  toggleSavedCalcs = () => {
    this.setState({
      useSavedCalcs: !this.state.useSavedCalcs,
    });
  };

  /**
   * Ensure that not too many scores can be chosen.
   * Should no spectra be visible, all are used for pca.
   */
  setMaxScores = () => {
    let availableSamples = 0;
    availableSamples = this.state.rawSpectra.filter((el) => el.checked).length;
    this.setState({
      maxScores:
        availableSamples === 0
          ? this.state.rawSpectra.length - 1
          : availableSamples - 1,
    });
  };

  /**
   * Sort the pcas by a value from the result in ascending or descending order
   * @param {string} valueToSortBy The key used to sort the pcas
   * @param {string} order  "asc" or "desc" for ascending or descending order respectively
   */
  sortPCAs = (valueToSortBy, order = "desc") => {
    // Sort PCAs in descending order by R² value
    let temp_pcas = this.state.pcas.sort((a, b) => {
      return b.result[valueToSortBy] - a.result[valueToSortBy];
    });
    // Invert array if decending order is wanted
    if (order === "asc") {
      temp_pcas.reverse();
    }
    // replace with sorted PCAs
    this.setState({ pcas: temp_pcas, pcaOrder: order });
  };

  render() {
    return (
      <SpectraViewerContext.Provider
        value={{
          ...this.state,
          setState: this.setContextState,
          updateAreas: this.updateAreas,
          setMaxScores: this.setMaxScores,
          toggleSavedCalcs: this.toggleSavedCalcs,
          sortPCAs: this.sortPCAs,
        }}
      >
        {this.props.children}
      </SpectraViewerContext.Provider>
    );
  }
}

SpectraViewerProvider.propTypes = {
  children: PropTypes.element,
};

export default SpectraViewerProvider;

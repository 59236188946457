import { findClickedRoi, pointInsideRegion } from "../../utils/PolygonUtil";
import { RegionROI } from "../../utils/ROI";
import Tool from "./Tool";

class FillTool extends Tool {
  name = "Fill";
  noConfig = true;
  includeBaseROI = false;

  setLayer(obj) {
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    this.selectedLayer = obj.selectedLayer;
    this.structures = obj.structures;
  }

  setPreviewRect() {}

  fillHole(p) {
    let historyItem = [];
    let histId = this.structures[this.selectedLayer].id;

    let tree = this.roiLayers[this.selectedLayer].tree;
    let layer = this.roiLayers[this.selectedLayer].layer;

    let regionRois = tree
      .search({
        minX: p.x,
        minY: p.y,
        maxX: p.x,
        maxY: p.y,
      })
      .sort((a, b) => a.roi.area - b.roi.area); //sorted rois, that are intersecting with clicked point

    for (let idx = 0; idx < regionRois.length; idx++) {
      let mainRoi = regionRois[idx].roi;
      if (mainRoi.regions.length > 1) {
        let mainRoiCopy = new RegionROI(mainRoi);
        mainRoiCopy.regions = [...mainRoi.regions];
        historyItem.push({ add: false, id: histId, roi: mainRoi.copy() });

        // if has holes
        for (let i = 1; i < mainRoi.regions.length; i++) {
          let hole = mainRoi.regions[i];
          let tempHoleRoi = new RegionROI({ regions: [hole] });
          if (pointInsideRegion(p, hole)) {
            //roi has clicked hole
            mainRoi.regions.splice(i, 1); //delete hole of affected polygon
            mainRoi.intRegions = undefined;
            let overlappingRegionRoiTreeItems = tree
              .search(tempHoleRoi.treeItem)
              .filter((treeItem) => {
                let p = {
                  x: treeItem.roi.regions[0][0][0],
                  y: treeItem.roi.regions[0][0][1],
                };
                return pointInsideRegion(p, hole);
              });
            let possibleRoiCandidates = [];
            if (overlappingRegionRoiTreeItems.length > 0) {
              for (let overlapTreeItem of overlappingRegionRoiTreeItems) {
                let oRoi = overlapTreeItem.roi;
                if (oRoi.uuid === mainRoi.uuid || oRoi.area > tempHoleRoi.area)
                  continue;

                possibleRoiCandidates.push(oRoi);
              }
            }

            let finalPossibleRoiCandidates = [...possibleRoiCandidates];

            let roisToExclude = [];

            //todo: handle holes
            for (let possibleOverlapRoi of possibleRoiCandidates) {
              let excludedOverlapRoi = null;
              let p = {
                x: possibleOverlapRoi.regions[0][0][0],
                y: possibleOverlapRoi.regions[0][0][1],
              };
              for (let fRoi of finalPossibleRoiCandidates) {
                if (
                  fRoi.regions.length > 1 &&
                  possibleOverlapRoi.area < fRoi.area &&
                  pointInsideRegion(p, fRoi.regions[0])
                ) {
                  excludedOverlapRoi = possibleOverlapRoi;
                  break;
                }
              }
              if (excludedOverlapRoi !== null) {
                roisToExclude.push(excludedOverlapRoi);
                finalPossibleRoiCandidates.filter(
                  (item) => item.uuid !== excludedOverlapRoi.uuid
                );
              }
            }
            finalPossibleRoiCandidates = finalPossibleRoiCandidates.filter(
              (item) => {
                let index = roisToExclude.findIndex(
                  (r) => r.uuid === item.uuid
                );
                return index < 0;
              }
            );

            for (let fRoi of finalPossibleRoiCandidates) {
              if (fRoi.regions.length > 1) {
                for (let h = 1; h < fRoi.regions.length; h++) {
                  mainRoi.regions.push(fRoi.regions[h]);
                }
                mainRoi.intRegions = undefined;
              }
              tree.remove(fRoi.treeItem);
              historyItem.push({ add: false, id: histId, roi: fRoi.copy() });
            }
            break;
          }
        }
        historyItem.push({ add: true, id: histId, roi: mainRoi });
        if (layer.regionRois.length !== tree.all().length) {
          layer.regionRois = tree.all().map((treeItem) => treeItem.roi);
        }
        window.projectHistory.add(historyItem);
        return;
      }
    }
  }

  mouse(params) {
    let { event, p } = params;
    // mouse middle button
    if (event.button === 1) {
      return;
    }

    if (event.type === "mousedown" && event.button === 0) {
      let clickedOnRoi = findClickedRoi(
        p,
        this.selectedLayer,
        this.structures,
        this.roiLayers,
        this.includeBaseROI
      );
      if (!clickedOnRoi) {
        //only try finding hole, if not clicked on roi
        this.fillHole(p);
      }
    }
  }

  drawCustomCursor() {}

  exit() {}

  renderConfiguration() {
    return null;
  }
}

export default FillTool;

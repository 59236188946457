import React, { Component } from "react";

import withStyles from '@mui/styles/withStyles';

import {
  Grid,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel
} from "@mui/material";

//import { Role } from "../common/utils";
//import { authenticationService } from "../common/services";

//const tabUrls = ["/settings/", "/settings/admin"];

const styles = {
  root: {
    background: "#EEEEEE"
  },
  paper: {
    padding: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
};

class SettingsPage extends Component {
  state = {};

  render() {
    //const { classes } = this.props;
    //const currentUser = authenticationService.currentUserValue;
    return (
      <Grid style={styles.paper} item xs={12}>
        <Card>
          <CardContent>
            <h1>Preferences</h1>
            <FormControl component="fieldset" fullWidth>
              {
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={false}
                      onChange={e => {
                        console.log("e", e)
                        //onChange(!includeBaseROI);
                        //this.setState({ includeBaseROI: !includeBaseROI });
                      }}
                    />
                  }
                  label="Update on slider move"
                />
              }
            </FormControl>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default withStyles(styles)(SettingsPage);

class Tool {
  name = "Tool Display Name";
  flag = false;
  prevX = 0;
  currX = 0;
  prevY = 0;
  currY = 0;
  downScale = 2;

  setLayer() {}

  setPreviewRect() {}

  initDrawing() {}

  updateDrawing() {}

  mouse() {}

  drawCustomCursor() {}

  renderConfiguration() {}

  exit() {}
}

export default Tool;

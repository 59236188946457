import React, { Component } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import withStyles from "@mui/styles/withStyles";
import {
  FormControlLabel,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Input,
  Button,
  IconButton,
  Chip,
} from "@mui/material";
import { Remove, Add, Delete } from "@mui/icons-material";

import { withSpectraViewer } from "../../contexts/SpectraViewerContext";

const styles = {
  applyButton: {
    width: "100%",
  },
  operationSetRow: {
    marginBottom: 10,
  },
  applyContainer: {
    display: "grid",
    gridTemplateRows: "1fr auto",
    overflow: "hidden",
  },
  applyMainContent: {
    height: "100%",
    overflow: "auto",
    borderBottom: "2px solid #EBEBEB",
  },
  operationButton: {
    width: "100%",
    justifyContent: "left",
    textAligne: "left",
    textTransform: "none",
  },
  chipIndikation: {
    width: 100,
    cursor: "pointer",
  },
  chipContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: 5,
    margin: 0,
  },
  chip: {
    margin: 5,
  },
  applyBottomContent: {
    borderTop: "3px solid #EBEBEB",
    padding: 10,
  },
};

const operations = {
  diff: {
    name: "differentiate",
    type: "int",
  },
  int: {
    name: "integrate",
    type: "int",
  },
  // pwr: {
  //   name: "powerTransform",
  //   type: "bool",
  // },
  minMax: {
    name: "scaleMinMax",
    type: "bool",
  },
  quant: {
    name: "scaleToQuantiles",
    type: "bool",
  },
  std: {
    name: "standardize",
    type: "bool",
  },
  vec: {
    name: "vectorNorm",
    type: "bool",
  },
  scores: {
    name: "maxPCAScores",
    type: "int",
  },
};

class OperationsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Updates the areas as they need to be updated when moving the boundaries inside the graph.
   * Cannot use <input value={}> since the direct update breaks it.
   */
  componentDidUpdate = () => {
    this.props.spectraViewer.areas.forEach((area) => {
      area.refs[0].current.value = area.coordRange[0].toFixed(3);
      area.refs[1].current.value = area.coordRange[1].toFixed(3);
    });
  };

  onAddOperationsSet = () => {
    let operationSetsCopy = JSON.parse(
      JSON.stringify(this.props.spectraViewer.operationSets)
    );
    operationSetsCopy.push([["scores", 1]]);
    this.props.spectraViewer.setState({ operationSets: operationSetsCopy });
  };

  onRemoveOperationSet = (idx) => {
    let operationSetsCopy = JSON.parse(
      JSON.stringify(this.props.spectraViewer.operationSets)
    );
    operationSetsCopy.splice(idx, 1);
    this.props.spectraViewer.setState({ operationSets: operationSetsCopy });
  };

  onAddOperation = (operation) => {
    let operationSetsCopy = JSON.parse(
      JSON.stringify(this.props.spectraViewer.operationSets)
    );
    let lastSet = operationSetsCopy[operationSetsCopy.length - 1];
    let toAdd = true;
    if (lastSet.length > 1) {
      // Increment if there already is an incrementable value
      if (lastSet[lastSet.length - 2][0] === operation) {
        // Only non-bool types are incremented
        if (operations[operation].type !== "bool") {
          lastSet[lastSet.length - 2][1] += 1;
        }
        toAdd = false;
      }
    }
    // Create new entry
    if (toAdd) {
      lastSet.splice(lastSet.length - 1, 0, [
        operation,
        operations[operation].type === "bool" ? true : 1,
      ]);
    }
    this.props.spectraViewer.setState({ operationSets: operationSetsCopy });
  };

  onChangeChip = (setIdx, idx, value) => {
    let operationSetsCopy = JSON.parse(
      JSON.stringify(this.props.spectraViewer.operationSets)
    );
    const key = operationSetsCopy[setIdx][idx][0];
    if (value === -1 || operations[key].type === "int") {
      operationSetsCopy[setIdx][idx][1] += value;
    }
    if (operationSetsCopy[setIdx][idx][1] <= 0) {
      operationSetsCopy[setIdx].splice(idx, 1);
    }
    if (operationSetsCopy[setIdx].length === 0) {
      operationSetsCopy.splice(setIdx, 1);
    }
    // Update operationsets if still present
    if (operationSetsCopy.length > 0) {
      this.props.spectraViewer.setState({ operationSets: operationSetsCopy });
    }
  };

  onApplySettings = () => {
    // Check for plausible pca settings
    for (
      let idx = 0;
      idx < this.props.spectraViewer.operationSets.length;
      idx++
    ) {
      // Get scores of opset
      let scores = this.props.spectraViewer.operationSets.slice(-1)[0][0][1];
      if (scores <= 0) {
        this.props.throwError(
          "Please select a minimum of 1 score. Possibly increase selected spectra."
        );
        return;
      } else if (scores > this.props.spectraViewer.maxScores) {
        this.props.throwError(
          `Too many scores. Increase selected spectra or reduce scores to ${this.props.spectraViewer.maxScores}`
        );
        return;
      }
    }

    // Calculate the pcas as required
    this.props.requestData("calculate_pcas");
    // Continue to pca selection tab
    if (this.props.project.type === "ESRTraining") {
      this.props.setActiveTab(2);
    }
  };

  /**
   * Updates the areas in the graph after entering them into the table.
   * @param {int} idx The index of the area you're referring to.
   * @param {JSON} area The area you're referring to.
   */
  onEnterArea = (idx, area) => {
    let tempAreas = this.props.spectraViewer.areas;
    tempAreas[idx].coordRange[0] = parseFloat(area.refs[0].current.value);
    tempAreas[idx].coordRange[1] = parseFloat(area.refs[1].current.value);
    this.props.spectraViewer.updateAreas(tempAreas);
    this.props.chart.dispatchAction({
      type: "brush",
      areas: tempAreas,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.applyContainer}>
        <div className={classes.applyMainContent}>
          <div className={classes.paddingContainer}>
            {Object.keys(operations).map((short) => {
              return (
                <React.Fragment key={uuidv4()}>
                  <Button
                    className={classes.operationButton}
                    onClick={() => {
                      if (short !== "scores") this.onAddOperation(short);
                      else this.onAddOperationsSet();
                    }}
                  >
                    <span className={classes.chipIndikation}>
                      <Chip
                        label={short}
                        color={short === "scores" ? "secondary" : "primary"}
                      />
                    </span>
                    {operations[short].name}
                    {short === "scores" ? " (add new operations set)" : ""}
                  </Button>
                  <br />
                </React.Fragment>
              );
            })}
            <ol>
              {this.props.spectraViewer.operationSets.map(
                (operationSet, setIdx) => {
                  return (
                    <li className={classes.operationSetRow} key={uuidv4()}>
                      <Paper className={classes.chipContainer} component="ul">
                        {operationSet.map((data, idx) => {
                          // Make sure that not too many scores are selected, but no fewer than 0
                          data[1] = Math.max(
                            0,
                            Math.min(
                              data[1],
                              this.props.spectraViewer.maxScores
                            )
                          );

                          const removeIcon = <Remove />;
                          let icon;

                          let hideRemove =
                            data[0] === "scores" && data[1] === 1;
                          let hideAdd =
                            data[0] === "scores" &&
                            data[1] >= this.props.spectraViewer.maxScores;

                          let labelValue = data[0];
                          if (operations[data[0]].type === "int") {
                            labelValue += " " + data[1];
                          } else {
                            hideAdd = true;
                          }

                          if (!hideAdd) {
                            icon = <Add />;
                          }

                          return (
                            <li key={uuidv4()}>
                              <Chip
                                className={classes.chip}
                                icon={icon}
                                label={labelValue}
                                onClick={() => {
                                  if (!hideAdd)
                                    this.onChangeChip(setIdx, idx, 1);
                                }}
                                onDelete={
                                  hideRemove
                                    ? undefined
                                    : () => this.onChangeChip(setIdx, idx, -1)
                                }
                                deleteIcon={removeIcon}
                                color={
                                  data[0] === "scores" ? "secondary" : "primary"
                                }
                              />
                            </li>
                          );
                        })}
                        {this.props.spectraViewer.operationSets.length > 1 && (
                          <li>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => {
                                this.onRemoveOperationSet(setIdx);
                              }}
                              size="large"
                            >
                              <Delete
                                fontSize="small"
                                style={{ color: "red" }}
                              />
                            </IconButton>
                          </li>
                        )}
                      </Paper>
                    </li>
                  );
                }
              )}
            </ol>
            {this.props.spectraViewer.areas.length > 0 && (
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>from</TableCell>
                      <TableCell>to</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.spectraViewer.areas.map((area, idx) => (
                      <TableRow key={idx}>
                        <TableCell component="th" scope="row">
                          {idx}
                        </TableCell>
                        <TableCell>
                          <Input
                            placeholder="from"
                            type="number"
                            defaultValue={area.coordRange[0].toFixed(3)}
                            onBlur={() => {
                              this.onEnterArea(idx, area);
                            }}
                            onKeyUp={(e) => {
                              if (e.key === "Enter") {
                                this.onEnterArea(idx, area);
                              }
                            }}
                            inputRef={area.refs[0]}
                            inputProps={{
                              "aria-label": "description",
                              step: this.props.xAxisData.stepX,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Input
                            placeholder="from"
                            type="number"
                            defaultValue={area.coordRange[1].toFixed(3)}
                            onBlur={() => {
                              this.onEnterArea(idx, area);
                            }}
                            onKeyUp={(e) => {
                              if (e.key === "Enter") {
                                this.onEnterArea(idx, area);
                              }
                            }}
                            inputRef={area.refs[1]}
                            inputProps={{
                              "aria-label": "description",
                              step: this.props.xAxisData.stepX,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                              let tempAreas = this.props.spectraViewer.areas;
                              this.props.spectraViewer.areas.splice(idx, 1);
                              this.props.spectraViewer.updateAreas(tempAreas);
                              this.props.chart.dispatchAction({
                                type: "brush",
                                areas: tempAreas,
                              });
                            }}
                            size="large"
                          >
                            <Delete fontSize="small" style={{ color: "red" }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
        {this.props.initialized && (
          <div className={classes.applyBottomContent}>
            <div className={classes.paddingContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      this.props.spectraViewer.toggleSavedCalcs();
                    }}
                    checked={this.props.spectraViewer.useSavedCalcs}
                  />
                }
                label="Use saved calculations"
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.applyButton}
                onClick={() => {
                  this.onApplySettings();
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

OperationsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  spectraViewer: PropTypes.object.isRequired,
  project: PropTypes.object,
  requestData: PropTypes.func,
  chart: PropTypes.object,
  xAxisData: PropTypes.object,
  initialized: PropTypes.bool,
  setActiveTab: PropTypes.func,
  throwError: PropTypes.func,
};

export default withSpectraViewer(withStyles(styles)(OperationsTab));

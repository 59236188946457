import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";

const styles = {
  canvasComp: {
    width: "100%",
    height: "100%",
  },
  CompContainer: {
    position: "absolute",
    zIndex: 100,
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "200px",
    height: "200px",
    // resize: "both", // TODO: Add listener to allow window resizing that executes handleSizeChanged()
    overflow: "hidden",
    background: "#D3D3D3",
    border: "1px solid black",
  },
};

class WindowTool extends Component {
  constructor(props) {
    super(props);
    if (props.componentRef) props.componentRef(this);
    this.state = {
      pos: { x: 0, y: 0 },
      dragging: false,
      fileId: null,
      size: { height: 200, width: 200 },
    };
  }

  componentDidMount() {
    this.canvas = document.getElementById("WindowToolCanvas");
    this.ctx = this.canvas.getContext("2d");
    this.ctx.canvas.width = this.state.size.width;
    this.ctx.canvas.height = this.state.size.height;
    let r = this.props.rendererDict["Full" + this.props.activeFileId];
    let idx =
      this.props.fullscreenFileIds.indexOf(this.props.activeFileId) === 0
        ? 1
        : 0;
    this.setState({
      pos: {
        x: r.canvas.width / 2 - this.ctx.canvas.width / 2,
        y: r.canvas.height / 2 - this.ctx.canvas.height / 2,
      },
      fileId: this.props.fullscreenFileIds[idx],
    });
    this.ctx.canvas.style.cursor = "pointer";
  }

  componentWillUnmount() {
    document.removeEventListener("mousemove", this.onMouseMove);
    document.removeEventListener("mouseup", this.onMouseUp);
  }

  componentDidUpdate() {
    if (this.state.dragging) {
      document.addEventListener("mousemove", this.onMouseMove);
      document.addEventListener("mouseup", this.onMouseUp);
    } else if (!this.state.dragging) {
      document.removeEventListener("mousemove", this.onMouseMove);
      document.removeEventListener("mouseup", this.onMouseUp);
    }

    let idx =
      this.props.fullscreenFileIds.indexOf(this.props.activeFileId) === 0
        ? 1
        : 0;
    let id = this.props.fullscreenFileIds[idx];
    if (id !== this.state.fileId) {
      this.setState({
        fileId: id,
      });
    }
  }

  onMouseDown = (e) => {
    if (e.button !== 0) return;
    this.ctx.canvas.style.cursor = "grab";
    this.setState({
      dragging: true,
    });
    e.stopPropagation();
    e.preventDefault();
  };

  onMouseUp = (e) => {
    this.setState({ dragging: false });
    this.ctx.canvas.style.cursor = "pointer";
    e.stopPropagation();
    e.preventDefault();
  };

  onMouseMove = (e) => {
    if (!this.state.dragging) return;
    let x = e.pageX - this.canvas.offsetWidth / 2;
    let y = e.pageY - 64 - this.canvas.offsetHeight / 2;
    let r = this.props.rendererDict["Full" + this.state.fileId];
    if (x > r.canvas.width - this.canvas.width)
      x = r.canvas.width - this.canvas.width;
    if (y > r.canvas.height - this.canvas.height)
      y = r.canvas.height - this.canvas.height;
    if (x < 0) x = 0;
    if (y < 0) y = 0;
    this.setState({
      pos: {
        x: x,
        y: y,
      },
    });
    e.stopPropagation();
    e.preventDefault();
  };

  updateCanvas = () => {
    this.ctx.canvas.width = this.state.size.width;
    this.ctx.canvas.height = this.state.size.height;
    let r = this.props.rendererDict["Full" + this.state.fileId];
    if (r) {
      if (r.ctx) {
        this.ctx.putImageData(
          r.ctx.getImageData(
            this.state.pos.x,
            this.state.pos.y,
            this.state.size.width,
            this.state.size.height
          ),
          0,
          0
        );
      }
    }
  };

  handleSizeChanged = (size) => {
    this.setState({ size });
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        className={classes.CompContainer}
        // onSizeChanged={this.handleSizeChanged}
        style={{
          left: this.state.pos.x + "px",
          top: this.state.pos.y + "px",
        }}
      >
        <canvas
          style={{
            height: this.state.size.height + "px",
            width: this.state.size.width + "px",
          }}
          id={"WindowToolCanvas"}
          className={classes.canvasComp}
          onContextMenu={(event) => event.preventDefault()}
          onMouseDown={this.onMouseDown}
        />
      </div>
    );
  }
}

WindowTool.propTypes = {
  classes: PropTypes.object.isRequired,
  componentRef: PropTypes.func,
  rendererDict: PropTypes.object,
  activeFileId: PropTypes.string,
  fullscreenFileIds: PropTypes.array,
};

export default withStyles(styles)(WindowTool);

import React from "react";
import PropTypes from "prop-types";
//import { userService } from "../../common/services";
import Backend from "../../common/utils/Backend";
import { withSpinloader } from "../../common/components/Spinloader";
import {
  TextField,
  Typography,
  CardActions,
  Button,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";

import withStyles from "@mui/styles/withStyles";

const styles = {
  root: {
    flexGrow: 1,
    width: "100%",
    height: "calc(100vh - 64px - 48px)",
    overflow: "hidden",
  },
  paper: {
    padding: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

class SystemContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      os: "Windows",
      settings: null,
    };
  }

  componentDidMount() {
    Backend.getLicensingInfo((result) => {
      let os = result.os;
      this.setState({ os: os });
    });
    Backend.readAppSettings((data) => {
      this.setState({ settings: data });
    });
  }

  onChangeMetaData = (field, e) => {
    const { settings } = this.state;
    settings[field] = e;
    this.setState({ settings });
  };

  saveClick = () => {
    // Check if contains Backslashes and replaces them
    // Also adds forward slash at the end if not contains
    const { settings } = this.state;
    const { storagePath, tempPath, galleryOutputPath } = settings;

    let collection = [storagePath, tempPath, galleryOutputPath];
    for (var [i, e] of collection.entries()) {
      e = e.replace(/\\/g, "/");
      while (e.includes("//")) {
        e = e.replace("//", "/");
      }
      if (!e.endsWith("/")) {
        e = e + "/";
      }
      collection[i] = e;
    }
    settings["storagePath"] = collection[0];
    settings["tempPath"] = collection[1];
    settings["galleryOutputPath"] = collection[2];

    this.setState(settings);

    Backend.writeAppSettings(this.state.settings, (data) => {
      console.log(data);
    });
    window.location.reload();
  };

  deleteTempFilesClick = () => {
    window.openResponseDialog(
      "Delete temporary files folder? Imported files have to be imported again. Projects will not be affected.",
      (response) => {
        if (response) {
          this.props.spinloader.show();
          Backend.deleteTempFiles((result) => {
            this.props.spinloader.hide();
            if (result.success) {
              if (result.deleted) {
                window.showSuccessSnackbar("Temp files folder deleted!");
              } else {
                window.showWarningSnackbar("No femp files folder found!");
              }
            } else {
              window.showErrorSnackbar("Temp files folder deleting failed!");
            }
          });
        }
      }
    );
  };

  render() {
    //const { classes } = this.props;
    const { settings } = this.state;
    return (
      <Card>
        <CardContent>
          <Typography variant="h6">System Actions</Typography>
          <Button onClick={this.deleteTempFilesClick}>Delete Temp Files</Button>
          {this.state.os === "Windows" && (
            <Typography variant="h6">System Settings</Typography>
          )}
          {this.state.os === "Windows" &&
            settings &&
            Object.keys(settings)
              .filter(
                (metaField) =>
                  !(
                    metaField === "schedulerStart" ||
                    metaField === "schedulerEnd"
                  )
              )
              .map((metaField) => (
                <TextField
                  fullWidth
                  key={metaField}
                  margin="normal"
                  label={metaField}
                  value={settings[metaField]}
                  onChange={(e) =>
                    this.onChangeMetaData(metaField, e.target.value)
                  }
                />
              ))}
          <br />
          <br />
          <Typography variant="h6">Job Scheduler</Typography>
          <br />

          {settings && (
            <Tooltip disableInteractive title="Start timed jobs">
              <TextField
                id="time_start"
                label="Start"
                type="time"
                defaultValue={settings.schedulerStart}
                onChange={(e) => {
                  settings.schedulerStart = e.target.value;
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </Tooltip>
          )}

          <span> </span>
          {settings && (
            <Tooltip disableInteractive title="Stop starting timed jobs">
              <TextField
                id="time_end"
                label="End"
                type="time"
                defaultValue={settings.schedulerEnd}
                onChange={(e) => {
                  settings.schedulerEnd = e.target.value;
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </Tooltip>
          )}
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" onClick={this.saveClick}>
            Save
          </Button>
        </CardActions>
      </Card>
    );
  }
}

SystemContainer.propTypes = {
  spinloader: PropTypes.object,
};

export default withSpinloader(withStyles(styles)(SystemContainer));

import React, { Component } from "react";
import PropTypes from "prop-types";

import { IconButton, Tooltip, SvgIcon } from "@mui/material";

import { Save } from "@mui/icons-material";
import classNames from "classnames";

import withStyles from "@mui/styles/withStyles";

import Backend from "../../common/utils/Backend";

const styles = (theme) => ({
  root: {
    position: "relative",
    height: "100%",
  },
  topTools: {
    position: "absolute",
    top: 0,
  },
  bottomTools: {
    position: "absolute",
    bottom: 0,
  },
  toolbarButton: {
    display: "inline-block",
    width: 40,
    height: 40,
    padding: 8,
    margin: 0,
  },
  toolbarButtonChecked: {
    width: 40,
    color: theme.palette.primary.main,
  },
});

class ProteomToolBar extends Component {
  constructor(props) {
    super(props);

    if (props.project.state && props.project.state === "success") {
      let downloadPath = Backend.downloadReport(this.props.project.id);
      console.log("downloadPath:", downloadPath);
    }
  }

  render() {
    const { classes, project } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.topTools}>
          {project.state && project.state === "success" && (
            <Tooltip disableInteractive title="Download excel sheet">
              <IconButton
                className={classNames(
                  classes.toolbarButton,
                  classes.toolbarButtonChecked
                )}
                href={Backend.downloadReport(this.props.project.id)}
                download
                size="large"
              >
                <SvgIcon>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="table"
                    className="svg-inline--fa fa-table fa-w-16"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z"
                    ></path>
                  </svg>
                </SvgIcon>
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className={classes.bottomTools}>
          <Tooltip disableInteractive title="Save [Ctrl]+[S]">
            <IconButton
              className={classNames(
                classes.toolbarButton,
                classes.toolbarButtonChecked
              )}
              onClick={this.props.onSave}
              size="large"
            >
              <Save />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  }
}

ProteomToolBar.propTypes = {
  classes: PropTypes.object.isRequired,
  onSave: PropTypes.func,
  project: PropTypes.object,
};

export default withStyles(styles)(ProteomToolBar);

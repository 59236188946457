import React, { Component } from "react";

import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import Table from "@mui/material/Table";
import {
  TableContainer,
  TableHead,
  Paper,
  TableRow,
  Select,
  MenuItem,
  TableBody,
  TableCell,
  IconButton,
  Tabs,
  Tab,
  Typography,
  Tooltip,
} from "@mui/material";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import {
  Remove,
  PlayArrow,
  ArrowDropDown,
  ArrowDropUp,
  Refresh,
  CloudDownload,
  GetApp,
} from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { withAllViewerContexts } from "../../../viewer/contexts/AllViewerContexts";
import Backend from "../../../common/utils/Backend";
import AIModelDialog from "../../../home/dialogs/AIModelDialog";
import TrainAIModelDialog from "../../../home/dialogs/TrainAIModelDialog";
import SideBarTabAISelectByModel from "./SideBarTabAISelectByModel";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const styles = {
  root: {
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateRows: "auto 1fr",
    overflow: "hidden",
  },
  tableContainer: {
    width: "100%",
    boxShadow: "none",
  },
  table: {
    width: "100%",
  },
  flexRowRemainingHeight: {
    flex: "1 1 auto",
    overflowY: "auto",
  },
  spinnerContainer: {
    width: "100%",
    height: "100%",
    padding: "20px",
    textAlign: "center",
  },
  tab: {
    minWidth: "100px",
    minHeight: "25px",
    fontWeight: "bold",
  },
  tabContainer: {
    height: "100%",
    display: "grid",
    gridTemplateRows: "auto 1fr",
  },
  modelSelectionContainer: {
    position: "reltative",
  },
  getOnlineModelsContainer: {
    position: "absolute",
    top: 5,
    right: 15,
  },
  accordionContainer: {
    height: "100%",
    overflow: "auto",
  },
};

// RIP AI-Cockpit
class SideBarTabAI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      online: true,
      isImporting: false,
    };
    try {
      this.state.online = props.viewerConfig.project.projectProperties.Online;
    } catch {
      console.log(
        "Error: 'online' could not be read in Project Module config!"
      );
    }
    this.initModelCounter = -1;
  }

  componentDidMount() {
    this.props.initAIFormData(false);
  }

  setFormDataAICockpit = (formDataAICockpit) => {
    this.props.setAIFormData(formDataAICockpit);
  };

  checkForAvailableIAMSubtype = (structure) => {
    let childs = this.findChilds(structure);
    for (let i = 1; i < childs.length; i++) {
      if (childs[i].tools.length > 0) return true;
    }
    return false;
  };

  findChilds = (subType) => {
    const { structures } = this.props;

    return structures.filter(
      (element) =>
        element.subtypeLevel === subType.subtypeLevel + 1 &&
        element.parentId === subType.id
    );
  };

  findClassificationSubtypes = (subType) => {
    const { structures } = this.props;

    return structures.filter(
      (element) =>
        element.subtypeLevel === subType.subtypeLevel + 1 &&
        element.parentId === subType.id &&
        element.classificationSubtype
    );
  };

  getParentIndex = (structure) => {
    let parentIndex = this.props.structures.findIndex(
      (element) => element.id === structure.parentId
    );
    return parentIndex;
  };

  findAllSubtypes = (str) => {
    let childs = this.findChilds(str);
    let allChilds = [];

    while (childs.length !== 0) {
      childs = childs.concat(this.findChilds(childs[0]));
      if (!allChilds.includes(childs[0])) {
        allChilds.push(childs[0]);
      }
      childs.shift();
    }

    return allChilds;
  };

  hideSubtypes = (subType, lv) => {
    if (subType.hasChild) {
      let childs = this.findChilds(subType);
      for (let i = 0; i < childs.length; i++) {
        this.hideSubtypes(childs[i], lv);
      }
    }
    // make visibility like parent
    if (subType.classificationSubtype && subType.subtypeLevel > lv) {
      let idx = this.getParentIndex(subType);
      subType.visible = this.props.structures[idx].visible;
    }
    subType.showSubtypes = false;
    subType.isUnfolded = false;
    this.props.projectContext.forceUpdate();
  };

  showSubtypes = (subType) => {
    const { structures } = this.props;
    for (let structure of structures) {
      if (structure.parentId === subType.id) {
        structure.isUnfolded = true;
      }
    }
    this.props.projectContext.setState({ structures });
  };

  /**
   * Assigns a given model to a given structure.
   * @param {string} selModel Name of model
   * @param {int} structureId Structure id
   */
  handleChangeModel = (selModel, structureId) => {
    const formDataAICockpit = this.props.formDataAICockpit;

    if (selModel === "null") {
      selModel = null;
    }

    // Assign model to structure
    formDataAICockpit[structureId].selectedModel = selModel;

    // Save changes
    this.props.setAIFormData(formDataAICockpit);
  };

  handleChangeVersion = (event, structure, selectedModel) => {
    if (selectedModel) {
      const formDataAICockpit = this.props.formDataAICockpit;
      const modelName = formDataAICockpit[structure].selectedModel;
      const versionName = event.target.value;
      formDataAICockpit[structure].models[selectedModel].selectedVersion =
        versionName;

      if (modelName !== null) {
        for (const key of Object.keys(formDataAICockpit)) {
          if (formDataAICockpit[key].selectedModel === modelName) {
            formDataAICockpit[key].selectedVersion = versionName;
            formDataAICockpit[key].models[selectedModel].selectedVersion =
              versionName;
          }
        }
      }
      const params = {
        selectedModel: selectedModel,
        selectedVersion: event.target.value,
      };
      this.props.onChangeTool("iam_ai_inference", params);
      this.props.setAIFormData(formDataAICockpit);
    }
  };

  handleImportModelSelection = (e) => {
    let files = e.target.files;
    this.setState({
      isImporting: true,
    });
    Backend.importAIModels(files[0], (result) => {
      if (!result.success) {
        window.showErrorSnackbar("Import failed");
      } else if (result.fileIsNew) {
        this.props.initAIFormData(false);
        window.showSuccessSnackbar("Successfully imported model");
      } else {
        window.showWarningSnackbar("Model already exists");
      }
      this.setState({
        isImporting: false,
      });
    });
  };

  handleChangeActiveTab = (event, value) => {
    this.setState({ activeTab: value });
  };

  getAvailableModelsForStructure = (availableModels, fullStructure) => {
    // get models that can be used with given structure
    const { structures } = this.props;

    if (availableModels) {
      // // different checks for parents and subtypes
      // if (!fullStructure.classificationSubtype) {
      return availableModels.filter((model) => {
        if (model.name.includes("Hyper") || model.name.includes("Efficient")) {
          return (
            model.versions[0].structure &&
            model.versions[0].structure.includes(
              fullStructure.label.substring(0, 3)
            )
          );
        } else if (
          model.versions.length > 0 &&
          model.versions[0].structure_indices
        ) {
          for (let idx of model.versions[0].structure_indices) {
            if (structures.length > idx) {
              if (structures[idx].label === fullStructure.label) {
                return true;
              }
            }
          }
        }
        return false;
      });
      // } else {
      //   let parentStructure = structures.filter(
      //     (element) => element.id === fullStructure.parentId
      //   )[0];
      //   return availableModels.filter(
      //     (element) =>
      //       element.name.includes(fullStructure.label.substring(0, 3)) &&
      //       element.name.includes(parentStructure.label.substring(0, 3)) &&
      //       element.modelType === "classification"
      //   );
      // }
    }
  };

  modelIncludesSubtypes = () => {
    // TODO: depending on naming of classification models, check if model has correct subtypes
    // let childs = this.findClassificationSubtypes(parentStructure);
    return true;
  };

  renderRow = (structure) => {
    const formDataAICockpit = this.props.formDataAICockpit;
    let selectedModel = formDataAICockpit[structure].selectedModel;
    const fullStructure = formDataAICockpit[structure].fullStructure;
    const { structures, availableModels } = this.props;

    // get available models for structure
    const availableModelsForStructure = this.getAvailableModelsForStructure(
      availableModels,
      fullStructure
    );
    const visibleVersions =
      selectedModel &&
      availableModelsForStructure &&
      availableModelsForStructure.length > 0 &&
      availableModelsForStructure.find((c) => c.name === selectedModel);

    let selectedDropDownVersion =
      (selectedModel &&
        formDataAICockpit[structure].models[selectedModel] &&
        formDataAICockpit[structure].models[selectedModel].selectedVersion) ||
      "";

    if (availableModelsForStructure) {
      let modelIndex = availableModelsForStructure.findIndex(
        (item) => item.name === selectedModel
      );
      if (modelIndex < 0) {
        selectedModel = "";
      }
    }

    if (visibleVersions) {
      let versionIndex = visibleVersions.versions.findIndex(
        (item) => item.label === selectedDropDownVersion
      );
      if (versionIndex < 0) {
        selectedDropDownVersion = "";
      }
    } else {
      selectedDropDownVersion = "";
    }

    // make line below last classification subtype to seperate classification subtypes from substructures
    let lastSubtype = false;
    if (fullStructure.classificationSubtype) {
      let parentStructure = structures.filter(
        (element) => element.id === fullStructure.parentId
      );
      let childs = null;
      if (parentStructure[0]) {
        childs = this.findClassificationSubtypes(parentStructure[0]);
      }
      // if fullStructure is last classification subtype --> draw divider
      if (
        childs[childs.length - 1] &&
        fullStructure.id === childs[childs.length - 1].id
      ) {
        lastSubtype = true;
      }
    }

    let id = structures.findIndex((element) => element.id === +structure);

    if (typeof structures[id] !== "undefined" && structures[id].isUnfolded) {
      return (
        <TableRow key={structure} style={{ width: "100%" }}>
          <TableCell
            align="left"
            component="th"
            scope="row"
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: (fullStructure.subtypeLevel + 1) * 10,
              borderBottom: lastSubtype
                ? "1px solid #595959"
                : "1px solid #E0E0E0",
            }}
          >
            {fullStructure.isSubtype && fullStructure.classificationSubtype && (
              <IconButton
                style={{ background: "none", padding: "2px" }}
                size="large"
              >
                <PlayArrow style={{ fontSize: "small" }} />
              </IconButton>
            )}
            {fullStructure.isSubtype && !fullStructure.classificationSubtype && (
              <IconButton
                style={{ background: "none", padding: "2px" }}
                size="large"
              >
                <Remove style={{ fontSize: "small" }} />
              </IconButton>
            )}

            {fullStructure.label}
          </TableCell>
          {fullStructure.hasChild && (
            <TableCell>
              <IconButton
                onClick={() => {
                  if (fullStructure.showSubtypes === false) {
                    this.showSubtypes(fullStructure);
                    fullStructure.showSubtypes = true;
                  } else {
                    this.hideSubtypes(
                      fullStructure,
                      fullStructure.subtypeLevel
                    );
                    fullStructure.isUnfolded = true;
                  }
                }}
                size="large"
              >
                {fullStructure.showSubtypes ? (
                  <ArrowDropUp />
                ) : (
                  <ArrowDropDown />
                )}
              </IconButton>
            </TableCell>
          )}
          {!fullStructure.hasChild && <TableCell />}
          <TableCell align="center">
            <Select
              variant="standard"
              value={selectedModel || "null"}
              onChange={(event) =>
                this.handleChangeModel(event.target.value, structure)
              }
              disabled={
                !(
                  availableModelsForStructure &&
                  availableModelsForStructure.length > 0
                )
              }
            >
              <MenuItem value="null">
                <em>Select Model ...</em>
              </MenuItem>
              {availableModelsForStructure &&
                availableModelsForStructure.map((model, idx) => {
                  if (typeof structure !== "undefined" || structure != null) {
                    return (
                      <MenuItem key={idx} value={model.name}>
                        {model.label}
                      </MenuItem>
                    );
                  }
                  return null;
                })}
            </Select>
          </TableCell>
          <TableCell align="right">
            <Select
              variant="standard"
              value={selectedDropDownVersion}
              onChange={(event) =>
                this.handleChangeVersion(event, structure, selectedModel)
              }
              disabled={!selectedModel}
            >
              {visibleVersions &&
                visibleVersions.versions.map((version, idx) => (
                  <MenuItem key={idx} value={version.label}>
                    {version.label}
                  </MenuItem>
                ))}
            </Select>
          </TableCell>
        </TableRow>
      );
    }
  };

  render() {
    const { classes, visible, formDataAICockpit, ...propsWithoutClasses } =
      this.props;
    if (!visible) return null;

    return (
      <div className={classes.root}>
        <Tabs
          className={classes.tabsContainer}
          style={{ minHeight: "30px" }}
          value={this.state.activeTab}
          onChange={this.handleChangeActiveTab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab className={classes.tab} label="model selection" />
          <Tab className={classes.tab} label="train model" />
        </Tabs>
        {this.state.activeTab === 0 && (
          <div
            style={{ position: "relative", height: "100%", overflow: "hidden" }}
          >
            <div className={classes.getOnlineModelsContainer}>
              {this.state.online && (
                <Tooltip
                  disableInteractive
                  title="Load available online AI Models"
                >
                  <IconButton
                    aria-label="Import"
                    onClick={() => this.props.initAIFormData(true)}
                    size="large"
                  >
                    <CloudDownload />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip
                disableInteractive
                title="Update available offline AI Models"
              >
                <IconButton
                  aria-label="Import"
                  onClick={() => this.props.initAIFormData(false)}
                  size="large"
                >
                  <Refresh />
                </IconButton>
              </Tooltip>

              {this.state.isImporting ? (
                <CircularProgress
                  size="20px"
                  thickness={3}
                  style={{
                    marginLeft: "15px",
                    marginTop: "15px",
                    marginRight: "15px",
                  }}
                />
              ) : (
                <Tooltip disableInteractive title="Import AI Model">
                  <IconButton
                    onClick={() =>
                      document.getElementById("importAIModel").click()
                    }
                    size="large"
                  >
                    <GetApp />
                  </IconButton>
                </Tooltip>
              )}
              <input
                type="file"
                id="importAIModel"
                style={{ position: "absolute", top: "-150px" }}
                accept=".modelhsa"
                onChange={this.handleImportModelSelection}
              />
            </div>
            {this.props.modelsInitialized ? (
              <div className={classes.tabContainer}>
                <AIModelDialog
                  dialog="AIDataTable"
                  structures={this.props.structures}
                  formDataAICockpit={this.props.formDataAICockpit}
                  setAvailableModels={this.props.setAvailableModels}
                  setFormDataAICockpit={this.setFormDataAICockpit}
                  setModelsInitialized={this.props.setModelsInitialized}
                  initAIFormData={this.props.initAIFormData}
                />
                <div className={classes.accordionContainer}>
                  <Accordion
                    expanded={this.props.showFirstAccordion}
                    onChange={() => this.props.toggleShowFirstAccordion()}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <Typography>Select by Model</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {this.props.showFirstAccordion && (
                        <SideBarTabAISelectByModel
                          availableModels={this.props.availableModels}
                          formDataAICockpit={this.props.formDataAICockpit}
                          structures={this.props.structures}
                          handleChangeModel={this.handleChangeModel}
                          handleChangeVersion={this.handleChangeVersion}
                          getAvailableModelsForStructure={
                            this.getAvailableModelsForStructure
                          }
                          onChangeTool={this.props.onChangeTool}
                          initAIFormData={this.props.initAIFormData}
                          setAiUsedStructures={this.props.setAiUsedStructures}
                          viewerConfig={this.props.viewerConfig}
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    square
                    expanded={!this.props.showFirstAccordion}
                    onChange={() => this.props.toggleShowFirstAccordion()}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2d-content"
                      id="panel2d-header"
                    >
                      <Typography>Select by Structure </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer
                        className={classes.tableContainer}
                        component={Paper}
                      >
                        {!this.props.showFirstAccordion && (
                          <Table className={classes.table} size="medium">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">Structure</TableCell>
                                <TableCell />
                                <TableCell align="center">Model</TableCell>
                                <TableCell align="right">Version</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {formDataAICockpit &&
                                Object.values(formDataAICockpit)
                                  .sort((a, b) =>
                                    a.structureIndex > b.structureIndex ? 1 : -1
                                  ) // sort because dictionary is sorted by structure index value
                                  .filter((item) => item !== "null")
                                  .map((element) =>
                                    this.renderRow(element.fullStructure.id)
                                  )}
                            </TableBody>
                          </Table>
                        )}
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            ) : (
              <div className={classes.spinnerContainer}>
                <CircularProgress />
              </div>
            )}
          </div>
        )}
        {this.state.activeTab === 1 && (
          <div>
            <TrainAIModelDialog
              dialog="TrainModelPages"
              structures={this.props.structures}
              roiLayers={this.props.roiLayers}
              projectId={this.props.projectId}
              onSave={this.props.onSave}
              ome={this.props.ome}
              histogramConfig={this.props.histogramConfig[this.props.fileId]}
              projectStringProperties={
                this.props.viewerConfig.project.projectStringProperties
              }
              {...propsWithoutClasses}
            />
          </div>
        )}
      </div>
    );
  }
}

// define the component's interface
SideBarTabAI.propTypes = {
  classes: PropTypes.object.isRequired,
  project: PropTypes.object,
  viewerConfig: PropTypes.object,
  structures: PropTypes.array,
  visible: PropTypes.bool,
  setAIFormData: PropTypes.func,
  formDataAICockpit: PropTypes.object,
  onChangeTool: PropTypes.func,
  projectContext: PropTypes.object,
  parentStructure: PropTypes.object,
  roiLayers: PropTypes.array,
  projectId: PropTypes.string,
  onSave: PropTypes.func,
  ome: PropTypes.object,
  setAvailableModels: PropTypes.func,
  setModelsInitialized: PropTypes.func,
  modelsInitialized: PropTypes.bool,
  availableModels: PropTypes.array,
  initAIFormData: PropTypes.func,
  showFirstAccordion: PropTypes.bool,
  toggleShowFirstAccordion: PropTypes.func,
  setAiUsedStructures: PropTypes.func,
  histogramConfig: PropTypes.object,
  fileId: PropTypes.string,
};

export default withAllViewerContexts(withStyles(styles)(SideBarTabAI));

import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  TextField,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  IconButton,
  Typography,
} from "@mui/material";

import { distance } from "../../utils/PolygonUtil";

import Crop169Icon from "@mui/icons-material/Crop169";
import CommentIcon from "@mui/icons-material/Comment";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import FormatSizeIcon from "@mui/icons-material/FormatSize";

import { faDrawPolygon, faRuler } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";

import { CommentROI } from "../../utils/ROI";
import SketchColorPicker from "../SketchColorPicker";

import Tool from "./Tool";

const scaleUnitLabels = ["km", "m", "mm", "µm", "nm", "pm", "fm", "am", "zm"];
const scaleUnits = [3, 1, -3, -6, -9, -12, -15, -18, -21];

const styles = {
  buttonStyle: { marginRight: 10, marginBottom: 10, minWidth: 195 },
  listButtonStyle: { marginRight: 5, marginBottom: 5 },
};

class CommentTool extends Tool {
  name = "Comment";
  noConfig = false;
  mouseActionState = "free";
  downScale = 2;
  points = [];
  commentState = {
    commentValue: "",
    color: "#D0021B",
    selectedTool: "rectangle",
  };
  selectedCommentIdx = 0;
  lastP = null;
  gripData = null;

  setLayer(obj) {
    this.ome = obj.ome;
    this.isBrightfield = obj.ome.channels[0].type === "brightfield";
    this.lineColor = this.isBrightfield ? "#000000" : "#ffffff";
    this.selectionColor = this.lineColor;
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    this.selectedLayer = obj.selectedLayer;
    this.structures = obj.structures;
    this.drawLayer = obj.drawLayer;
    this.commentLayer = obj.commentLayer
      ? obj.commentLayer
      : { commentRois: [] };
  }

  setPreviewRect() {}

  distance(p1, p2) {
    let deltaX = p1.x - p2.x;
    let deltaY = p1.y - p2.y;
    return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
  }

  generateDistancePoints = (p1, p2) => {
    let vector = {
      x: p1.x - p2.x,
      y: p1.y - p2.y,
    };
    let length = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
    let dl = length / 20;
    vector.x /= length;
    vector.y /= length;
    return [
      [p1.x, p1.y],
      [p2.x, p2.y],
      [p2.x - (dl * vector.y) / 2, p2.y + (dl * vector.x) / 2],
      [p2.x + (dl * vector.y) / 2, p2.y - (dl * vector.x) / 2],
      [p2.x, p2.y],
      [p1.x, p1.y],
      [p1.x - (dl * vector.y) / 2, p1.y + (dl * vector.x) / 2],
      [p1.x + (dl * vector.y) / 2, p1.y - (dl * vector.x) / 2],
    ];
  };

  generateArrowPoints = (p1, p2) => {
    let vector = {
      x: p1.x - p2.x,
      y: p1.y - p2.y,
    };
    let length = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
    let dl = length / 20;
    vector.x /= length;
    vector.y /= length;
    return [
      [p1.x, p1.y],
      [p2.x + vector.x * dl, p2.y + vector.y * dl],
      [
        p2.x + vector.x * dl - (dl * vector.y) / 2,
        p2.y + vector.y * dl + (dl * vector.x) / 2,
      ],
      [p2.x, p2.y],
      [
        p2.x + vector.x * dl + (dl * vector.y) / 2,
        p2.y + vector.y * dl - (dl * vector.x) / 2,
      ],
      [p2.x + vector.x * dl, p2.y + vector.y * dl],
    ];
  };

  deleteCommentRoi(idx) {
    this.commentLayer.commentRois.splice(idx, 1);
    window.forceSidebarUpdate();
  }

  changeRoiFontSize(idx) {
    if (this.commentLayer.commentRois[idx].fontScaleFactor === 2 / 3) {
      this.commentLayer.commentRois[idx].fontScaleFactor = 1;
    } else if (this.commentLayer.commentRois[idx].fontScaleFactor === 1) {
      this.commentLayer.commentRois[idx].fontScaleFactor = 4 / 3;
    } else {
      this.commentLayer.commentRois[idx].fontScaleFactor = 2 / 3;
    }
  }

  changeSelectedColor(idx, color) {
    this.commentLayer.commentRois[idx].color = color;
    window.forceSidebarUpdate();
  }

  changeSelectedCommentValue(idx, commentValue) {
    this.commentLayer.commentRois[idx].commentValue = commentValue;
    window.forceSidebarUpdate();
  }

  changeSelectedCommentIdx(idx) {
    // provides bad user-experience when using for documentation purposes
    // window.zoomToRect(this.commentLayer.commentRois[idx].bounds);
    this.selectedCommentIdx = idx;
    window.forceSidebarUpdate();
  }

  computePolygonArea(points) {
    let X = [];
    let Y = [];
    let numPoints = points.length;
    for (let p of points) {
      X.push(p[0]);
      Y.push(p[1]);
    }
    let area = 0; // Accumulates area
    let j = numPoints - 1;

    for (let i = 0; i < numPoints; i++) {
      area += (X[j] + X[i]) * (Y[j] - Y[i]);
      j = i; //j is previous vertex to i
    }
    return area / 2;
  }

  computLengthValue(physicalLength, area) {
    physicalLength = Math.abs(physicalLength);
    let computedValue = 0;
    // display pixel if no unit is set
    if (!this.ome.physicalSizeX) {
      return Math.round(physicalLength) + " px";
    }
    let unitLength = physicalLength;

    // find best fitting exponennt
    let unitExponent = 1;
    let unitExponentIndex = 1;
    for (
      unitExponentIndex = 0;
      unitExponentIndex < scaleUnits.length;
      unitExponentIndex++
    ) {
      unitExponent = area
        ? scaleUnits[unitExponentIndex] * 2
        : scaleUnits[unitExponentIndex];
      unitLength = physicalLength / Math.pow(10, unitExponent);
      if (unitLength > 1) {
        break;
      }
    }
    if (area) {
      computedValue =
        Math.round(unitLength * 100) / 100 +
        " " +
        scaleUnitLabels[unitExponentIndex] +
        "²";
    } else {
      computedValue =
        Math.round(unitLength * 100) / 100 +
        " " +
        scaleUnitLabels[unitExponentIndex];
    }
    return computedValue;
  }

  pointInsideBounds(p, bounds) {
    return !(
      p.x < bounds.left ||
      p.x > bounds.right ||
      p.y < bounds.top ||
      p.y > bounds.bottom
    );
  }

  addPoint = (event, p) => {
    let eventPoint = { x: event.clientX, y: event.clientY };
    if (this.points.length < 3) {
      this.points.push([p.x, p.y]);
      this.lastEventPoint = eventPoint;
    } else {
      const dist = distance(this.lastEventPoint, eventPoint);
      if (dist < 10) {
        this.points[this.points.length - 1] = [p.x, p.y];
      } else {
        this.points.push([p.x, p.y]);
        this.lastEventPoint = eventPoint;
      }
    }
  };

  mouse(params) {
    let { event, p } = params;
    let mouseP = p;
    if (
      this.mouseActionState !== "draw" &&
      this.commentLayer["commentRois"].length > 0 &&
      this.commentLayer["commentRois"][this.selectedCommentIdx]
    ) {
      let selectedCommentRoi =
        this.commentLayer["commentRois"][this.selectedCommentIdx];
      if (this.mouseActionState === "free") {
        let minArea = Number.MAX_SAFE_INTEGER;
        for (let i = 0; i < this.commentLayer.commentRois.length; i++) {
          if (this.commentLayer.commentRois[i].containsPoint(mouseP)) {
            let commentRoiArea =
              this.commentLayer.commentRois[i].width *
              this.commentLayer.commentRois[i].height;
            if (commentRoiArea < minArea) {
              this.selectedCommentIdx = i;
              minArea = commentRoiArea;
            }
          }
        }
      } else {
        if (event.type === "mouseup" || event.type === "mouseleave") {
          this.mouseActionState = "free";
        } else if (event.type === "mousemove") {
          if (this.mouseActionState === "move") {
            let deltaX = mouseP.x - this.lastP.x;
            let deltaY = mouseP.y - this.lastP.y;
            let selectedCommentRoi =
              this.commentLayer["commentRois"][this.selectedCommentIdx];
            let regions = [];
            for (let region of selectedCommentRoi.regions) {
              regions.push([region[0] + deltaX, region[1] + deltaY]);
            }
            this.commentLayer["commentRois"][this.selectedCommentIdx].regions =
              regions;
            this.commentLayer["commentRois"][
              this.selectedCommentIdx
            ].updateBounds();
            this.lastP = mouseP;
          } else if (this.mouseActionState === "resize") {
            let deltaX = mouseP.x - this.lastP.x;
            let deltaY = mouseP.y - this.lastP.y;
            if (
              selectedCommentRoi.type === "rectangle" ||
              selectedCommentRoi.type === "region" ||
              selectedCommentRoi.type === "commentBox"
            ) {
              let deltaXProc =
                (selectedCommentRoi.width + deltaX) / selectedCommentRoi.width;
              let deltaYProc =
                (selectedCommentRoi.height + deltaY) /
                selectedCommentRoi.height;
              let regions = [];
              for (let region of selectedCommentRoi.regions) {
                let x = selectedCommentRoi.bounds.left;
                let y = selectedCommentRoi.bounds.top;
                let w = region[0] - x;
                let h = region[1] - y;
                regions.push([x + w * deltaXProc, y + h * deltaYProc]);
              }
              this.commentLayer["commentRois"][
                this.selectedCommentIdx
              ].regions = regions;
              this.commentLayer["commentRois"][
                this.selectedCommentIdx
              ].updateBounds();
              let length = this.computePolygonArea(selectedCommentRoi.regions);
              let unitLength =
                length * this.ome.physicalSizeX * this.ome.physicalSizeY;
              if (!this.ome.physicalSizeX) {
                unitLength = length;
              }
              let computedValue = this.computLengthValue(unitLength, true);
              this.commentLayer["commentRois"][
                this.selectedCommentIdx
              ].computedValue = computedValue;
              if (
                this.commentLayer["commentRois"][
                  this.selectedCommentIdx
                ].commentValue.endsWith("m²")
              ) {
                this.commentLayer["commentRois"][
                  this.selectedCommentIdx
                ].commentValue = computedValue;
              }
            } else if (
              selectedCommentRoi.type === "distance" ||
              selectedCommentRoi.type === "arrow"
            ) {
              let regions = selectedCommentRoi.regions;
              let p1 = {
                x: regions[0][0],
                y: regions[0][1],
              };
              let p2 = {
                x: regions[1][0],
                y: regions[1][1],
              };

              if (selectedCommentRoi.type === "arrow") {
                p2.x = regions[3][0];
                p2.y = regions[3][1];
              }

              //to do: smaller distance p1, p1 to mouseP, then new distance roi
              if (this.distance(mouseP, p1) < this.distance(mouseP, p2)) {
                p1.x += deltaX;
                p1.y += deltaY;
              } else {
                p2.x += deltaX;
                p2.y += deltaY;
              }
              if (selectedCommentRoi.type === "arrow") {
                regions = this.generateArrowPoints(p1, p2);
              } else {
                regions = this.generateDistancePoints(p1, p2);
              }

              let length = this.distance(p1, p2);
              let computedValue = this.computLengthValue(
                this.ome.physicalSizeX * length,
                false
              );
              if (!this.ome.physicalSizeX) {
                computedValue = this.computLengthValue(length, false);
              }
              let commentValue = selectedCommentRoi.commentValue;
              if (commentValue.endsWith("m")) {
                commentValue = computedValue;
              }
              this.commentLayer["commentRois"][this.selectedCommentIdx] =
                new CommentROI(
                  regions,
                  selectedCommentRoi.color,
                  selectedCommentRoi.type,
                  commentValue,
                  computedValue
                );
            }

            this.lastP = mouseP;
          }
        }
      }
      if (event.type === "mousedown" && event.button === 0) {
        if (
          this.gripData !== null &&
          this.pointInsideBounds(mouseP, this.gripData.b)
        ) {
          this.mouseActionState = "resize";
        } else if (selectedCommentRoi.containsPoint(mouseP)) {
          this.mouseActionState = "move";
        } else {
          this.mouseActionState = "draw";
        }
        this.lastP = mouseP;
      }
    }
    let computedValue = "";
    let commentValue = this.commentState.commentValue;
    if (event.button !== 0) return;
    if (event.type === "mousedown") {
      if (
        this.commentLayer["commentRois"].length > 0 &&
        this.commentLayer["commentRois"][this.selectedCommentIdx]
      ) {
        let selectedCommentRoi =
          this.commentLayer["commentRois"][this.selectedCommentIdx];
        if (
          this.gripData !== null &&
          this.pointInsideBounds(mouseP, this.gripData.b)
        ) {
          this.mouseActionState = "resize";
          this.lastP = mouseP;
          return;
        } else if (selectedCommentRoi.containsPoint(mouseP)) {
          this.mouseActionState = "move";
          this.lastP = mouseP;
          return;
        }
      } else {
        this.mouseActionState = "draw";
      }
      // update position history
      this.startPoint = mouseP;
      this.points = [];
      this.addPoint(event, mouseP);
      if (this.commentLayer.commentRois) {
        this.commentLayer.commentRois.push(
          new CommentROI(
            this.points,
            this.commentState.color,
            this.commentState.selectedTool,
            this.commentState.commentValue,
            computedValue
          )
        );
        this.selectedCommentIdx = this.commentLayer["commentRois"].length - 1;
        window.forceSidebarUpdate();
      } else {
        this.commentLayer["commentRois"] = [
          new CommentROI(
            this.points,
            this.commentState.color,
            this.commentState.selectedTool,
            this.commentState.commentValue,
            computedValue
          ),
        ];
      }

      // set drawing flag
      this.mouseActionState = "draw";
    } else if (event.type === "mouseup") {
      let lastRoi =
        this.commentLayer.commentRois[this.commentLayer.commentRois.length - 1];
      if (!lastRoi) {
        console.log("LAST ROI WAS EMPTY");
      } else if (
        lastRoi.regions.length < 4 ||
        lastRoi.width === 0 ||
        lastRoi.height === 0
      ) {
        console.log("last roi :", lastRoi);
        window.showWarningSnackbar("Comment could not be created!");
        this.commentLayer.commentRois.pop();
        window.forceSidebarUpdate();
      }
      // release drawing flag
      this.mouseActionState = "free";
    } else if (event.type === "mousemove") {
      if (this.mouseActionState === "draw") {
        if (
          this.commentState.selectedTool === "rectangle" ||
          this.commentState.selectedTool === "commentBox"
        ) {
          this.points = [
            [this.startPoint.x, this.startPoint.y],
            [mouseP.x, this.startPoint.y],
            [mouseP.x, mouseP.y],
            [this.startPoint.x, mouseP.y],
          ];
          let width =
            Math.abs(this.startPoint.x - mouseP.x) * this.ome.physicalSizeX;
          let height =
            Math.abs(this.startPoint.y - mouseP.y) * this.ome.physicalSizeY;
          if (!this.ome.physicalSizeX) {
            width = Math.abs(this.startPoint.x - mouseP.x);
            height = Math.abs(this.startPoint.y - mouseP.y);
          }
          let unitLength = width * height;
          computedValue = this.computLengthValue(unitLength, true);
          if (
            this.commentState.commentValue === "" &&
            this.commentState.selectedTool !== "commentBox"
          )
            commentValue = computedValue;
        } else if (this.commentState.selectedTool === "region") {
          this.addPoint(event, mouseP);
          let length = this.computePolygonArea(this.points);
          let unitLength =
            length * this.ome.physicalSizeX * this.ome.physicalSizeY;
          if (!this.ome.physicalSizeX) {
            unitLength = length;
          }
          computedValue = this.computLengthValue(unitLength, true);
          if (
            this.commentState.commentValue === "" &&
            this.commentState.selectedTool !== "commentBox"
          )
            commentValue = computedValue;
        } else if (this.commentState.selectedTool === "arrow") {
          let vector = {
            x: this.startPoint.x - mouseP.x,
            y: this.startPoint.y - mouseP.y,
          };
          let length = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
          this.points = this.generateArrowPoints(this.startPoint, mouseP);

          if (!this.ome.physicalSizeX) {
            computedValue = this.computLengthValue(length, false);
          } else {
            computedValue = this.computLengthValue(
              this.ome.physicalSizeX * length,
              false
            );
          }
        } else if (this.commentState.selectedTool === "distance") {
          let vector = {
            x: this.startPoint.x - mouseP.x,
            y: this.startPoint.y - mouseP.y,
          };
          let length = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
          this.points = this.generateDistancePoints(this.startPoint, mouseP);

          if (!this.ome.physicalSizeX) {
            computedValue = this.computLengthValue(length, false);
          } else {
            computedValue = this.computLengthValue(
              this.ome.physicalSizeX * length,
              false
            );
          }
          if (
            this.commentState.commentValue === "" &&
            this.commentState.selectedTool !== "commentBox"
          )
            commentValue = computedValue;
        } else {
          return;
        }

        this.commentLayer["commentRois"][
          this.commentLayer["commentRois"].length - 1
        ] = new CommentROI(
          this.points,
          this.commentState.color,
          this.commentState.selectedTool,
          commentValue,
          computedValue
        );
        window.forceSidebarUpdate();
      }
    }
  }

  drawCustomCursor(ctx, mousePosition, fct, canvas) {
    if (
      this.mouseActionState === "free" &&
      this.commentLayer["commentRois"].length > 0 &&
      this.commentLayer["commentRois"][this.selectedCommentIdx]
    ) {
      ctx.beginPath();
      ctx.globalAlpha = 1.0;
      let selectedCommentRoi =
        this.commentLayer["commentRois"][this.selectedCommentIdx];

      let pointInsideSelection =
        selectedCommentRoi.containsPoint(mousePosition);

      ctx.strokeStyle = pointInsideSelection ? "red" : this.selectionColor;
      let b = selectedCommentRoi.bounds;
      // no custom cursor
      ctx.rect(b.left, b.top, b.right - b.left, b.bottom - b.top);
      ctx.stroke();
      ctx.closePath();

      let cursor = pointInsideSelection ? "move" : "default";
      let gripPoints = [];
      if (
        selectedCommentRoi.type === "rectangle" ||
        selectedCommentRoi.type === "region" ||
        selectedCommentRoi.type === "commentBox"
      ) {
        gripPoints = [
          {
            x: b.right,
            y: b.bottom,
          },
        ];
      } else if (selectedCommentRoi.type === "distance") {
        gripPoints = [
          {
            x: selectedCommentRoi.regions[0][0],
            y: selectedCommentRoi.regions[0][1],
          },
          {
            x: selectedCommentRoi.regions[1][0],
            y: selectedCommentRoi.regions[1][1],
          },
        ];
      } else if (selectedCommentRoi.type === "arrow") {
        gripPoints = [
          {
            x: selectedCommentRoi.regions[0][0],
            y: selectedCommentRoi.regions[0][1],
          },
          {
            x: selectedCommentRoi.regions[3][0],
            y: selectedCommentRoi.regions[3][1],
          },
        ];
      }
      for (let gripPoint of gripPoints) {
        let gripSize = 14 / fct;
        let gripSizeInner = 10 / fct;
        this.gripData = {
          p: {
            x: gripPoint.x,
            y: gripPoint.y,
          },
          b: {
            left: gripPoint.x - gripSize / 2,
            right: gripPoint.x + gripSize / 2,
            top: gripPoint.y - gripSize / 2,
            bottom: gripPoint.y + gripSize / 2,
          },
          bInner: {
            left: gripPoint.x - gripSizeInner / 2,
            right: gripPoint.x + gripSizeInner / 2,
            top: gripPoint.y - gripSizeInner / 2,
            bottom: gripPoint.y + gripSizeInner / 2,
          },
        };

        ctx.fillStyle = "black";
        ctx.fillRect(
          this.gripData.b.left,
          this.gripData.b.top,
          gripSize,
          gripSize
        );
        ctx.fillStyle = "white";
        ctx.fillRect(
          this.gripData.bInner.left,
          this.gripData.bInner.top,
          gripSizeInner,
          gripSizeInner
        );

        if (this.pointInsideBounds(mousePosition, this.gripData.b)) {
          if (
            selectedCommentRoi.type === "arrow" ||
            selectedCommentRoi.type === "distance"
          ) {
            cursor = "pointer";
          } else {
            cursor = "nw-resize";
          }
          break;
        }
      }

      canvas.style.cursor = cursor;
    }
  }

  updateCommentRoi = () => {
    let computedValue = 0;
    if (this.commentLayer["commentRois"].length > 0) {
      this.commentLayer["commentRois"][this.selectedCommentIdx] =
        new CommentROI(
          this.commentLayer["commentRois"][this.selectedCommentIdx].regions,
          this.commentState.color,
          this.commentState.selectedTool,
          this.commentState.commentValue,
          computedValue
        );
    }
  };

  exit() {}

  renderConfiguration = () => {
    return (
      <div>
        <Typography variant="h6">{this.name}:</Typography>
        <ConfigForm
          selectedCommentIdx={this.selectedCommentIdx}
          changeSelectedCommentIdx={(idx) => {
            this.changeSelectedCommentIdx(idx);
          }}
          commentRois={this.commentLayer["commentRois"]}
          commentState={this.commentState}
          changeCommentState={(commentState) => {
            this.commentState = commentState;
            window.forceSidebarUpdate();
          }}
          updateCommentRoi={() => {
            this.updateCommentRoi(this.commentState);
          }}
          deleteCommentRoi={(idx) => {
            this.deleteCommentRoi(idx);
          }}
          changeSelectedColor={(idx, color) => {
            this.changeSelectedColor(idx, color);
          }}
          changeSelectedCommentValue={(idx, commentValue) => {
            this.changeSelectedCommentValue(idx, commentValue);
          }}
          changeRoiFontSize={(idx) => {
            this.changeRoiFontSize(idx);
          }}
        />
      </div>
    );
  };
}

class ConfigForm extends Component {
  changeCommentType = (type) => {
    let commentState = this.props.commentState;
    commentState["selectedTool"] = type;
    commentState["commentValue"] = "";
    this.props.changeCommentState(commentState);
  };
  render() {
    const { commentValue, color, selectedTool } = this.props.commentState;
    return (
      <div style={{ height: "calc(100% - 32px)", overflowY: "hidden" }}>
        <div style={{ height: "190px" }}>
          <div style={{ display: "flex", marginTop: 20 }}>
            <TextField
              style={{ width: "300px", marginRight: "20px" }}
              value={commentValue}
              size="small"
              label="Default value"
              placeholder="Enter default value..."
              variant="outlined"
              onChange={(e) => {
                let commentState = this.props.commentState;
                commentState["commentValue"] = e.target.value;
                this.props.changeCommentState(commentState);
              }}
            />
            <SketchColorPicker
              color={color}
              handleChange={(color) => {
                let commentState = this.props.commentState;
                commentState["color"] = color;
                this.props.changeCommentState(commentState);
              }}
            />
          </div>
          <br />
          <div>
            <Button
              width="150px"
              style={styles.buttonStyle}
              variant="contained"
              onClick={() => {
                this.changeCommentType("rectangle");
              }}
              color={selectedTool === "rectangle" ? "primary" : "default"}
              startIcon={<Crop169Icon />}
            >
              Rectangle
            </Button>
            <Button
              width="150px"
              style={styles.buttonStyle}
              variant="contained"
              onClick={() => {
                this.changeCommentType("arrow");
              }}
              color={selectedTool === "arrow" ? "primary" : "default"}
              startIcon={<ArrowForwardOutlinedIcon />}
            >
              Arrow
            </Button>
          </div>
          <div>
            <Button
              width="150px"
              style={styles.buttonStyle}
              variant="contained"
              onClick={() => {
                this.changeCommentType("region");
              }}
              color={selectedTool === "region" ? "primary" : "default"}
              startIcon={<FontAwesomeIcon icon={faDrawPolygon} />}
            >
              Region
            </Button>
            <Button
              width="150px"
              style={styles.buttonStyle}
              variant="contained"
              onClick={() => {
                this.changeCommentType("distance");
              }}
              color={selectedTool === "distance" ? "primary" : "default"}
              startIcon={<FontAwesomeIcon icon={faRuler} />}
            >
              Distance
            </Button>
          </div>
          <div>
            <Button
              width="150px"
              style={styles.buttonStyle}
              variant="contained"
              onClick={() => {
                this.changeCommentType("commentBox");
              }}
              color={selectedTool === "commentBox" ? "primary" : "default"}
              startIcon={<CommentIcon />}
            >
              COMMENT
            </Button>
          </div>
        </div>
        <div
          style={{
            height: "calc(100% - 138px)",
            overflow: "auto",
            maxHeight: "300px",
            marginTop: "10px",
          }}
        >
          <List component="nav">
            {this.props.commentRois
              .map((commentRoi, i) => {
                return (
                  <ListItem
                    key={i}
                    button
                    selected={this.props.selectedCommentIdx === i}
                    onClick={() => this.props.changeSelectedCommentIdx(i)}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        {commentRoi.type === "rectangle" && <Crop169Icon />}
                        {commentRoi.type === "arrow" && (
                          <ArrowForwardOutlinedIcon />
                        )}
                        {commentRoi.type === "region" && (
                          <FontAwesomeIcon icon={faDrawPolygon} />
                        )}
                        {commentRoi.type === "distance" && (
                          <FontAwesomeIcon icon={faRuler} />
                        )}
                        {commentRoi.type === "commentBox" && <CommentIcon />}
                      </Avatar>
                    </ListItemAvatar>
                    <TextField
                      label={
                        commentRoi.computedValue
                          ? commentRoi.computedValue.length > 0 &&
                            commentRoi.type !== "commentBox"
                            ? "Comment, Size: " + commentRoi.computedValue
                            : "Comment"
                          : "Comment"
                      }
                      style={{ marginRight: "36px" }}
                      fullWidth
                      multiline
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={commentRoi.commentValue}
                      onChange={(e) => {
                        this.props.changeSelectedCommentValue(
                          i,
                          e.target.value
                        );
                      }}
                    />
                    <div style={{ marginLeft: "-36px", marginRight: "36px" }}>
                      {commentRoi.type !== "commentBox" && (
                        <SketchColorPicker
                          color={commentRoi.color}
                          handleChange={(color) => {
                            this.props.changeSelectedColor(i, color);
                          }}
                        />
                      )}
                    </div>
                    <ListItemSecondaryAction>
                      <Tooltip
                        disableInteractive
                        title="Change Comment Font Size"
                      >
                        <IconButton
                          edge="end"
                          aria-label="changeRoiFontSize"
                          onClick={() => {
                            this.props.changeRoiFontSize(i);
                          }}
                          size="large"
                        >
                          <FormatSizeIcon />
                        </IconButton>
                      </Tooltip>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => this.props.deleteCommentRoi(i)}
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })
              .reverse()}
          </List>
        </div>
      </div>
    );
  }
}

ConfigForm.propTypes = {
  commentState: PropTypes.object,
  changeCommentState: PropTypes.func,
  commentRois: PropTypes.array,
  selectedCommentIdx: PropTypes.number,
  changeSelectedCommentIdx: PropTypes.func,
  changeSelectedCommentValue: PropTypes.func,
  changeSelectedColor: PropTypes.func,
  changeRoiFontSize: PropTypes.func,
  deleteCommentRoi: PropTypes.func,
};

export default CommentTool;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

const styles = () => ({
  root: {
    position: "relative",
    height: "100%",
    width: "100%",
    textAlign: "center",
  },
  view3d: { position: "relative", width: "100%", height: 456, zIndex: 100000 },
  hiddenView3d: {
    position: "absolute",
    width: "100%",
    height: 456,
    visibility: "hidden",
  },
});

class Preview3d extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shownProteinId: "",
    };
  }
  addScript = (src) => {
    let script = document.getElementById(src);
    if (script === null) {
      script = document.createElement("script");
      script.id = src;
      script.src = src;
      script.async = false;
      document.head.appendChild(script);
    }

    return script;
  };
  addStyle = (href) => {
    let link = document.getElementById(href);
    if (link === null) {
      link = document.createElement("link");
      link.id = href;
      link.href = href;
      link.rel = "stylesheet";
      document.head.appendChild(link);
    }
  };
  initPeptide = () => {};

  updateProtein = () => {
    if (this.props.selectedRow) {
      if (this.state.shownProteinId !== this.props.selectedRow.proteinId) {
        this.getUniprotData(this.props.selectedRow, (found) => {
          console.log("result found:", found);
        });
      }
    }
  };

  componentDidUpdate = () => {
    this.updateProtein();
  };

  getUniprotData = (selectedRow, callback) => {
    let proteinId = selectedRow.proteinId;
    let locationMin = selectedRow.location;
    let locationMax = selectedRow.location + selectedRow.name.length;
    let viewer3D = this;
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        //myFunction(this);
        let xmlDoc = this.responseXML;
        //let x = xmlDoc.getElementsByTagName("dbReference");
        for (let dbReference of xmlDoc.getElementsByTagName("dbReference")) {
          if (dbReference.getAttribute("type") === "PDB") {
            let viewerId = dbReference.getAttribute("id");
            for (let property of dbReference.childNodes) {
              if (
                property.getAttribute &&
                property.getAttribute("type") === "chains"
              ) {
                let chainsArr = property.getAttribute("value").split(", ");
                for (let value of chainsArr) {
                  let valueArr = value.split("=");
                  let chain = valueArr[0];
                  let positionsArr = valueArr[1].split("-");
                  let minPos = positionsArr[0];
                  let maxPos = positionsArr[1];
                  if (locationMin > minPos && locationMax < maxPos) {
                    viewer3D.openMolecule(
                      proteinId,
                      viewerId,
                      chain,
                      locationMin,
                      locationMax
                    );
                    callback(true);
                    return;
                  }
                }
              }
            }
          }
        }
      }
      callback(false);
    };
    xmlhttp.open(
      "GET",
      "https://www.uniprot.org/uniprot/" + proteinId + ".xml",
      true
    );
    xmlhttp.send();
  };

  getLiteMolScope = () => {
    if (typeof this.liteMolScope === "undefined") {
      let element = document.getElementById("litemol_1cbs");
      this.liteMolScope = window.angular.element(element).isolateScope();
    }
    return this.liteMolScope;
  };

  openMolecule = (proteinId, moleculeId, chain, locationMin, locationMax) => {
    this.setState({ shownProteinId: proteinId });
    const liteMolScope = this.getLiteMolScope();
    liteMolScope.LiteMolComponent.destroyPlugin();
    liteMolScope.LiteMolComponent.createPlugin();
    liteMolScope.LiteMolComponent.moleculeId = moleculeId;
    liteMolScope.LiteMolComponent.loadMolecule();
    //this.liteMolScope.LiteMolComponent.colorChains("A", [255, 0, 0]);
    setTimeout(() => {
      let sequenceAnnotation =
        liteMolScope.LiteMolComponent.loadSequenceAnnotation();
      console.log("sequenceAnnotation:", sequenceAnnotation);
      liteMolScope.LiteMolComponent.colorChains("#nothing#", [0, 255, 0]);
      console.log("chain:", chain);
      chain =
        chain.split("/").length > 1 ? chain.split("/")[1] : chain.split("/")[0];
      let selectionDetails = {
        entity_id: "1",
        struct_asym_id: chain,
        start_residue_number: locationMin,
        end_residue_number: locationMax,
      };
      liteMolScope.LiteMolComponent.highlightOn(selectionDetails);
    }, 1000);
  };

  initLiteMolScope = () => {
    console.log("initLiteMolScope()");
    //Bootstrapping / Installing the library
    window.angular.element(document).ready(() => {
      this.litemolelem = document.getElementById("litemol_1cbs");
      window.angular.bootstrap(document, ["pdb.component.library"]);
    });

    //bind to the component scope on window.onload
    window.onload = () => {
      window.liteMolElement = document.getElementById("litemol_1cbs");
      window.liteMolScope = window.angular
        .element(window.liteMolElement)
        .isolateScope();
    };
  };

  componentDidMount = () => {
    console.log("Preview3d componentDidMOunt()");
    this.addStyle(
      "//www.ebi.ac.uk/pdbe/pdb-component-library/v1.0/css/pdb.component.library.min-1.0.0.css"
    );
    const angularScript = this.addScript(
      "https://www.ebi.ac.uk/pdbe/pdb-component-library/libs/angular.1.4.7.min.js"
    );
    this.addScript(
      "https://www.ebi.ac.uk/pdbe/pdb-component-library/libs/d3.min.js"
    );
    this.addScript(
      "https://www.ebi.ac.uk/pdbe/pdb-component-library/v1.0/js/pdb.component.library.min-1.0.0.js"
    );
    if (typeof window.angular === "undefined") {
      angularScript.onload = () => {
        this.initLiteMolScope();
      };
    } else {
      let element = document.getElementById("litemol_1cbs");
      element.replaceWith(window.liteMolElement);
      window.liteMolScope = window.angular.element(element).isolateScope();
    }
    this.updateProtein();
  };
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.props.selectedRow ? (
          <Typography variant="h6">
            Protein ID: {this.props.selectedRow.proteinId}
          </Typography>
        ) : (
          <Typography variant="body1">
            Please select a protein from the results to view its properties
            here.
          </Typography>
        )}
        <div
          className={
            this.props.selectedRow &&
            this.props.selectedRow.proteinId === this.state.shownProteinId
              ? classes.view3d
              : classes.hiddenView3d
          }
        >
          <pdb-lite-mol id="litemol_1cbs" hide-controls="false"></pdb-lite-mol>
        </div>
        {this.props.selectedRow && (
          <div className={classes.centeredRow}>
            <a
              href={
                "https://www.uniprot.org/uniprot/" +
                this.props.selectedRow.proteinId
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.uniprot.org/uniprot/
              {this.props.selectedRow.proteinId}
            </a>
          </div>
        )}
      </div>
    );
  }
}

Preview3d.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedRow: PropTypes.object,
};

export default withStyles(styles)(Preview3d);

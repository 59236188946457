import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  Grid,
  ListItem,
  ListItemText,
  ListItemIcon,
  List,
  Tooltip,
  DialogActions,
} from "@mui/material";
import Backend from "../../common/utils/Backend";
import { Folder, ArrowUpward } from "@mui/icons-material";

class FolderChooser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dirfiles: [],
      currentPath: "",
    };

    this.updateFolderList("");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open && this.props.open) {
      this.updateFolderList("");
    }
  }

  updateFolderList = (currentPath) => {
    // load list of available project types from backend
    Backend.walkDir(currentPath, (e) => {
      let filteredDirFiles = e.filter((entry) => {
        if (entry.type === "directory") {
          let sameMrxsFile = e.find((element) => {
            return element.path === entry.path + ".mrxs";
          });
          return typeof sameMrxsFile === "undefined";
        } else {
          return true;
        }
      });
      // write received info into state
      this.setState({
        currentPath: currentPath,
        dirfiles: filteredDirFiles,
      });
    });
  };

  isDisabled = (relativePath) => {
    let { project } = this.props;
    if (project) {
      const projectFiles = [
        ...new Set(project.files.map((file) => file.relativePath)),
      ];
      if (projectFiles.includes(relativePath)) return true;
    }
    return false;
  };

  render() {
    const { onClose, settings, ...other } = this.props;

    const { dirfiles, currentPath } = this.state;

    return (
      <Dialog onClose={() => onClose()} {...other} maxWidth="lg">
        <DialogTitle>Choose Directory for Auto Import</DialogTitle>
        <DialogContent style={{ paddingTop: 0, width: 800 }}>
          <Grid container>
            <List
              dense={true}
              style={{
                border: "2px solid rgb(218, 218, 218)",
                minHeight: "400px",
                maxHeight: "500px",
                width: "100%",
                overflowY: "auto",
              }}
            >
              {currentPath && (
                <ListItem>
                  <ListItemIcon>
                    <Tooltip
                      disableInteractive
                      title={`Up to "${
                        currentPath.substring(
                          0,
                          currentPath.lastIndexOf("\\")
                        ) || "root"
                      }"`}
                    >
                      <IconButton
                        onClick={() => {
                          this.updateFolderList(
                            currentPath.substring(
                              0,
                              currentPath.lastIndexOf("\\")
                            )
                          );
                        }}
                        size="large"
                      >
                        <ArrowUpward />
                      </IconButton>
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary={currentPath} />
                </ListItem>
              )}
              {dirfiles.map((e, id) =>
                e.type === "file" ? null : (
                  <ListItem
                    key={id}
                    button
                    onClick={() => this.updateFolderList(e.path)}
                  >
                    <ListItemIcon style={{ color: "#FFE896" }}>
                      <Folder />
                    </ListItemIcon>
                    <ListItemText
                      primary={e.path.replace(/^.*[\\/]/, "")}
                      style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                    />
                  </ListItem>
                )
              )}
            </List>
          </Grid>
          <br />
          <Grid container>
            <TextField
              fullWidth
              label="Folder"
              value={settings.storagePath + currentPath}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => onClose(currentPath)}
            color="primary"
            autoFocus
          >
            Choose
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

FolderChooser.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  project: PropTypes.object,
  settings: PropTypes.object,
};

export default FolderChooser;

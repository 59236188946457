import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import { List, ListItem, ListItemText, Typography } from "@mui/material";

import { withSpectraViewer } from "../contexts/SpectraViewerContext";

const styles = {
  root: {
    height: "100%",
    overflow: "auto",
    opacity: 0.8,
    backgroundColor: "#000000",
    color: "#ffffff",
  },
  mainContent: {
    overflow: "auto",
  },
};

class SpectraLeftTableResults extends Component {
  render() {
    const { classes } = this.props;
    const { rawSpectra } = this.props.spectraViewer;
    return (
      <div className={classes.root}>
        <div className={classes.mainContent}>
          {rawSpectra && rawSpectra.length > 0 && (
            <List>
              <ListItem>
                <ListItemText primary={"Type"} />
                <Typography style={{ marginRight: "10px" }}>Value</Typography>
              </ListItem>
              {Object.keys(
                rawSpectra[this.props.spectraViewer.selectedSpectrum].meta
                  .measurement
              ).map((key, idx) => {
                const value =
                  rawSpectra[this.props.spectraViewer.selectedSpectrum].meta
                    .measurement[key];
                return (
                  <ListItem key={idx}>
                    <ListItemText primary={key + ":"} />
                    <Typography
                      style={{
                        marginRight: "10px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {value.toString()}
                    </Typography>
                  </ListItem>
                );
              })}
            </List>
          )}
        </div>
      </div>
    );
  }
}

SpectraLeftTableResults.propTypes = {
  classes: PropTypes.object.isRequired,
  spectraViewer: PropTypes.object.isRequired,
  leftTableWidth: PropTypes.number,
  setParentState: PropTypes.func,
};

export default withSpectraViewer(withStyles(styles)(SpectraLeftTableResults));

import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { lighten, darken } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import { v4 as uuidv4 } from "uuid";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Backend from "../../common/utils/Backend";

const caseState = {
  cs0: {
    label: "In preperation",
    color: "#8B572A",
  },
  cs1: {
    label: "For Review",
    color: "#417505",
  },
  cs2: {
    label: "Finished",
    color: "#417505",
  },
  cs3: {
    label: "Customer Data",
    color: "#4A90E2",
  },
};

const tags = {
  t1: {
    label: "High Priority",
    color: "red",
  },
  t2: {
    label: "US",
    color: "lightblue",
  },
  t3: {
    label: "VisioPharm",
    color: "blue",
  },
  t4: {
    label: "Breast",
    color: "purple",
  },
};

// const patients = {
//   p1: {
//     firstName: "Rose",
//     lastName: "Acacia",
//     birthDate: "27.11.1977",
//     gender: "Female",
//   },
//   p2: {
//     firstName: "Kaylee",
//     lastName: "Rogers",
//     birthDate: "11.02.1970",
//     gender: "Male",
//   },
//   p3: {
//     firstName: "Nora",
//     lastName: "Hernandez",
//     birthDate: "02.02.1927",
//     gender: "Female",
//   },
//   p4: {
//     firstName: "Fraser",
//     lastName: "Morris",
//     birthDate: "12.11.1987",
//     gender: "Male",
//   },
// };

// const Users = {
//   u1: {
//     firstName: "Brittany",
//     lastName: "Hill",
//   },
//   u2: {
//     firstName: "Dona",
//     lastName: "Murphy",
//   },
//   u3: {
//     firstName: "Robyn",
//     lastName: "Flores",
//   },
// };

const cases = [
  {
    id: 0,
    caseId: "H14-DEMO-0001-US",
    creationDate: "5/26/2020",
    modificationDat: "5/26/2020",
    Patient: "p1",
    description: "Breast",
    caseHolder: "u1",
    caseState: "cs1",
    tags: ["t1", "t2"],
  },
  {
    id: 1,
    caseId: "T15-0103-AP",
    creationDate: "5/26/2020",
    modificationDat: "5/26/2020",
    Patient: "p2",
    description: "Brain",
    caseHolder: "u1",
    caseState: "cs2",
    tags: ["t4", "t2"],
  },
  {
    id: 2,
    caseId: "T15-0103-US",
    creationDate: "5/26/2020",
    modificationDat: "5/26/2020",
    Patient: "p3",
    description: "Brain",
    caseHolder: "u1",
    caseState: "cs3",
    tags: [],
  },
  {
    id: 3,
    caseId: "T13-0091-AP",
    creationDate: "5/26/2020",
    modificationDat: "5/26/2020",
    Patient: "p1",
    description: "Sigmoid",
    caseHolder: "u1",
    caseState: "cs3",
    tags: ["t3", "t4"],
  },
  {
    id: 4,
    caseId: "T14-0103-AP",
    creationDate: "5/26/2020",
    modificationDat: "5/26/2020",
    Patient: "p4",
    description: "Skin",
    caseHolder: "u1",
    caseState: "cs0",
    tags: ["t4"],
  },
];

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  {
    id: "readableId",
    numeric: false,
    disablePadding: false,
    label: "ID",
    width: "50px",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    width: "",
  },
  {
    id: "creationDateTime",
    numeric: true,
    disablePadding: false,
    label: "Created",
    width: "100px",
  },
  {
    id: "tags",
    numeric: false,
    label: "Tags",
    width: "100px",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    width: "150px",
  },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = () => () => {
    //this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox checked={numSelected > 0} onChange={onSelectAllClick} />
          </TableCell>
          {rows.map(
            (row) => (
              <TableCell
                key={row.id}
                align={row.numeric ? "right" : "left"}
                padding={row.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  disableInteractive
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onSelectAllClick: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  numSelected: PropTypes.number,
};

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
});

let EnhancedTableToolbar = (props) => {
  const { numSelected, classes, onDeleteClick } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Cases
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 && (
          <div style={{ width: "max-content" }}>
            <Tooltip disableInteractive title="Delete selected projects">
              <IconButton
                aria-label="Delete"
                onClick={onDeleteClick}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  onDeleteClick: PropTypes.func,
  numSelected: PropTypes.number,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = () => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 500,
  },
  tableRow: {
    cursor: "pointer",
  },
  tableWrapper: {
    overflowX: "auto",
    height: "calc(100vh - 232px)",
  },
  paginationSpacer: {
    flex: "0.0 1 0%",
  },
  creatingJobBar: {
    backgroundColor: darken("#216DC0", 0.3),
  },
  input: {
    width: "calc(100% - 48px)",
  },
  tagBox: {
    display: "inline-block",
    width: 20,
    height: 20,
    borderRadius: 5,
    color: "#fff",
    border: "1px solid rgba(0, 0, 0, 0.54)",
    margin: 2,
  },
  caseStateContainer: {
    borderRadius: 5,
    padding: 5,
    width: "100%",
    textAlign: "center",
    border: "1px solid rgba(0, 0, 0, 0.54)",
    color: "white",
  },
});

class ProjectsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "desc",
      orderBy: "changeDateTime",
      selected: [],
      page: 0,
      rowsPerPage: 50,
      activeTab: 0,
    };
    Backend.loadAvailableProjectTypes((e) => {
      this.setState({ projectTypes: e });
    });
  }

  handleChange = (event, value) => {
    if (value !== this.state.activeTab) {
      if (value === 1) {
        window.document.title = `Finished - HSA Kit ${window.version}`;
      } else {
        window.document.title = `Projects - HSA Kit ${window.version}`;
      }
      if (this.state.selected.length > 0) {
        this.setState({
          activeTab: value,
          selected: [],
        });
      } else {
        this.setState({
          activeTab: value,
        });
      }
    }
  };

  handleClick = (event, value) => {
    console.log("value", value);
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;
  handleChangePage = () => {
    console.log("change page");
  };

  render() {
    const { classes } = this.props;
    const projects = cases;

    const orderBy = "id";
    const { order, selected, rowsPerPage, page, activeTab } = this.state;

    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
          onDeleteClick={() => console.log("delte click")}
        />

        <div className={classes.tableWrapper}>
          <Table className={classes.table} style={{ tableLayout: "fixed" }}>
            <colgroup>
              <col width="50px" />
              {rows.map((row) => (
                <col key={uuidv4()} width={row.width} />
              ))}
            </colgroup>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={cases.length}
              activeTab={this.state.activeTab}
            />
            <TableBody>
              {stableSort(cases, getSorting(order, orderBy))
                .filter((row) => {
                  if (activeTab === 1) {
                    return row.state === "success";
                  } else {
                    return row.state !== "success";
                  }
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n) => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      onClick={() => this.props.onChangeSelection(n.id)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                      onContextMenu={(event) =>
                        this.handleContextmenu(event, n)
                      }
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isSelected}
                          onClick={(event) =>
                            this.handleSelectOneClick(event, n)
                          }
                        />
                      </TableCell>
                      <TableCell align="left">{n.id}</TableCell>
                      <TableCell align="left">{n.caseId}</TableCell>
                      <TableCell align="right">{n.creationDate}</TableCell>
                      <TableCell align="left">
                        {n.tags.map((tag) => (
                          <Tooltip
                            disableInteractive
                            key={uuidv4()}
                            title={tags[tag].label}
                            placement="top"
                          >
                            <div
                              className={classes.tagBox}
                              style={{ background: tags[tag].color }}
                            />
                          </Tooltip>
                        ))}
                      </TableCell>
                      <TableCell align="right">
                        <div
                          className={classes.caseStateContainer}
                          style={{ background: caseState[n.caseState].color }}
                        >
                          {caseState[n.caseState].label}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[50, 100, 250]}
          classes={{ spacer: classes.paginationSpacer }}
          component="div"
          count={projects.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

ProjectsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  projects: PropTypes.array,
  selectedCase: PropTypes.number,
  onChangeSelection: PropTypes.func,
};

export default withStyles(styles)(ProjectsTable);

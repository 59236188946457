import { withPersistentStorage } from "./PersistentStorageContext";
import { withProjectHistory } from "./ProjectHistoryContext";
import { withProject } from "./ProjectContext";
import { withSpinloader } from "../../common/components/Spinloader";

/**
 * Wraps often used react contexts arrour a component and saves some lines ^^
 *
 * @param {React.Component} component - React Component that will be wrapped
 */
export const withAllViewerContexts = (component) =>
  withPersistentStorage(
    withProjectHistory(withProject(withSpinloader(component)))
  );

import React, { Component } from "react";
import PropTypes from "prop-types";

import { RegionROI } from "../../utils/ROI";
import RBush from "rbush";
import { updateDrawLayer } from "../../utils/PolygonUtil";
import Backend from "../../../common/utils/Backend";

import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { hasIntersection } from "../../utils/PolygonUtil";

import Tool from "./Tool";
import Grid from "@mui/material/Grid";

class GridAnnotationTool extends Tool {
  name = "GridAnnotationTool";
  gridSize = 512;
  overlap = 0;
  startPoint = null;
  endPoint = null;
  pointTL = { x: 0, y: 0 };
  pointBR = { x: 0, y: 0 };
  tiles = [];
  rightClick = false;
  n = 9;
  gridOffsetX = 0;
  gridOffsetY = 0;
  progressText = "make a selection";
  exportClasses = true;
  baseROIOnly = true;
  currentFileId = null;
  init = false;

  setLayer(obj) {
    this.ome = obj.ome;
    this.fileId = obj.fileId;
    this.structures = obj.structures;
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    this.selectedLayer = obj.selectedLayer;
    this.allRoiLayers = obj.allRoiLayers;
    this.project = obj.viewerConfig_project;
    this.projectNoViewConfig = obj.project;
    this.drawLayer = obj.drawLayer;
    this.rendererDict = obj.rendererDict;
    this.gridLayer = this.structures.findIndex((s) => s.label === "Grid");
    this.gridLayer = this.gridLayer > 0 ? this.gridLayer : 0;
    window.tileExportProgress = this.printFunction;
    this.disableExport =
      this.structures.findIndex((s) => s.label === "Grid") > -1 ? false : true;
    if (this.currentFileId !== this.fileId) {
      this.tiles = [];
      this.currentFileId = this.fileId;
    }
    if (!this.init) {
      let rendererObj = this.rendererDict[
        this.fileId
      ].props.persistentStorage.load("gridConfig" + this.fileId);
      if (rendererObj) {
        this.gridSize = rendererObj.gridSize;
        this.overlap = rendererObj.overlap;
        this.gridOffsetX = rendererObj.gridOffsetX;
        this.gridOffsetY = rendererObj.gridOffsetY;
        this.rendererDict[this.fileId].gridTileCount = rendererObj.tileCount;
      }
      this.init = true;
    }
    if (
      this.rendererDict[this.fileId].gridTileCount !==
      this.roiLayers[this.gridLayer].layer.regionRois.length
    ) {
      this.tiles = [];
    }
  }

  mouse(params) {
    let { event, p } = params;
    if (event.type === "mousedown") {
      if (this.startPoint === null && event.button !== 1) {
        this.startPoint = p;
      }
      if (event.button === 2) {
        this.rightClick = true;
      }
    } else if (event.type === "mouseup") {
      if (this.startPoint !== null) {
        this.endPoint = p;
        this.createSelectionArea(this.startPoint, this.endPoint);
        if (this.structures[this.gridLayer].label === "Grid") {
          this.treeSelection();
        }

        this.startPoint = null;
        this.drawLayer.regionRois = [];
        this.rightClick = false;
      }
    } else if (event.type === "mousemove" && this.startPoint) {
      this.endPoint = p;
      this.createSelectionArea(this.startPoint, this.endPoint);
      if (this.structures[this.gridLayer].label === "Grid") {
        this.treeSelection();
      }
    }
  }

  //mark tiles
  drawCustomCursor(ctx, mp, f) {
    if (this.tiles.length > 0 && this.gridLayer !== 0) {
      for (let tile of this.tiles) {
        if (tile.roi.comment !== "export flagged") {
          let idx = this.tiles.indexOf(tile);
          if (idx >= 0) {
            this.tiles.splice(idx, 1);
          }
        }
      }
      ctx.beginPath();
      ctx.globalAlpha = 1.0;
      ctx.strokeStyle = "#03FF00";
      ctx.lineWidth = 3 / f;
      this.tiles.forEach((e) => {
        ctx.rect(e.minX, e.minY, e.maxX - e.minX, e.maxY - e.minY);
      });

      ctx.stroke();
      ctx.closePath();
    }
  }

  createSelectionArea = (sP, eP) => {
    if (sP.x <= eP.x) {
      this.pointTL.x = sP.x;
      this.pointBR.x = eP.x;
    } else if (sP.x > eP.x) {
      this.pointTL.x = eP.x;
      this.pointBR.x = sP.x;
    }

    if (sP.y <= eP.y) {
      this.pointTL.y = sP.y;
      this.pointBR.y = eP.y;
    } else if (sP.y > eP.y) {
      this.pointTL.y = eP.y;
      this.pointBR.y = sP.y;
    }

    let points = [];
    points.push([this.pointTL.x, this.pointTL.y]);
    points.push([this.pointBR.x, this.pointTL.y]);
    points.push([this.pointBR.x, this.pointBR.y]);
    points.push([this.pointTL.x, this.pointBR.y]);

    let drawRegion = {
      regions: [points],
      inverted: false,
    };

    this.drawLayer.regionRois = []; //only one region will be drawn to the draw layer
    updateDrawLayer(this.drawLayer, drawRegion, false, "#FF0000", 0, "zero");
  };

  treeSelection = () => {
    let treeItems = this.roiLayers[this.gridLayer].tree.search({
      minX: this.pointTL.x,
      minY: this.pointTL.y,
      maxX: this.pointBR.x,
      maxY: this.pointBR.y,
    });
    for (let treeItem of treeItems) {
      if (treeItem.roi.comment === "" && !this.rightClick) {
        treeItem.roi.comment = "export flagged";
        this.tiles.push(treeItem);
      } else if (treeItem.roi.comment === "export flagged" && this.rightClick) {
        if (this.tiles.includes(treeItem)) {
          treeItem.roi.comment = "";
          let idx = this.tiles.indexOf(treeItem);
          if (idx >= 0) {
            this.tiles.splice(idx, 1);
          }
        }
      } else if (
        treeItem.roi.comment === "export flagged" &&
        !this.rightClick
      ) {
        if (!this.tiles.includes(treeItem)) {
          this.tiles.push(treeItem);
        }
      }
    }
  };

  createGrid = () => {
    let baseRois = [];
    if (this.baseROIOnly) {
      baseRois = this.roiLayers[0].layer.regionRois;
      if (baseRois.length === 0) {
        window.showWarningSnackbar("No Base ROI set!");
        return;
      }
    } else {
      let maxX = this.ome.sizeX;
      let maxY = this.ome.sizeY;
      let wholeSceneRoi = new RegionROI({
        regions: [
          [
            [0, 0],
            [maxX, 0],
            [maxX, maxY],
            [0, maxY],
            [0, 0],
          ],
        ],
      });
      baseRois = [wholeSceneRoi];
    }
    this.tiles = [];
    let idx = this.structures.findIndex((s) => s.label === "Grid");
    let r = this.rendererDict[this.fileId];
    if (idx === -1) {
      r.props.projectContext.addStructure("Grid", true);
      idx = this.structures.findIndex((s) => s.label === "Grid");
    }
    if (idx === -1) {
      this.printFunction("TE;Can't create grid");
      window.showErrorSnackbar("Can't create grid");
      return;
    }
    r.props.setSelectedLayer(idx);

    // remove for loop if it is sure that we only need one color
    let str = idx;
    // delete all rois
    this.roiLayers[str].layer.regionRois = [];
    this.roiLayers[str].tree = new RBush();

    for (let baseRoi of baseRois) {
      // make all tiles
      let regions = [];

      // make x*y tiles
      for (
        let x = baseRoi.bounds.left + this.gridOffsetX;
        x < baseRoi.bounds.right;
        x += this.gridSize - this.overlap
      ) {
        for (
          let y = baseRoi.bounds.top + this.gridOffsetY;
          y < baseRoi.bounds.bottom;
          y += this.gridSize - this.overlap
        ) {
          regions = [];
          // make four corners of tile
          let c_1 = [x, y];
          regions.push(c_1);
          let c_2 = [x + this.gridSize, y];
          regions.push(c_2);
          let c_3 = [x + this.gridSize, y + this.gridSize];
          regions.push(c_3);
          let c_4 = [x, y + this.gridSize];
          regions.push(c_4);
          regions.push(c_1);

          let roi = new RegionROI({ regions: [regions] });
          if (hasIntersection(roi, baseRoi)) {
            this.roiLayers[str].layer.regionRois.push(roi);
            this.roiLayers[str].tree.insert(roi.treeItem);
          }
        }
      }
    }

    r.gridTileCount = this.roiLayers[str].layer.regionRois.length;

    this.saveGridConfig(r.gridTileCount);
  };

  onClickExport = () => {
    let tiles = [];
    for (let tile in this.tiles) {
      tiles.push({ x: this.tiles[tile].minX, y: this.tiles[tile].minY });
    }
    if (tiles.length < 1) {
      this.printFunction("TE;No tiles selected");
      window.showWarningSnackbar("No tiles selected");
      return;
    }
    if (
      this.structures[this.selectedLayer].label === "Grid" ||
      this.structures[this.selectedLayer].label === "Base ROI"
    ) {
      this.printFunction("TE;Select a structure");
      window.showWarningSnackbar("Select a structure");
      return;
    }
    this.printFunction("TE;Creating Dataset...");
    let pconfig = this.projectNoViewConfig.files;
    let fileIndex = pconfig.findIndex((s) => s.id === this.fileId);

    window.showSuccessSnackbar(
      "Selected structure: " + this.structures[this.selectedLayer].label
    );

    let exportObject = {
      size: this.gridSize,
      overlap: this.overlap,
      classes: this.exportClasses,
      baseROIOnly: this.baseROIOnly,
      tiles: tiles,
      selectedStructure: this.structures[this.selectedLayer].label,
      selectedStructureId: this.structures[this.selectedLayer].id,
      fileConfig: pconfig[fileIndex],
      projectId: this.projectNoViewConfig.id,
    };
    Backend.tileExport(exportObject);
  };

  printFunction = (line) => {
    console.debug(line);
    if (line.includes("TE;")) {
      let newLine = line.split(";");
      newLine = newLine[1];
      if (!newLine.includes("ERROR")) {
        this.progressText = newLine;
        if (newLine.includes("Dataset created")) {
          window.showSuccessSnackbar(newLine);
        }
      } else {
        this.progressText = "Dataset could not be created";
      }

      window.forceSidebarUpdate();
    }
  };

  saveGridConfig = (tileCount) => {
    let saveObject = {
      gridSize: this.gridSize,
      overlap: this.overlap,
      gridOffsetX: this.gridOffsetX,
      gridOffsetY: this.gridOffsetY,
      tileCount: tileCount,
    };
    this.rendererDict[this.fileId].props.persistentStorage.save(
      "gridConfig" + this.fileId,
      saveObject
    );
  };

  moveAnnotations = () => {
    this.rendererDict[this.fileId].moveAnnotations();
  };

  exit() {}

  renderConfiguration() {
    return (
      <div>
        <Typography variant="h6">{this.name}:</Typography>
        <ConfigForm
          exportClasses={this.exportClasses}
          baseROIOnly={this.baseROIOnly}
          progressText={this.progressText}
          n={this.n}
          gridSize={this.gridSize}
          onChangeGridSize={(e) => {
            this.n =
              parseInt(e.target.value) > this.gridSize
                ? this.n + 1
                : this.n - 1;
            this.n = this.n > 6 ? this.n : 6;
            this.gridSize = Math.pow(2, this.n);
            this.gridOffsetX = 0;
            this.gridOffsetY = 0;

            if (this.overlap * 2 > this.gridSize) {
              this.overlap = parseInt(this.gridSize / 2, 10);
            }

            window.forceSidebarUpdate();
          }}
          overlap={this.overlap}
          onChangeOverlap={(e) => {
            let value =
              parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value);
            this.overlap = value > this.gridSize ? this.overlap : value;
            window.forceSidebarUpdate();
          }}
          createGrid={this.createGrid}
          onClickExport={this.onClickExport}
          gridOffsetX={this.gridOffsetX}
          gridOffsetY={this.gridOffsetY}
          onChangeGridOffsetX={(e) => {
            let v =
              parseInt(e.target.value) > -this.gridSize &&
              parseInt(e.target.value) < this.gridSize
                ? parseInt(e.target.value)
                : this.gridOffsetX;
            this.gridOffsetX = v;
            window.forceSidebarUpdate();
          }}
          onChangeGridOffsetY={(e) => {
            let v =
              parseInt(e.target.value) > -this.gridSize &&
              parseInt(e.target.value) < this.gridSize
                ? parseInt(e.target.value)
                : this.gridOffsetY;
            this.gridOffsetY = v;
            window.forceSidebarUpdate();
          }}
          onChangeExportClasses={(e) => {
            this.exportClasses = !e;
            window.forceSidebarUpdate();
          }}
          onChangeBaseROIOnly={(e) => {
            this.baseROIOnly = !e;
            window.forceSidebarUpdate();
          }}
          disableExport={this.disableExport}
        />
      </div>
    );
  }
}

class ConfigForm extends Component {
  state = { mode: "copy" };
  render() {
    let {
      gridSize,
      overlap,
      createGrid,
      onClickExport,
      gridOffsetX,
      gridOffsetY,
      progressText,
      exportClasses,
      baseROIOnly,
      disableExport,
    } = this.props;
    return (
      <div>
        <div>{progressText}</div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={exportClasses}
                onChange={() => {
                  this.props.onChangeExportClasses(exportClasses);
                }}
              />
            }
            label="Export with Subtypes"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={baseROIOnly}
                onChange={() => {
                  this.props.onChangeBaseROIOnly(baseROIOnly);
                }}
              />
            }
            label="Consider Base ROI"
          />
        </div>
        <Grid container spacing={2}>
          <Grid item key={"OffsetX"} xs={6}>
            <TextField
              name="grid Offset X"
              margin="normal"
              type="number"
              size="small"
              label="Grid Offset X (px)"
              placeholder="Enter grid size..."
              value={gridOffsetX}
              onChange={(e) => this.props.onChangeGridOffsetX(e)}
              variant="outlined"
            />
          </Grid>
          <Grid item key={"OffsetY"} xs={6}>
            <TextField
              name="grid Offset Y"
              margin="normal"
              type="number"
              size="small"
              label="Grid Offset Y (px)"
              placeholder="Enter grid size..."
              value={gridOffsetY}
              onChange={(e) => this.props.onChangeGridOffsetY(e)}
              variant="outlined"
            />
          </Grid>
          <Grid item key={"Gridsize"} xs={6}>
            <TextField
              inputProps={{ step: Math.pow(2, this.n) }}
              name="grid size"
              margin="normal"
              type="number"
              size="small"
              label="Grid Size (px)"
              placeholder="Enter grid size..."
              value={gridSize}
              onChange={(e) => this.props.onChangeGridSize(e)}
              variant="outlined"
            />
          </Grid>
          <Grid item key={"overlap"} xs={6}>
            <TextField
              inputProps={{ step: 5 }}
              name="overlap"
              margin="normal"
              type="number"
              size="small"
              label="Overlap (px)"
              placeholder="Enter overlap..."
              value={overlap}
              onChange={(e) => this.props.onChangeOverlap(e)}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Button
          style={{ marginTop: "5px" }}
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => createGrid()}
        >
          Create Grid
        </Button>
        <Button
          style={{ marginTop: "5px" }}
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => onClickExport()}
          disabled={disableExport}
        >
          Export Selection
        </Button>
      </div>
    );
  }
}

ConfigForm.propTypes = {
  gridSize: PropTypes.number,
  overlap: PropTypes.number,
  createGrid: PropTypes.func,
  onClickExport: PropTypes.func,
  gridOffsetX: PropTypes.number,
  gridOffsetY: PropTypes.number,
  progressText: PropTypes.string,
  exportClasses: PropTypes.bool,
  baseROIOnly: PropTypes.bool,
  disableExport: PropTypes.bool,
  onChangeExportClasses: PropTypes.func,
  onChangeBaseROIOnly: PropTypes.func,
  onChangeGridOffsetX: PropTypes.func,
  onChangeGridOffsetY: PropTypes.func,
  onChangeGridSize: PropTypes.func,
  onChangeOverlap: PropTypes.func,
};

export default GridAnnotationTool;

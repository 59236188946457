import React, { Component } from "react";
import PropTypes from "prop-types";

import classNames from "classnames";
import withStyles from "@mui/styles/withStyles";

import {
  DialogContent,
  DialogContentText,
  ImageListItem,
  ImageListItemBar,
  ImageList,
} from "@mui/material";

const styles = () => ({
  dialogContent: {},
  images: {
    width: "100%",
    height: "calc(100% - 36px)",
    objectFit: "contain",
  },
  projectTile: {
    cursor: "pointer",
    padding: 5,
    "&:hover": {
      background: "#AAA",
    },
  },
  projectTileSelected: {
    background: "#1BA1E2!important",
  },
  tileHeadline: {
    textAlign: "center",
    padding: 0,
    marginBottom: 4,
    lineHeight: "32px",
  },
  imgContainer: {
    width: "100%",
  },
});

class TrainModelStep1 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, formData, viewerConfig } = this.props;
    let defaultImages = viewerConfig.project.projectProperties[
      "MedicalAICockpitImages"
    ]
      ? false
      : true;

    return (
      <DialogContent className={classes.dialogContent}>
        <DialogContentText
          component={"span"}
          style={{ paddingTop: "0px", marginBottom: "20px" }}
        >
          <div>
            Choose the model type for your training:
            <ul>
              <li>
                <strong>Classification:</strong> assign objects to different
                classes
              </li>
              <li>
                <strong>Object Detection:</strong> detect object and draw
                bounding box around it
              </li>
              <li>
                <strong>Segmentation:</strong> detect object and draw exact
                border around object
              </li>
              <li>
                <strong>Instance Segmentation:</strong> segmentation +
                differentiate between touching objects
              </li>
            </ul>
          </div>
        </DialogContentText>
        <ImageList
          style={{ margin: "0px" }}
          //rowHeight={360}
          spacing={8}
          cols={4}
        >
          <ImageListItem
            className={classNames(
              classes.projectTile,
              formData["modelType"] === "classification" &&
                classes.projectTileSelected
            )}
            onClick={() =>
              this.props.updateFormDataGeneral("modelType", "classification")
            }
          >
            <img
              src={
                defaultImages
                  ? "img/classification_default.jpg"
                  : "img/classification_med.jpg"
              }
              alt="classification img"
              className={classes.images}
            />
            <ImageListItemBar title="Classification" position="bottom" />
          </ImageListItem>
          <ImageListItem
            className={classNames(
              classes.projectTile,
              formData["modelType"] === "object detection" &&
                classes.projectTileSelected
            )}
            onClick={() =>
              this.props.updateFormDataGeneral("modelType", "object detection")
            }
          >
            <img
              src={
                defaultImages
                  ? "img/object_detection_default.jpg"
                  : "img/object_detection_med.jpg"
              }
              alt="objdetection img"
              className={classes.images}
            />
            <ImageListItemBar title="Object Detection" position="bottom" />
          </ImageListItem>
          <ImageListItem
            className={classNames(
              classes.projectTile,
              formData["modelType"] === "segmentation" &&
                classes.projectTileSelected
            )}
            onClick={() =>
              this.props.updateFormDataGeneral("modelType", "segmentation")
            }
          >
            <img
              src={
                defaultImages
                  ? "img/segmentation_default.jpg"
                  : "img/segmentation_med.jpg"
              }
              alt="segmentation img"
              className={classes.images}
              loading="lazy"
            />
            <ImageListItemBar title="Segmentation" position="bottom" />
          </ImageListItem>
          <ImageListItem
            className={classNames(
              classes.projectTile,
              formData["modelType"] === "instance segmentation" &&
                classes.projectTileSelected
            )}
            onClick={() =>
              this.props.updateFormDataGeneral(
                "modelType",
                "instance segmentation"
              )
            }
          >
            <img
              src={
                defaultImages
                  ? "img/instance_segmentation_default.jpg"
                  : "img/instance_segmentation_med.jpg"
              }
              alt="segmentation img"
              className={classes.images}
            />
            <ImageListItemBar title="Instance Segmentation" position="bottom" />
          </ImageListItem>
        </ImageList>
      </DialogContent>
    );
  }
}

TrainModelStep1.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  projectType: PropTypes.string,
  onChangeName: PropTypes.func,
  onSaveName: PropTypes.func,
  availableTypes: PropTypes.array,
  onProjectType: PropTypes.func,
  preloadedImages: PropTypes.object,
  formData: PropTypes.object,
  updateFormDataGeneral: PropTypes.func,
  viewerConfig: PropTypes.object,
};

export default withStyles(styles)(TrainModelStep1);

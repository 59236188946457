import React, { Component } from "react";

import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";

import {
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  MenuItem,
  TextField,
  IconButton,
} from "@mui/material";
import PublishIcon from "@mui/icons-material/Publish";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import Backend from "../../../common/utils/Backend";
import CircularProgress from "@mui/material/CircularProgress";

import { withAllViewerContexts } from "../../contexts/AllViewerContexts";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const styles = {
  root: {
    width: "100%",
  },
};
let usableModels = {};

class SideBarTabAISelectByModel extends Component {
  constructor(props) {
    super(props);
    this.selectedModels = [];
    this.aiCockpitLoaded = false;
    this.state = {
      expandedIdx: -1, //nothing expanded on mount
      export: false,
      delete: false,
      init: false,
    };
  }

  componentDidUpdate = () => {
    if (this.props.formDataAICockpit && !this.aiCockpitLoaded) {
      this.aiCockpitLoaded = true;
      this.init();
    }

    this.updateModelCheckboxes();
  };

  modelIsSelected = (modelName) => {
    return this.selectedModels.indexOf(modelName) > -1;
  };

  /**
   * Evaluate all AI model checkboxes, if they should be checked or not.
   * Models for a structure with an appointed model should be deactivated.
   * Needed values are stored in props.
   */
  updateModelCheckboxes = () => {
    const { formDataAICockpit } = this.props;
    let checkboxesChanged = false;
    if (formDataAICockpit) {
      // Iterate over all present models
      for (const [key, model] of Object.entries(usableModels)) {
        let modelIsDisabled = false;
        let modelIsChecked = false;

        // Iterate over all possible structures of each model
        for (let usableStructure of model.usableStructures) {
          // Get current state of currently viewed possible structure of current model
          let selectedModel =
            formDataAICockpit[usableStructure.id].selectedModel;
          let structureIsChecked = usableStructure.isChecked;
          let structureIsDisabled = usableStructure.isDisabled;

          // A model has already been assigned to the current structure
          if (selectedModel !== null) {
            structureIsChecked = true;

            // It is the same as the current model
            if (key === selectedModel) {
              modelIsChecked = true;
              structureIsDisabled = false;
            }
            // It is a different model
            else {
              structureIsDisabled = true;
              modelIsDisabled = true;
            }
          }
          // No model selected for this structure
          else {
            structureIsChecked = false;
            structureIsDisabled = false;
          }

          // Update usable structure attibutes
          if (
            structureIsChecked !== usableStructure.isChecked ||
            structureIsDisabled !== usableStructure.isDisabled
          ) {
            usableStructure.isDisabled = structureIsDisabled;
            usableStructure.isChecked = structureIsChecked;
            checkboxesChanged = true;
          }
        }

        // Update model attributes
        if (
          modelIsDisabled !== model.isDisabled ||
          modelIsChecked !== model.isChecked
        ) {
          model.isDisabled = modelIsDisabled;
          model.isChecked = modelIsChecked;
          checkboxesChanged = true;
        }
      }
      if (checkboxesChanged) {
        this.updateSelectedTool();
      }
    }
  };

  /**
   * Runs all checks when component initializes or updates.
   */
  init = () => {
    const { structures, formDataAICockpit, availableModels } = this.props;
    usableModels = {};

    for (let row of Object.values(formDataAICockpit)) {
      // Filter models per structure
      let availableModelsForStructure =
        this.props.getAvailableModelsForStructure(
          availableModels,
          row.fullStructure
        );

      // Extract necessary information from structure
      let usableStructure = {
        id: row.fullStructure.id,
        structurePath: row.fullStructure.label,
        name: row.fullStructure.label,
        isChecked: false,
        isDisabled: false,
      };

      // Find the topmost parent and build the structure path name as follows:
      // Top - Intermediate - Intermediate - Target Structure
      let parentId = row.fullStructure.parentId;
      let findParentId = (structure) => structure.id === parentId;
      while (parentId > 0) {
        let parentStructure = structures.find(findParentId);
        if (parentStructure) {
          usableStructure.structurePath =
            parentStructure.label + " - " + usableStructure.structurePath;

          parentId = parentStructure.parentId;
        } else {
          parentId = -1;
        }
      }

      if (availableModelsForStructure) {
        // Ensure structures are assigned to models
        for (let model of availableModelsForStructure) {
          if (model.versions.length === 0) continue;
          let usableStructurClone = JSON.parse(JSON.stringify(usableStructure));

          // In case of missing model, insert model into usable models
          if (!(model.name in usableModels)) {
            // Create new usable model from model
            usableModels[model.name] = {
              label: model.label,
              name: model.name,
              versions: [...model.versions],
              usableStructures: [usableStructurClone],
              isChecked: false,
              isDisabled: false,
            };

            try {
              usableModels[model.name].structure_indices =
                model.versions[model.versions.length - 1].structure_indices;
            } catch {
              console.log(
                "Error: Structure indices in last model version not found"
              );
            }
          }

          // In case of normal model, simply add model
          else {
            usableModels[model.name].usableStructures.push(usableStructurClone);
          }
        }
      }
    }

    this.updateModelCheckboxes();
    this.setState({ init: true });
  };

  componentDidMount = () => {
    if (this.props.formDataAICockpit && !this.aiCockpitLoaded) {
      this.aiCockpitLoaded = true;
      this.init();
    }
  };

  updateSelectedTool = () => {
    // No model is selected -> exit
    if (!(this.state.expandedIdx >= 0)) {
      this.props.onChangeTool("none");
      return;
    }

    // Only modify the currently selected model
    // Except models with "Hyper" and/or "Efficiant" in their name -> exit
    let selectedModel = Object.values(usableModels)[this.state.expandedIdx];
    if (
      !selectedModel.isChecked ||
      selectedModel.name.includes("Hyper") ||
      selectedModel.name.includes("Efficient")
    ) {
      this.props.onChangeTool("none");
      return;
    }

    for (let structure of selectedModel.usableStructures) {
      // Ensure that each structure only has 1 model assigned.
      if (structure.isDisabled || !structure.isChecked) {
        continue;
      }

      // Assign checked model to its structure.
      this.props.setAiUsedStructures(
        {
          structureName: structure.name,
          selectedModel: selectedModel.name,
          selectedVersion: selectedModel.versions[0].label,
          id: structure.id,
        },
        "add"
      );
      // No break
    }

    for (let structure of selectedModel.usableStructures) {
      if (structure.isDisabled || !structure.isChecked) {
        continue;
      }

      // Find global index of current structure
      let selectedLayerIdx = this.props.structures.findIndex(
        (item) => item.id === structure.id
      );

      // Check if in viewerless "FilesGallery" mode and keep current layer
      const fileClassification =
        this.props.viewerConfig.project.projectStringProperties["ViewerType"] ==
        "FilesGallery";
      if (fileClassification) {
        selectedLayerIdx = this.props.projectContext.selectedLayer; // do not change layer for classification models
      }

      // Update selected structure and open the AI preview window for it
      if (selectedLayerIdx >= 0) {
        this.props.projectContext.setState(
          { selectedLayer: selectedLayerIdx },
          () => {
            this.props.onChangeTool("iam_ai_inference", {
              selectedModel: selectedModel.name,
              selectedVersion: selectedModel.versions[0].label,
            });
          }
        );
        return;
      }
      break;
    }

    this.props.onChangeTool("none");
  };

  handleExpandingChange = (idx) => {
    let expandedIdx = this.state.expandedIdx === idx ? -1 : idx;
    this.setState({ expandedIdx }, () => this.updateSelectedTool());
  };

  onExportClick = (modelName, version) => {
    this.setState({ export: true });
    var aiModel = {
      Name: modelName,
      WeightsName: version,
    };
    Backend.exportAIModel(JSON.stringify(aiModel), (result) => {
      console.log("Success", result);
      this.setState({ export: false });
    });
  };

  onDeleteClick = (modelName, version) => {
    window.openResponseDialog("Delete model forever?", (response) => {
      if (response) {
        this.setState({ delete: true });
        var aiModel = {
          Name: modelName,
          WeightsName: version,
        };
        Backend.deleteAIModel(JSON.stringify(aiModel), (result) => {
          this.props.initAIFormData(false);
          this.setState({ delete: false });
          if (result.successful) {
            window.showSuccessSnackbar(result.information);
          } else {
            window.showErrorSnackbar(result.information);
          }
        });
      }
    });
  };

  // toggle model and uncheck all structures or check best fit structures
  toggleModel = (model) => {
    const usableStructures = usableModels[model.name].usableStructures;
    if (model.isChecked) {
      this.props.setAiUsedStructures(null, "remove_all");
      usableStructures.forEach((usableStructure) => {
        if (usableStructure.isChecked && !usableStructure.isDisabled) {
          this.props.handleChangeModel(null, usableStructure.id);
        }
      });
    } else {
      if (model.structure_indices) {
        const structures = this.props.structures;
        usableStructures.forEach((usableStructure) => {
          for (let idx of model.structure_indices) {
            if (
              idx < structures.length &&
              structures[idx].label === usableStructure.name
            ) {
              this.props.handleChangeModel(model.name, usableStructure.id);
              break;
            }
          }
        });
      } else if (model.structure) {
        let structureNameArray = model.structure.split(",");
        for (let structureName of structureNameArray) {
          for (let usableStructure of usableStructures) {
            if (!usableStructure.isChecked) {
              if (usableStructure.name === structureName) {
                this.props.handleChangeModel(model.name, usableStructure.id);
                break;
              }
            }
          }
        }
      } else {
        this.props.handleChangeModel(model.name, usableStructures[0].id);
      }
    }
  };

  handleModelChange = (model, usableStructure) => {
    this.props.handleChangeModel(
      usableStructure.isChecked ? null : model.name,
      usableStructure.id
    );
  };

  render() {
    const { expandedIdx } = this.state;
    const { classes, formDataAICockpit } = this.props;
    return (
      <div className={classes.root}>
        {Object.values(usableModels).map((model, idx) => {
          let checkedStructure = model.usableStructures.find(
            (item) => item.isChecked
          );
          let selectedDropDownVersion =
            model.versions[model.versions.length - 1].label;
          let selectedModel = model.name;
          const modelVersion = model.versions.find(
            (version) => version.label === selectedDropDownVersion
          );

          // Select the correct version of if the currently opened structure.
          let structure = null;
          if (checkedStructure && model) {
            structure = checkedStructure.id;
            selectedDropDownVersion =
              (model &&
                formDataAICockpit[structure].models[selectedModel] &&
                formDataAICockpit[structure].models[selectedModel]
                  .selectedVersion) ||
              "";
          }

          return (
            <Accordion
              key={idx}
              expanded={expandedIdx === idx}
              onChange={() => this.handleExpandingChange(idx)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
              >
                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <Tooltip
                      disableInteractive
                      title="depending on Structure selection"
                    >
                      <Checkbox
                        checked={model.isChecked}
                        disabled={!model.isChecked && model.isDisabled}
                        onChange={() => {
                          this.toggleModel(model);
                          this.updateModelCheckboxes();
                        }}
                      />
                    </Tooltip>
                  }
                  label={model.label}
                />
              </AccordionSummary>
              <AccordionDetails>
                {model.versions.length > 0 && expandedIdx === idx && (
                  <div style={{ width: "100%" }}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ width: 170 }}>Version</TableCell>
                          <TableCell>
                            <div>
                              <TextField
                                select
                                label="Version"
                                value={selectedDropDownVersion}
                                onChange={(event) => {
                                  this.props.handleChangeVersion(
                                    event,
                                    structure,
                                    selectedModel
                                  );
                                }}
                                disabled={!model.isChecked}
                              >
                                {model.versions.map((version, idx) => (
                                  <MenuItem key={idx} value={version.label}>
                                    {version.label}
                                  </MenuItem>
                                ))}
                              </TextField>

                              {this.state.export ? (
                                <CircularProgress
                                  size="20px"
                                  thickness="3"
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginRight: "15px",
                                  }}
                                />
                              ) : (
                                <Tooltip
                                  disableInteractive
                                  title="Export AI Model"
                                >
                                  <IconButton
                                    aria-label="Export"
                                    onClick={() =>
                                      this.onExportClick(
                                        model.label,
                                        selectedDropDownVersion
                                      )
                                    }
                                    style={{
                                      float: "right",
                                    }}
                                  >
                                    <PublishIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {this.state.delete ? (
                                <CircularProgress
                                  size="20px"
                                  thickness="3"
                                  style={{
                                    marginLeft: "15px",
                                    marginTop: "15px",
                                    marginRight: "15px",
                                  }}
                                />
                              ) : (
                                <Tooltip
                                  disableInteractive
                                  title="Delete AI Model"
                                >
                                  <IconButton
                                    aria-label="Delete"
                                    onClick={() =>
                                      this.onDeleteClick(
                                        model.label,
                                        selectedDropDownVersion
                                      )
                                    }
                                    style={{
                                      float: "right",
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Name:</TableCell>
                          <TableCell>{model.label}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Type:</TableCell>
                          <TableCell>{modelVersion.modeltype}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Structures:</TableCell>
                          <TableCell>
                            {model.usableStructures.map(
                              (usableStructure, structureIdx) => (
                                <div key={structureIdx}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={usableStructure.isChecked}
                                        disabled={usableStructure.isDisabled}
                                        onChange={() => {
                                          if (
                                            model.structure_indices &&
                                            model.structure_indices.length ==
                                              model.usableStructures.length
                                          ) {
                                            for (let struct of model.usableStructures) {
                                              this.handleModelChange(
                                                model,
                                                struct
                                              );
                                            }
                                          } else {
                                            this.handleModelChange(
                                              model,
                                              usableStructure
                                            );
                                          }

                                          this.updateModelCheckboxes();
                                        }}
                                      />
                                    }
                                    label={usableStructure.structurePath}
                                  />
                                </div>
                              )
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Creation Date:</TableCell>
                          <TableCell>{modelVersion.datetime}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Objects used for training:</TableCell>
                          <TableCell>
                            {modelVersion.trainingobjectscount}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Epochs:</TableCell>
                          <TableCell>
                            {modelVersion.epochs && modelVersion.epochs !== 0
                              ? modelVersion.epochs
                              : "-"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    {
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              Validation Loss:
                              <br />
                              {modelVersion.validationloss &&
                              modelVersion.validationloss !== 1
                                ? parseFloat(
                                    modelVersion.validationloss.toPrecision(4)
                                  )
                                : "-"}
                            </TableCell>
                            <TableCell>
                              Validation Mean IoU:
                              <br />
                              {modelVersion.validationmeaniou &&
                              modelVersion.validationmeaniou !== 1
                                ? parseFloat(
                                    modelVersion.validationmeaniou.toPrecision(
                                      4
                                    )
                                  )
                                : "-"}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    }
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    );
  }
}

// define the component's interface
SideBarTabAISelectByModel.propTypes = {
  classes: PropTypes.object.isRequired,
  availableModels: PropTypes.array,
  formDataAICockpit: PropTypes.object,
  structures: PropTypes.array,
  getAvailableModelsForStructure: PropTypes.func,
  handleChangeModel: PropTypes.func,
  handleChangeVersion: PropTypes.func,
  onChangeTool: PropTypes.func,
  projectContext: PropTypes.object,
  initAIFormData: PropTypes.func,
  setAiUsedStructures: PropTypes.func,
  viewerConfig: PropTypes.object,
};

export default withAllViewerContexts(
  withStyles(styles)(SideBarTabAISelectByModel)
);

import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";

const styles = {
  root: {
    backgroundColor: "transparent",
    margin: "20px 0px",
    height: "30px",
    position: "relative",
  },

  keyFrame: {
    position: "absolute",
    backgroundColor: "black",
    borderRadius: "100%",
  },

  interval: {
    position: "absolute",
  },
};

class TimeChart extends Component {
  render() {
    const {
      classes,
      structure,
      keyFrames,
      frameWidth,
      interpolatedIntervals,
      isSelected,
    } = this.props;
    const timeChartHeight = 30;
    const keyFrameElSize = 8; //Math.min(frameWidth, timeChartHeight) / 2;
    const keyFrameEls = [];
    for (let keyFrame in keyFrames) {
      keyFrameEls.push(
        <div
          key={keyFrame}
          className={classes.keyFrame}
          style={{
            left:
              Math.floor(
                keyFrame * frameWidth + (frameWidth - keyFrameElSize) / 2
              ) + "px",
            width: keyFrameElSize + "px",
            height: keyFrameElSize + "px",
            top: Math.floor((timeChartHeight - keyFrameElSize) / 2) + "px",
          }}
        ></div>
      );
    }

    const intervalEls = [];
    const intervalElHeight = 2;
    for (let interval of interpolatedIntervals) {
      intervalEls.push(
        <div
          key={interval[0]}
          className={classes.interval}
          style={{
            left: Math.floor(interval[0] * frameWidth + frameWidth / 2) + "px",
            width: (interval[1] - interval[0]) * frameWidth + "px",
            height: intervalElHeight + "px",
            top: Math.floor((timeChartHeight - intervalElHeight) / 2) + "px",
            backgroundColor: structure.color,
          }}
        ></div>
      );
    }

    return (
      <div
        className={classes.root}
        style={{
          border: isSelected
            ? "2px dashed" + structure.color
            : "2px solid " + structure.color,
          backgroundColor: structure.color + "50",
        }}
      >
        {intervalEls}
        {keyFrameEls}
      </div>
    );
  }
}

TimeChart.propTypes = {
  classes: PropTypes.object.isRequired,
  structure: PropTypes.object,
  keyFrames: PropTypes.object,
  interpolatedIntervals: PropTypes.array,
  frameWidth: PropTypes.number,
  isSelected: PropTypes.bool,
};

export default withStyles(styles)(TimeChart);

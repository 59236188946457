import {
  updateDrawLayer,
  updateLayer,
  findSiblingRoiLayers,
  findSameLayer,
  findClickedRoi,
  checkIfStructureHidden,
} from "../../utils/PolygonUtil";

import { Typography } from "@mui/material";
import { pointsToCirclePolygon } from "../../utils/PolygonUtil";
import Tool from "./Tool";
import React from "react";
import OverlapConfigForm from "./ConfigForms/OverlapConfigForm";

class EllipseTool extends Tool {
  name = "Ellipse";
  noConfig = false;
  flag = false;
  downScale = 2;
  points = [];
  removeOverlap = null;
  removeOverlapSame = null;
  tempRemoveOverlap = null;
  tempRemoveOverlapSame = null;

  setLayer(obj) {
    this.ome = obj.ome;
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    let layerResults = findSameLayer(obj.structures, obj.selectedLayer);
    this.selectedLayer = layerResults[0];
    this.parentLayer = layerResults[1];
    this.originalSelectedLayer = obj.selectedLayer;
    this.structures = obj.structures;
    this.drawLayer = obj.drawLayer;

    const project = obj.viewerConfig_project;
    if (this.removeOverlap === null) {
      this.removeOverlap = project.projectProperties["PreventOverlap"];
    }
    if (this.tempRemoveOverlap === null) {
      this.tempRemoveOverlap = project.projectProperties["PreventOverlap"];
    }
    if (this.removeOverlapSame === null) {
      this.removeOverlapSame = project.projectProperties["PreventOverlapSame"];
    }

    // set removeOverlap
    // save current settings (in tempRemoveOverlap) if structure gets changed
    if (obj.selectedLayer === 0) {
      if (!this.noConfig) {
        this.noConfig = true;
        this.tempRemoveOverlap = this.removeOverlap;
        this.tempRemoveOverlapSame = this.removeOverlapSame;
        this.removeOverlap = false;
        this.removeOverlapSame = false;
        window.forceSidebarUpdate();
      }
    } else {
      if (this.noConfig) {
        this.noConfig = false;
        this.removeOverlap = this.tempRemoveOverlap;
        this.removeOverlapSame = this.tempRemoveOverlapSame;
        window.forceSidebarUpdate();
      }
    }
  }

  setPreviewRect() {}

  mouse(params) {
    let { event, p, color, subtype, name, positionInRoiLayer, fullyLoaded } =
      params;
    if (
      event.type === "mousedown" &&
      (event.button === 0 || event.button === 2)
    ) {
      checkIfStructureHidden(
        this.structures,
        this.selectedLayer,
        subtype,
        name,
        color
      );
      this.drawLayer.regionRois = [];
      this.drawLayer.inverted = false;
      // right mouse button -> clear
      this.clear = event.button === 2;
      this.drawLayer.clear = this.clear;

      // update position history
      this.startPoint = p;

      // set drawing flag
      this.flag = true;

      // check if mouse down is inside region? => exapand region
      if (this.removeOverlapSame) {
        this.clickedOnRoi = findClickedRoi(
          p,
          this.selectedLayer,
          this.structures,
          this.roiLayers,
          this.includeBaseROI
        );
      }
    } else if (
      event.type === "mouseup" ||
      (this.flag && event.type === "mouseleave")
    ) {
      // release drawing flag
      this.flag = false;
      let drawRegion = {
        regions: [],
        inverted: false,
      };
      if (this.drawLayer.regionRois.length > 0) {
        drawRegion.regions = [this.drawLayer.regionRois[0]];

        // soll der selected layer sein
        let overlapRoiLayers = [];
        if (this.removeOverlapSame) {
          overlapRoiLayers.push(this.roiLayers[this.selectedLayer]);
        }
        if (this.removeOverlap) {
          let siblingRoiLayers = findSiblingRoiLayers(
            this.structures,
            this.selectedLayer,
            this.roiLayers
          );
          siblingRoiLayers.map((layer) => overlapRoiLayers.push(layer));
        }

        updateLayer(
          this.layer,
          drawRegion,
          this.clear,
          color,
          subtype,
          name,
          this.roiLayers[this.selectedLayer].tree,
          positionInRoiLayer,
          fullyLoaded,
          false,
          this.roiLayers[this.parentLayer],
          this.structures[this.originalSelectedLayer].id,
          overlapRoiLayers,
          this.clickedOnRoi
        );
        this.drawLayer.regionRois = [];
      }
    } else if (event.type === "mousemove") {
      if (this.flag) {
        //draw Circle
        let drawRegion = pointsToCirclePolygon(
          this.startPoint,
          p,
          32,
          this.ome.sizeX,
          this.ome.sizeY
        );

        this.drawLayer.regionRois = []; //only one region will be drawn to the draw layer
        updateDrawLayer(
          this.drawLayer,
          drawRegion,
          false,
          color,
          subtype,
          name
        );
      }
    }
  }

  drawCustomCursor() {
    // no custom cursor
  }

  exit() {}

  renderConfiguration() {
    return (
      <div>
        <Typography variant="h6">{this.name}:</Typography>
        <OverlapConfigForm
          removeOverlap={this.removeOverlap}
          removeOverlapSame={this.removeOverlapSame}
          onChangeRemoveOverlap={(e) => (this.removeOverlap = e)}
          onChangeRemoveOverlapSame={(e) => (this.removeOverlapSame = e)}
        />
      </div>
    );
  }
}

export default EllipseTool;

/**
 * Generates an array which references all contained structures to their parent structure.
 * Each row of the array starts with the child and ends with the topmost parent.
 * The function stops at structures or substructures.
 * Overlying structures will not see the subtypes contained inside the structure.
 * @param {Array} structures All structures, substructures, and subtypes seen in sidebar.
 * @returns {Array} Array of parents, child is the first entry of each row, respectively.
 */

/*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
export function generateParentList(structures) {
  let parentList = [];
  // First, each structure contains itself sa a minimum.
  for (let structure of structures) {
    parentList.push([structure]);
  }

  // Find all parents of a structure, bottom up.
  for (let idx = 0; idx < parentList.length; idx++) {
    while (true) {
      let currentStruct = parentList[idx];
      // We're done when we reach the topmost layer
      // or if the next level would be a (sub-) structure
      if (
        !currentStruct[currentStruct.length - 1].classificationSubtype ||
        currentStruct[currentStruct.length - 1].parentId === 0
      ) {
        break;
      }

      // Add the parent structure to the row.
      parentList[idx].push(
        structures[
          getParentIndex(currentStruct[currentStruct.length - 1], structures)
        ]
      );
    }
  }

  return parentList;
}

/**
 * Generates an array which references all contained structures to their child structures.
 * Each row of the array starts with the parent and ends with the bottom-most child.
 * The function stops at structures or substructures.
 * Underlying structures will not see the subtypes contained inside the structure.
 * @param {Array} structures All structures, substructures, and subtypes seen in sidebar.
 * @returns {Array} Array of children, parent is the first entry of each row, respectively.
 */
export function generateChildrenList(structures) {
  let childrenList = [];
  for (let structure of structures) {
    childrenList.push(
      generateAListOfAllChildrenRecursively(structure, structures)
    );
  }
  return childrenList;
}

/**
 * Recursively goes down the structure tree, beginning from the given parent.
 * Adds all structures along the way until it reaches a structure without children.
 * @param {Object} structure The selected structure/substructure/subtype for this iteration.
 * @param {Array} structures All structures, substructures, and subtypes seen in sidebar.
 * @returns {Array} Array of Children when done.
 * @returns {Array} Object of a child in an array when child does not have a child of its own.
 */
function generateAListOfAllChildrenRecursively(structure, structures) {
  // Continue until we reach the bottom-most layer
  // or if the current structure would be a (sub-) structure
  if (structure.hasChild) {
    // Add the structure itself to the tree
    let allChildren = [];
    allChildren.push(structure);

    // Find all children of the structure, that are subtypes, not substructures
    let children = structures.filter(
      (struct) =>
        struct.classificationSubtype && struct.parentId === structure.id
    );

    // Recursively add all children and children of children
    for (let child of children) {
      allChildren.push(
        ...generateAListOfAllChildrenRecursively(child, structures)
      );
    }
    return allChildren;
  } else {
    // No children, bottom-level child returns itself
    return [structure];
  }
}

/**
 * Returns index of the topmost parent structure
 * @param {Object} structure The selected structure/substructure/subtype from the sidebar.
 * @param {Array} structures All structures, substructures, and subtypes seen in sidebar.
 * @returns {Int} the index of the topmost parent structure.
 */
export function getParentIndexLayer(structure, structures) {
  let index = structures.findIndex((element) => element === structure);
  while (
    structures[index].subtypeLevel !== 0 &&
    structures[index].classificationSubtype
  ) {
    index = getParentIndex(structures[index], structures);
  }
  return structures.findIndex((element) => element === structures[index]);
}

/**
 * Returns index of the direct parent structure
 * @param {Object} structure The selected structure/substructure/subtype from the sidebar.
 * @param {Array} structures All structures, substructures, and subtypes seen in sidebar.
 * @returns {Int} the index of the parent structure.
 */
export function getParentIndex(structure, structures) {
  // return index of parent structure
  return structures.findIndex((element) => element.id === structure.parentId);
}

/**
 * Returns objects for this structure, including its subtypes.
 * @param {Object} structure The selected structure/substructure/subtype from the sidebar.
 * @param {Array} structures All structures, substructures, and subtypes seen in sidebar.
 * @param {List} roiLayers Annotations of the structures ordered by structure.
 * @returns {List} All ROIs for a certain structure, including its subtypes.
 */
export function getContainedRegionRois(structure, structures, roiLayers) {
  let resultRois = [];
  let idx = structures.indexOf(structure);
  let parentList = generateParentList(structures);
  let decendentList = generateChildrenList(structures);

  // The parent contains all annotation objects,
  // their subtype is assigend in the annotations object.
  let parent = parentList[idx][parentList[idx].length - 1];
  let parent_idx = structures.findIndex((struct) => struct.id === parent.id);

  // Consider all decendents
  decendentList[idx].forEach((decendent) => {
    resultRois.push(
      // Find all annotations that belong to the structure
      ...roiLayers[parent_idx].layer.regionRois.filter(
        (roi) => roi.structureId === decendent.id
      )
    );
  });

  return resultRois;
}

/**
 * Updates the full structure tree of a structure, ensuring correctly displayed icons.
 * @param {Object} structure The full tree of this structure will be updated.
 * @param {Array} structures All structures. Contains the structure given.
 * @returns {Array} Updated structures.
 */
export function updateStructures(structure, structures) {
  // Check for missing strucutre import
  if (!structure) {
    console.debug("Error: No structure given, canceling strucutre update");
    return structures;
  }

  // Get toplevel parent
  let toplevelParentIndex = getParentIndexLayer(structure, structures);

  // Not found, therefore return unedited structures
  if (toplevelParentIndex === -1) {
    console.debug(
      "Error: Could not find top level parent when updating structures."
    );
    return structures;
  }

  // Get all children of toplevel parent
  let fullChildrenList = generateChildrenList(structures);
  let children = fullChildrenList[toplevelParentIndex];

  // Update their status
  for (let structure of children) {
    // Find children of currently viewed child
    let currentChildren = fullChildrenList.find(
      (children) => children[0].id === structure.id
    );

    // Update properties
    structure.hasChild = currentChildren.length > 0;

    // Find location of current structure in structures
    let structureIndex = structures.findIndex(
      (struct) => struct.id === structure.id
    );

    // Overwrite with updated properties
    structures[structureIndex] = structure;
  }

  return structures;
}

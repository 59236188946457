import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Grid,
  TablePagination,
  Tooltip,
  //TextField,
  CircularProgress,
} from "@mui/material";

import { withTiles } from "../contexts/TilesContext";
import { withPersistentStorage } from "../contexts/PersistentStorageContext";
import { withAllViewerContexts } from "../contexts/AllViewerContexts";
import CroppedImage from "./CroppedImage";
import SceneImage from "./SceneImage";
import GalleryToolBoxes from "./GalleryToolBoxes";
import { getParentIndexLayer } from "../utils/StructuresUtils";
import withStyles from "@mui/styles/withStyles";

const styles = () => ({
  tabsContainer: {
    width: "100%",
  },
  tab: {
    minWidth: 345 / 3,
    "& *": {
      display: "inline-block",
      fontSize: "20px",
      lineHeight: "18px",
    },
    "& svg": {
      marginRight: "5px",
      position: "relative",
      top: "2px",
    },
  },
  flexVerticalContainer: {
    display: "flex",
    flexFlow: "column",
    height: "100%",
  },
  flexRowContentHeight: {
    flex: "0 1 auto",
    padding: "10px",
  },
  flexRowRemainingHeight: {
    flex: "1 1 auto",
    overflowY: "auto",
  },
  spacing: {
    padding: "10px",
    paddingBottom: 0,
  },
  dragIndicator: {
    position: "absolute",
    color: "#000000",
    cursor: "grab",
    bottom: 5,
    right: 5,
  },
  grabbing: {
    cursor: "grabbing",
  },
  toolBox: {
    marginLeft: "5px",
    marginRight: "10px",
    marginTop: "7px",
    display: "inline-block",
  },
  aiLabel: {
    display: "block",
    position: "absolute",
    bottom: "11px",
    right: "4px",
    background: "black",
    color: "white",
    zIndex: 99999,
    padding: "0 5px",
    margin: 0,
  },
  imgOuter: {
    "&:hover .aiLabel": {
      display: "none",
    },
  },
});

class Gallery extends Component {
  constructor(props) {
    super(props);
    // transmit context to componentRef
    if (props.componentRef) props.componentRef(this);

    this.state = {
      page: 0,
      rowsPerPage: this.getRowsPerPageForProject(),
      drawContour: false,
      showFileNames: true,
      galleryMounted: false,
      drawCrosshair: this.props.showGallery ? false : true,
      crosshairColor: "#FF0000",
      crosshairOpacity: 1.0,
      crosshairLineWidth: 1,
      canvasSize: this.props.showGallery ? 300 : 750,
      startData: 50,
      automaticTraining: false,
      elementCount: 0,
      z: 0,
      t: 0,
      playingZ: false,
      zsr: 60,
      playDirectionZ: 1,
      selectedWithKey: false,
      isBrightfield:
        this.props.ome &&
        this.props.ome.channels.length === 1 &&
        this.props.ome.channels[0].type === "brightfield",
      objectToLoad: {
        index: 0,
        roi: null,
        rois: [],
      },
      progressText: "0/50",
      trainingAcc: "0",
    };

    this.enoughAnnosCount = 0;
    this.tile = [];
    this.pageIndex = 0;
    this.classificationId = [0, 0, 0];
    this.pageInput = "";
    this.validationAcc = 0;
  }

  setDrawContour = (e) => {
    // set value from toolbox component
    this.setState({ drawContour: e });
  };

  setShowFileNames = (e) => {
    // set value from toolbox component
    this.setState({ showFileNames: e });
  };

  setAutomaticTraining = (e) => {
    // set value from toolbox component
    this.setState({ automaticTraining: e });
  };

  setDrawCrosshair = (e) => {
    // set value from toolbox component
    this.setState({ drawCrosshair: e });
  };

  setCrosshairColor = (e) => {
    // set value from toolbox component
    this.setState({ crosshairColor: e });
  };

  setCrosshairOpacity = (e) => {
    // set value from toolbox component
    this.setState({ crosshairOpacity: e });
  };

  setCrosshairLineWidth = (e) => {
    // set value from toolbox component
    this.setState({ crosshairLineWidth: e });
  };

  setStartData = (e) => {
    // set value from toolbox component
    this.setState({ startData: e });
  };

  componentDidMount() {
    this.setElementCount();
    setTimeout(() => {
      this.setState({ galleryMounted: true });
    }, 1000);
  }

  allImagesLoaded = () => {
    const { tiles } = this.props;
    // check if all images finished loading
    let allImagesComplete = true;
    for (let value of Object.values(tiles.getVisibleImages())) {
      if (!value.complete) {
        allImagesComplete = false;
      }
    }
    return allImagesComplete;
  };

  setObjectToLoad = (fromChangePage, fromMount) => {
    const { structures, roiLayers, selectedLayer } = this.props;
    // set next roi for loading its images from backend or cache

    let idx = 0;
    let loadedRois;
    if (fromMount) {
      // if component mounts
      if (this.props.showGallery) {
        idx =
          this.props.tiles.getPage(structures[selectedLayer].id) *
          this.state.rowsPerPage;
      } else {
        idx =
          this.props.tiles.getTilePage(structures[selectedLayer].id) *
          this.state.rowsPerPage;
      }
      loadedRois = [];
    } else {
      // if objectToLoad was already set
      let pg;
      if (this.props.showGallery) {
        pg = this.props.tiles.getPage(structures[selectedLayer].id);
      } else {
        pg = this.props.tiles.getTilePage(structures[selectedLayer].id);
      }
      idx = fromChangePage
        ? pg * this.state.rowsPerPage
        : this.state.objectToLoad.index + 1;
      // get rois that are already loaded
      loadedRois = this.state.objectToLoad.rois;
    }

    // get next roi
    let roi;
    if (!structures[selectedLayer].classificationSubtype) {
      if (this.props.project.type.includes("HistoPointCounting")) {
        roi = roiLayers[selectedLayer].layer.regionRois.filter(
          (element) =>
            element.isSubtype && element.subtypeName !== "leer [leer]"
        )[idx];
      } else {
        roi = roiLayers[selectedLayer].layer.regionRois[idx];
      }
    } else {
      // get subtypeRois
      let parentIdx = getParentIndexLayer(
        structures[selectedLayer],
        structures
      );
      let subtypeRois = roiLayers[parentIdx].layer.regionRois.filter(
        (element) => this.isRoiSubtype(element)
      );
      roi = subtypeRois[idx];
    }

    // push new roi to loaded rois
    if (!loadedRois.includes(roi) && roi) {
      loadedRois.push(roi);
    }

    // new objectToLoad
    let x = {
      index: idx,
      roi: roi,
      rois: loadedRois,
    };
    this.setState({ objectToLoad: x });
  };

  getRowsPerPageForProject() {
    // checks default value for rows per page for specific modules
    if (this.props.showGallery) {
      if (this.getProjectLabel() === "Polarity - Gallery") {
        return 10;
      } else if (this.getProjectLabel() === "Tiles Tool") {
        return 18;
      } else {
        return 10;
      }
    } else {
      return 1;
    }
  }

  getRowsPerPageOptionsForProject() {
    // checks default value for rows per page options for specific modules
    if (this.props.showGallery) {
      if (this.getProjectLabel() === "Polarity - Gallery") {
        return [10, 15];
      } else if (this.getProjectLabel() === "Tiles Tool") {
        return [18];
      } else {
        return [10, 20];
      }
    } else {
      return [1];
    }
  }

  getProjectLabel = () => {
    // returns project label from viewerconfig
    if (this.props.viewerConfig) {
      return this.props.viewerConfig.project.label;
    }
  };

  findParentIndex = () => {
    const { structures, selectedLayer } = this.props;
    // returns index of parentstructure in all structures
    let parentIndex = structures.findIndex(
      (element) => element.id === structures[selectedLayer].parentId
    );
    return parentIndex;
  };

  checkToolInConfig = (toolName) => {
    // checks if tool exists in viewerconfig
    if (this.props.viewerConfig) {
      if (this.props.viewerConfig.project.toolsInProject[toolName]) {
        return true;
      } else {
        return false;
      }
    }
  };

  setElementCount = () => {
    const { structures, selectedLayer, roiLayers, project } = this.props;

    let elCnt = 0;
    let structure = structures[selectedLayer];
    if (
      this.props.viewerConfig.project.projectStringProperties["ViewerType"] !==
      "FilesGallery"
    ) {
      // calculate number of elements in selected layer
      if (structure.isSubtype && structure.classificationSubtype) {
        // get all childNames to find rois
        let childNames = [];
        this.findAllSubtypes(true).forEach((element) => {
          childNames.push(element.label);
        });
        // get number of rois of subtypes in parent layer
        let parentIndex = getParentIndexLayer(
          structures[selectedLayer],
          structures
        );
        elCnt = roiLayers[parentIndex].layer.regionRois.filter(
          (element) =>
            element.isSubtype &&
            (childNames.includes(element.subtypeName) ||
              element.subtypeName === structure.label)
        ).length;
      } else {
        if (project.type.includes("HistoPointCounting")) {
          // count only subtype objects
          elCnt = roiLayers[selectedLayer].layer.regionRois.filter(
            (element) =>
              element.isSubtype && element.subtypeName !== "leer [leer]"
          ).length;
        } else {
          // count all objects in parent layer
          elCnt = roiLayers[selectedLayer].layer.regionRois.length;
        }
      }
      this.state.elementCount !== elCnt &&
        this.setState({ elementCount: elCnt });
    } else {
      // calculate number of files that belong to selected class
      if (structure.classificationSubtype) {
        // get all childNames to find rois
        let childIds = [];
        this.findAllSubtypes(true).forEach((element) => {
          childIds.push(element.id);
        });
        childIds.push(structure.id); // add selected structure
        elCnt = project.files.filter(
          (element) => element.classId && childIds.includes(element.classId)
        ).length;
      } else {
        elCnt = project.files.length;
      }

      this.state.elementCount !== elCnt &&
        this.setState({ elementCount: elCnt });
    }
  };

  componentDidUpdate() {
    const { structures, selectedLayer } = this.props;
    // get subtypes for selected structure
    let subtypes = this.props.tiles.getSubtypes(structures[selectedLayer].id);
    // set colors for subtypes
    if (subtypes[0]) {
      this.classificationId[1] = subtypes[0].id;
    }
    if (subtypes[1]) {
      this.classificationId[2] = subtypes[1].id;
    }
    this.classificationId[0] = structures[selectedLayer].id;
  }

  updateGallery = () => {
    // update gallery (from cropped image)
    this.forceUpdate();
  };

  UNSAFE_componentWillMount() {
    const { selectedObjects, structures, selectedLayer } = this.props;

    // push saved acc points to context
    if (this.props.tiles.getAccPoints().length === 0) {
      for (let i = 0; i < selectedObjects.coordinates.length; i++) {
        let acc = selectedObjects.coordinates[i]["acc"];
        if (Math.round(selectedObjects.coordinates[i]["acc"]) !== -1) {
          this.props.tiles.pushAccPoint(acc * 100);
        }
      }
      // set first iteration false if there were AL iteratoins before
      if (selectedObjects.coordinates.length > 1) {
        this.props.tiles.setFirstIteration(false);
      }
    }

    // set context annotations count
    if (this.props.tiles.getStrAnnoCount().length === 0) {
      let object = this.props.persistentStorage.load("contextObject");
      if (object && object.length === this.props.structures.length) {
        this.props.tiles.setAnnoCount(object);
      } else {
        this.setAnnotationsContext();
      }
    }

    // set context slected subtypes and pages
    if (this.props.tiles.getSubtypesPages().length === 0) {
      let object = this.props.persistentStorage.load("subtypesPagesObject");
      if (object && object.length === this.props.structures.length) {
        this.props.tiles.setSubtypesPages(object);
      } else {
        this.setSubtypesPagesContext();
      }
    }

    // set context for accuracy graph
    if (this.props.tiles.getGraphAcc().length === 0) {
      let object = this.props.persistentStorage.load("accGraphObject");
      if (object) {
        this.props.tiles.setGraphAcc(object);
      }
    }

    // check if new dynamic structure
    if (this.props.tiles.getSubtypesPages().length !== structures.length) {
      this.setDynamicStructure();
    }

    // set page in context
    let page = 0;
    if (this.props.showGallery) {
      page = this.props.tiles.getPage(structures[selectedLayer].id);
    } else {
      page = this.props.tiles.getTilePage(structures[selectedLayer].id);
    }

    if (this.state.page !== page) {
      this.setState({ page: page });
    }

    this.setSubtypeValues();

    // set objectToLoad
    this.setObjectToLoad(false, true);

    // set special image size for tiles project module
    if (!this.props.showGallery) {
      this.props.projectContext.setState({ galleryImageSize: 750 });
    }
  }

  setDynamicStructure = () => {
    const { structures } = this.props;
    // put new dynamic structure to context

    // get index of new dynamic structure
    let dynamicIndex = 0;
    structures.forEach((element, idx) => {
      let sameId = this.props.tiles
        .getSubtypesPages()
        .filter((str) => str.id === element.id);
      if (sameId > -1) {
        dynamicIndex = idx;
      }
    });

    // put dynamic structure to context object
    this.props.tiles.pushDynamicStructureSP(
      structures[dynamicIndex],
      dynamicIndex
    );
    this.props.tiles.pushDynamicStructureAC(
      structures[dynamicIndex].id,
      dynamicIndex
    );
  };

  setAnnotationsContext = () => {
    const { structures } = this.props;
    // create context for annotationCount
    let lenStructures = structures.length;
    for (let i = 0; i < lenStructures; i++) {
      this.props.tiles.pushStrAnnoCount(structures[i].id);
    }
  };

  setSubtypesPagesContext = () => {
    const { structures } = this.props;
    // create context selected subtypes an pages
    let lenStructures = structures.length;
    for (let i = 0; i < lenStructures; i++) {
      this.props.tiles.pushSubtypesPages(structures[i].label, structures[i].id);
    }
  };

  findAllSubtypes = () => {
    const { structures, selectedLayer } = this.props;
    // get all subtypes of selectedLayer (also subsubtypes)

    // first get all direct childs
    let childs = this.findChilds(structures[selectedLayer]);
    let allChilds = [];

    // search for childs of childa until no childs are checked for childs
    while (childs.length !== 0) {
      // code block to be executed
      childs = childs.concat(this.findChilds(childs[0]));
      if (!allChilds.includes(childs[0]) && childs[0].classificationSubtype) {
        allChilds.push(childs[0]);
      }
      childs.shift();
    }
    return allChilds;
  };

  setSubtypeValues = () => {
    const { structures, selectedLayer } = this.props;

    // get selected subtypes for classification
    let subtypesInContext =
      this.props.tiles.getSubtypes(structures[selectedLayer].id).length !== 0;

    if (subtypesInContext) {
      // set classificationIds
      let subtypes = this.props.tiles.getSubtypes(structures[selectedLayer].id);

      // set ida for subtypes
      if (subtypes[0]) {
        this.classificationId[1] = subtypes[0].id;
      }
      if (subtypes[1]) {
        this.classificationId[2] = subtypes[1].id;
      }
      this.classificationId[0] = structures[selectedLayer].id;
    } else {
      // put subtypes to context if not in context yet
      let subtype_1;
      subtype_1 = this.findChilds(structures[selectedLayer]);
      let twoSubtypes = subtype_1.slice(0, 2);
      this.props.tiles.setSubtypes(structures[selectedLayer].id, twoSubtypes);
    }
  };

  getSubtypes = () => {
    const { structures, selectedLayer } = this.props;
    // get direct classification subtypes of selected structure
    let subtypes;
    subtypes = structures.filter(
      (element) =>
        element.classificationSubtype &&
        element.parentId === structures[selectedLayer].id
    );
    return subtypes;
  };

  UNSAFE_componentWillUpdate(nextProps) {
    const { selectedObjects, structures, selectedLayer, roiLayers } =
      this.props;

    // set isBrightfield if ome is null
    if (this.props.ome === null && nextProps.ome !== null) {
      this.setState({
        isBrightfield:
          nextProps.ome &&
          nextProps.ome.channels.length === 1 &&
          nextProps.ome.channels[0].type === "brightfield",
      });
    }

    // set subtypes for classification
    this.setSubtypeValues();

    // if parent color is white --> change it
    if (!structures[selectedLayer].isSubtype) {
      let clr = structures[selectedLayer].color;
      roiLayers[selectedLayer].layer.regionRois.forEach(function (element) {
        if (element.color === "#FFFFFF") {
          element.color = clr;
        }
      });
    }

    // find selectedObjects in roiLayers and set properties
    let selObjCord = selectedObjects.coordinates;
    if (
      (selObjCord &&
        selObjCord.length > 0 &&
        structures[selectedLayer].isSubtype === false) ||
      this.props.selObjs === true
    ) {
      if (selObjCord[0] !== null) {
        // set properties for selectedObjects
        selectedObjects.coordinates
          .map(
            (x) =>
              roiLayers[selectedLayer].layer.regionRois.filter(
                (element) =>
                  parseInt(element.bounds.left, 10) === x.x &&
                  parseInt(element.bounds.top, 10) === x.y &&
                  element.isSubtype
              )[0]
          )
          .filter((element) => element && !element.isLabeled)
          .map((roi) => {
            roi.isSelObj = true;
            roi.aiAnnotated = true;
            return true;
          });
      }
    }

    // set layer for subtype- or parent structure i active tool
    let indexLayer = 0;
    if (
      structures[selectedLayer] &&
      structures[selectedLayer].isSubtype &&
      structures[selectedLayer].classificationSubtype
    ) {
      indexLayer = getParentIndexLayer(structures[selectedLayer], structures);
    } else {
      indexLayer = nextProps.selectedLayer;
    }
    if (nextProps.tools[nextProps.activeTool]) {
      nextProps.tools[nextProps.activeTool].setLayer({
        layer: nextProps.roiLayers[indexLayer].layer,
        ome: nextProps.ome,
        fileId: nextProps.fileId,
        projectId: nextProps.projectId,
        structures: nextProps.structures,
        roiLayers: nextProps.roiLayers,
        drawLayer: nextProps.drawLayer,
        selectedLayer: nextProps.selectedLayer,
        updateProject: nextProps.updateProject,
        commentLayer: nextProps.commentLayer,
        allRoiLayers: nextProps.allRoiLayers,
        viewerConfig_project: nextProps.viewerConfig.project,
        project: nextProps.project,
        ctx: this.ctx,
        histogramConfig: nextProps.histogramConfig,
        updateFileClasses: nextProps.updateFileClasses,
      });
    }

    // update elementCount
    this.setElementCount();

    // set page from context
    let page = 0;
    if (this.props.showGallery) {
      page = this.props.tiles.getPage(structures[selectedLayer].id);
    } else {
      page = this.props.tiles.getTilePage(structures[selectedLayer].id);
    }

    if (this.state.page !== page) {
      this.setState({ page: page });
    }

    // if selected structure changed --> reset object/roi to load next
    if (
      this.props.tiles.getStructure() !== this.props.tiles.getStructureBefore()
    ) {
      this.setObjectToLoad(false, true);
      this.props.tiles.setStructure(structures[selectedLayer]);
    }

    // check if new dynamic structure
    if (this.props.tiles.getSubtypesPages().length !== structures.length) {
      this.setDynamicStructure();
    }
  }

  scroll = (top) => {
    // scrolls that cropped image that calls function (selected image) is on top in gallery
    var el = document.getElementById("galleryWindow");
    el.scrollTo({ top: el.scrollTop + top - 90, behavior: "smooth" });
  };

  setSelectedWithKey = (b) => {
    // sets variable if classifying with keys (automatic srolling) or with mouse (no automatic scrolling)
    this.setState({ selectedWithKey: b });
  };

  selectNewImage = (dir) => {
    const { viewerConfig } = this.props;
    // select next or previous roi with right or left arrow key
    if (!this.state.selectedWithKey) {
      // set selectedWith key true --> automatic scrolling enabled
      this.setSelectedWithKey(true);
    }

    if (
      viewerConfig.project.projectStringProperties["ViewerType"] !==
      "FilesGallery"
    ) {
      this.selectNewRoi(dir);
    } else {
      this.selectNewFile(dir);
    }

    this.forceUpdate();
  };

  selectNewRoi = (dir) => {
    const { selectedLayer, roiLayers, structures, project } = this.props;

    // if not classification structure (parent)
    if (!structures[selectedLayer].classificationSubtype) {
      let roiLay = [];
      if (project.type.includes("HistoPointCounting")) {
        roiLay = roiLayers[selectedLayer].layer.regionRois.filter(
          (element) =>
            element.isSubtype && element.subtypeName !== "leer [leer]"
        );
      } else {
        roiLay = roiLayers[selectedLayer].layer.regionRois;
      }

      // get index of currently selected roi
      let idx = roiLay.findIndex((element) => element.selected);

      if (dir === "right") {
        // right key
        if (roiLay[idx + 1] && roiLay[idx]) {
          // make next roi selected
          roiLay[idx + 1].selected = true;
          roiLay[idx + 1].selectedWithKey = true;
          roiLay[idx].selected = false;
          roiLay[idx].selectedWithKey = false;
          // check if change page
          if (idx === (this.state.page + 1) * this.state.rowsPerPage - 1) {
            this.setPage(this.state.page + 2);
          }
        }
      } else {
        // left key
        if (roiLay[idx - 1] && roiLay[idx]) {
          //make previous roi selected
          roiLay[idx - 1].selected = true;
          roiLay[idx - 1].selectedWithKey = true;
          roiLay[idx].selected = false;
          roiLay[idx].selectedWithKey = false;
          // check if change page
          if (idx % this.state.rowsPerPage === 0) {
            this.setPage(this.state.page);
          }
        }
      }
    }

    // if classification structure
    if (structures[selectedLayer].classificationSubtype) {
      let parentLayer = getParentIndexLayer(
        structures[selectedLayer],
        structures
      );

      // get index of selRoi in all rois
      let idxSelRoi = roiLayers[parentLayer].layer.regionRois.findIndex(
        (element) => element.selected
      );

      // get all rois of subtype
      let childLabels = [];
      this.findChilds(structures[selectedLayer]).forEach((element) => {
        childLabels.push(element.label);
      });
      let subtypeRois = roiLayers[parentLayer].layer.regionRois.filter(
        (element) =>
          element.subtypeName === structures[selectedLayer].label ||
          childLabels.includes(element.subtypeName)
      );

      // get index of selRoi in subtype rois
      let idxInSubtypes = subtypeRois.findIndex((element) => element.selected);

      // get next or previous roi
      let nextRoi = subtypeRois[idxInSubtypes];
      if (dir === "right") {
        if (subtypeRois[idxInSubtypes + 1]) {
          nextRoi = subtypeRois[idxInSubtypes + 1];
        }
      } else {
        if (subtypeRois[idxInSubtypes - 1]) {
          nextRoi = subtypeRois[idxInSubtypes - 1];
        }
      }

      // get index of next roi
      let idxNextRoi = roiLayers[parentLayer].layer.regionRois.findIndex(
        (element) => element === nextRoi
      );
      if (idxNextRoi === -1) {
        return;
      }

      // set next roi selected
      roiLayers[parentLayer].layer.regionRois[idxNextRoi].selected = true;
      roiLayers[parentLayer].layer.regionRois[
        idxNextRoi
      ].selectedWithKey = true;

      // set selected roi to not selected
      if (idxNextRoi !== idxSelRoi) {
        roiLayers[parentLayer].layer.regionRois[idxSelRoi].selected = false;
        roiLayers[parentLayer].layer.regionRois[
          idxSelRoi
        ].selectedWithKey = false;
      }
    }
  };

  selectNewFile = (dir) => {
    const { project, structures, selectedLayer } = this.props;

    let visibleFiles = project.files;
    if (structures[selectedLayer].classificationSubtype) {
      visibleFiles = project.files.filter(
        (element) => element.classId === structures[selectedLayer].id
      );
    }

    // get index of currently selected file
    let idx = visibleFiles.findIndex((element) => element.selectedInGallery);
    // make file selectedIngallery true and other files false
    visibleFiles.forEach((f) => {
      f.selectedInGallery = false;
    });
    if (dir === "right" && project.files[idx + 1]) {
      visibleFiles[idx + 1].selectedInGallery = true;
      // check if change page
      if (idx === (this.state.page + 1) * this.state.rowsPerPage - 1) {
        this.setPage(this.state.page + 2);
      }
    } else if (dir === "left" && project.files[idx - 1]) {
      visibleFiles[idx - 1].selectedInGallery = true;
      // check if change page
      if (idx % this.state.rowsPerPage === 0) {
        this.setPage(this.state.page);
      }
    }
  };

  deleteSelectedRoi = () => {
    const { selectedLayer, roiLayers, structures } = this.props;
    // remove selected roi from roilayer

    // if not classification structure
    if (!structures[selectedLayer].classificationSubtype) {
      // get index of selected roi
      let idx = roiLayers[selectedLayer].layer.regionRois.findIndex(
        (element) => element.selected
      );
      // adjust annotation count
      if (
        this.state.automaticTraining &&
        roiLayers[selectedLayer].layer.regionRois[idx].isSubtype &&
        !roiLayers[selectedLayer].layer.regionRois[idx].aiAnnotated
      ) {
        this.props.tiles.setStrAnnoCount(
          roiLayers[selectedLayer].layer.regionRois[idx].structureId,
          -1
        );
      }
      // delete selected roi
      roiLayers[selectedLayer].layer.regionRois.splice(idx, 1);
      // make next roi selected true
      if (roiLayers[selectedLayer].layer.regionRois[idx]) {
        roiLayers[selectedLayer].layer.regionRois[idx].selected = true;
        roiLayers[selectedLayer].layer.regionRois[idx].selectedWithKey = true;
      }
    }

    // if classification structure
    if (structures[selectedLayer].classificationSubtype) {
      let parentLayer = getParentIndexLayer(
        structures[selectedLayer],
        structures
      );
      // get index of selected roi
      let idx = roiLayers[parentLayer].layer.regionRois.findIndex(
        (element) => element.selected
      );
      // delete selected roi
      roiLayers[parentLayer].layer.regionRois.splice(idx, 1);
      // get index of next subtype roi
      let childLabels = []; // all subtypenames
      this.findChilds(structures[selectedLayer]).forEach((element) => {
        childLabels.push(element.label);
      });
      let idxNext = 0; // index of next subtype
      // search for next roi that has is same subtype or subsubtype, ...
      for (
        let i = idx;
        i < roiLayers[parentLayer].layer.regionRois.length;
        i++
      ) {
        if (
          childLabels.includes(
            roiLayers[parentLayer].layer.regionRois[i].subtypeName
          ) ||
          roiLayers[parentLayer].layer.regionRois[i].subtypeName ===
            structures[selectedLayer].label
        ) {
          idxNext = i;
          break;
        }
      }
      // make next roi of subtype selected true
      if (roiLayers[parentLayer].layer.regionRois[idxNext]) {
        roiLayers[parentLayer].layer.regionRois[idxNext].selected = true;
        roiLayers[parentLayer].layer.regionRois[idxNext].selectedWithKey = true;
      }
    }

    this.forceUpdate();
  };

  handleChangePage = (page, direction) => {
    const { selectedLayer, structures } = this.props;
    // change page

    // change page with key shortcuts
    if (direction === "down") {
      page = this.state.page + 1;
    } else if (direction === "up") {
      page = this.state.page - 1;
      page = page < 0 ? 0 : page;
    } else {
      page = direction;
    }

    // set context page
    if (this.props.showGallery) {
      this.props.tiles.setPage(structures[selectedLayer].id, page);
    } else {
      this.props.tiles.setTilePage(structures[selectedLayer].id, page);
    }

    this.props.onGallerychangePage();
    this.setState({ page });
    this.props.tiles.setPageIndex(page);

    // prevent loading first roi on each page if changing multiple pages fast
    if (this.allImagesLoaded()) {
      this.setObjectToLoad(true);
    } else {
      let structureId = structures[selectedLayer].id;
      setTimeout(this.checkIfLoadNewObject, 2000, page, structureId);
    }

    // scroll to top after change page
    this.scrollToTop();
    this.forceUpdate();
  };

  checkIfLoadNewObject = (pg, prevStr) => {
    const { selectedLayer, structures } = this.props;
    let structureId = structures[selectedLayer].id;
    if (pg === this.state.page && prevStr === structureId) {
      // load object if not changed page for 2 seconds
      this.setObjectToLoad(true);
    } else {
      // do not load object from skipped pages
    }
  };

  scrollToTop = () => {
    // scroll to top --> eg. if change page
    var el = document.getElementById("galleryWindow");
    el.scrollTop = 0;
  };

  handleChangeRowsPerPage = (event) => {
    // change number of elements on one page
    this.setState({ rowsPerPage: event.target.value });
  };

  setPage = (p) => {
    const { selectedLayer, structures } = this.props;
    // set page specific page p

    this.pageInput = "";
    // if p is not a number
    if (!p) {
      this.forceUpdate();
      return;
    }

    // if pg is negative
    let pg = p - 1;
    if (pg < 0) {
      this.forceUpdate();
      return;
    }

    // if pg is out of range --> go to last page
    let numObjsInLayer = this.getNumberOfElements();
    if (this.state.rowsPerPage * pg > numObjsInLayer) {
      pg = Math.ceil(numObjsInLayer / this.state.rowsPerPage) - 1;
    }

    // set context page
    if (this.props.showGallery) {
      this.props.tiles.setPage(structures[selectedLayer].id, pg);
    } else {
      this.props.tiles.setTilePage(structures[selectedLayer].id, pg);
    }

    this.props.onGallerychangePage();
    this.setState({ page: pg });
    this.props.tiles.setPageIndex(pg);
    this.setObjectToLoad(true);
    this.scrollToTop();
    this.forceUpdate();
  };

  getNumberOfElements = () => {
    const { selectedLayer, roiLayers, structures, project } = this.props;
    // return number of objects of selected structure
    let numEls = 0;
    if (
      this.props.viewerConfig.project.projectStringProperties["ViewerType"] !==
      "FilesGallery"
    ) {
      if (!structures[selectedLayer].classificationSubtype) {
        numEls = roiLayers[selectedLayer].layer.regionRois.length;
      } else {
        let idx = this.findParentIndex();
        numEls = roiLayers[idx].layer.regionRois.filter(
          (element) => element.subtypeName === structures[idx].label
        ).length;
      }
    } else {
      if (!structures[selectedLayer].classificationSubtype) {
        numEls = project.files.length;
      } else {
        numEls = project.files.filter(
          (element) =>
            element.classId && element.classId == structures[selectedLayer].id
        ).length;
      }
    }
    return numEls;
  };

  classifyImageWithKey = (classId) => {
    const { viewerConfig } = this.props;
    // classify roi / file
    if (
      viewerConfig.project.projectStringProperties["ViewerType"] !==
      "FilesGallery"
    ) {
      this.classifyRoiWithKey(classId);
    } else {
      this.classifyFileWithKey(classId);
    }

    // update
    this.forceUpdate();
    window.forceSidebarUpdate();
  };

  classifyRoiWithKey = (classId) => {
    const { roiLayers, selectedLayer, structures } = this.props;
    // classify roi with key shortcut (0 is parent, 1 is first subtype, ...)

    let layer = selectedLayer;
    if (structures[selectedLayer].classificationSubtype) {
      layer = getParentIndexLayer(structures[selectedLayer], structures);
    }
    // get all subtypes / childs
    let childs = this.findChilds(structures[selectedLayer]);
    // get index of selected roi that gets classified
    let idx = roiLayers[layer].layer.regionRois.findIndex(
      (element) => element.selected
    );

    // make adjustments for point-counting module
    if (this.props.project.type.includes("HistoPointCounting")) {
      // if classify tile to parent --> return
      if (classId === 0) {
        return;
      }
    }

    // classify roi
    let roiWasSubtype =
      roiLayers[layer].layer.regionRois[idx].isSubtype &&
      !roiLayers[layer].layer.regionRois[idx].aiAnnotated;
    let bufferRoiId = roiLayers[layer].layer.regionRois[idx].structureId;

    // set properties of roi for classification if classifying to subtype
    if (childs[classId - 1]) {
      roiLayers[layer].layer.regionRois[idx].color = childs[classId - 1].color;
      roiLayers[layer].layer.regionRois[idx].isSubtype = true;
      roiLayers[layer].layer.regionRois[idx].isAnnotated = true;
      roiLayers[layer].layer.regionRois[idx].isLabeled = true;
      roiLayers[layer].layer.regionRois[idx].subtypeName =
        childs[classId - 1].label;
      roiLayers[layer].layer.regionRois[idx].structureId =
        childs[classId - 1].id;
      roiLayers[layer].layer.regionRois[idx].isSelObj = false;
      roiLayers[layer].layer.regionRois[idx].aiAnnotated = false;
    }

    // set properties of roi for classification if classifying to parent
    if (classId === 0) {
      roiLayers[layer].layer.regionRois[idx].color =
        structures[selectedLayer].color;
      roiLayers[layer].layer.regionRois[idx].isSubtype = false;
      roiLayers[layer].layer.regionRois[idx].isAnnotated = false;
      roiLayers[layer].layer.regionRois[idx].isLabeled = false;
      roiLayers[layer].layer.regionRois[idx].subtypeName =
        structures[selectedLayer].label;
      roiLayers[layer].layer.regionRois[idx].structureId =
        structures[selectedLayer].id;
      roiLayers[layer].layer.regionRois[idx].isSelObj = false;
      roiLayers[layer].layer.regionRois[idx].aiAnnotated = false;
    }

    // increase annotationCount
    let currentRoi = roiLayers[layer].layer.regionRois[idx];
    if (
      this.state.automaticTraining &&
      !structures[selectedLayer].classificationSubtype
    ) {
      if (currentRoi.isSubtype && !roiWasSubtype) {
        // if classify unlabeled to labeled
        this.props.tiles.setStrAnnoCount(currentRoi.structureId, 1);
      } else if (currentRoi.isSubtype && roiWasSubtype) {
        // if classify labeled to labeled
        this.props.tiles.setStrAnnoCount(currentRoi.structureId, 1);
        this.props.tiles.setStrAnnoCount(bufferRoiId, -1);
      } else if (roiWasSubtype) {
        // if classify labeled to unlabeled
        this.props.tiles.setStrAnnoCount(bufferRoiId, -1);
      }
    }

    // start training automatically if enough annotations
    if (this.state.automaticTraining) {
      let childs = this.findChilds(structures[selectedLayer]);
      let structureId = structures[selectedLayer].id;
      let annotations =
        this.getNuberChildRois(childs) -
        this.props.tiles.getStrAnnoCountElement(structureId);
      if (annotations >= this.state.startData && !this.props.alruns) {
        let enoughAnnos = this.enoughAnnotations(childs);

        if (enoughAnnos) {
          this.props.tiles.setAnnotationCount(0);
          this.props.tiles.setParentAnnoCount(
            structures[selectedLayer].id,
            annotations
          );
          this.props.startAutomaticTraining();
        } else {
          // give warning if too less annotations
          this.giveWarning();
        }
      }
    }
  };

  classifyFileWithKey = (classId) => {
    const { selectedLayer, structures, project } = this.props;
    // classify file with key shortcut (0 is parent, 1 is first subtype, ...)

    // get all subtypes / childs
    let parentStructure = structures[selectedLayer];
    if (structures[selectedLayer].classificationSubtype) {
      parentStructure = structures.filter(
        (element) => element.id === structures[selectedLayer].parentId
      )[0];
    }
    let childs = this.findChilds(parentStructure);
    // get index of selected roi that gets classified
    let idx = project.files.findIndex((element) => element.selectedInGallery);
    // assign classId
    if (childs[classId - 1]) {
      project.files[idx].classId = childs[classId - 1].id;
    }
  };

  classifyTilesGalleryWithKey = (classId) => {
    const { roiLayers, selectedLayer, structures } = this.props;
    const { page } = this.state;
    // classify tile with key shortcut --> function was made for project tilestool

    // get all subtypes / childs
    let childs = this.findChilds(structures[selectedLayer]);

    // set properties of roi for classification
    if (childs[classId - 1]) {
      roiLayers[selectedLayer].layer.regionRois[page].color =
        childs[classId - 1].color;
      roiLayers[selectedLayer].layer.regionRois[page].isSubtype = true;
      roiLayers[selectedLayer].layer.regionRois[page].isAnnotated = true;
      roiLayers[selectedLayer].layer.regionRois[page].subtypeName =
        childs[classId - 1].label;
      roiLayers[selectedLayer].layer.regionRois[page].structureId =
        childs[classId - 1].id;
    }

    // update
    this.forceUpdate();
  };

  setPageSelectedRoi = (roi) => {
    const { roiLayers, selectedLayer, structures } = this.props;
    // select correct page after shortcut from viewer in project tilestool

    // index of element will be page to select (beacuse only one image per page)
    let p = roiLayers[selectedLayer].layer.regionRois.findIndex(
      (element) => element === roi
    );
    // set context page
    if (this.props.showGallery) {
      this.props.tiles.setPage(structures[selectedLayer].id, p);
    } else {
      this.props.tiles.setTilePage(structures[selectedLayer].id, p);
    }

    this.setObjectToLoad(false, true);
    this.setState({ page: p });
  };

  giveWarning = () => {
    // show warning snackbar if not enought annotations for training
    if (this.enoughAnnosCount < 2) {
      this.props.trainingWarning("tooFewAnnotations");
      this.enoughAnnosCount = this.enoughAnnosCount + 1;
    }
  };

  handleDivMouseDown = (e) => {
    // set that mouse is down
    this.props.tiles.setIsMousedown(true);
    if (this.props.tiles.getOnPageInput()) {
      return;
    } else {
      // // disable page input textfield
      // document.getElementById("pageInput").disabled = true;
      if (document.getElementById("nAnnos") !== null) {
        document.getElementById("nAnnos").disabled = true;
      }
      if (document.getElementById("roiComment") !== null) {
        document.getElementById("roiComment").disabled = true;
      }
    }
    if (!this.props.showGallery) {
      return;
    }
    e.preventDefault();
  };

  handleDivMouseUp = (e) => {
    const { tools, activeTool } = this.props;
    // set that mouse is up
    this.props.tiles.setIsMousedown(false);
    if (
      !this.props.tiles.getIsOnImage() ||
      this.props.tiles.getIsInOtherImage()
    ) {
      // if finish drawing on other image or outside of image
      if (tools[activeTool]) {
        let p1;
        let prps = this.props.tiles.getRoiProps();
        tools[activeTool].mouse({
          event: e,
          p: p1,
          color: prps.color,
          subtype: prps.isSubtype,
          name: prps.subtypeName,
          positionInRoiLayer: -2,
          fullyLoaded: true,
        });
        this.props.tiles.setIsInOtherImage(false);
        this.forceUpdate();
      }
    }
  };

  getNuberChildRois = (childs) => {
    // get number of annotations of all childs (from context)
    let n = 0;
    childs.forEach((element) => {
      n = n + this.props.tiles.getStrAnnoCountElement(element.id);
    });
    return n;
  };

  enoughAnnotations = (childs) => {
    // check if enough annotations for training of dl model (at least one class with 5 annotations and one other class with 1 annotation)
    let minFive = false;
    let twoAnnotated = 0;
    childs.forEach((element) => {
      // check if minimum of five in one class
      if (this.props.tiles.getStrAnnoCountElement(element.id) >= 5) {
        minFive = true;
      }
      // check if two classes with at least one element
      if (this.props.tiles.getStrAnnoCountElement(element.id) >= 1) {
        twoAnnotated = twoAnnotated + 1;
      }
    });

    // if one class with at least five and one other class with at least one annotation --> enough annotations for training
    let enough = false;
    if (minFive && twoAnnotated >= 2) {
      enough = true;
    }
    return enough;
  };

  isRoiSubtype = (roi) => {
    const { structures, selectedLayer } = this.props;
    if (
      roi.subtypeName === structures[selectedLayer].label &&
      roi.color === structures[selectedLayer].color
    ) {
      // roi is element of selected structure
      return true;
    }

    // calculate childs of structure and check labels of childs
    let childLabels = [];
    let childColors = [];
    this.findChilds(structures[selectedLayer]).forEach((element) => {
      childLabels.push(element.label);
      childColors.push(element.color);
    });

    if (
      roi.isSubtype &&
      childLabels.includes(roi.subtypeName) &&
      childColors.includes(roi.color)
    ) {
      // roi is child of selected structure
      return true;
    } else {
      return false;
    }
  };

  isFileSubtype = (fileClassId) => {
    const { structures, selectedLayer } = this.props;
    if (fileClassId == structures[selectedLayer].id) {
      // roi is element of selected structure
      return true;
    }
  };

  findChilds = (subType) => {
    const { structures } = this.props;
    // return direct classification subtypes of selected structure
    return structures.filter(
      (element) =>
        element.subtypeLevel === subType.subtypeLevel + 1 &&
        element.parentId === subType.id &&
        element.classificationSubtype
    );
  };

  setProgress = (line) => {
    // get acc and epochs of training from backend messages
    let epochs = "";
    let acc = "";
    // get epoch in training
    if (line.includes("Epoch")) {
      epochs = line.split("Epoch")[1];
      if (epochs.includes("early stopping")) {
        // if early stopping save accuracy of training in local storage and graph
        epochs = "50/50";
        this.props.tiles.pushGraphAcc(this.validationAcc);
        this.props.persistentStorage.save(
          "accGraphObject",
          this.props.tiles.getGraphAcc()
        );
      } else if (epochs.includes("50/50")) {
        // if training finished save accuracy of training in local storage and graph
        epochs = "50/50";
        this.props.tiles.pushGraphAcc(this.validationAcc);
        this.props.persistentStorage.save(
          "accGraphObject",
          this.props.tiles.getGraphAcc()
        );
      }
      // set epochs for frontend text
      this.setState({ progressText: epochs });
    }
    // get acc in training
    if (line.includes("val_accuracy")) {
      acc = line.split("val_accuracy: ")[1];
      this.validationAcc = parseFloat(acc) * 100;
      this.setState({ trainingAcc: acc });
    }
  };

  filterObjects = (element) => {
    if (!this.props.project.type.includes("HistoPointCounting")) {
      return true;
    } else {
      // only show classified tiles and tiles that are not classified as "leer" in point counting module
      return element.isSubtype && element.subtypeName !== "leer [leer]";
    }
  };

  renderCroppedImage = (roi, context, id) => {
    const { galleryImageSize } = this.props.projectContext;
    return (
      <CroppedImage
        componentRef={(c) => (this.tile[id] = c)}
        onRefresh={() => this.forceUpdate()}
        ome={this.props.ome}
        histogramConfig={this.props.histogramConfig}
        visibleImage={this.visibleImage}
        coloredImages={this.coloredImages}
        roI={roi}
        structures={this.props.structures}
        selectedLayer={this.props.selectedLayer}
        canvasWidth={galleryImageSize}
        gallery={this.props.gallery}
        contour={this.state.drawContour}
        activeTool={this.props.activeTool}
        tools={this.props.tools}
        number={context.state.number}
        fileId={this.props.fileId}
        //contx={this.props.contx}
        visImgs={this.props.visImgs}
        index={id}
        indexOffset={this.state.page * this.state.rowsPerPage}
        page={this.pageIndex}
        roiLayers={this.props.roiLayers}
        classificationId={this.classificationId}
        fromAI={true}
        drawLayer={this.props.drawLayer}
        updateGallery={this.updateGallery}
        startData={this.state.startData}
        alruns={this.props.alruns}
        setAlruns={this.props.setAlruns}
        z={this.state.z}
        t={this.state.t}
        showZStackBar={this.props.showZStackBar}
        automaticTraining={this.state.automaticTraining}
        startAutomaticTraining={this.props.startAutomaticTraining}
        trainingWarning={this.props.trainingWarning}
        setFUllyAnnotated={this.props.setFUllyAnnotated}
        setAnnotated={this.props.setAnnotated}
        showGallery={this.props.showGallery}
        drawCrosshair={this.state.drawCrosshair}
        crosshairColor={this.state.crosshairColor}
        crosshairOpacity={this.state.crosshairOpacity}
        crosshairLineWidth={this.state.crosshairLineWidth}
        scroll={this.scroll}
        setSelectedWithKey={this.setSelectedWithKey}
        selectedWithKey={this.state.selectedWithKey}
        isBrightfield={this.state.isBrightfield}
        objectToLoad={this.state.objectToLoad}
        setObjectToLoad={this.setObjectToLoad}
        giveWarning={this.giveWarning}
        project={this.props.project}
        globalZ={this.props.globalZ}
        opacity={this.props.opacity}
      />
    );
  };

  renderSceneImage = (file) => {
    const { galleryImageSize } = this.props.projectContext;
    return (
      <SceneImage
        files={this.props.project.files}
        file={file}
        imageSize={galleryImageSize}
        structures={this.props.structures}
        selectedLayer={this.props.selectedLayer}
        classificationId={this.classificationId}
        scroll={this.scroll}
        updateGallery={this.updateGallery}
        setSelectedWithKey={this.setSelectedWithKey}
        selectedWithKey={this.state.selectedWithKey}
        showFileNames={this.state.showFileNames}
        galleryMounted={this.state.galleryMounted}
      />
    );
  };

  render() {
    const { structures, selectedLayer, roiLayers, project, tools } = this.props;
    const { classes, ...propsWithoutClasses } = this.props;
    const { page, rowsPerPage } = this.state;
    const { galleryImageSize } = this.props.projectContext;

    let params = {
      classificationFiles: project.files.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    };
    tools.iam_ai_inference?.onParameterChange(params);

    // tooltip describes how many annotations it takes for automatic start of training
    let toolTipCircularProgress =
      "if (" +
      this.state.startData +
      "/" +
      this.state.startData +
      ") training starts automatically";

    // calculate number of annotations for progess in bottom right (circular progress)
    let childs = this.findChilds(structures[selectedLayer]);
    let structureId = structures[selectedLayer].id;
    let annotations =
      this.getNuberChildRois(childs) -
      this.props.tiles.getStrAnnoCountElement(structureId);
    let progress = (annotations / this.state.startData) * 100;
    if (annotations > this.state.startData) {
      progress = 100;
    }

    // get subtypes / childs of selected structure for classification
    let childsGallery = this.findChilds(structures[selectedLayer]);
    if (!this.props.project.type.includes("HistoPointCounting")) {
      // add parent / selected layer to childs / subtypes
      childsGallery.unshift(structures[selectedLayer]);
    }

    // calculate rois that will be displayed in gallery depending on selected structure
    let roisForImages = [];
    if (!structures[selectedLayer].classificationSubtype) {
      // show all rois if parent structure (exception --> point counting module (look at filterObjects function))
      roisForImages = roiLayers[selectedLayer].layer.regionRois.filter(
        (element) => this.filterObjects(element)
      );
    } else {
      // only show subtype rois if subtype structure
      roisForImages = roiLayers[
        getParentIndexLayer(structures[selectedLayer], structures)
      ].layer.regionRois.filter((element) => this.isRoiSubtype(element));
    }

    // calculate files that will be displayed in gallery depending on selected structure
    let displayedFiles = [];
    if (!structures[selectedLayer].classificationSubtype) {
      // show all files if parent structure
      displayedFiles = project.files;
    } else {
      // only show subtype rois if subtype structure
      displayedFiles = project.files.filter((element) =>
        this.isFileSubtype(element.classId)
      );
    }

    return (
      <div
        style={{
          margin: 0,
          marginRight: "5px",
          background: "#fff",
          height: "100%",
          width: "100%",
        }}
        onMouseDown={this.handleDivMouseDown}
        onMouseUp={this.handleDivMouseUp}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      >
        <GalleryToolBoxes
          childsGallery={childsGallery}
          childs={childs}
          classificationId={this.classificationId}
          page={this.state.page}
          trainingAcc={this.state.trainingAcc}
          startData={this.state.startData}
          progressText={this.state.progressText}
          isFilesGallery={
            this.props.viewerConfig.project.projectStringProperties[
              "ViewerType"
            ] === "FilesGallery"
          }
          setStartData={this.setStartData}
          setDrawContour={this.setDrawContour}
          setShowFileNames={this.setShowFileNames}
          setAutomaticTraining={this.setAutomaticTraining}
          setDrawCrosshair={this.setDrawCrosshair}
          setCrosshairColor={this.setCrosshairColor}
          setCrosshairOpacity={this.setCrosshairOpacity}
          setCrosshairLineWidth={this.setCrosshairLineWidth}
          {...propsWithoutClasses}
        />

        {this.props.viewerConfig.project.projectStringProperties[
          "ViewerType"
        ] == "FilesGallery" && (
          <Grid
            container
            justifyContent="center"
            spacing={2}
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "calc(100% - 80px)",
              width: "calc(100% - 330px)",
              marginLeft: "330px",
              marginTop: "10px",
              alignContent: "flex-start",
            }}
            id="galleryWindow"
            onScroll={() => {
              // if scrolling manually set selected with key to false --> no more automatic scrolling
              if (this.state.selectedWithKey) {
                this.setState({ selectedWithKey: false });
              }
            }}
          >
            {displayedFiles
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((file, id) => {
                return (
                  <Grid
                    item
                    style={{
                      width:
                        galleryImageSize !== -1 ? galleryImageSize : "100%",
                      height: "auto",
                    }}
                    key={id}
                  >
                    {this.renderSceneImage(file)}
                  </Grid>
                );
              })}
          </Grid>
        )}

        {this.props.viewerConfig.project.projectStringProperties[
          "ViewerType"
        ] !== "FilesGallery" && (
          <Grid
            item
            xs={12}
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              maxHeigth: "400px",
              height: "calc(100% - 70px)",
              marginLeft: "330px",
            }}
            id="galleryWindow"
            onScroll={() => {
              // if scrolling manually set selected with key to false --> no more automatic scrolling
              if (this.state.selectedWithKey) {
                this.setState({ selectedWithKey: false });
              }
            }}
          >
            <Grid
              container
              justifyContent="center"
              spacing={1}
              style={{
                maxHeight: "calc(100vh - 360px)",
                padding: "20px 20px",
              }}
            >
              {roisForImages
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((roi, id) => {
                  // give roi correct color
                  let clr = structures[selectedLayer].color;
                  if (roi.color === "#FFFFFF") {
                    roi.color = clr;
                  }

                  return (
                    <Grid style={{ margin: "3px" }} key={id}>
                      <div
                        className={classes.imgOuter}
                        style={{ padding: "3px", position: "relative" }}
                      >
                        {roi.isLabeled || !roi.isSelObj ? (
                          <span></span>
                        ) : (
                          <div
                            className={`${classes.aiLabel} aiLabel`}
                            style={{
                              border: "3px solid " + roi.color,
                            }}
                          >
                            AI
                          </div>
                        )}
                        {!this.props.showGallery && (
                          <div
                            style={{
                              border: "3px solid" + roi.color,
                              position: "absolute",
                              zIndex: 99999,
                              top: "5px",
                              right: "10px",
                            }}
                          >
                            {roi.tileName}
                          </div>
                        )}
                        {this.renderCroppedImage(roi, this.props.tiles, id, 4)}
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        )}

        <div style={{ marginTop: "10px" }}>
          <TablePagination
            style={{ display: "inline-block" }}
            labelRowsPerPage="Images per page:"
            rowsPerPageOptions={this.getRowsPerPageOptionsForProject()}
            component="div"
            count={this.state.elementCount}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            labelDisplayedRows={() =>
              `Page ${this.state.page + 1} of ${Math.ceil(
                this.state.elementCount / this.state.rowsPerPage
              )} (${this.state.elementCount} objects)`
            }
          />

          {this.state.automaticTraining &&
            !this.props.structures[this.props.selectedLayer]
              .classificationSubtype && (
              <Tooltip
                disableInteractive
                title={toolTipCircularProgress}
                placement="top"
              >
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    width: "55px",
                    height: "55px",
                    float: "right",
                    margin: "0px 20px 0px 0px",
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontSize: "13px",
                    fontWeight: "bold",
                    textAlign: "center",
                    paddingTop: "17px",
                  }}
                >
                  {annotations + "/" + this.state.startData}
                  <CircularProgress
                    variant="determinate"
                    size={55}
                    value={progress}
                    style={{
                      position: "absolute",
                      zIndex: 10,
                      top: 0,
                      left: 0,
                    }}
                  />
                </div>
              </Tooltip>
            )}
        </div>
      </div>
    );
  }
}

Gallery.propTypes = {
  classes: PropTypes.object.isRequired,
  componentRef: PropTypes.func,
  roiLayers: PropTypes.array,
  ome: PropTypes.object,
  fileId: PropTypes.string,
  projectId: PropTypes.string,
  structures: PropTypes.array,
  selectedLayer: PropTypes.number,
  visibleImage: PropTypes.array,
  coloredImages: PropTypes.array,
  histogramConfig: PropTypes.object,
  gallery: PropTypes.func,
  activeTool: PropTypes.string,
  tools: PropTypes.array,
  visImgs: PropTypes.array,
  selectedObjects: PropTypes.object,
  drawLayer: PropTypes.object,
  saveChangesGallery: PropTypes.func,
  applyDL: PropTypes.func,
  test: PropTypes.bool,
  updateCount: PropTypes.number,
  alruns: PropTypes.bool,
  setAlruns: PropTypes.func,
  graphData: PropTypes.number,
  updateViewer: PropTypes.func,
  onGallerychangePage: PropTypes.func,
  showZStackBar: PropTypes.bool,
  // from withTiles
  tiles: PropTypes.object,
  globalZ: PropTypes.number,
  opacity: PropTypes.number,
  project: PropTypes.object,
  viewerConfig: PropTypes.object,
  persistentStorage: PropTypes.object,
  showGallery: PropTypes.bool,
  selObjs: PropTypes.bool,
  updateProject: PropTypes.func,
  commentLayer: PropTypes.object,
  allRoiLayers: PropTypes.array,
  startAutomaticTraining: PropTypes.func,
  trainingWarning: PropTypes.func,
  setFUllyAnnotated: PropTypes.func,
  setAnnotated: PropTypes.func,
  updateFileClasses: PropTypes.func,
  projectContext: PropTypes.object,
};

export default withAllViewerContexts(
  withPersistentStorage(withTiles(withStyles(styles)(Gallery)))
);

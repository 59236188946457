import React, { Component } from "react";

import PropTypes from "prop-types";

import * as THREE from "three";

class ViewHelper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scene: new THREE.Scene(),
      renderer: new THREE.WebGLRenderer({ antialias: true, alpha: true }),
      camera: new THREE.PerspectiveCamera(75, 800 / 800, 0.1, 1000),
      propsCam: this.props.camera,
      cameraPosition: new THREE.Vector3(0, 0, 1),
      focusPoint: new THREE.Vector3(0, 0, 0),
    };
  }

  componentDidMount = () => {
    let width = this.props.viewHelperSize;
    let height = this.props.viewHelperSize;
    //Config Renderer
    this.state.renderer.setClearColor(0x000000, 0);
    this.state.renderer.setSize(width, height);
    this.mount.appendChild(this.state.renderer.domElement);
    //Config Camera
    let cameraPosition = new THREE.Vector3(0, 0, 1);
    let focusPoint = new THREE.Vector3(0, 0, 0);
    this.setState({
      cameraPosition,
      focusPoint,
    });
    this.state.camera.position.set(
      cameraPosition.x,
      cameraPosition.y,
      cameraPosition.z
    );
    this.state.camera.lookAt(focusPoint);
    // Custom Axes
    let color1 = new THREE.Color("#ff3653");
    let color2 = new THREE.Color("#8adb00");
    let color3 = new THREE.Color("#2c8fff");
    let geometry = new THREE.BoxGeometry(0.4, 0.01, 0.01).translate(0.2, 0, 0);
    let xAxis = new THREE.Mesh(geometry, this.getAxisMaterial(color1));
    let yAxis = new THREE.Mesh(geometry, this.getAxisMaterial(color2));
    let zAxis = new THREE.Mesh(geometry, this.getAxisMaterial(color3));
    yAxis.rotation.z = Math.PI / 2;
    zAxis.rotation.y = -Math.PI / 2;
    this.state.scene.add(xAxis);
    this.state.scene.add(zAxis);
    this.state.scene.add(yAxis);
    let posXAxisHelper = new THREE.Sprite(this.getSpriteMaterial(color1, "X"));
    posXAxisHelper.userData.type = "posX";
    let posYAxisHelper = new THREE.Sprite(this.getSpriteMaterial(color2, "Z"));
    posYAxisHelper.userData.type = "posY";
    let posZAxisHelper = new THREE.Sprite(this.getSpriteMaterial(color3, "Y"));
    posZAxisHelper.userData.type = "posZ";
    let negXAxisHelper = new THREE.Sprite(this.getSpriteMaterial(color1));
    negXAxisHelper.userData.type = "negX";
    let negYAxisHelper = new THREE.Sprite(this.getSpriteMaterial(color2));
    negYAxisHelper.userData.type = "negY";
    let negZAxisHelper = new THREE.Sprite(this.getSpriteMaterial(color3));
    negZAxisHelper.userData.type = "negZ";
    posXAxisHelper.position.x = 0.4;
    posYAxisHelper.position.y = 0.4;
    posZAxisHelper.position.z = 0.4;
    negXAxisHelper.position.x = -0.4;
    negYAxisHelper.position.y = -0.4;
    negZAxisHelper.position.z = -0.4;
    posXAxisHelper.scale.setScalar(0.3);
    negXAxisHelper.scale.setScalar(0.2);
    posYAxisHelper.scale.setScalar(0.3);
    negYAxisHelper.scale.setScalar(0.2);
    posZAxisHelper.scale.setScalar(0.3);
    negZAxisHelper.scale.setScalar(0.2);
    this.state.scene.add(posXAxisHelper);
    this.state.scene.add(posYAxisHelper);
    this.state.scene.add(posZAxisHelper);
    this.state.scene.add(negXAxisHelper);
    this.state.scene.add(negYAxisHelper);
    this.state.scene.add(negZAxisHelper);
    this.renderScene();
    //start animation
    this.start();
  };

  getAxisMaterial = (color) => {
    return new THREE.MeshBasicMaterial({ color: color, toneMapped: false });
  };

  getSpriteMaterial = (color, text = null) => {
    let canvas = document.createElement("canvas");
    canvas.width = 64;
    canvas.height = 64;

    let context = canvas.getContext("2d");
    context.beginPath();
    context.arc(32, 32, 16, 0, 2 * Math.PI);
    context.closePath();
    context.fillStyle = color.getStyle();
    context.fill();

    if (text !== null) {
      context.font = "24px Arial";
      context.textAlign = "center";
      context.fillStyle = "#000000";
      context.fillText(text, 32, 41);
    }
    let texture = new THREE.CanvasTexture(canvas);

    return new THREE.SpriteMaterial({ map: texture, toneMapped: false });
  };

  renderScene = () => {
    if (this.state.renderer) {
      let cameraPosition = new THREE.Vector3(0, 0, 1).applyQuaternion(
        this.props.camera.quaternion
      );
      // let cube = this.state.scene.children[2];
      let posXAxisHelper = this.state.scene.children[3];
      let posYAxisHelper = this.state.scene.children[4];
      let posZAxisHelper = this.state.scene.children[5];
      let negXAxisHelper = this.state.scene.children[6];
      let negYAxisHelper = this.state.scene.children[7];
      let negZAxisHelper = this.state.scene.children[8];

      if (cameraPosition.x >= 0) {
        posXAxisHelper.material.opacity = 1;
        negXAxisHelper.material.opacity = 0.5;
      } else {
        posXAxisHelper.material.opacity = 0.5;
        negXAxisHelper.material.opacity = 1;
      }
      if (cameraPosition.y >= 0) {
        posYAxisHelper.material.opacity = 1;
        negYAxisHelper.material.opacity = 0.5;
      } else {
        posYAxisHelper.material.opacity = 0.5;
        negYAxisHelper.material.opacity = 1;
      }
      if (cameraPosition.z >= 0) {
        posZAxisHelper.material.opacity = 1;
        negZAxisHelper.material.opacity = 0.5;
      } else {
        posZAxisHelper.material.opacity = 0.5;
        negZAxisHelper.material.opacity = 1;
      }

      this.state.camera.position.set(
        cameraPosition.x,
        cameraPosition.y,
        cameraPosition.z
      );
      this.state.camera.position.set(
        cameraPosition.x,
        cameraPosition.y,
        cameraPosition.z
      );
      this.state.camera.lookAt(this.state.focusPoint);
      this.state.renderer.render(this.state.scene, this.state.camera);
    }
  };

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  };

  stop = () => {
    cancelAnimationFrame(this.frameId);
  };

  animate = () => {
    //Animate Models Here
    //ReDraw Scene with Camera and Scene Object
    this.renderScene();
    this.frameId = window.requestAnimationFrame(this.animate);
  };

  render() {
    return (
      <div
        ref={(ref) => (this.mount = ref)}
        style={{ position: "absolute", right: 0, bottom: 0 }}
      />
    );
  }
}

// define the component's interface
ViewHelper.propTypes = {
  viewHelperSize: PropTypes.number,
  camera: PropTypes.object,
};

export default ViewHelper;

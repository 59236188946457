import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import { withSpectraViewer } from "../contexts/SpectraViewerContext";

import { Redirect } from "react-router-dom";
import { Tabs, Tab } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faChartArea,
  faChartLine,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

import SpectraTab from "./SpectraSidebarTabs/SpectraTab";
import OperationsTab from "./SpectraSidebarTabs/OperationsTab";
import ModelTab from "./SpectraSidebarTabs/ModelTab";
import AnalysisTab from "./SpectraSidebarTabs/AnalysisTab";

const styles = {
  root: {
    height: "100%",
    display: "grid",
    gridTemplateRows: "auto 1fr",
    overflow: "hidden",
  },
  tab: {
    minWidth: 395 / 3,
    minHeight: 44,
    height: 44,
    paddingBottom: 0,
    paddingTop: 0,
    "& *": {
      display: "inline-block",
      fontSize: "16px",
      lineHeight: "16px",
    },
    "& svg": {
      marginRight: "5px",
      position: "relative",
      top: "4px",
    },
  },
};

class SpectraSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      returnHome: false,
    };
  }
  handleTabChange = (event, value) => {
    this.setState({ activeTab: value });
  };

  render() {
    const { classes } = this.props;
    const { activeTab } = this.state;

    return this.props.project.type === "ESRTraining" ? (
      // ESR Training Module
      <div className={classes.root}>
        {/* Return to homescreen by rendering the following line */}
        {this.state.returnHome && <Redirect push to="/" />}
        <Tabs
          //className={`${classes.tabsContainer} ${classes.flexRowContentHeight}`}
          value={activeTab}
          onChange={this.handleTabChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            className={classes.tab}
            icon={<FontAwesomeIcon icon={faChartArea} size="lg" />}
            label={
              "Spectra (" + this.props.spectraViewer.rawSpectra.length + ")"
            }
          />
          <Tab
            className={classes.tab}
            icon={<FontAwesomeIcon icon={faCog} size="lg" />}
            label="Settings"
          />
          <Tab
            className={classes.tab}
            icon={<FontAwesomeIcon icon={faCheckCircle} size="lg" />}
            label="Models"
          />
          <Tab
            className={classes.tab}
            icon={<FontAwesomeIcon icon={faChartLine} size="lg" />}
            label="Analysis"
          />
        </Tabs>
        {this.state.activeTab === 0 && (
          <SpectraTab
            project={this.props.project}
            datasets={this.props.datasets}
            toggleLegend={this.props.toggleLegend}
            toggleAll={this.props.toggleAll}
            initialized={this.props.initialized}
            updateChartData={this.props.updateChartData}
            setInitialized={this.props.setInitialized}
            showRawSpectra={this.props.showRawSpectra}
            onSave={this.props.onSave}
            returnHome={() => this.setState({ returnHome: true })}
          />
        )}
        {this.state.activeTab === 1 && (
          <OperationsTab
            project={this.props.project}
            requestData={this.props.requestData}
            chart={this.props.chart}
            xAxisData={this.props.xAxisData}
            initialized={this.props.initialized}
            setActiveTab={(tab_no) => this.setState({ activeTab: tab_no })}
            throwError={this.props.throwError}
          />
        )}
        {this.state.activeTab === 2 && (
          <ModelTab
            project={this.props.project}
            initialized={this.props.initialized}
            onSave={this.props.onSave}
            requestData={this.props.requestData}
            setActiveTab={(value) => this.setState({ activeTab: value })}
            returnHome={() => this.setState({ returnHome: true })}
          />
        )}
        {this.state.activeTab === 3 && (
          <AnalysisTab
            datasets={this.props.datasets}
            toggleLegend={this.props.toggleLegend}
            showRawSpectra={this.props.showRawSpectra}
            showAnalysisResults={this.props.showAnalysisResults}
          />
        )}
      </div>
    ) : (
      // ESR Evaluation Module
      <div className={classes.root}>
        {/* Return to homescreen by rendering the following line */}
        {this.state.returnHome && <Redirect push to="/" />}
        <Tabs
          //className={`${classes.tabsContainer} ${classes.flexRowContentHeight}`}
          value={activeTab}
          onChange={this.handleTabChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            className={classes.tab}
            icon={<FontAwesomeIcon icon={faChartArea} size="lg" />}
            label={
              "Spectra (" + this.props.spectraViewer.rawSpectra.length + ")"
            }
          />
          <Tab
            className={classes.tab}
            icon={<FontAwesomeIcon icon={faCog} size="lg" />}
            label="Models"
          />
          <Tab
            className={classes.tab}
            icon={<FontAwesomeIcon icon={faChartLine} size="lg" />}
            label="Analysis"
          />
        </Tabs>
        {this.state.activeTab === 0 && (
          <SpectraTab
            project={this.props.project}
            datasets={this.props.datasets}
            toggleLegend={this.props.toggleLegend}
            toggleAll={this.props.toggleAll}
            initialized={this.props.initialized}
            updateChartData={this.props.updateChartData}
            setInitialized={this.props.setInitialized}
            showRawSpectra={this.props.showRawSpectra}
            onSave={this.props.onSave}
            returnHome={() => this.setState({ returnHome: true })}
          />
        )}
        {this.state.activeTab === 1 && (
          <ModelTab
            project={this.props.project}
            initialized={this.props.initialized}
            onSave={this.props.onSave}
            requestData={this.props.requestData}
            setActiveTab={(value) => this.setState({ activeTab: value })}
            returnHome={() => this.setState({ returnHome: true })}
          />
        )}
        {this.state.activeTab === 2 && (
          <AnalysisTab
            datasets={this.props.datasets}
            toggleLegend={this.props.toggleLegend}
            showRawSpectra={this.props.showRawSpectra}
            showAnalysisResults={this.props.showAnalysisResults}
          />
        )}
      </div>
    );
  }
}

SpectraSideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  spectraViewer: PropTypes.object.isRequired,
  project: PropTypes.object,
  xAxisData: PropTypes.object,
  chart: PropTypes.object,
  datasets: PropTypes.array,
  initialized: PropTypes.bool,
  setInitialized: PropTypes.func,
  maxScores: PropTypes.number,
  toggleLegend: PropTypes.func,
  toggleAll: PropTypes.func,
  onChangeMetaData: PropTypes.func,
  requestData: PropTypes.func,
  onSave: PropTypes.func,
  showRawSpectra: PropTypes.func,
  showAnalysisResults: PropTypes.func,
  throwError: PropTypes.func,
  updateChartData: PropTypes.func,
};

export default withSpectraViewer(withStyles(styles)(SpectraSideBar));

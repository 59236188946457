import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from '@mui/styles/withStyles';
import { TextField, Button, Typography, Tabs, Tab } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faDna } from "@fortawesome/free-solid-svg-icons";

import ProteomSettings from "./ProteomSettings";
import Preview3d from "../components/Preview3d";

const styles = {
  paddingContainer: {
    padding: 10,
  },
  formItem: {
    width: "100%",
    marginBottom: 20,
  },
  numberInput: {
    width: 50,
    marginRight: 10,
  },
  applyButton: {
    width: "100%",
  },
  tab: {
    minWidth: 395 / 3,
    minHeight: 44,
    height: 44,
    paddingBottom: 0,
    paddingTop: 0,
    "& *": {
      display: "inline-block",
      fontSize: "16px",
      lineHeight: "16px",
    },
    "& svg": {
      marginRight: "5px",
      position: "relative",
      top: "4px",
    },
  },
};

class ProteomeSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };

    window.forceSidebarUpdate = this.forceSidebarUpdate;
  }

  // // file handling, if upload need later on
  // componentDidMount = () => {
  //   this.fileInputButton = document.getElementById("proteomFileInput");
  //   this.fileInputButton.addEventListener("change", this.readSingleFile, false);
  // };

  // readSingleFile(e) {
  //   let file = e.target.files[0];
  //   if (!file) {
  //     return;
  //   }
  //   let reader = new FileReader();
  //   reader.onload = function (e) {
  //     let contents = e.target.result;
  //     //displayContents(contents);
  //     let lines = contents.split("\n");
  //     let initIdx = 0;
  //     //important columns:
  //     // ID => Protein ID (more)
  //     //
  //     for (let i = 0; i < lines.length; i++) {
  //       lines[i] = lines[i].split("\t");
  //       if (initIdx === 0 && lines[i].length > 10) {
  //         initIdx = i;
  //       }
  //     }
  //     let lineTable = lines.splice(initIdx, lines.length);
  //     console.log("lineTable:", initIdx, lineTable.length, lineTable);
  //   };
  //   reader.readAsText(file);
  // }

  // openProteomFile = () => {
  //   this.fileInputButton.click();
  // };

  handleTabChange = (event, value) => {
    this.setState({ activeTab: value });
  };
  render() {
    const { classes, project } = this.props;
    const { activeTab } = this.state;

    return (
      <div>
        <Tabs
          //className={`${classes.tabsContainer} ${classes.flexRowContentHeight}`}
          value={activeTab}
          onChange={this.handleTabChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            //id="viewTabBtn"
            className={classes.tab}
            icon={<FontAwesomeIcon icon={faDna} size="lg" />}
            label="View"
          />
          <Tab
            //id="viewTabBtn"
            className={classes.tab}
            icon={<FontAwesomeIcon icon={faCog} size="lg" />}
            label="Properties"
          />
        </Tabs>
        {this.state.activeTab === 0 && (
          <Preview3d selectedRow={this.props.selectedRow} />
        )}

        {/* <input
          // onClick={console.log("test")}
          style={{ display: "none" }}
          type="file"
          id="proteomFileInput"
        /> */}
        {this.state.activeTab === 1 && (
          <div className={classes.paddingContainer}>
            <Typography className={classes.formItem} variant="h6">
              Analysis Properties
            </Typography>

            {project && (
              <React.Fragment>
                <TextField
                  label="Title"
                  variant="outlined"
                  value={project.name ? project.name : ""}
                  onChange={(e) => this.props.changeProjectName(e.target.value)}
                  className={classes.formItem}
                />
                {project.files.length > 0 && (
                  <TextField
                    label="File"
                    variant="outlined"
                    value={project.files[0].fileName}
                    className={classes.formItem}
                    InputProps={{
                      readOnly: true,
                    }}
                    //onClick={this.openProteomFile}
                  />
                )}

                <ProteomSettings
                  metaData={project.metaData}
                  onChangeMetaData={this.props.onChangeMetaData}
                  projectProperties={
                    project.viewerConfig.project.projectProperties
                  }
                  projectStringProperties={
                    project.viewerConfig.project.projectStringProperties
                  }
                />
              </React.Fragment>
            )}

            <Button
              variant="contained"
              color="primary"
              className={classes.applyButton}
              onClick={this.props.saveAndRunProject}
            >
              Apply
            </Button>
          </div>
        )}
      </div>
    );
  }
}

ProteomeSideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  project: PropTypes.object,
  selectedRow: PropTypes.object,
  onChangeMetaData: PropTypes.func,
  changeProjectName: PropTypes.func,
  saveAndRunProject: PropTypes.func,
};

export default withStyles(styles)(ProteomeSideBar);

import React from "react";
import PropTypes from "prop-types";
import Backend from "../../common/utils/Backend";
import ModuleSelectionDialog from "./ModuleSelectionDialog";
import {
  Grid,
  Typography,
  IconButton,
  Card,
  CardContent,
  Tooltip,
  Button,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import TextField from "@mui/material/TextField";

import {
  faImages,
  faCube,
  faFillDrip,
  faPen,
  faPencilRuler,
  faDrawPolygon,
  faClone,
  faMousePointer,
  faCamera,
  faDownload,
  faSquareFull,
  faTable,
  faThLarge,
  faBrain,
  faHandPointer,
} from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ViewQuilt,
  Save,
  ZoomOutMap,
  CheckBoxOutlineBlank,
  ZoomIn,
} from "@mui/icons-material";
import PublishIcon from "@mui/icons-material/Publish";
import GetAppIcon from "@mui/icons-material/GetApp";
import SortIcon from "@mui/icons-material/Sort";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "calc(100vh - 64px - 48px)",
    overflow: "hidden",
  },
  paper: {
    padding: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  activeColor: {
    color: theme.palette.primary.main,
  },
  textIcon: {
    fontSize: 18,
    fontWeight: "bold",
  },
  tileContainer: {
    position: "relative",
    width: 270,
    height: 180,
    overflow: "hidden",
  },
  textInputField: {
    width: "calc(100% - 320px)",
  },
  previewDiv: {
    cursor: "pointer",
    background: "gray",
    color: "white",
    top: "50%",
    width: "100%",
    position: "relative",
    height: 180,
    textAlign: "center",
    fontSize: 40,
    lineHeight: "150px",
  },
  tileImage: {
    width: "100%",
    position: "relative",
    cursor: "pointer",
  },
  previewIcon: {
    marginTop: "-8px",
  },
  tileBar: {
    position: "absolute",
    left: "0",
    bottom: "0",
    width: "100%",
    height: "auto",
    padding: "14px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "1em",
    color: "white",
    background: "rgba(0,0,0,0.5)",
  },
  middleStructuresColumn: {
    float: "left",
    marginRight: 10,
  },
});

class ModuleCreationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDevState: false,
      previewFile: null,
      previewImgUrl: "",
      dialogOpen: false,
      selectedProject: null,
      name: "",
      moduleIdError: false,
      moduleLabelError: false,
      previewError: false,
      noStructuresError: false,
      project: {
        isUserModule: true,
        label: "",
        description: "",
        annotations: "pixel",
        order: 0,
        metaData: [
          "Datum",
          "Versuchsnummer",
          "AK Verdünnung",
          "Mitarbeiter",
          "Färbung",
          "AK",
        ],
        ProjectProperties: {
          AutomaticBaseRoiWholeImage: false,
          Hidden: false,
          HideSubMenus: false,
          Online: false,
          PreventOverlap: true,
          PreventOverlapSame: true,
        },
        ProjectStringProperties: {
          SideBarWidth: "",
        },
        toolsInProject: {
          GalleryTool: true,
          TilesTool: false,
          AdjustLayoutTool: true,
          FindSmallestROITool: true,
          PlotNearestTool: false,
          DrawRectangleAnnotationTool: true,
          DrawEllipseAnnotationTool: true,
          DrawPixelAnnotationTool: true,
          DrawRegionAnnotationTool: true,
          SelectRegionAnnotationTool: true,
          CopyRegionAnnotationTool: true,
          FillHoleAnnotationTool: true,
          OtherTools: true,
          CommentTool: true,
          ScreenshotTool: true,
          ZoomOriginalTool: true,
          ZoomInOutTool: true,
          ZoomFitTool: true,
          SaveTool: true,
          LandmarkTool: true,
          HeatmapTool: true,
          SaveImagesTool: false,
          PassiveLearningTool: false,
          ApplyModelTool: false,
          RoiTab: true,
          AICockpit: true,
          ResultTab: false,
          AITrainingTool: false,
          ShowALTabTool: false,
          GridTool: false,
          GridAnnotationTool: true,
          GalleryTrainingTool: false,
        },
        structures: [
          {
            id: 1,
            label: "Base ROI",
            color: "rgba(0, 0, 0, 0.0)",
            inversed: "true",
            toolNames: ["iam_base_roi"],
          },
        ],
        dynamicStructure: {
          label: "Structure {}",
          toolNames: [],
        },
        models: [],
        job: "job_simple_analysis",
      },
    };

    Backend.loadReducedAvailableProjectTypes((projectTypes) => {
      if (
        projectTypes.some((projectType) => projectType.name === "testProject")
      ) {
        this.setState({ isDevState: true });
      }
    });
  }

  setProject = (obj) => {
    let projectObject = this.state.project;
    const structureKeys = [
      "id",
      "label",
      "color",
      "toolNames",
      "inversed",
      "parentId",
      "classificationSubtype",
    ];

    Object.keys(projectObject).forEach((key) => {
      if (key in obj) {
        if (key === "structures") {
          projectObject["structures"] = [];
          for (let structure of obj["structures"]) {
            let structureObj = {};
            for (let k of structureKeys) {
              if (k in structure) {
                structureObj[k] = structure[k];
              }
            }
            projectObject["structures"].push(structureObj);
          }
        } else {
          projectObject[key] = obj[key];
        }
      }
    });
    this.setState({ project: projectObject });
  };

  handleStructureSelect = ({ target }) => {
    let uploadedFile = target.files[0];
    if (typeof uploadedFile === "undefined") return false;

    if (
      !(uploadedFile.type === "" && uploadedFile.name.endsWith(".strhsa")) &&
      uploadedFile.type !== "application/json"
    ) {
      window.showErrorSnackbar("Wrong file type == " + uploadedFile.type);
      return false;
    }

    if (uploadedFile) {
      let readFile = new FileReader();
      readFile.onload = (e) => {
        let contents = e.target.result;
        let structures = JSON.parse(contents);
        let resultStructures = [];
        for (let structure of structures) {
          let tempStructure = {
            id: structure.id,
            label: structure.label,
            color: structure.color,
            toolNames: structure.toolNames ? structure.toolNames : [],
          };
          if (structure.inversed) {
            tempStructure.inversed = structure.inversed;
          }
          if (structure.parentId) {
            tempStructure.parentId = structure.parentId;
          }
          if (structure.classificationSubtype) {
            tempStructure.classificationSubtype =
              structure.classificationSubtype;
          }
          resultStructures.push(tempStructure);
        }
        this.setProject({
          structures: resultStructures,
        });
        this.setState({
          noStructuresError: false,
        });
      };
      readFile.readAsText(uploadedFile);
    } else {
      console.log("Failed to load file");
    }
  };

  handlePreviewSelect = ({ target }) => {
    if (target.files.length > 0) {
      this.setState({
        previewError: false,
        previewFile: target.files[0],
        previewImgUrl: window.URL.createObjectURL(target.files[0]),
      });
    }
  };

  renderIcon(param) {
    switch (param) {
      case "GalleryTool":
        return <FontAwesomeIcon icon={faImages} />;
      case "3DVisualisation":
        return <FontAwesomeIcon icon={faCube} />;
      case "TilesTool":
        return <FontAwesomeIcon icon={faSquareFull} />;
      case "FindSmallestROITool":
        return <SortIcon />;
      case "PlotNearestTool":
        return <ScatterPlotIcon />;
      case "AdjustLayoutTool":
        return <ViewQuilt />;
      case "DrawRectangleAnnotationTool":
        return <CheckBoxOutlineBlank />;
      case "DrawPixelAnnotationTool":
        return <FontAwesomeIcon icon={faPen} />;
      case "DrawMagicWandAnnotationTool":
        return <FontAwesomeIcon icon={faPen} />;
      case "DrawRegionAnnotationTool":
        return <FontAwesomeIcon icon={faDrawPolygon} />;
      case "DrawEllipseAnnotationTool":
        return <FontAwesomeIcon icon={faCircle} mask={["far"]} />;
      case "FillHoleAnnotationTool":
        return <FontAwesomeIcon icon={faFillDrip} />;
      case "SelectRegionAnnotationTool":
        return <FontAwesomeIcon icon={faMousePointer} />;
      case "TilesClassificationTool":
        return <FontAwesomeIcon icon={faHandPointer} />;
      case "CopyRegionAnnotationTool":
        return <FontAwesomeIcon icon={faClone} />;
      case "AITrainingTool":
        return <FontAwesomeIcon icon={faBrain} />;
      case "GridTool":
        return <FontAwesomeIcon icon={faThLarge} />;
      case "OtherTools":
        return <FontAwesomeIcon icon={faThLarge} />;
      case "SaveImagesTool":
        return <FontAwesomeIcon icon={faDownload} />;
      case "CommentTool":
        return <RateReviewIcon />;
      case "ScreenshotTool":
        return <FontAwesomeIcon icon={faCamera} />;
      case "ZoomOriginalTool":
        return <div className={this.props.classes.textIcon}>1:X</div>;
      case "ZoomFitTool":
        return <ZoomOutMap />;
      case "ZoomInOutTool":
        return <ZoomIn />;
      case "SaveTool":
        return <Save />;
      case "PassiveLearningTool":
      case "RoiTab":
        return <FontAwesomeIcon icon={faPencilRuler} />;
      case "AICockpit":
      case "ShowALTabTool":
      case "ApplyModelTool":
        return <FontAwesomeIcon icon={faBrain} />;
      default:
        return <FontAwesomeIcon icon={faTable} />;
    }
  }

  onCreateProjectType = () => {
    let missingInput = false;
    if (this.state.name === "") {
      window.showErrorSnackbar("Please set a Module ID");
      this.setState({
        moduleIdError: true,
      });
      missingInput = true;
    }
    if (this.state.project.label === "") {
      this.setState({
        moduleLabelError: true,
      });
      window.showErrorSnackbar("Please set a Module Name");
    }
    if (this.state.previewImgUrl === "") {
      this.setState({
        previewError: true,
      });
      window.showErrorSnackbar("Please select a Preview Image");
      missingInput = true;
    }
    if (this.state.previewImgUrl.includes("data:image")) {
      this.setState({
        previewError: true,
      });
      window.showErrorSnackbar("Please select your own Preview Image");
      missingInput = true;
    }
    if (this.state.project.structures.length === 0) {
      this.setState({
        noStructuresError: true,
      });
      window.showErrorSnackbar("Please select a valid structures file");
      missingInput = true;
    }
    if (missingInput) return;
    Backend.loadReducedAvailableProjectTypes((projectTypes) => {
      if (projectTypes.find((item) => item.name === this.state.name)) {
        window.showErrorSnackbar(
          "Project Module with ID " + this.state.name + " already exists!"
        );
        this.setState({ moduleIdError: true });
        return;
      }

      Backend.createProjectModuleTypeImage(
        this.state.name,
        this.state.previewFile,
        () => {
          window.showSuccessSnackbar(
            "Project Module " +
              this.state.project.label +
              " succesfully created"
          );
        }
      );
      let projectObject = this.state.project;
      projectObject.isUserModule = true;
      Backend.createProjectModuleTypeJsonFile(
        this.state.name,
        projectObject,
        (response) => console.log("Project json file created:", response)
      );
    });
  };
  renderPreviewImage = () => {
    let imagePreview;
    if (this.state.previewImgUrl === "") {
      imagePreview = (
        <div
          onClick={() => document.getElementById("selectImage").click()}
          className={this.props.classes.previewDiv}
        >
          <PublishIcon
            className={this.props.classes.previewIcon}
            fontSize="large"
          />{" "}
          <span>Preview</span>
          <input
            style={{ display: "none" }}
            type="file"
            accept="image/png, image/jpeg"
            id="selectImage"
            onChange={this.handlePreviewSelect}
          />
        </div>
      );
    } else {
      imagePreview = (
        <img
          onClick={() => document.getElementById("selectImage").click()}
          src={this.state.previewImgUrl}
          className={this.props.classes.tileImage}
          alt=""
        />
      );
    }
    return (
      <div
        style={{ border: this.state.previewError ? "3px solid red" : "none" }}
      >
        {imagePreview}
        <input
          style={{ display: "none" }}
          type="file"
          accept="image/png, image/jpeg"
          id="selectImage"
          onChange={this.handlePreviewSelect}
        />
      </div>
    );
  };

  onSelectProject = (projectType) => {
    console.log("projectType:", projectType);
    this.setState({
      name: projectType.name,
      dialogOpen: false,
    });
    this.setProject(projectType);
  };

  exportSelectedProjectType = () => {
    Backend.loadReducedAvailableProjectTypes((projectTypes) => {
      if (projectTypes.find((item) => item.name === this.state.name)) {
        Backend.exportProjectTypes(
          [this.state.name],
          this.state.name,
          (response) => {
            window.showSuccessSnackbar("Project Module exported!");
            console.log("response:", response);
          }
        );
      } else {
        window.showErrorSnackbar("module does not exist!");
      }
    });
  };

  exportNewProjectTypes = () => {
    Backend.loadAvailableProjectTypes((projectTypes) => {
      let ownProjectTypes = projectTypes
        .filter((item) => item.isUserModule)
        .map((item) => item.name);
      if (ownProjectTypes.length > 0) {
        Backend.exportProjectTypes(
          ownProjectTypes,
          "project_modules",
          (response) => {
            window.showSuccessSnackbar("Project Modules exported!");
            console.log("response:", response);
          }
        );
      } else {
        window.showErrorSnackbar("No Project Modules created to export!");
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { isDevState } = this.state;
    return (
      <Card style={{ display: isDevState ? "block" : "none" }}>
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6">Create Project Module</Typography>
            </Grid>
            <Grid item xs={3}>
              {Object.keys(this.state.project.toolsInProject).map(
                (key, idx) => (
                  <Tooltip disableInteractive key={idx} title={key}>
                    <IconButton
                      style={{ width: 38 }}
                      className={
                        this.state.project.toolsInProject[key]
                          ? classes.activeColor
                          : ""
                      }
                      onClick={() => {
                        let toolsInProjectObject =
                          this.state.project.toolsInProject;
                        toolsInProjectObject[key] = !toolsInProjectObject[key];
                        this.setProject({
                          toolsInProject: toolsInProjectObject,
                        });
                      }}
                      size="large"
                    >
                      {this.renderIcon(key)}
                    </IconButton>
                  </Tooltip>
                )
              )}
            </Grid>

            <Grid item xs={9}>
              <div className={classes.middleStructuresColumn}>
                <div className={classes.tileContainer}>
                  {this.renderPreviewImage()}

                  <div className={classes.tileBar}>
                    {this.state.project.label}
                  </div>
                </div>
                <div>
                  <Button
                    style={{
                      border: this.state.noStructuresError
                        ? "3px solid red"
                        : "none",
                    }}
                    onClick={() =>
                      document.getElementById("selectStructures").click()
                    }
                  >
                    <PublishIcon />
                    {this.state.project.structures.length} Structures
                  </Button>{" "}
                  <input
                    style={{ display: "none" }}
                    type="file"
                    accept=".strhsa"
                    id="selectStructures"
                    onChange={this.handleStructureSelect}
                  />
                </div>

                <Button
                  size="small"
                  color="primary"
                  onClick={() => this.exportSelectedProjectType()}
                >
                  <GetAppIcon /> Export selected Project Module
                </Button>
                <br />
                <Button
                  size="small"
                  color="primary"
                  onClick={() => this.exportNewProjectTypes()}
                >
                  <GetAppIcon /> Export all new Project Modules
                </Button>
                <br />
                <Button
                  size="small"
                  color="primary"
                  onClick={() => {
                    document.getElementById("selectImportFile").click();
                  }}
                >
                  <PublishIcon /> Import Project Modules
                </Button>

                <input
                  type="file"
                  id="selectImportFile"
                  style={{ display: "none" }}
                  accept="application/zip"
                  onChange={(e) => {
                    console.log("file:", e.target.files);
                    if (e.target.files.length > 0) {
                      Backend.importProjectTypes(
                        e.target.files[0],
                        (response) => {
                          console.log("response:", response);
                        }
                      );
                    }
                  }}
                />
              </div>
              <div>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => this.setState({ dialogOpen: true })}
                >
                  Open existing Project Modules
                </Button>
                <ModuleSelectionDialog
                  open={this.state.dialogOpen}
                  onClose={() => this.setState({ dialogOpen: false })}
                  onSelectProject={this.onSelectProject}
                />
                <br />
                <TextField
                  className={classes.textInputField}
                  error={this.state.moduleIdError}
                  helperText={this.state.moduleIdError ? "Set a module ID" : ""}
                  id="module_name"
                  label="Module ID"
                  type="text"
                  value={this.state.name}
                  variant="outlined"
                  size="small"
                  margin="normal"
                  onChange={(e) =>
                    this.setState({
                      name: e.target.value,
                      moduleIdError: false,
                    })
                  }
                />
                <TextField
                  className={classes.textInputField}
                  error={this.state.moduleLabelError}
                  helperText={
                    this.state.moduleLabelError ? "Set a module name" : ""
                  }
                  id="module_label"
                  label="Module Name"
                  type="text"
                  value={this.state.project.label}
                  variant="outlined"
                  size="small"
                  margin="normal"
                  onChange={(e) =>
                    this.setProject({
                      label: e.target.value,
                      moduleLabelError: false,
                    })
                  }
                />
                <TextField
                  className={classes.textInputField}
                  id="module_description"
                  label="Module Description"
                  type="text"
                  value={this.state.project.description}
                  variant="outlined"
                  size="small"
                  margin="normal"
                  onChange={(e) =>
                    this.setProject({ description: e.target.value })
                  }
                />
              </div>
            </Grid>
          </Grid>
          <Button
            size="small"
            color="primary"
            onClick={this.onCreateProjectType}
          >
            create new Project Type
          </Button>
        </CardContent>
      </Card>
    );
  }
}

ModuleCreationContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ModuleCreationContainer);

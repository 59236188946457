import React, { Component } from "react";
import PropTypes from "prop-types";

const PersistentStorageContext = React.createContext();

export const withPersistentStorage = (Component) => {
  const WrappedComponent = (props) => (
    <PersistentStorageContext.Consumer>
      {(context) => <Component {...props} persistentStorage={context} />}
    </PersistentStorageContext.Consumer>
  );

  WrappedComponent.displayName = `withPersistentStorage(${
    Component.displayName || Component.name || "Component"
  })`;

  return WrappedComponent;
};

// this is the main component, it has to be added at the root of the app.
// all components that use withPersistentStorage(...) will have access to it via this.props.persistentStorage...
class PersistentStorageProvider extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    const loadedState = JSON.parse(localStorage.getItem(this.props.projectId));
    this.state = loadedState ? loadedState : {};
  }

  setMountedState = (stateObject, callback) => {
    if (this._isMounted) {
      this.setState(stateObject, callback);
    }
  };

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  save(key, value) {
    // let stateObject = JSON.parse(localStorage.getItem(this.props.projectId));
    let v = JSON.stringify(value);
    let stateObject = { ...this.state }; //copy whole state for saving
    stateObject[key] = v;
    localStorage.setItem(this.props.projectId, JSON.stringify(stateObject));
    this.setMountedState({ [key]: v }); //only update key value in state
  }

  load(key) {
    if (typeof this.state[key] === "string") {
      try {
        return JSON.parse(this.state[key]);
      } catch (e) {
        return this.state[key];
      }
    } else {
      return this.state[key];
    }
  }

  loadAll() {
    let stateObject = {};
    for (const [key, value] of Object.entries(this.state)) {
      try {
        stateObject[key] = JSON.parse(value);
      } catch (e) {
        stateObject[key] = value;
      }
    }
    return stateObject;
  }

  render() {
    return (
      <PersistentStorageContext.Provider
        value={{
          save: (key, value) => this.save(key, value),
          load: (key) => this.load(key),
          loadAll: () => this.loadAll(),
        }}
      >
        {this.props.children}
      </PersistentStorageContext.Provider>
    );
  }
}

PersistentStorageProvider.propTypes = {
  projectId: PropTypes.string,
  children: PropTypes.element.isRequired,
};

export default PersistentStorageProvider;

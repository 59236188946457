import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

import Backend from "../../common/utils/Backend";
import { withScanViewerContext } from "../contexts/ScanViewerContext";

import { Folder, ArrowUpward, Image } from "@mui/icons-material";

const styles = (theme) => ({
  root: {},
  selectFileButton: {
    width: "100%",
  },
  dialogTitle: {
    paddingBottom: 0,
  },
  listItem: {
    cursor: "pointer",
  },
  fileIcon: {
    color: theme.palette.primary.main,
  },
  upButton: {
    marginLeft: -12,
  },
  contentBox: {
    border: "2px solid rgb(218, 218, 218)",
    height: 500,
    overflowY: "auto",
  },
  resultFileRow: {
    display: "grid",
    overflow: "hidden",
    width: "100%",
    gridTemplateColumns: "auto 1fr auto",
    marginTop: 8,
  },
  folderPathText: {
    maxWidth: 200,
    lineHeight: "60px",
    marginRight: 5,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    direction: "rtl",
    overflow: "hidden",
  },
  extensionDropDown: {
    marginTop: 8,
    marginLeft: 5,
  },
  dialogContent: {
    paddingTop: 0,
    width: 800,
  },
});

/**
 * dialog window for saving file
 */
class SelectFileDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPath: "",
      inputIsValid: false,
      fileName: "",
      currentFiles: [],
      selectedExtension: props.fileFormats[0],
    };
    this.updateCurrentFiles("");
  }

  /**
   * when Dialog window opens: update files and
   * check if filename already exists -> update num Time points
   * @param {object} prevProps
   */
  componentDidUpdate = (prevProps) => {
    // if Dialog Window opens:
    if (!prevProps.open && this.props.open) {
      // update Current Files
      this.updateCurrentFiles(this.state.currentPath, () => {
        // update num time points
        const file = this.state.currentFiles.filter((value) => {
          return value.path === this.state.fileName + ".hsasld";
        });
        if (file.length > 0) {
          this.onListItemClick(file[0]);
        }
      });
    }
  };

  /**
   * update visible files of selected folder
   * @param {String} path filepath
   * @param {func} callback callback
   */
  updateCurrentFiles = (path, callback) => {
    const { fileFormats } = this.props;
    Backend.walkDir(path, (result) => {
      if (fileFormats) {
        result = result.filter((entry) => {
          if (entry.type === "directory") return true;
          let pathParts = entry.path.split(".");
          let extension = pathParts[pathParts.length - 1].toLowerCase();
          if (fileFormats.indexOf(extension) < 0) {
            return false;
          } else {
            return true;
          }
        });
      }
      this.setState({ currentFiles: result, currentPath: path }, callback);
    });
  };

  /**
   * if in directory, update folder, else update textfield when clicking on file
   * @param {Object} item
   */
  onListItemClick = (item) => {
    if (item.type === "directory") {
      this.updateCurrentFiles(item.path);
    } else {
      this.setState(
        {
          fileName: item.path
            .replace(/^.*[\\/]/, "")
            .split(".")
            .slice(0, -1)
            .join("."),
        },
        () => {
          this.validateFilePath();
        }
      );
    }
  };

  /**
   * set filename state and validate filepath
   * @param {ActionEvent} e User input in textfield
   */
  onChangeFileName = (e) => {
    this.setState({ fileName: e.target.value }, () => {
      this.validateFilePath();
    });
  };

  /**
   * assemble [currentpath]/[filename].[extension] if currentpath is available.
   * Else: [filename].[extension]
   */
  validateFilePath = () => {
    let fullFilePath = "";
    if (this.state.currentPath === "") {
      fullFilePath = this.state.fileName + "." + this.state.selectedExtension;
    } else {
      fullFilePath =
        this.state.currentPath +
        "/" +
        this.state.fileName +
        "." +
        this.state.selectedExtension;
    }
    this.changeFilePath(fullFilePath);
  };

  /**
   * validate file path and check if file already exists. If so, get timepoints
   * @param {String} fullFilePath Filepath inkl currentPath
   */
  changeFilePath = (fullFilePath) => {
    this.props.scanViewerContext.setState({ fullFilePath });
    const pathParts = fullFilePath.split(".");
    const extension = pathParts[pathParts.length - 1].toLowerCase();
    const fileName = fullFilePath
      .replace(/^.*[\\/]/, "")
      .split(".")
      .slice(0, -1)
      .join(".");
    const inputIsValid = fileName !== "";
    this.setState({ inputIsValid });

    if (inputIsValid) {
      this.props.scanViewerContext.sendFilePath(fullFilePath);
    }

    if (extension === "hsasld") {
      Backend.getFileMetadata(fullFilePath, (result) => {
        if (result.sizeT) {
          this.setState({ numTimePoints: result.sizeT });
        } else {
          this.setState({ numTimePoints: 0 });
        }
      });
    }
  };

  render() {
    const { classes, fileFormats, open } = this.props;
    const {
      currentFiles,
      currentPath,
      selectedExtension,
      fileName,
      numTimePoints,
      inputIsValid,
    } = this.state;
    const { filePath } = this.props.scanViewerContext;
    return (
      <React.Fragment>
        <Dialog maxWidth="lg" open={open} onClose={() => this.props.onClose()}>
          <DialogTitle className={classes.dialogTitle}>
            Select Result WSI File
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <div className={classes.resultFileRow}>
              <div className={classes.folderPathText}>
                {"/"}
                {currentPath}
              </div>
              <TextField
                error={!inputIsValid}
                className={classes.resultFileName}
                id="standard-basic"
                label="File Name"
                variant="standard"
                value={fileName}
                onChange={this.onChangeFileName}
              />
              <FormControl className={classes.extensionDropDown} size="small">
                <InputLabel>Extension</InputLabel>
                <Select
                  value={selectedExtension}
                  label="Extension"
                  onChange={(e) =>
                    this.setState({ fileFormat: e.target.value })
                  }
                >
                  {fileFormats.map((fileFormat, idx) => {
                    return (
                      <MenuItem key={idx} value={fileFormat}>
                        .{fileFormat}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <Grid container>
              <Grid item xs={6}>
                <List className={classes.contentBox} dense={true}>
                  {currentPath !== "" && (
                    <ListItem>
                      <ListItemIcon>
                        <Tooltip
                          disableInteractive
                          title={`Up to "${
                            currentPath.substring(
                              0,
                              currentPath.lastIndexOf("/")
                            ) || "root"
                          }"`}
                        >
                          <IconButton
                            className={classes.upButton}
                            onClick={() => {
                              this.updateCurrentFiles(
                                currentPath.substring(
                                  0,
                                  currentPath.lastIndexOf("/")
                                )
                              );
                            }}
                            size="large"
                          >
                            <ArrowUpward />
                          </IconButton>
                        </Tooltip>
                      </ListItemIcon>
                      <ListItemText primary={currentPath} />
                    </ListItem>
                  )}
                  {currentFiles.map((item, idx) => {
                    return (
                      <ListItem
                        key={idx}
                        button
                        className={classes.listItem}
                        onClick={() => this.onListItemClick(item)}
                      >
                        <ListItemIcon>
                          {item.type === "directory" ? (
                            <Folder style={{ color: "#FFE896" }} />
                          ) : (
                            <Tooltip
                              disableInteractive
                              title={
                                <div
                                  style={{ display: "inline-block" }}
                                  key={item.path}
                                >
                                  <div style={{ display: "inline-block" }}>
                                    {
                                      <img
                                        style={{
                                          display: "block",
                                          background: "black",
                                          margin: "5px",
                                          objectFit: "contain",
                                        }}
                                        width="200"
                                        height="200"
                                        src={
                                          "/api/project/image_thumbnail?path=" +
                                          encodeURIComponent(item.path)
                                        }
                                        alt=""
                                      />
                                    }
                                    <div
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                      }}
                                    >
                                      {item.path}
                                    </div>
                                  </div>
                                </div>
                              }
                            >
                              <Image className={classes.fileIcon} />
                            </Tooltip>
                          )}
                        </ListItemIcon>
                        <Tooltip
                          disableInteractive
                          key={idx}
                          title={
                            <div>
                              {item.type === "directory" ? (
                                <div>
                                  <div
                                    style={{
                                      width: 80,
                                      display: "inline-block",
                                    }}
                                  >
                                    Full Path:{" "}
                                  </div>
                                  {item.path}
                                </div>
                              ) : (
                                <div>
                                  <div
                                    style={{
                                      width: 80,
                                      display: "inline-block",
                                    }}
                                  >
                                    File Size:{" "}
                                  </div>
                                  {item.fileSize}
                                  <br />
                                  <div
                                    style={{
                                      width: 80,
                                      display: "inline-block",
                                    }}
                                  >
                                    Creation Date:{" "}
                                  </div>
                                  {item.creationTime}
                                </div>
                              )}
                            </div>
                          }
                        >
                          <ListItemText>
                            {item.path.replace(/^.*[\\/]/, "")}
                          </ListItemText>
                        </Tooltip>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.contentBox}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>File Name:</TableCell>
                        <TableCell>{this.state.fileName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Full Path:</TableCell>
                        <TableCell>
                          {this.state.currentPath +
                            "/" +
                            this.state.fileName +
                            "." +
                            this.state.selectedExtension}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>File Status:</TableCell>
                        <TableCell>
                          {numTimePoints > 0
                            ? "WSI File already exists"
                            : "WSI File does not exist"}
                        </TableCell>
                      </TableRow>
                      {numTimePoints > 0 && (
                        <TableRow>
                          <TableCell>Time Points:</TableCell>
                          <TableCell>{numTimePoints}</TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell>Action on Save:</TableCell>
                        <TableCell>
                          {numTimePoints > 0
                            ? "Time Point " +
                              (numTimePoints + 1) +
                              " will be added"
                            : "WSI File will be created"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  {numTimePoints > 0 && (
                    <img
                      style={{
                        display: "block",
                        background: "black",
                        margin: "5px",
                        objectFit: "contain",
                        width: "100%",
                      }}
                      src={
                        "/api/project/image_thumbnail?path=" +
                        encodeURIComponent(filePath)
                      }
                      alt=""
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.onClose()} color="primary">
              Cancel
            </Button>
            <Button
              disabled={!inputIsValid}
              onClick={() => this.props.onConfirm()}
              variant="contained"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

SelectFileDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  scanViewerContext: PropTypes.object.isRequired,
  fileFormats: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default withScanViewerContext(withStyles(styles)(SelectFileDialog));

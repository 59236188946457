import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import MUIDataTable from "mui-datatables";

// define the component's styling
const styles = () => ({
  dataTable: {
    boxShadow: "none !important",
    "& .MuiTableSortLabel-active": {
      height: "auto !important",
    },
  },
});

const handleRowClick = () => {
  // console.log(rowData, rowMeta);
};

const columns = [
  {
    name: "name",
    label: "Name",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "structure",
    label: "Structure",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "datetime",
    label: "Date created",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "validationloss",
    label: "Validation Loss",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "validationmiou",
    label: "Validation Mean IoU",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "epochs",
    label: "Epochs",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "trainingObjectsCount",
    label: "Object Count",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "modelType",
    label: "Model Type",
    options: {
      filter: false,
      sort: true,
    },
  },
];

class AIModelDialogTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
  }

  render() {
    const { data, classes } = this.props;

    const options = {
      sortOrder: {
        name: "datetime",
        direction: "desc",
      },
      filter: true,
      filterType: "dropdown",
      print: false,
      download: false,
      responsive: "standard",
      rowsSelected: this.props.rowsSelected,
      selectToolbarPlacement: "none",
      draggableColumns: {
        enabled: true,
      },
      tableBodyMaxHeight: "auto",
      onRowSelectionChange: (currentRowsSelected) => {
        let selectedObject = this.props.rowsSelected;
        if (currentRowsSelected[0] != null && currentRowsSelected.length < 2) {
          if (!selectedObject.includes(currentRowsSelected[0].dataIndex)) {
            selectedObject.push(currentRowsSelected[0].dataIndex);
          } else {
            var index = selectedObject.indexOf(
              currentRowsSelected[0].dataIndex
            );
            if (index > -1) {
              selectedObject.splice(index, 1);
            }
          }
        } else selectedObject = [];
        this.props.handleSelectedAIModels(selectedObject);
        if (selectedObject.length > 0) {
          this.props.handleAddButton(false);
        } else this.props.handleAddButton(true);
      },
      onRowClick: handleRowClick,
      textLabels: {
        body: {
          noMatch: "Sorry, no matching AIModels found",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        pagination: {
          next: "Next Page",
          previous: "Previous Page",
          rowsPerPage: "Rows per page:",
          displayRows: "of",
        },
        toolbar: {
          search: "Search",
          downloadCsv: "Download CSV",
          print: "Print",
          viewColumns: "View Columns",
          filterTable: "Filter Table",
        },
        filter: {
          all: "All",
          title: "Filters",
          reset: "Reset",
        },
        viewColumns: {
          title: "Show Columns",
          titleAria: "Show/Hide Table Columns",
        },
        selectedRows: {
          text: "row(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows",
        },
      },
    };

    return (
      <MUIDataTable
        className={classes.dataTable}
        data={data
          .filter((x) => x.verified != true)
          .map((row) => {
            return {
              name: row.name,
              structure: row.versions[0] ? row.versions[0].structure : "-",
              validationloss:
                row.versions[0] && row.versions[0].validationloss !== 1
                  ? row.versions[0].validationloss
                  : "-",
              validationmiou:
                row.versions[0] && row.versions[0].validationmeaniou !== 1
                  ? row.versions[0].validationmeaniou
                  : "-",
              trainingObjectsCount: row.versions[0]
                ? row.versions[0].trainingobjectscount
                : "-",
              epochs:
                row.versions[0] && row.versions[0].epochs !== 0
                  ? row.versions[0].epochs
                  : "-",
              datetime: row.versions[0] ? row.versions[0].datetime : "-",
              modelType: row.versions[0] ? row.versions[0].modeltype : "-",
            };
          })}
        columns={columns}
        options={options}
      />
    );
  }
}

// define the component's interface
AIModelDialogTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array,
  rowsSelected: PropTypes.array,
  handleSelectedAIModels: PropTypes.func,
  handleAddButton: PropTypes.func,
};

export default withStyles(styles)(AIModelDialogTable);

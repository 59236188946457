import React from "react";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
//import { Delete, Edit } from "@mui/icons-material";
import SystemContainer from "./components/SystemContainer";
import UsersContainer from "./components/UsersContainer";
import ImporterContainer from "./components/ImporterContainer";
import ModuleCreationContainer from "./components/ModuleCreationContainer";

const styles = {
  root: {
    flexGrow: 1,
    width: "100%",
    height: "calc(100vh - 64px)",
    overflow: "auto",
  },
  rootContent: {
    margin: 0,
    width: "100%",
  },
  paper: {
    padding: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

class AdminPage extends React.Component {
  saveClick = () => {};

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid className={classes.rootContent} container spacing={3}>
          <Grid item xs={8}>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <UsersContainer />
            </Grid>
            <Grid item xs={12}>
              <ModuleCreationContainer />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <SystemContainer />
            </Grid>
            <Grid item xs={12}>
              <ImporterContainer />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

AdminPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminPage);

import React, { Component } from "react";
import PropTypes from "prop-types";

import p1 from "./images/p1.jpg";
import p2 from "./images/p2.jpg";
import p3 from "./images/p3.jpg";
import p4 from "./images/p4.jpg";

import { Paper } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

const styles = {
  root: {
    background: "#EEEEEE",
    width: "100%",
    height: "calc(100% - 64px)",
    overflowY: "auto",
    padding: 5,
  },
  paper: {
    width: 800,
    margin: "10px auto",
    padding: 10,
    "& img": {
      width: "100%",
    },
  },
};

class PredictionPage extends Component {
  state = {};

  render() {
    const { classes } = this.props;
    const images = [p1, p2, p3, p4];
    return (
      <div className={classes.root}>
        {images.map((item, idx) => (
          <Paper key={idx} className={classes.paper}>
            <img src={item} alt="" />
          </Paper>
        ))}
      </div>
    );
  }
}

PredictionPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PredictionPage);

import React from "react";
import PropTypes from "prop-types";

import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

class SketchColorPicker extends React.Component {
  state = {
    displayColorPicker: false,
    color: "#ffffff",
    yPosition: 0,
  };

  handleClick = (e) => {
    let y = e.clientY;
    if (window.innerHeight - e.clientY < 330) {
      y -= 330;
    }
    this.setState({
      yPosition: y,
      displayColorPicker: true,
    });
    e.stopPropagation();
  };

  handleClose = (e) => {
    this.setState({ displayColorPicker: false });
    e.stopPropagation();
  };

  handleChange = (color) => {
    this.setState({ color: color.hex });
    this.props.handleChange(color.hex);
  };

  render() {
    const { color } = this.props;
    const styles = reactCSS({
      pickerContainer: {
        position: "relative",
      },
      default: {
        color: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: color,
        },
        swatch: {
          padding: "5px",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
          marginTop: 6,
        },
        popover: {
          position: "fixed",
          zIndex: "2",
          right: 0,
          marginRight: "10px",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });

    return (
      <div style={styles.pickerContainer}>
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker ? (
          <div
            style={{
              position: "fixed",
              zIndex: "2",
              right: 0,
              marginRight: "10px",
              top: this.state.yPosition + 10,
            }}
          >
            <div style={styles.cover} onClick={this.handleClose} />
            <SketchPicker
              color={this.state.color}
              onChange={this.handleChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

SketchColorPicker.propTypes = {
  color: PropTypes.string,
  handleChange: PropTypes.func,
};

export default SketchColorPicker;

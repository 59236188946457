import React, { Component } from "react";
import PropTypes from "prop-types";

import SketchColorPicker from "./SketchColorPicker";
import {
  getParentIndexLayer,
  getParentIndex,
  getContainedRegionRois,
} from "../utils/StructuresUtils";

import {
  Divider,
  TextField,
  ListItem,
  ListItemText,
  Tooltip,
  IconButton,
  Checkbox,
} from "@mui/material";

import withStyles from "@mui/styles/withStyles";

import { Tools } from "./VerticalToolBar";
import {
  VisibilityOff,
  Visibility,
  ArrowDropDown,
  ArrowDropUp,
  Remove,
  PlayArrow,
} from "@mui/icons-material";
import ListItemOptionsMenu from "./ListItemOptionsMenu";

// define the component's styling
const styles = () => ({
  listItem: {
    "&.Mui-selected": {
      background: "rgba(0, 0, 0, 0.2) !important",
    },
  },
});

class ListItemSubType extends Component {
  findChilds = (subType) => {
    const { structures } = this.props;
    // return all direct childs
    return structures.filter(
      (element) =>
        element.subtypeLevel === subType.subtypeLevel + 1 &&
        element.parentId === subType.id
    );
  };

  findAllSubtypes = (str) => {
    // find all childs of structure (not only direct childs)
    let childs = this.findChilds(str);
    let allChilds = [];
    while (childs.length !== 0) {
      childs = childs.concat(this.findChilds(childs[0]));
      if (!allChilds.includes(childs[0])) {
        allChilds.push(childs[0]);
      }
      childs.shift();
    }
    return allChilds;
  };

  hideSubtypes = (subType, lv) => {
    const { structures } = this.props;
    // hide subtypes if not unfolded anymore in sidebar
    if (subType.hasChild) {
      let childs = this.findChilds(subType);
      for (let i = 0; i < childs.length; i++) {
        this.hideSubtypes(childs[i], lv);
      }
    }
    // make visibility like parent
    if (subType.classificationSubtype && subType.subtypeLevel > lv) {
      let idx = getParentIndex(subType, structures);
      subType.visible = structures[idx].visible;
    }
    subType.showSubtypes = false;
    subType.isUnfolded = false;
  };

  showSubtypes = (subType) => {
    const { structures } = this.props;
    // unfold direct subtypes of structure
    structures.forEach(function (element) {
      if (element.parentId === subType.id) {
        element.isUnfolded = true;
      }
    });
  };

  visibilitySubtypesOff = (subType) => {
    // set visibility of rois in viewer of subtypes to invisible
    if (subType.hasChild) {
      let childs = this.findChilds(subType);
      for (let i = 0; i < childs.length; i++) {
        this.visibilitySubtypesOff(childs[i]);
      }
    }
    subType.visible = false;
  };

  visibilitySubtypesOn = (subType) => {
    // set visibility of rois in viewer of subtypes to visible
    if (subType.hasChild) {
      let childs = this.findChilds(subType);
      for (let i = 0; i < childs.length; i++) {
        this.visibilitySubtypesOn(childs[i]);
      }
    }
    subType.visible = true;
  };

  itemClicked = () => {
    const {
      structures,
      onChangeTool,
      activeTool,
      showGallery,
      updateStructureChange,
      onSelectLayer,
      subType,
    } = this.props;

    if (subType.tools.length > 0) {
      let activeToolName = activeTool;
      if (!activeToolName || activeToolName === Tools.NONE) {
        onChangeTool("none");
      } else {
        if (
          typeof subType.allToolNames !== "undefined" &&
          subType.allToolNames.includes(activeToolName)
        ) {
          let toolNames = Object.values(subType.tools).map((t) => t.name);
          if (
            !toolNames.includes(activeToolName) ||
            activeToolName === Tools.NONE
          ) {
            onChangeTool(subType.tools[0].name);
          }
        }
      }
    } else if (activeTool && activeTool.startsWith("iam_")) {
      onChangeTool("none");
    }
    if (showGallery) {
      updateStructureChange();
    }

    onSelectLayer(structures.findIndex((element) => element.id === subType.id));
    this.props.tiles.setStructure(subType);
    this.forceUpdate();
  };

  adaptRois = (str, newName) => {
    const { roiLayers, structures } = this.props;
    // change subtypename of rois
    let parentIdxLayer = getParentIndexLayer(str, structures);
    roiLayers[parentIdxLayer].layer.regionRois.forEach((element) => {
      if (element.structureId === str.id) {
        element.subtypeName = newName;
      }
    });
  };

  render() {
    const {
      roiLayers,
      selectedLayer,
      structures,
      projectContext,
      onChangeLayers,
      updateViewer,
      // item specific
      subType,
      parent,
      i,
      onSelectLayer,
      classes,
      renderLocation,
      formData,
      allStructuresEnabled,
    } = this.props;

    // only draw if it is in subtypes of parent
    if (
      renderLocation == "SideBarTabRois" &&
      !this.findAllSubtypes(parent).includes(subType)
    ) {
      return <div></div>;
    }

    // calculate if draw divider to seperate substructures from classification subtypes
    let drawDivider = false;
    let parentIndex = structures.findIndex(
      (element) => element.id === subType.parentId
    );
    let childs = this.findChilds(structures[parentIndex]);
    let subStructures = childs.filter(
      (element) => !element.classificationSubtype
    );
    if (subStructures.length !== 0) {
      // parent has substructures
      let classificationChilds = childs.filter(
        (element) => element.classificationSubtype
      );
      // if subType is first classification subtype --> draw divider
      if (
        classificationChilds[classificationChilds.length - 1] &&
        subType.label ===
          classificationChilds[classificationChilds.length - 1].label
      ) {
        drawDivider = true;
      }
    }

    const index = structures.findIndex((element) => element.id === subType.id);

    let numberOfObjects = getContainedRegionRois(
      subType,
      structures,
      roiLayers
    ).length;

    // check if structure is unfolded/visible in sidebar
    if (!subType.isUnfolded && renderLocation == "SideBarTabRois") {
      return null;
    }

    return (
      <div
        ref={(el) => {
          this.listItemRef = el;
        }}
        style={{
          height: 48,
          width: "100%",
        }}
      >
        {(renderLocation == "AI-Cockpit" ||
          this.props.isInView(this.listItemRef) > 0) && (
          <div key={index + 200 + i}>
            <ListItem
              disabled={
                numberOfObjects == 0 &&
                renderLocation == "AI-Cockpit" &&
                !allStructuresEnabled
              }
              button
              selected={
                selectedLayer ===
                  structures.findIndex(
                    (element) => element.id === subType.id
                  ) && renderLocation == "SideBarTabRois"
              }
              className={classes.listItem}
              style={{
                paddingBottom: 0,
                paddingTop: 0,
              }}
              onClick={() => {
                if (renderLocation == "SideBarTabRois") {
                  this.itemClicked();
                }
              }}
            >
              {renderLocation == "AI-Cockpit" && (
                <Checkbox
                  onChange={(e) => {
                    this.props.updateFormDataStructures(
                      structures[subType.index],
                      e.currentTarget.checked
                    );
                  }}
                  checked={
                    formData.selStructures.findIndex(
                      (item) => item.id === structures[subType.index].id
                    ) > -1
                  }
                />
              )}

              <IconButton
                style={{
                  background: "none",
                  paddingLeft: (subType.subtypeLevel - 1) * 24,
                }}
                size="large"
              >
                {subType.classificationSubtype ? (
                  <PlayArrow style={{ fontSize: "small" }} />
                ) : (
                  <Remove style={{ fontSize: "small" }} />
                )}
              </IconButton>
              {selectedLayer === index ? (
                <Tooltip
                  disableInteractive
                  title={numberOfObjects + " objects"}
                >
                  <span style={{ width: "100%", minWidth: 0 }}>
                    <TextField
                      variant="standard"
                      style={{
                        width: "100%",
                        "& input": {
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        },
                      }}
                      value={subType.label}
                      onChange={(e) => {
                        if (
                          e.target.value.includes("_") ||
                          e.target.value.includes("/")
                        ) {
                          window.showWarningSnackbar(
                            "Do no use / and _ inside structure names."
                          );
                          return; // do not allow these signs in structure label
                        }
                        // update rois and structure
                        this.adaptRois(subType, e.target.value);
                        subType.label = e.target.value;
                        projectContext.forceUpdate();
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </span>
                </Tooltip>
              ) : (
                <Tooltip
                  disableInteractive
                  title={numberOfObjects + " objects"}
                >
                  <span style={{ width: "100%", minWidth: 0 }}>
                    <ListItemText
                      style={{
                        padding: "0px",
                      }}
                      primary={
                        <div
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {subType.label}
                        </div>
                      }
                    />
                  </span>
                </Tooltip>
              )}
              {subType.hasChild && renderLocation == "SideBarTabRois" && (
                <IconButton
                  onClick={() => {
                    if (subType.showSubtypes === false) {
                      this.showSubtypes(subType);
                      subType.showSubtypes = true;
                    } else {
                      this.hideSubtypes(subType, subType.subtypeLevel);
                      subType.isUnfolded = true;
                    }
                    setTimeout(() => {
                      this.props.forceSidebarUpdate();
                    }, 10);
                  }}
                  size="large"
                >
                  {subType.showSubtypes ? <ArrowDropUp /> : <ArrowDropDown />}
                </IconButton>
              )}
              {renderLocation == "SideBarTabRois" && (
                <Tooltip disableInteractive title="Hide current structure">
                  <IconButton
                    onClick={() => {
                      subType.visible = !subType.visible;
                      if (!subType.visible) {
                        this.visibilitySubtypesOff(subType);
                      } else {
                        this.visibilitySubtypesOn(subType);
                      }
                      this.forceUpdate();
                      onChangeLayers(roiLayers);
                    }}
                    size="large"
                  >
                    {subType.visible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </Tooltip>
              )}

              <SketchColorPicker
                color={subType.color}
                handleChange={(color) => {
                  subType.color = color;
                  // adjust roi color
                  let parLayer = getParentIndexLayer(subType, structures);
                  roiLayers[parLayer].layer.regionRois.forEach(function (
                    element
                  ) {
                    if (
                      subType.classificationSubtype &&
                      element.subtypeName === subType.label
                    ) {
                      element.color = color;
                    }
                    if (
                      !subType.classificationSubtype &&
                      element.subtypeName === ""
                    ) {
                      element.color = color;
                    }
                  });
                  updateViewer();
                }}
              />
              {!this.props.viewerConfig.project.projectProperties[
                "HideSubMenus"
              ] &&
                renderLocation == "SideBarTabRois" && (
                  <ListItemOptionsMenu
                    projectContext={projectContext}
                    structure={subType}
                    onSelectLayer={onSelectLayer}
                  />
                )}
            </ListItem>
            {drawDivider && <Divider />}
          </div>
        )}
      </div>
    );
  }
}

// define the component's interface
ListItemSubType.propTypes = {
  classes: PropTypes.object.isRequired,
  structures: PropTypes.array,
  roiLayers: PropTypes.array,
  onChangeTool: PropTypes.func,
  activeTool: PropTypes.string,
  showGallery: PropTypes.bool,
  updateStructureChange: PropTypes.func,
  projectContext: PropTypes.object,
  viewerConfig: PropTypes.object,
  tiles: PropTypes.object,
  onChangeLayers: PropTypes.func,
  updateViewer: PropTypes.func,
  onSelectLayer: PropTypes.func,
  isAdmin: PropTypes.bool,
  // item specific
  subType: PropTypes.object,
  parent: PropTypes.object,
  i: PropTypes.number,
  selectedLayer: PropTypes.number,
  isInView: PropTypes.func,
  forceSidebarUpdate: PropTypes.func,
  renderLocation: PropTypes.string,
  updateFormDataStructures: PropTypes.func,
  formData: PropTypes.object,
  allStructuresEnabled: PropTypes.bool,
};

export default withStyles(styles)(ListItemSubType);

import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";

import {
  DialogContent,
  DialogContentText,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

const styles = () => ({
  dialogContent: {
    paddingTop: 0,
    maxWidth: 900,
    minHeight: 420,
  },
  images: {
    width: 100,
    height: 100,
    objectFit: "contain",
  },
});

class TrainModelStep1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verticalFlip: false,
      horizontalFlip: false,
    };
  }

  render() {
    const { classes, formData, viewerConfig } = this.props;
    let defaultImages = viewerConfig.project.projectProperties[
      "MedicalAICockpitImages"
    ]
      ? false
      : true;

    return (
      <DialogContent className={classes.dialogContent}>
        <DialogContentText
          component={"span"}
          style={{ paddingTop: "0px", marginBottom: "20px" }}
        >
          <div>
            Which transformations of your data are realistic and do not change
            the meaning of the image?
          </div>
          <div>Select possible transformations:</div>
        </DialogContentText>
        <Grid container>
          <Grid item xs={5} style={{ marginLeft: "20px" }}>
            <img
              style={{
                transform: "scaleX(-1)",
                marginBottom: "20px",
                marginTop: "10px",
                marginRight: "20px",
              }}
              src={
                defaultImages
                  ? "img/original_orientation_default.jpg"
                  : "img/original_orientation_med.jpg"
              }
              alt="horizontal img"
              className={classes.images}
            />
            <FormControlLabel
              label="horizontal flip"
              control={
                <Checkbox
                  checked={formData["augmentations"].includes("h_flip")}
                  onChange={(e) => {
                    this.props.updateFormDataAugmentations(e, "h_flip");
                  }}
                />
              }
            />
            <br />
            <img
              style={{
                marginLeft: "scaleY(-1)",
                marginBottom: "20px",
                marginRight: "20px",
              }}
              src={
                defaultImages
                  ? "img/original_orientation_default.jpg"
                  : "img/original_orientation_med.jpg"
              }
              alt="vertical img"
              className={classes.images}
            />
            <FormControlLabel
              label="vertical flip"
              control={
                <Checkbox
                  checked={formData["augmentations"].includes("v_flip")}
                  onChange={(e) =>
                    this.props.updateFormDataAugmentations(e, "v_flip")
                  }
                />
              }
            />
            <br />
            <img
              style={{ transform: "rotate(30deg)", marginRight: "20px" }}
              src={
                defaultImages
                  ? "img/original_orientation_default.jpg"
                  : "img/original_orientation_med.jpg"
              }
              alt="rotation img"
              className={classes.images}
            />
            <FormControlLabel
              label="rotation"
              control={
                <Checkbox
                  checked={formData["augmentations"].includes("rotation")}
                  onChange={(e) =>
                    this.props.updateFormDataAugmentations(e, "rotation")
                  }
                />
              }
            />
          </Grid>
          <Grid item xs={4}>
            <div>
              <div style={{ paddingTop: "80px" }}>original orientation</div>
              <img
                src={
                  defaultImages
                    ? "img/original_orientation_default.jpg"
                    : "img/original_orientation_med.jpg"
                }
                alt="original img"
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    );
  }
}

TrainModelStep1.propTypes = {
  classes: PropTypes.object.isRequired,
  project: PropTypes.object,
  files: PropTypes.array,
  onChangeFiles: PropTypes.func,
  preloadedImages: PropTypes.object,
  formData: PropTypes.object,
  updateFormDataAugmentations: PropTypes.func,
  viewerConfig: PropTypes.object,
};

export default withStyles(styles)(TrainModelStep1);

import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";

const styles = {
  root: {
    background: "none",
    width: "100%",
  },
  treeRoot: {
    flexGrow: 1,
    maxWidth: 400,
  },
  expansionRow: {
    width: "100%",
  },
  expansionDetails: {
    flex: "none",
    padding: 0,
    paddingBottom: 10,
  },
  tagsTreeItem: {
    zIndex: 3,
    position: "relative",
    "& .coloredSquare": {
      width: 20,
      height: 20,
      border: "1px solid black",
      position: "absolute",
      left: 0,
      top: 2,
      zIndex: 1,
    },
    "& .tagsLabel": {
      marginLeft: 20,
    },
  },
};
const tags = [
  {
    id: "1",
    name: "tag 1",
    color: "#ffffff",
    subTags: [],
  },
  {
    id: "2",
    name: "tag 2",
    color: "#ffff00",
    subTags: [
      {
        id: "4",
        name: "sub tag 1",
        color: "#ff0000",
      },
      {
        id: "5",
        name: "sub tag 2",
        color: "#00ff00",
      },
    ],
  },
  {
    id: "3",
    name: "tag 3",
    color: "#0000ff",
    subTags: [],
  },
];

class CasesSideBar extends Component {
  state = {};

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Accordion className={classes.expansionRow}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="priority-content"
            id="priority-header"
          >
            <Typography>Important</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>Important Cases</div>
          </AccordionDetails>
        </Accordion>

        <Accordion className={classes.expansionRow}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="cases-content"
            id="cases-header"
          >
            <Typography>Cases</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>All Cases</div>
          </AccordionDetails>
        </Accordion>

        <Accordion className={classes.expansionRow}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="tags-content"
            id="tags-header"
          >
            <Typography>Tags</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.expansionDetails}>
            <TreeView
              className={classes.treeRoot}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              {tags.map((tag) => {
                return (
                  <TreeItem
                    className={classes.tagsTreeItem}
                    key={tag.id}
                    nodeId={tag.id}
                    label={
                      <span>
                        <div
                          className="coloredSquare"
                          style={{
                            background: tag.color,
                          }}
                        />
                        <div className="tagsLabel">{tag.name}</div>
                      </span>
                    }
                  >
                    {tag.subTags.map((subtag) => {
                      return (
                        <TreeItem
                          className={classes.tagsTreeItem}
                          key={subtag.id}
                          nodeId={subtag.id}
                          label={
                            <span>
                              <div
                                className="coloredSquare"
                                style={{
                                  background: subtag.color,
                                }}
                              />
                              <div className="tagsLabel">{subtag.name}</div>
                            </span>
                          }
                        />
                      );
                    })}
                  </TreeItem>
                );
              })}
            </TreeView>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

CasesSideBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CasesSideBar);

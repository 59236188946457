import React, { Component } from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

import withStyles from "@mui/styles/withStyles";
import withTheme from "@mui/styles/withTheme";

import DragIndicator from "@mui/icons-material/DragIndicator";
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";

import {
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
} from "@mui/material";

import { Remove, PlayArrow } from "@mui/icons-material";

import { getParentIndexLayer } from "../utils/StructuresUtils";

const styles = {
  root: {
    position: "absolute",
    top: 76,
    left: 5,
    height: 400,
    width: 500,
    border: "2px solid rgb(85, 85, 85)",
    opacity: 0.8,
    backgroundColor: "#000000",
    color: "#ffffff",
  },
  dragIndicator: {
    color: "#fff",
    position: "absolute",
    top: 5,
    right: 0,
    cursor: "grab",
  },
  grabbing: {
    cursor: "grabbing",
  },
  resizableContainer: {
    "& .react-resizable-handle": {
      pointerEvents: "all",
    },
    "& .react-resizable-handle-se::before": {
      content: "''",
      display: "block",
      position: "absolute",
      bottom: 3,
      right: 3,
      width: 6,
      height: 6,
      borderBottom: "1px solid #fff",
      borderRight: "1px solid #fff",
    },
  },
};

class ResultTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      grabbing: false,
      tableSize: {
        width: 500,
        height: 499,
      },
      structuredTableValues: props.structures.map((structure) => {
        return {
          label: structure.label,
          id: structure.id,
          percentage: 0,
          numberObjects: 0,
        };
      }),
    };

    window.updateResultTable = this.updateResultTable;
  }

  componentDidMount = () => {
    this.updateResultTable();
  };

  updateResultTable = () => {
    const { structures } = this.props;
    let stateObject = [];
    structures.forEach((structure) => {
      let percentageNumber = this.getPercentageAndNumber(structure);
      let obj = {
        label: structure.label,
        id: structure.id,
        percentage: percentageNumber.percentage,
        numberObjects: percentageNumber.numberObjects,
      };
      stateObject.push(obj);
    });
    this.setState({ structuredTableValues: stateObject });
  };

  findChilds = (str) => {
    // return direct classification subtypes
    return this.props.structures.filter(
      (element) =>
        element.subtypeLevel === str.subtypeLevel + 1 &&
        element.parentId === str.id &&
        element.classificationSubtype
    );
  };

  findAllSubtypes = (structure) => {
    // get all subtypes of selectedLayer (also subsubtypes)

    // first get all direct childs
    let childs = this.findChilds(structure);
    let allChilds = [];

    // search for childs of childs until no childs are checked for childs
    while (childs.length !== 0) {
      // code block to be executed
      childs = childs.concat(this.findChilds(childs[0]));
      if (!allChilds.includes(childs[0]) && childs[0].classificationSubtype) {
        allChilds.push(childs[0]);
      }
      childs.shift();
    }
    return allChilds;
  };

  getPercentageAndNumber = (structure) => {
    const { allRoiLayers, fileId, structures } = this.props;

    let percNumb = { percentage: 0, numberObjects: 0 };

    // get number of all objects of structure
    let parentLayer = getParentIndexLayer(structure, structures);
    if (structure.classificationSubtype) {
      // for subtypes
      // get number of objects:

      // get all childNames to find rois
      let childIds = [];
      let childLabels = [];
      this.findAllSubtypes(structure).forEach((element) => {
        childIds.push(element.id);
        childLabels.push(element.label);
      });
      childIds.push(structure.id); // push current structure
      childLabels.push(structure.label); // push current structure

      percNumb.numberObjects = allRoiLayers[fileId][
        parentLayer
      ].layer.regionRois.filter(
        (element) => childIds.includes(element.structureId) /* ||
          childLabels.includes(element.subtypeName)*/
      ).length;

      // get percentage:
      // get number of parent objects
      let parentIdx = structures.findIndex(
        (element) => element.id === structure.parentId
      );

      let numberParentObjects = this.getPercentageAndNumber(
        structures[parentIdx]
      ).numberObjects;

      if (percNumb.numberObjects > 0 && numberParentObjects > 0) {
        percNumb.percentage = percNumb.numberObjects / numberParentObjects;
      } else {
        percNumb.percentage = 0;
      }
    } else {
      // for parent structures
      percNumb.numberObjects =
        allRoiLayers[fileId][parentLayer].layer.regionRois.length;
      percNumb.percentage = "-";
    }

    return percNumb;
  };

  renderIcon = (structure) => {
    if (structure.isSubtype) {
      return (
        <IconButton
          style={{ background: "none", color: "#FFFFFF", padding: "2px" }}
          size="large"
        >
          {structure.classificationSubtype ? (
            <PlayArrow style={{ fontSize: "small" }} />
          ) : (
            <Remove style={{ fontSize: "small" }} />
          )}
        </IconButton>
      );
    }
  };

  renderRow = (structure, structureIdx) => {
    const { structures, selectedLayer } = this.props;
    let childs = this.findChilds(structures[selectedLayer]);
    let childIds = childs.map((obj) => {
      return obj.id;
    });
    let isSelected =
      childIds.includes(structure.id) ||
      structure.id === structures[selectedLayer].id;

    return (
      <ListItem
        key={structureIdx}
        style={{
          paddingLeft: structures[structureIdx].subtypeLevel * 10 + 10,
          color: "#FFFFFF",
          border: isSelected ? "2px dashed white" : "none",
        }}
      >
        {this.renderIcon(structures[structureIdx])}
        <ListItemText primary={structure.label + ":"} />
        <Typography
          style={{
            marginRight: "100px",
          }}
        >
          {structure.percentage === "-"
            ? ""
            : (structure.percentage * 100).toFixed(2) + "%"}
        </Typography>
        <Typography
          style={{
            marginRight: "40px",
          }}
        >
          {structure.numberObjects}
        </Typography>
      </ListItem>
    );
  };

  onResize = (event, { size }) => {
    // resize element
    let minSizeWidth = 240;
    let minSizeHeight = 150;
    let maxSizeWidth = 600;
    let maxSizeHeight = 1200;
    size.width = size.width < minSizeWidth ? minSizeWidth : size.width;
    size.width = size.width > maxSizeWidth ? maxSizeWidth : size.width;
    size.height = size.height < minSizeHeight ? minSizeHeight : size.height;
    size.height = size.height > maxSizeHeight ? maxSizeHeight : size.height;

    this.setState({ tableSize: size });
  };

  render() {
    const { classes } = this.props;
    const { grabbing, structuredTableValues } = this.state;

    return (
      <Draggable
        handle=".handle"
        onStart={() => this.setState({ grabbing: true })}
        onStop={() => this.setState({ grabbing: false })}
      >
        <Resizable
          className={classes.resizableContainer}
          height={this.state.tableSize.height}
          width={this.state.tableSize.width}
          onResize={this.onResize}
          resizeHandles={["se"]}
        >
          <div
            ref={(c) => (this.root = c)}
            className={classNames(classes.root, grabbing && classes.grabbing)}
            style={{
              height: this.state.tableSize.height,
              width: this.state.tableSize.width,
            }}
          >
            <div
              style={{
                overflow: "auto",
                maxHeight: this.state.tableSize.height - 4,
                marginRight: "20px",
                direction: "rtl",
              }}
            >
              <List style={{ direction: "ltr" }}>
                <ListItem key="structureItem">
                  <ListItemText primary={"Structure"} />
                  <Typography style={{ marginRight: "60px" }}>
                    Percentage
                  </Typography>
                  <Typography style={{ marginRight: "10px" }}>
                    Objects
                  </Typography>
                </ListItem>
                {structuredTableValues.map((structure, index) =>
                  this.renderRow(structure, index)
                )}
              </List>
            </div>
            <DragIndicator
              className={classNames(
                "handle",
                classes.dragIndicator,
                grabbing && classes.grabbing
              )}
            />
          </div>
        </Resizable>
      </Draggable>
    );
  }
}

// define the component's interface
ResultTable.propTypes = {
  classes: PropTypes.object.isRequired,
  structures: PropTypes.array,
  allRoiLayers: PropTypes.object,
  fileId: PropTypes.string,
  selectedLayer: PropTypes.number,
};

export default withTheme(withStyles(styles)(ResultTable));

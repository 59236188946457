import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Grid,
  Button,
  FormControl,
  TextField,
  FormHelperText,
  Select,
  InputLabel,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Slider,
  Tooltip,
  LinearProgress,
  Typography,
  IconButton,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Backend from "../../../common/utils/Backend";
import { withSpinloader } from "../../../common/components/Spinloader";
import { RegionROI, createRoisFromAnno } from "../../utils/ROI";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  generateParentList,
  getParentIndexLayer,
} from "../../utils/StructuresUtils";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";

import Tool from "./Tool";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

/**
 * Counts number of decimals of a number
 * @param {Number} x
 */
function countDecimals(x) {
  if (Math.floor(x.valueOf()) === x.valueOf()) return 0;
  return x.toString().split(".")[1].length || 0;
}

const styles = {
  input: {
    width: 55,
  },
};

class InstantAnalysisTool extends Tool {
  constructor(toolConfig, viewer) {
    super();
    this.toolConfig = toolConfig;
    this.name = toolConfig.tooltip;
    this.state = {
      preview: toolConfig.preview,
      prevW: 512,
      prevH: 512,
      showDownloadProgress: false,
      modelDownloadProgress: false,
    };
    this.viewer = viewer;
  }

  /**
   * Update File Specific information
   * @param {Object} layer
   * @param {Object} ome
   * @param {String} fileId
   * @param {String} projectId
   * @param {Object[]} roiLayers
   */
  setLayer(obj) {
    this.id = obj.fileId;
    this.layer = obj.layer;
    this.ome = obj.ome;
    this.pixelLengthX = obj.ome.physicalSizeX * Math.pow(10, 6); //pixel x length in µm
    this.pixelLengthY = obj.ome.physicalSizeY * Math.pow(10, 6);
    this.pixelArea = this.pixelLengthX * this.pixelLengthY; //pixel area in µm
    this.isBrightfield = obj.ome.channels[0].type === "brightfield";
    this.projectId = obj.projectId;
    this.roiLayers = obj.roiLayers;
    this.structures = obj.structures;
    this.selectedLayer = obj.selectedLayer;
    this.updateProject = obj.updateProject;
    this.updateFileClasses = obj.updateFileClasses;
    this.ctx = obj.ctx;
    this.histogramConfig = obj.histogramConfig;

    if (obj.structures[obj.selectedLayer]) {
      this.toolLayerConfig = obj.structures[obj.selectedLayer].tools.find(
        (c) => c.name === this.toolConfig.name
      );
    }

    if (typeof this.toolLayerConfig !== "undefined") {
      Object.assign(this.state, this.toolLayerConfig.parameters);
    }
  }

  /**
   * Update Preview Area (e.g. on move)
   * @param {Number} x
   * @param {Number} y
   * @param {Number} w
   * @param {Number} h
   */
  setPreviewRect(x, y) {
    this.previewRect = {
      x: x - this.state.prevW / 2,
      y: y - this.state.prevH / 2,
      w: this.state.prevW,
      h: this.state.prevH,
    };
  }

  setPreviewSize(w, h) {
    let newState = {
      prevW: w,
      prevH: h,
    };
    Object.assign(this.state, newState);
  }

  updateTrainingProgress = (line) => {
    if (line.includes("Downloading model from:")) {
      this.state.modelDownloadProgress = 0;
      this.state.showDownloadProgress = true;
    } else if (line.includes("[DownloadProgress]")) {
      // Extract progress number
      this.state.modelDownloadProgress = line
        .split("[DownloadProgress]")[1]
        .split("%")[0];
    } else if (line.includes("Download complete")) {
      this.state.showDownloadProgress = false;
    }
  };

  mouse() {}

  /**
   * Calculates the parent structure, grandparent if selection is subtype
   * @param {number} selectedLayer
   * @param {object} structures
   */
  findOuterParentIdx(selectedLayer, structures) {
    let selectedStructure = structures[selectedLayer];
    while (
      selectedStructure.classificationSubtype &&
      selectedStructure.parentId !== 0
    ) {
      selectedStructure = this.findParentStructure(
        structures,
        selectedStructure
      );
    }
    return structures.findIndex((s) => s.id === selectedStructure.parentId);
  }

  findParentStructure(structures, selectedStructure) {
    return structures.find((s) => s.id === selectedStructure.parentId);
  }

  /**
   * Calculates Instant analysis on a smaller preview area
   * @param {Component} component
   */
  calcPreview(component) {
    // add viewer values to parameter to use in tool iams
    component.props.state.z = this.viewer.state.z; //self.config.Parameters["z"]
    component.props.state.t = this.viewer.state.t; //self.config.Parameters["t"]
    component.props.state.selectedLayer = component.props.selectedLayer; //self.config.Parameters["selectedLayer"]

    let fileClassification =
      this.viewer.state.viewerConfig.project.projectStringProperties[
        "ViewerType"
      ] == "FilesGallery";

    // Check for presence of BaseROI
    if (
      component.props.selectedLayer > 0 &&
      this.roiLayers[0].layer.regionRois.length === 0 &&
      !fileClassification
    ) {
      window.showWarningSnackbar("No Base ROI set!");
      return;
    }

    // warning if channel is not selected and also aborting analysis
    if (
      component.props.state.toolParams &&
      component.props.state.channel &&
      component.props.state.channel < 0
    ) {
      window.showWarningSnackbar("No channel selected!");
      return;
    }

    // Check for missing preview rectangle
    if (typeof this.previewRect === "undefined" && !fileClassification) return;

    // get outerParentIndex (for classification models use other function)
    let formDataAICockpit = this.viewer.state.formDataAICockpit;
    let selLayerStructureId =
      component.props.structures[component.props.selectedLayer].id;

    let modelName = null;
    let modelType = null;
    if (formDataAICockpit !== null) {
      modelName = formDataAICockpit[selLayerStructureId]
        ? formDataAICockpit[selLayerStructureId].selectedModel
        : null;

      modelType =
        modelName && formDataAICockpit[selLayerStructureId].models[modelName]
          ? formDataAICockpit[selLayerStructureId].models[modelName].modelType
          : null;
    }

    const outerParentidx =
      modelType === "classification"
        ? getParentIndexLayer(
            component.props.structures[component.props.selectedLayer],
            component.props.structures
          )
        : this.findOuterParentIdx(
            component.props.selectedLayer,
            component.props.structures
          );

    let stateObject = this.state;
    stateObject["parentIdx"] = outerParentidx;

    let start = new Date().getTime();

    component.props.spinloader.showWithTimeout(1, 10);

    let layersToSave = [0];
    if (outerParentidx > 0) {
      layersToSave.push(outerParentidx);
    }
    if (this.state.depending_structure > 0) {
      if (!layersToSave.includes(this.state.depending_structure))
        layersToSave.push(this.state.depending_structure);
    }
    if (this.state.depending_channel) {
      layersToSave.push(this.state.depending_channel);
    } else {
      for (let parameter of this.toolConfig.parameters) {
        if (parameter.name === "required_structures") {
          let required_structures = parameter.ui.default;
          if (Array.isArray(required_structures)) {
            for (let required_struture of required_structures) {
              if (!layersToSave.includes(required_struture)) {
                layersToSave.push(required_struture);
              }
            }
          } else {
            layersToSave.push(parameter.ui.default);
          }
          break;
        }
      }
    }

    let previewRect = {};
    if (!fileClassification) {
      previewRect = {
        x: Math.floor(this.previewRect.x),
        y: Math.floor(this.previewRect.y),
        w: Math.floor(this.previewRect.w),
        h: Math.floor(this.previewRect.h),
      };
    }

    // Check if the preview rectangle is inside a valid parent region.
    if (outerParentidx > 0 && !fileClassification) {
      let treeItems = this.roiLayers[outerParentidx].tree.search({
        minX: previewRect.x,
        minY: previewRect.y,
        maxX: previewRect.x + previewRect.w,
        maxY: previewRect.y + previewRect.h,
      });
      if (treeItems.length === 0) {
        window.showWarningSnackbar(
          "No parent structures overlapping preview rectangle!"
        );
        component.props.spinloader.hide();
        return;
      }
    }

    // In case of multilayered image, set z-Layer
    if (!fileClassification && this.viewer.rendererdict[this.id].state.z) {
      stateObject.z = this.viewer.rendererdict[this.id].state.z;
    }

    // In case of video, set time
    if (!fileClassification && this.viewer.rendererdict[this.id].state.t) {
      stateObject.t = this.viewer.rendererdict[this.id].state.t;
    }

    // Data sent to Backend request
    const data = {
      name: this.toolConfig.name,
      projectId: this.projectId,
      fileIds: [this.id],
      parameters: stateObject,
      preview: true,
      rect: previewRect,
      project: this.viewer.createProjectModel(layersToSave, previewRect),
    };

    // ALMs
    if (this.toolConfig.name.includes("alm_")) {
      Backend.activeLearningSignalR(
        data,
        () => {
          component.props.spinloader.hide();
        },
        (error) => {
          component.props.spinloader.hide();
          window.openErrorDialog(error);
        }
      );
    }

    // IAMS
    else {
      const selectedLayer = this.selectedLayer;

      // Get the layer where the annotations are saved
      const parentlist = generateParentList(this.structures);

      // Send Backend request
      Backend.instantAnalysisSignalR(
        data,
        component.props.spinloader,
        (project) => {
          // this.updateProject(project);
          // update last used tool for the structure
          this.structures[this.selectedLayer].selectedToolName =
            this.toolConfig.name;

          // Iterate through all annotations of the currently open file/scene
          for (let anno of project.files.find((c) => c.id === this.id)
            .annotations) {
            // Get index of currently selected structure/subtype
            // NOTE: .id in annotations is named wrong. It's supposed to be names .index >:(
            const layerIndex = anno.id === -1 ? selectedLayer : anno.id;

            // Skip Base ROI
            if (layerIndex === 0) {
              continue;
            }

            // Get id of structure, where the current annotations are saved
            const annotationSaveStructureId =
              parentlist[layerIndex][parentlist[layerIndex].length - 1].id;

            // Get index of said structure in all structures
            const annotationSaveStructureIndex = this.roiLayers.findIndex(
              (roiLayer) => roiLayer.id === annotationSaveStructureId
            );

            if (anno.geoJSON === null) anno.geoJSON = { coordinates: [] };
            let newRois = createRoisFromAnno(anno);

            // Find and remove all items currently present inside the preview area.
            if (
              "x" in previewRect &&
              // Check if there are any old annotations present.
              this.roiLayers[annotationSaveStructureIndex].tree.collides({
                minX: previewRect.x,
                minY: previewRect.y,
                maxX: previewRect.x + previewRect.w,
                maxY: previewRect.y + previewRect.h,
              })
            ) {
              // Then find all items in the area.
              let itemsToRemove = this.roiLayers[
                annotationSaveStructureIndex
              ].tree.search({
                minX: previewRect.x,
                minY: previewRect.y,
                maxX: previewRect.x + previewRect.w,
                maxY: previewRect.y + previewRect.h,
              });
              // Then remove them.
              for (let itemToRemove of itemsToRemove) {
                this.roiLayers[annotationSaveStructureIndex].tree.remove(
                  itemToRemove
                );
              }
            }

            // Add new ROIs to selected structure
            for (let roi of newRois) {
              this.roiLayers[layerIndex].tree.insert(roi.treeItem);
            }

            // Diplay the newly generated ROIs in the layers
            this.roiLayers[layerIndex].layer.regionRois = this.roiLayers[
              layerIndex
            ].tree
              .all()
              .map((item) => item.roi);

            // Update the saved layer
            this.roiLayers[annotationSaveStructureIndex].layer.regionRois =
              this.roiLayers[annotationSaveStructureIndex].tree
                .all()
                .map((item) => item.roi);
          }

          // if classification model to classify full files --> assign classIds
          if (fileClassification) {
            this.updateFileClasses(project);
          }

          console.debug(
            `IAM Frontend Execution time: ${new Date().getTime() - start} ms`
          );
          component.props.spinloader.hide();
        },
        (error) => {
          component.props.spinloader.hide();
          window.openErrorDialog(error);
        }
      );
    }
  }

  /**
   * Calculates Instant analysis on the full image
   * @param {Component} component
   * @param {Boolean} applyToAll apply on all images of project?
   */
  calcFull(component, applyToAll = false) {
    component.props.state.z = this.viewer.state.z; //self.config.Parameters["z"]
    component.props.state.t = this.viewer.state.t; //self.config.Parameters["t"]
    const outerParentidx = this.findOuterParentIdx(
      component.props.selectedLayer,
      component.props.structures
    );
    let stateObject = this.state;
    stateObject["parentIdx"] = outerParentidx;
    let layersToSave = [0, outerParentidx];
    if (this.state.depending_channel) {
      layersToSave.push(this.state.depending_channel);
    }
    if (this.state.depending_structure) {
      layersToSave.push(this.state.depending_structure);
    }
    var start = new Date().getTime();
    component.props.spinloader.show();
    const project = this.viewer.createProjectModel(layersToSave);
    let fileIds = applyToAll
      ? project.files
          .filter((file) => !file.excludeScene)
          .map((file) => file.id)
      : [this.id];

    let scenesLeft = fileIds.length;
    component.props.spinloader.showWithTimeout(scenesLeft);

    let data = {
      name: this.toolConfig.name,
      projectId: this.projectId,
      fileIds: fileIds,
      parameters: this.state,
      preview: false,
      rect: null,
      project: project,
    };

    if (this.toolConfig.name.includes("alm_")) {
      // save project before classify images
      const projectModel = this.viewer.createProjectModel("all");
      Backend.saveProject(projectModel, () => {});
      Backend.activeLearningSignalR(
        data,
        (alModel) => {
          component.props.spinloader.hide();
          this.viewer.getSelectedObjects(alModel);
        },
        (error) => {
          component.props.spinloader.hide();
          window.openErrorDialog(error);
        }
      );
    } else {
      let selectedLayer = this.selectedLayer;
      let resultProject = null;
      let callbackFunction = (project, fileId) => {
        scenesLeft -= 1;
        if (resultProject === null) {
          resultProject = project;
        } else {
          resultProject.files = resultProject.files.map((file) => {
            if (file.id === fileId) {
              return project.files.find((pFile) => pFile.id === fileId);
            } else return file;
          });
        }
        this.updateProject(project, fileId);

        if (fileId === this.id) {
          // load annotation layers
          for (let anno of project.files.find((c) => c.id === this.id)
            .annotations) {
            let layerIndex = anno.id === -1 ? selectedLayer : anno.id;
            if (anno.geoJSON === null) continue;
            anno.geoJSON.coordinates = anno.geoJSON.coordinates.filter(
              (coord) => coord.length > 0
            );
            this.roiLayers[layerIndex].layer.regionRois =
              anno.geoJSON.coordinates.map((c, idx) => {
                return new RegionROI({
                  regions: typeof c[0][0][0] === "number" ? c : c[0],
                  tileName:
                    component.props.toolConfig.name === "iam_tiler"
                      ? this.calcTileName(component, idx)
                      : null,
                  structureId: this.structures[selectedLayer].id,
                });
              });
            this.roiLayers[layerIndex].tree.clear();
            for (let roi of this.roiLayers[layerIndex].layer.regionRois) {
              this.roiLayers[layerIndex].tree.insert(roi.treeItem);
            }
          }
        }

        console.debug(
          `IAM Frontend Execution time: ${new Date().getTime() - start} ms`
        );
        component.props.spinloader.resetTimer(scenesLeft, 5);
        if (scenesLeft > 0) {
          this.runAnalysis(
            data,
            callbackFunction,
            fileIds,
            fileIds.length - scenesLeft,
            component
          );
        } else {
          component.props.spinloader.hide();
        }
      };
      this.runAnalysis(data, callbackFunction, fileIds, 0, component);
    }
  }

  runAnalysis = (data, callbackFunction, fileIds, idx, component) => {
    if (fileIds.length > 1) {
      component.props.spinloader.showWithProgress({
        message: `Running Analysis ${idx + 1} of ${fileIds.length}`,
        progress: (((idx + 1) / fileIds.length) * 100).toFixed(0),
      });
    } else {
      component.props.spinloader.show();
    }
    const fileId = fileIds[idx];
    data.fileIds = [fileId];
    Backend.instantAnalysisSignalR(
      data,
      component.props.spinloader,
      (project) => callbackFunction(project, fileId),
      (error) => {
        if (fileId === this.id) {
          component.props.spinloader.hide();
          window.openErrorDialog(error);
        } else {
          window.showWarningSnackbar(
            "Apply Process failed for file: " +
              data.project.files.find((file) => file.id === fileId).fileName
          );
        }
      }
    );
  };

  calcTileName = (component, idx) => {
    let letters = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    let numberRows = Math.ceil(
      component.props.ome.sizeY / component.props.state.size
    );
    let numberCellsAlphabet = 26 * numberRows;

    let firstLetter = "";
    if (Math.floor(idx / numberCellsAlphabet) > 0) {
      firstLetter = letters[Math.floor(idx / numberCellsAlphabet) - 1];
    }

    let secondLetter = "";
    idx = idx - Math.floor(idx / numberCellsAlphabet) * numberCellsAlphabet;
    let lettersIdx = Math.floor(idx / numberRows);
    secondLetter = letters[lettersIdx];
    let numberTile = idx - lettersIdx * numberRows;

    return firstLetter + secondLetter + String(numberTile + 1);
  };

  /**
   * Draw a custom cursor
   * ctx: canvas context, e.g. circle, rectangle, ...
   */
  drawCustomCursor(ctx, mousePosition, scale, canvas, center) {
    const sizeSliderParams = [
      "area_min",
      "min_area",
      "area_max",
      "max_area",
      "cell_radius",
      "min_area_cells",
      "nuclei_cell_radius_cell_488",
      "min_area_647",
      "area_range_555",
      "lumen_size",
    ];
    if (this.state.formControlInUse) {
      if (
        sizeSliderParams.includes(this.state.formControlInUse) ||
        this.state.formControlInUse.includes("area_min")
      ) {
        let area = this.state[this.state.formControlInUse] / this.pixelArea;
        let radius = Math.abs(Math.sqrt(area / Math.PI));
        ctx.beginPath();
        ctx.globalAlpha = 1.0;
        ctx.strokeStyle = this.isBrightfield ? "#000000" : "#ffffff";
        ctx.arc(center.x, center.y, radius, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.closePath();
      } else if (this.state.formControlInUse.includes("area_range")) {
        for (let range_value of this.state[this.state.formControlInUse]) {
          let area = range_value / this.pixelArea;
          let radius = Math.abs(Math.sqrt(area / Math.PI));
          ctx.beginPath();
          ctx.globalAlpha = 1.0;
          ctx.strokeStyle = this.isBrightfield ? "#000000" : "#ffffff";
          ctx.arc(center.x, center.y, radius, 0, 2 * Math.PI);
          ctx.stroke();
          ctx.closePath();
        }
      }
    }
  }

  onParameterChange = (e) => {
    if (typeof e === "undefined") return;
    Object.assign(this.state, e);
    if (this.toolLayerConfig) {
      this.toolLayerConfig.parameters = Object.assign(
        this.toolLayerConfig.parameters,
        e
      );
    }
  };

  resetParameters = () => {
    let stateObject = this.configFormRef.state;
    for (let parameter of this.configFormRef.props.toolConfig.parameters) {
      stateObject[parameter.name] = parameter.ui.default;
    }
    this.configFormRef.setState(stateObject);
    setTimeout(() => {
      this.configFormRef.props.onParameterChange(stateObject);
      this.configFormRef.props.onApply(this.configFormRef);
    }, 10);
  };

  exit() {}

  /**
   * Renders the Tool Configuration Inputs
   */
  renderConfiguration() {
    return (
      <div>
        <div>
          <Typography variant="h6">
            {this.name}
            <Tooltip title="reset values to default">
              <IconButton
                style={{ float: "right", top: "-4px" }}
                onClick={() => this.resetParameters()}
              >
                <SettingsBackupRestoreIcon />
              </IconButton>
            </Tooltip>
          </Typography>
        </div>

        <ConfigForm
          ome={this.ome}
          toolConfig={this.toolConfig}
          state={this.state}
          onParameterChange={this.onParameterChange}
          structures={this.structures}
          selectedLayer={this.selectedLayer}
          histogramConfig={this.histogramConfig}
          onApply={(component) => {
            return this.state.preview
              ? this.calcPreview(component)
              : this.calcFull(component);
          }}
          onApplyAll={(component) => {
            return this.calcFull(component, true);
          }}
          componentRef={(c) => {
            this.configFormRef = c;
          }}
        />
      </div>
    );
  }
}

/**
 * Configuration Component to render the parameter inputs dynamically
 */
class ConfigFormRaw extends Component {
  constructor(props) {
    super(props);
    this.state = props.state;
    this.accordionExpanded = false;
    if (props.componentRef) props.componentRef(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(nextProps.state);
  }

  validateParameters = () => {
    for (let parameter of this.props.toolConfig.parameters) {
      if (parameter.ui.component === "flselect") {
        let nChannels = this.props.ome.channels.length;
        let cIndex = this.state[parameter.name];
        if (cIndex >= nChannels) {
          this.onParameterChange(
            parameter.name,
            this.props.ome.channels.length - 1
          );
        } else if (cIndex < 0) {
          if (this.props.structures[this.props.selectedLayer].toolParams) {
            for (let toolParam of this.props.structures[
              this.props.selectedLayer
            ].toolParams) {
              let tempCIndex = this.props.ome.channels.findIndex(
                (c) => c.name === toolParam
              );
              if (tempCIndex >= 0) {
                cIndex = tempCIndex;
                break;
              }
            }
          }
        }
        this.onParameterChange(parameter.name, cIndex);
      }
    }
  };

  componentDidMount = () => {
    this.validateParameters();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.selectedLayer !== this.props.selectedLayer) {
      this.validateParameters();
    }
  };

  /**
   * Update tool state
   */
  onParameterChange(key, value) {
    let stateObject = { formControlInUse: key };
    stateObject[key] = value;
    if (this.state[key] !== value) {
      this.setState(stateObject);
      setTimeout(() => this.props.onParameterChange(stateObject), 10);
    }
  }

  transformExponential(v, exponent) {
    let result = v ** (1 / exponent);
    return result;
  }

  render() {
    let { onApply, onApplyAll, toolConfig, selectedLayer } = this.props;
    let formControls = [];
    let optionalFormControls = [];

    for (let parameter of toolConfig.parameters) {
      const digits = parameter.ui.step ? countDecimals(parameter.ui.step) : 0;
      let formControl = null;
      // hide parameter on unwanted structures
      if (
        (parameter.ui.hiddenStructures &&
          parameter.ui.hiddenStructures.includes(selectedLayer)) ||
        !(parameter.name in this.state)
      ) {
        continue;
      }
      const component_type = parameter.ui.component;

      switch (component_type) {
        case "required_structures":
          break;
        /* Range Slider */
        case "range":
        case "exp_range":
          formControl = (
            <FormControl
              key={toolConfig.name + "_" + parameter.name}
              component="fieldset"
              fullWidth
            >
              <FormHelperText>
                {parameter.ui.label +
                  " = " +
                  this.state[parameter.name][0].toFixed(digits) +
                  " ... " +
                  this.state[parameter.name][1].toFixed(digits)}
              </FormHelperText>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <TextField
                    variant="standard"
                    size="small"
                    style={styles.input}
                    value={this.state[parameter.name][0].toFixed(digits)}
                    margin="dense"
                    onChange={(e) =>
                      this.onParameterChange(parameter.name, [
                        e.target.value === "" ? 0 : Number(e.target.value),
                        this.state[parameter.name][1],
                      ])
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.onParameterChange("formControlInUse", false);
                        onApply(this);
                      }
                    }}
                    inputProps={{
                      step: parameter.ui.step,
                      min: parameter.ui.min,
                      max: parameter.ui.max,
                      type: "number",
                      "aria-labelledby": "input-slider",
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <Slider
                    min={this.transformExponential(
                      parameter.ui.min /
                        (parameter.ui.step ? parameter.ui.step : 1),
                      component_type === "exp_range" ? 2 : 1
                    )}
                    max={this.transformExponential(
                      parameter.ui.max /
                        (parameter.ui.step ? parameter.ui.step : 1),
                      component_type === "exp_range" ? 2 : 1
                    )}
                    step={
                      this.transformExponential(
                        parameter.ui.max /
                          (parameter.ui.step ? parameter.ui.step : 1),
                        component_type === "exp_range" ? 2 : 1
                      ) / 1000
                    }
                    value={[
                      this.transformExponential(
                        this.state[parameter.name][0] /
                          (parameter.ui.step ? parameter.ui.step : 1),
                        component_type === "exp_range" ? 2 : 1
                      ),
                      this.transformExponential(
                        this.state[parameter.name][1] /
                          (parameter.ui.step ? parameter.ui.step : 1),
                        component_type === "exp_range" ? 2 : 1
                      ),
                    ]}
                    onChange={(e, v) => {
                      if (component_type === "exp_range") {
                        v = [v[0] ** 2, v[1] ** 2];
                      } else {
                        v = [
                          v[0] * (parameter.ui.step ? parameter.ui.step : 1),
                          v[1] * (parameter.ui.step ? parameter.ui.step : 1),
                        ];
                      }
                      this.onParameterChange(parameter.name, v);
                    }}
                    onChangeCommitted={() => {
                      this.onParameterChange("formControlInUse", false);
                      onApply(this);
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    variant="standard"
                    size="small"
                    style={styles.input}
                    value={this.state[parameter.name][1].toFixed(digits)}
                    margin="dense"
                    onChange={(e) =>
                      this.onParameterChange(parameter.name, [
                        this.state[parameter.name][0],
                        e.target.value === "" ? 0 : Number(e.target.value),
                      ])
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onApply(this);
                      }
                    }}
                    inputProps={{
                      step: parameter.ui.step,
                      min: parameter.ui.min,
                      max: parameter.ui.max,
                      type: "number",
                      "aria-labelledby": "input-slider",
                    }}
                  />
                </Grid>
              </Grid>
            </FormControl>
          );
          break;
        /* Normal Slider */
        case "slider":
        case "exp_slider":
          formControl = (
            <FormControl
              key={toolConfig.name + "_" + parameter.name}
              component="fieldset"
              fullWidth
            >
              <FormHelperText>
                {parameter.ui.label +
                  " = " +
                  (typeof this.state[parameter.name] !== "undefined"
                    ? this.state[parameter.name].toFixed(digits)
                    : "")}
              </FormHelperText>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                  <Slider
                    min={this.transformExponential(
                      parameter.ui.min /
                        (parameter.ui.step ? parameter.ui.step : 1),
                      component_type === "exp_slider" ? 2 : 1
                    )}
                    max={this.transformExponential(
                      parameter.ui.max /
                        (parameter.ui.step ? parameter.ui.step : 1),
                      component_type === "exp_slider" ? 2 : 1
                    )}
                    step={
                      this.transformExponential(
                        parameter.ui.max /
                          (parameter.ui.step ? parameter.ui.step : 1),
                        component_type === "exp_slider" ? 2 : 1
                      ) / 1000
                    }
                    value={this.transformExponential(
                      this.state[parameter.name] /
                        (parameter.ui.step ? parameter.ui.step : 1),
                      component_type === "exp_slider" ? 2 : 1
                    )}
                    onChangeCommitted={(e) => {
                      if (e.type === "mouseup") {
                        this.onParameterChange("formControlInUse", false);
                        onApply(this);
                      }
                    }}
                    onChange={(e, v) => {
                      if (component_type === "exp_slider") v = v ** 2;
                      this.onParameterChange(
                        parameter.name,
                        v * (parameter.ui.step ? parameter.ui.step : 1)
                      );
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    variant="standard"
                    size="small"
                    style={styles.input}
                    value={this.state[parameter.name].toFixed(digits)}
                    margin="dense"
                    onChange={(e) => {
                      this.onParameterChange(
                        parameter.name,
                        e.target.value === "" ? 0 : Number(e.target.value)
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.onParameterChange("formControlInUse", false);
                        onApply(this);
                      }
                    }}
                    //onBlur={handleBlur}
                    inputProps={{
                      step: parameter.ui.step,
                      min: parameter.ui.min,
                      max: parameter.ui.max,
                      type: "number",
                      "aria-labelledby": "input-slider",
                    }}
                  />
                </Grid>
              </Grid>
            </FormControl>
          );
          break;
        /* Slider without update when release slider */
        case "staticSlider":
          formControl = (
            <FormControl
              key={toolConfig.name + "_" + parameter.name}
              component="fieldset"
              fullWidth
            >
              <FormHelperText>
                {parameter.ui.label +
                  " = " +
                  (typeof this.state[parameter.name] !== "undefined"
                    ? this.state[parameter.name].toFixed(digits)
                    : "")}
              </FormHelperText>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                  <Slider
                    min={
                      parameter.ui.min /
                      (parameter.ui.step ? parameter.ui.step : 1)
                    }
                    max={
                      parameter.ui.max /
                      (parameter.ui.step ? parameter.ui.step : 1)
                    }
                    value={
                      this.state[parameter.name] /
                      (parameter.ui.step ? parameter.ui.step : 1)
                    }
                    onChangeCommitted={() => {
                      this.onParameterChange("formControlInUse", false);
                    }}
                    onChange={(e, v) => {
                      this.onParameterChange(
                        parameter.name,
                        v * (parameter.ui.step ? parameter.ui.step : 1)
                      );
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    variant="standard"
                    size="small"
                    style={styles.input}
                    value={this.state[parameter.name].toFixed(digits)}
                    margin="dense"
                    onChange={(e) => {
                      this.onParameterChange(
                        parameter.name,
                        e.target.value === "" ? 0 : Number(e.target.value)
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onApply(this);
                      }
                    }}
                    //onBlur={handleBlur}
                    inputProps={{
                      step: parameter.ui.step,
                      min: parameter.ui.min,
                      max: parameter.ui.max,
                      type: "number",
                      "aria-labelledby": "input-slider",
                    }}
                  />
                </Grid>
              </Grid>
            </FormControl>
          );
          break;
        /* Selector */
        case "select":
          formControl = (
            <FormControl
              key={toolConfig.name + "_" + parameter.name}
              component="fieldset"
              fullWidth
              margin="dense"
            >
              <InputLabel htmlFor={parameter.name}>
                {parameter.ui.label}
              </InputLabel>
              <Select
                label={parameter.ui.label}
                value={this.state[parameter.name]}
                onChange={(e) =>
                  this.onParameterChange(parameter.name, e.target.value)
                }
                inputProps={{
                  name: parameter.name,
                  id: parameter.name,
                }}
              >
                {parameter.ui.options.map((c) => (
                  <MenuItem key={c.name} value={c.name}>
                    {c.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
          break;
        /* Fluorescence Channel Selector */
        case "flselect":
          formControl = (
            <FormControl
              key={toolConfig.name + "_" + parameter.name}
              component="fieldset"
              style={{ marginTop: 10 }}
              fullWidth
            >
              <InputLabel htmlFor={parameter.name}>
                {parameter.ui.label}
              </InputLabel>
              <Select
                label={parameter.ui.label}
                value={this.state[parameter.name]}
                onChange={(e) => {
                  this.onParameterChange(parameter.name, e.target.value);
                }}
                inputProps={{
                  name: parameter.name,
                  id: parameter.name,
                }}
              >
                <MenuItem key="defaultValue" value={-1}>
                  Select a Channel to use
                </MenuItem>
                {this.props.ome.channels.map((c, index) => (
                  <MenuItem key={c.name} value={index}>
                    <span
                      style={{
                        width: "40px",
                        height: "20px",
                        border: "1px solid #555459",
                        display: "inline-block",
                        background: this.props.histogramConfig
                          ? this.props.histogramConfig.channels[index].color
                          : "#ffffff",
                        float: "left",
                        marginRight: "10px",
                      }}
                    ></span>
                    {c.name}
                  </MenuItem>
                ))}
                {this.props.structures[this.props.selectedLayer].toolParams &&
                  this.props.structures[this.props.selectedLayer].toolParams
                    .length === 1 &&
                  this.props.ome.channels.length < 4 &&
                  this.state[parameter.name] > 2 && (
                    <MenuItem
                      key={
                        this.props.structures[this.props.selectedLayer]
                          .toolParams[0]
                      }
                      value={this.props.ome.channels.length}
                    >
                      {
                        this.props.structures[this.props.selectedLayer]
                          .toolParams[0]
                      }
                    </MenuItem>
                  )}
              </Select>
            </FormControl>
          );
          break;
        /* Layer Dependency Selector */
        case "structureselect":
          // validation
          if (this.state[parameter.name] >= this.props.structures.length) {
            this.onParameterChange(
              parameter.name,
              this.props.structures.length - 1
            );
          }
          formControl = (
            <FormControl
              key={toolConfig.name + "_" + parameter.name}
              component="fieldset"
              fullWidth
            >
              <InputLabel htmlFor={parameter.name}>
                {parameter.ui.label}
              </InputLabel>
              <Select
                label={parameter.ui.label}
                value={this.state[parameter.name]}
                onChange={(e) =>
                  this.onParameterChange(parameter.name, e.target.value)
                }
                inputProps={{
                  name: parameter.name,
                  id: parameter.name,
                }}
              >
                {this.props.structures &&
                  this.props.structures.map((c, index) => (
                    <MenuItem key={index} value={index}>
                      {c.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          );
          break;
        /* Numeric Input */
        case "number":
          formControl = (
            <FormControl
              key={toolConfig.name + "_" + parameter.name}
              component="fieldset"
              fullWidth
            >
              <TextField
                label={parameter.ui.label}
                value={this.state[parameter.name]}
                onChange={(e) =>
                  this.onParameterChange(parameter.name, e.target.value)
                }
                type="number"
                margin="normal"
                inputProps={{
                  min: parameter.ui.min,
                  max: parameter.ui.max,
                  step: "1",
                }}
              />
            </FormControl>
          );
          break;
        /* Checkbox, Boolean Input */
        case "checkbox":
          formControl = (
            <FormControl
              key={toolConfig.name + "_" + parameter.name}
              component="fieldset"
              fullWidth
            >
              <Tooltip
                disableInteractive
                title={parameter.description}
                placement="left"
              >
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state[parameter.name]}
                        onChange={(e) => {
                          this.onParameterChange(
                            parameter.name,
                            e.target.checked
                          );
                          //setTimeout(() => onApply(this), 10);
                        }}
                        value={parameter.ui.label}
                      />
                    }
                    label={parameter.ui.label}
                  />
                </div>
              </Tooltip>
            </FormControl>
          );
          break;
        /* Text Input */
        default:
          formControl = (
            <FormControl
              key={toolConfig.name + "_" + parameter.name}
              component="fieldset"
              fullWidth
            >
              <TextField
                label={parameter.ui.label}
                value={this.state[parameter.name]}
                onChange={(e) =>
                  this.onParameterChange(parameter.name, e.target.value)
                }
                margin="normal"
              />
            </FormControl>
          );
      }
      if (parameter.ui.optional) {
        optionalFormControls.push(formControl);
      } else {
        formControls.push(formControl);
      }
    }

    return (
      <div>
        {formControls}
        {optionalFormControls.length > 0 && (
          <Accordion
            square
            expanded={this.accordionExpanded}
            onChange={() => {
              this.accordionExpanded = !this.accordionExpanded;
              this.forceUpdate();
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography>
                Optional parameters: {this.state.selectedModel}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>{optionalFormControls}</AccordionDetails>
          </Accordion>
        )}
        {!this.state.preview && this.props.selectedLayer === 0 && (
          // Detect Base ROI for all files
          <Button
            style={{ marginTop: "5px" }}
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => onApplyAll(this)}
          >
            Apply to all files
          </Button>
        )}
        <Button
          style={{ marginTop: "5px" }}
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => onApply(this)}
        >
          Apply
        </Button>
        {this.state.showDownloadProgress && (
          <div>
            <TextField
              style={{ marginTop: "10px", textAlign: "center", width: "100%" }}
              label="Downloading weights from existing model:"
              fullWidth
              defaultValue="-"
              value={this.state.modelDownloadProgress + "%"}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
            />
            <LinearProgress
              variant="determinate"
              value={this.state.modelDownloadProgress}
            />
          </div>
        )}
      </div>
    );
  }
}

ConfigFormRaw.propTypes = {
  state: PropTypes.object,
  toolConfig: PropTypes.object,
  ome: PropTypes.object,
  structures: PropTypes.array,
  selectedLayer: PropTypes.number,
  onParameterChange: PropTypes.func,
  onApply: PropTypes.func,
  onApplyAll: PropTypes.func,
  histogramConfig: PropTypes.object,
  componentRef: PropTypes.func,
};

const ConfigForm = withSpinloader(ConfigFormRaw);

export default InstantAnalysisTool;

import React from "react";
//import { userService } from "../../common/services";
import Backend from "../../common/utils/Backend";
import {
  TextField,
  Typography,
  CardActions,
  Button,
  Card,
  CardContent,
  Tooltip,
  ListItem,
  List,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import FolderChooser from "./FolderChooser";

const styles = {
  root: {
    flexGrow: 1,
    width: "100%",
    height: "calc(100vh - 64px - 48px)",
    overflow: "hidden",
  },
  paper: {
    padding: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

class ImporterContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: null,
      showFolderChooser: false,
    };
  }

  componentDidMount() {
    Backend.readImporterSettings((settings) => {
      // convert string to array
      if (settings.importerFolders)
        settings.folders = settings.importerFolders.split(";");
      else settings.folders = [];
      this.setState({ settings });
    });
  }

  onChangeMetaData = (field, e) => {
    const { settings } = this.state;
    settings[field] = e;
    this.setState({ settings });
  };

  saveClick = () => {
    const { settings } = this.state;
    settings.importerFolders = settings.folders.join(";");
    Backend.writeImporterSettings(settings, (data) => {
      console.log(data);
    });
  };
  addDirectoryClick = () => {
    this.setState({ showFolderChooser: true });
  };

  handleClose = (path) => {
    if (path) {
      const { settings } = this.state;
      settings.folders.push(path);
      this.setState({ settings });
    }
    this.setState({ showFolderChooser: false });
  };

  removeDirectoryClick = (path) => {
    const { settings } = this.state;
    settings.folders = settings.folders.filter((e) => e !== path);
    this.setState({ settings });
  };

  render() {
    //const { classes } = this.props;
    const { settings, showFolderChooser } = this.state;

    if (!settings) return null;
    return (
      <Card>
        <FolderChooser
          open={showFolderChooser}
          settings={settings}
          onClose={this.handleClose}
        />
        <CardContent>
          <Typography variant="h6">Auto Import Settings</Typography>
          <List dense={true}>
            {settings.folders.map((folder, folderIdx) => (
              <ListItem key={folderIdx}>
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={folder}
                  secondary={settings.storagePath + folder}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => this.removeDirectoryClick(folder)}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          <Button
            size="small"
            color="primary"
            startIcon={<AddIcon />}
            onClick={this.addDirectoryClick}
          >
            Add Directory
          </Button>
          <br />
          <br />
          <Tooltip disableInteractive title="Start timed imports">
            <TextField
              id="time_start"
              label="Start"
              type="time"
              defaultValue={settings.importerStart}
              onChange={(e) => {
                settings.importerStart = e.target.value;
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </Tooltip>

          <span> </span>
          <Tooltip disableInteractive title="Stop starting timed imports">
            <TextField
              id="time_end"
              label="End"
              type="time"
              defaultValue={settings.importerEnd}
              onChange={(e) => {
                settings.importerEnd = e.target.value;
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </Tooltip>

          <span> </span>
          <Tooltip disableInteractive title="Date and Time of last Import">
            <TextField
              style={{ float: "right" }}
              id="time_end"
              label="Newer than"
              type="datetime-local"
              defaultValue={settings.importerNewerThan}
              onChange={(e) => {
                settings.importerNewerThan = e.target.value;
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </Tooltip>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" onClick={this.saveClick}>
            Save
          </Button>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(ImporterContainer);

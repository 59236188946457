import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const styles = {
  root: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
  },
  sliderContainer: {
    margin: 0,
    width: "100%",
    marginTop: -16,
  },
  input: {
    width: 55,
  },
  iconButton: {
    height: 40,
    marginTop: 16,
  },
};

class CustomSelector extends React.Component {
  render() {
    const { params, classes } = this.props;
    return (
      <div className={classes.root}>
        <FormControl
          component="fieldset"
          sx={{ m: 1, minWidth: 150 }}
          margin="dense"
        >
          <InputLabel htmlFor={params.name}>{params.label}</InputLabel>
          <Select
            label={params.label}
            value={
              params.options.find((item) => item.name === params.selectedValue)
                .name
            }
            onChange={(e) => params.onChange(e.target.value)}
            inputProps={{
              name: params.selectedValue,
              id: params.selectedValue,
            }}
          >
            {params.options.map((value) => (
              <MenuItem key={value.name} value={value.name}>
                {value.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
}

CustomSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomSelector);

import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  Button,
  IconButton,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";

import withStyles from "@mui/styles/withStyles";

import {
  Cancel,
  CheckCircle,
  ErrorOutline,
  HourglassEmpty,
  Timeline,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

import SketchColorPicker from "../../../viewer/components/SketchColorPicker";
import { withSpectraViewer } from "../../contexts/SpectraViewerContext";

const TooltipTable = withStyles({
  tooltip: {
    background: "#FFFFFF",
    border: "#EBEBEB solid 2px",
  },
})(Tooltip);

class SpectraTab extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.showRawSpectra();
  }

  /**
   * Determines which icon to diplay as a status of analysis results.
   * @param {Object} spectrum A spectrum for which analysis results can be displayed.
   * @returns {Tooltip} Renderable mui icon with mouseover information.
   */
  statusIcon = (spectrum) => {
    // In case of temporary spectra, that will not be analyzed
    if (spectrum.isTemporary) {
      return (
        <Tooltip
          disableInteractive
          arrow
          title="Generated spectrum, will not be analyzed"
        >
          <Timeline />
        </Tooltip>
      );
    }

    // In case of not defined or empty rated spectra
    if (
      !this.props.spectraViewer.ratedSpectra ||
      this.props.spectraViewer.ratedSpectra.length === 0
    ) {
      return (
        <Tooltip disableInteractive arrow title="Awaiting PCA choice">
          <HourglassEmpty />
        </Tooltip>
      );
    }
    // Attempt to find spectrum in array of rated spectra
    let spectrumRating = undefined;
    let spectrumWithinTolerances = undefined;
    let error = false;
    this.props.spectraViewer.ratedSpectra.some((ratedSpectrum) => {
      if (ratedSpectrum.id === spectrum.id) {
        spectrumWithinTolerances = ratedSpectrum.pass;
        spectrumRating = ratedSpectrum.rating;
        error = "Error" in ratedSpectrum.rating;
        return true;
      }
      return false;
    });
    // Define when a Spectrum is allowed to pass
    if (spectrumRating) {
      return (
        <TooltipTable
          arrow
          interactive
          placement="left"
          title={
            <TableContainer>
              <Table>
                <TableBody>
                  {Object.entries(spectrumRating).map(([key, value]) => {
                    return (
                      <TableRow key={key} label={key}>
                        <TableCell>{key}</TableCell>
                        <TableCell>
                          {typeof value === "number"
                            ? value < 0.01 && value !== 0
                              ? value.toExponential(3)
                              : Number(value).toFixed(6)
                            : value}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          }
        >
          {error ? (
            <ErrorOutline interactive="true" style={{ color: "#EB144C" }} />
          ) : spectrumWithinTolerances ? (
            <CheckCircle interactive="true" style={{ color: "#00D084" }} />
          ) : (
            <Cancel interactive="true" style={{ color: "#EB144C" }} />
          )}
        </TooltipTable>
      );
    } else {
      return (
        <Tooltip disableInteractive arrow title="Awaiting analysis">
          <HourglassEmpty />
        </Tooltip>
      );
    }
  };

  render() {
    const { spectraViewer } = this.props;
    return (
      <div
        style={{
          overflow: "auto",
          gridTemplateRows: this.props.project.type === "auto 1fr auto",
        }}
      >
        {this.props.initialized && (
          <Button
            style={{ width: "100%" }}
            onClick={() => this.props.toggleAll()}
          >
            Toggle visibility of all spectra
          </Button>
        )}
        {this.props.initialized &&
          // List all spectra
          this.props.datasets.map((item, idx) => {
            return (
              <React.Fragment key={idx}>
                {/* Spectra */}
                <ListItem
                  button
                  key={555}
                  selected={spectraViewer.selectedSpectrum === idx}
                  style={{
                    paddingBottom: 0,
                    paddingTop: 0,
                  }}
                  onClick={() =>
                    this.props.spectraViewer.setState({ selectedSpectrum: idx })
                  }
                >
                  <span style={{ width: "100%", minWidth: 0 }}>
                    <ListItemText
                      style={{
                        padding: "0px",
                      }}
                      primary={
                        <div
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item.name}
                        </div>
                      }
                    />
                  </span>
                  {this.props.project.type === "ESREvaluation" &&
                    // Statusicon used for pca compliance
                    this.statusIcon(item)}
                  {
                    // Visibility Button
                    <Tooltip
                      disableInteractive
                      title={item.checked ? "Hide" : "Show"}
                    >
                      <IconButton
                        onClick={(e) => {
                          this.props.toggleLegend(item.name);
                          e.stopPropagation();
                        }}
                        size="large"
                      >
                        {item.checked ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </Tooltip>
                  }
                  <SketchColorPicker
                    color={item.color}
                    handleChange={(newColor) => {
                      item.color = newColor;
                      this.props.updateChartData(this.props.datasets, () => {});
                    }}
                  />
                </ListItem>
              </React.Fragment>
            );
          })}
        {this.props.initialized && this.props.project.type !== "ESREvaluation" && (
          // Option to change spectra settings
          <Button
            style={{ width: "100%" }}
            onClick={() => this.props.setInitialized(false)}
          >
            Change Spectra Parameters
          </Button>
        )}
      </div>
    );
  }
}

SpectraTab.propTypes = {
  spectraViewer: PropTypes.object.isRequired,
  project: PropTypes.object,
  datasets: PropTypes.array,
  toggleLegend: PropTypes.func,
  toggleAll: PropTypes.func,
  initialized: PropTypes.bool,
  updateChartData: PropTypes.func,
  setInitialized: PropTypes.func,
  showRawSpectra: PropTypes.func,
  onSave: PropTypes.func,
  returnHome: PropTypes.func,
};

export default withSpectraViewer(SpectraTab);

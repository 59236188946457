import React, { Component } from "react";
import PropTypes from "prop-types";

import { withSpinloader } from "../../common/components/Spinloader";
import { withRouter } from "react-router-dom";
import Backend from "../../common/utils/Backend";

const ScanViewerContext = React.createContext();

export const withScanViewerContext = (Component) => {
  const WrappedComponent = (props) => (
    <ScanViewerContext.Consumer>
      {(context) => <Component {...props} scanViewerContext={context} />}
    </ScanViewerContext.Consumer>
  );

  WrappedComponent.displayName = `withScanViewerContext(${
    Component.displayName || Component.name || "Component"
  })`;

  return WrappedComponent;
};

/**
 * handles the communication with backend.
 * this is the main component, it has to be added at the root of the app.
 * all components that use withPersistentStorage(...) will have access to it via this.props.persistentStorage...
 */
class ScanViewerProvider extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      mapVisible: true,
      cameraVisible: false,
      gridVisible: true,
      commentsVisible: true,
      sideBarContent: "fluorescence",
      sharpness: 0,
      streamReady: false,
      slideScanning: false,
      activeTab: 0,
      filePath: "",
      commentCount: 0,
      sharpnessBenchmark: 80,
      w: 400,
      h: 400,
      fluorescenceChannels: "",
      selectedChannel: "",
      cameraMinFramerate: 0,
      cameraMaxFramerate: 30,
      cameraMinExposureTime: 30,
      cameraMaxExposureTime: 99999,
      cameraMinGain: 0.1,
      cameraMaxGain: 12.0,
      cameraMinGamma: 0.0,
      cameraMaxGamma: 3.95,
      cameraMinBlackValue: 0.0,
      cameraMaxBlackValue: 31.9,
      pixelSizeCamera: 3.45,
      cameraColorMin: 0,
      cameraColorMax: 15.99,
    };

    this.channels = [];
    this.socket = Backend.connectScanner();
    this.socket.onopen = () => {
      console.log("SCANNER CONNECTED");
      this.send({
        state: "toggle",
        target: "camera",
        value: this.state.cameraVisible,
      });
      this.send({
        state: "toggle",
        target: "grid",
        value: this.state.gridVisible,
      });
    };
    this.socket.onclose = () => {
      console.log("SCANNER DISCONNECTED");
    };
    let lastStateUpdateTime = performance.now();
    this.socket.onmessage = (ev) => {
      const data = JSON.parse(ev.data);
      if (data.state) {
        if (data.state === "stream_ready") {
          this.setState({ streamReady: true });
        } else if (data.state === "stitching_start") {
          props.spinloader.show();
        } else if (data.state === "stitching") {
          props.spinloader.showWithProgress({
            message: "Progress",
            progress: data.progress,
          });
        } else if (data.state === "stitching_end") {
          props.spinloader.hide();
        } else if (data.state === "camera_error") {
          window.openResponseDialog(
            "Camera could not be found. Retry?",
            (response) => {
              if (!response) {
                this.exitScanner();
                this.props.history.push("/");
              }
            }
          );
        } else if (data.state === "saving_error") {
          props.spinloader.hide();
          window.openWarningDialog(
            "Number of channels not equal. Please save in separate file."
          );
        } else if (data.state === "cameraParameters") {
          console.log("update params", data);
          this.setState({
            cameraMinFramerate: data.cameraMinFramerate,
            cameraMaxFramerate: data.cameraMaxFramerate,
            cameraMinExposureTime: data.cameraMinExposureTime,
            cameraMaxExposureTime: data.cameraMaxExposureTime,
            cameraMinGain: data.cameraMinGain,
            cameraMaxGain: data.cameraMaxGain,
            cameraMinGamma: data.cameraMinGamma,
            cameraMaxGamma: data.cameraMaxGamma,
            cameraMinBlackValue: data.cameraMinBlackValue,
            cameraMaxBlackValue: data.cameraMaxBlackValue,
            pixelSizeCamera: data.pixelSizeCamera,
          });
        }
      }
      if (data.sharpness && data.sharpness !== this.state.sharpness) {
        // reduce state updates to max 10/s
        if (performance.now() - lastStateUpdateTime > 100) {
          this.setState({ sharpness: data.sharpness });
          lastStateUpdateTime = performance.now();
        }
      }
    };
  }

  setMountedState = (stateObject, callback) => {
    if (this._isMounted) {
      this.setState(stateObject, callback);
    }
  };

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("beforeunload", this.exitScanner);
    const cameraVisibleValue =
      localStorage.getItem("scanCameraVisible") === "undefined"
        ? false
        : localStorage.getItem("scanCameraVisible") === "true"
        ? true
        : false;
    const mapVisibleValue =
      localStorage.getItem("scanMapVisible") === "undefined"
        ? true
        : localStorage.getItem("scanMapVisible") === "true"
        ? true
        : false;
    const gridVisibleValue =
      localStorage.getItem("scanGridVisible") === "undefined"
        ? true
        : localStorage.getItem("scanGridVisible") === "true"
        ? true
        : false;
    const commentsVisibleValue =
      localStorage.getItem("scanCommentsVisible") === "undefined"
        ? true
        : localStorage.getItem("scanCommentsVisible") === "true"
        ? true
        : false;
    const sideBarContentValue = localStorage.getItem("scanSideBarContent");
    this.setState({
      cameraVisible: cameraVisibleValue,
      mapVisible: mapVisibleValue,
      gridVisible: gridVisibleValue,
      commentsVisible: commentsVisibleValue,
      sideBarContent: sideBarContentValue,
    });
  }

  componentWillUnmount() {
    this.exitScanner();
    window.removeEventListener("beforeunload", this.exitScanner);
    this._isMounted = false;
  }

  changeScanState = (scanState) => {
    if (scanState === "scan_save") {
      this.changeTab(0);
    }
    if (this.socket) {
      this.send({ state: scanState });
      this.setMountedState({ slideScanning: scanState === "scan_start" });
      if (scanState === "scan_start") {
        this.setState({ commentCount: 5 });
      } else if (scanState === "scan_pause") {
        this.setState({ commentCount: 6 });
      } else if (scanState === "scan_reset") {
        this.setState({ commentCount: 0 });
        this.channels = [];
      }
    }
  };

  send = (objectToSend) => {
    if (this.socket !== null && this.socket.readyState === 1) {
      this.socket.send(JSON.stringify(objectToSend));
    }
  };

  setScanRendererSize = (w, h) => {
    this.setState({ w: w });
    this.setState({ h: h });
    this.send({
      state: "renderSize",
      w: w,
      h: h,
    });
  };

  changeMicroscopeParams = (paramJson) => {
    paramJson.state = "microscope_params";
    this.send(paramJson);
  };

  moveCenter = (dx, dy) => {
    this.send({
      state: "scan_translate",
      dx: dx,
      dy: dy,
    });
  };

  sendMinimapClickPosition = (x, y) => {
    this.send({
      state: "minimap_click",
      x: x,
      y: y,
    });
  };

  sendFilePath = (filePath) => {
    this.setState({ filePath: filePath });
    this.send({
      state: "file_name_change",
      filePath: filePath,
    });
  };

  changeTab = (tabIdx) => {
    this.setMountedState({ activeTab: tabIdx });
  };

  createWSI = () => {
    this.send({
      state: "scan_save",
      filePath: this.state.filePath,
      channels: this.channels,
    });
    this.channels = [];
  };

  setVignetteState = (value) => {
    this.send({
      state: "vignette",
      value: value,
    });
  };

  setFeaturePointsState = (checked, numFeaturePoints) => {
    this.send({
      state: "featurePoint",
      checked: checked,
      numFeaturePoints: numFeaturePoints,
    });
  };

  toggleCamera = () => {
    const value = !this.state.cameraVisible;
    this.send({
      state: "toggle",
      target: "camera",
      value: value,
    });
    this.setMountedState({ cameraVisible: value });
    localStorage.setItem("scanCameraVisible", value.toString());
  };

  toggleMinimap = () => {
    const value = !this.state.mapVisible;
    this.setMountedState({ mapVisible: value });
    localStorage.setItem("scanMapVisible", value.toString());
  };

  toggleGrid = () => {
    const value = !this.state.gridVisible;
    this.send({
      state: "toggle",
      target: "grid",
      value: value,
    });
    this.setMountedState({ gridVisible: value });
    localStorage.setItem("scanGridVisible", value.toString());
  };

  toggleComments = () => {
    const value = !this.state.commentsVisible;
    this.setMountedState({ commentsVisible: value });
    localStorage.setItem("scanCommentsVisible", value.toString());
  };

  toggleFluorescence = () => {
    let value = "";
    if (this.state.sideBarContent === "fluorescence") {
      this.setMountedState({ sideBarContent: "microscopeSettings" });
      value = "microscopeSettings";
    } else {
      this.setMountedState({ sideBarContent: "fluorescence" });
      value = "fluorescence";
    }
    localStorage.setItem("scanSideBarContent", value);
  };

  updateFluorescenceParams = (paramJson) => {
    this.send(paramJson);
  };

  deleteTile = (x, y) => {
    this.send({
      state: "delete",
      x: x,
      y: y,
    });
  };

  undo = () => {
    this.send({
      state: "undo",
    });
  };

  redo = () => {
    this.send({
      state: "redo",
    });
  };

  zoomIn = () => {
    this.send({
      state: "zoom_in",
    });
  };

  zoomOut = () => {
    this.send({
      state: "zoom_out",
    });
  };

  zoomReset = () => {
    this.send({
      state: "zoom_reset",
    });
  };

  exitScanner = () => {
    this.send({
      state: "exit_scanner",
    });
  };

  render() {
    return (
      <ScanViewerContext.Provider
        value={{
          channels: this.channels,
          setState: this.setMountedState,
          changeScanState: this.changeScanState,
          setScanRendererSize: this.setScanRendererSize,
          sendMinimapClickPosition: this.sendMinimapClickPosition,
          sendFilePath: this.sendFilePath,
          changeMicroscopeParams: this.changeMicroscopeParams,
          changeTab: this.changeTab,
          moveCenter: this.moveCenter,
          createWSI: this.createWSI,
          setVignetteState: this.setVignetteState,
          setFeaturePointsState: this.setFeaturePointsState,
          toggleCamera: this.toggleCamera,
          toggleMinimap: this.toggleMinimap,
          toggleGrid: this.toggleGrid,
          toggleFluorescence: this.toggleFluorescence,
          updateFluorescenceParams: this.updateFluorescenceParams,
          toggleComments: this.toggleComments,
          deleteTile: this.deleteTile,
          undo: this.undo,
          redo: this.redo,
          zoomReset: this.zoomReset,
          zoomIn: this.zoomIn,
          zoomOut: this.zoomOut,
          ...this.state,
        }}
      >
        {this.props.children}
      </ScanViewerContext.Provider>
    );
  }
}

ScanViewerProvider.propTypes = {
  children: PropTypes.element.isRequired,
  spinloader: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(withSpinloader(ScanViewerProvider));

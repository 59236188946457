import React, { Component } from "react";
import PropTypes from "prop-types";

import Backend from "../common/utils/Backend";

import Button from "@mui/material/Button";

import {
  Select,
  InputLabel,
  FormHelperText,
  FormControl,
  Typography,
  MenuItem,
} from "@mui/material";

import withStyles from "@mui/styles/withStyles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(600 + 3 * 2 * 8)]: {
      // [theme.breakpoints.up(500 + theme.spacing(3 * 2))]: {
      // In the mui v5 update, theme.spacing returns "48px" instead of 48.
      // Therefore, hardcoding the default theme.spacing multiplyer of 8.
      width: "100%",
      height: "calc(100% - 64px)",
      margin: 0,
      overflow: "auto",
    },
  },
  paper: {
    maxWidth: 600,
    margin: "20px auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
});

class LicensingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      licenseStatus: "",
      validationMsg: "",
      hardwareID: "",
      os: "Windows",
      expirationDate: "",
      isSubmitting: false,
      validationObject: {
        storagePath: true,
        tempPath: true,
        galleryOutputPath: true,
        javaPath: true,
      },
      formData: {
        storagePath: "",
        tempPath: "",
        galleryOutputPath: "",
        javaPath: "",
        localhost: false,
        licenseKey: "",
      },
    };

    Backend.getLicensingInfo((result) => this.setState(result));
    Backend.readAppSettingsLicensing((result) => {
      const formData = result;
      this.setState({ formData });
    });
  }

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  correctPath(path) {
    path = path.replace(/\\/g, "/");
    while (path.includes("//")) {
      path = path.replace("//", "/");
    }
    if (!path.endsWith("/")) {
      path = path + "/";
    }
    return path;
  }

  handleSubmit = () => {
    this.setState({
      isSubmitting: true,
    });

    const { formData } = this.state;

    let storagePath = formData.storagePath;
    let tempPath = formData.tempPath;
    let galleryOutputPath = formData.galleryOutputPath;
    let javaPath = formData.javaPath;

    storagePath = this.correctPath(storagePath);
    tempPath = this.correctPath(tempPath);
    galleryOutputPath = this.correctPath(galleryOutputPath);

    formData["storagePath"] = storagePath;
    formData["tempPath"] = tempPath;
    formData["galleryOutputPath"] = galleryOutputPath;
    formData["javaPath"] = javaPath;

    this.setState({ formData });

    Backend.writeAppSettingsLicensing(this.state.formData, (data) => {
      let allValid = true;
      let dialogString = "";
      let validationObject = this.state.validationObject;
      for (const [key, value] of Object.entries(data)) {
        if (!value) {
          allValid = false;
          validationObject[key] = false;

          if (key === "javaPath") {
            dialogString += "File not found: ";
          } else {
            dialogString += "Folder not found: ";
          }
          dialogString += formData[key] + "\n";
        }
      }
      if (allValid) {
        window.location.reload();
      } else {
        window.openWarningDialog(dialogString);
        this.setState({ isSubmitting: false, validationObject });
      }
    });
  };

  removeErrorStyle = (key) => {
    let validationObject = this.state.validationObject;
    if (validationObject[key] !== "none") {
      validationObject[key] = true;
      this.setState({ validationObject });
    }
  };

  render() {
    const { classes } = this.props;
    const { isSubmitting, error, formData } = this.state;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          {this.state.licenseStatus === "INVALID" && (
            <h1>Invalid License Key!</h1>
          )}
          {this.state.licenseStatus === "CRACKED" && (
            <h1>Cracked License Key!</h1>
          )}
          {this.state.licenseStatus === "VALID" && <h1>Valid License Key</h1>}
          <div>
            <strong>License State: </strong>
            {this.state.licenseStatus}
            <br />
            {this.state.validationMsg !== "" && (
              <React.Fragment>
                <strong>Validation Message: </strong>
                {this.state.validationMsg}
                <br />
              </React.Fragment>
            )}
            {this.state.expirationDate !== "" && (
              <React.Fragment>
                <strong>Expiration Date: </strong>
                {this.state.expirationDate}
                <br />
              </React.Fragment>
            )}

            <strong>Hardware Id: </strong>
            {this.state.hardwareID}
          </div>
          <br />

          {this.state.licenseStatus === "VALID" && (
            <Button color="primary" variant="contained" href="/login">
              Sign In
            </Button>
          )}
          <ValidatorForm className={classes.form} onSubmit={this.handleSubmit}>
            {error && (
              <Typography
                align="center"
                variant="caption"
                style={{ color: "#ff0000" }}
              >
                {error}
              </Typography>
            )}
            <TextValidator
              style={{
                border: this.state.validationObject.storagePath
                  ? "none"
                  : "5px solid red",
              }}
              onClick={() => this.removeErrorStyle("storagePath")}
              fullWidth
              margin="normal"
              name="storagePath"
              label="Storage Path"
              type="text"
              validators={["required"]}
              errorMessages={["this field is required"]}
              onChange={this.handleChange}
              value={formData.storagePath}
            />
            <TextValidator
              style={{
                border: this.state.validationObject.tempPath
                  ? "none"
                  : "5px solid red",
              }}
              onClick={() => this.removeErrorStyle("tempPath")}
              fullWidth
              margin="normal"
              name="tempPath"
              label="Tempfiles Path"
              type="text"
              validators={["required"]}
              errorMessages={["this field is required"]}
              onChange={this.handleChange}
              value={formData.tempPath}
            />
            <TextValidator
              style={{
                border: this.state.validationObject.galleryOutputPath
                  ? "none"
                  : "5px solid red",
              }}
              onClick={() => this.removeErrorStyle("galleryOutputPath")}
              fullWidth
              margin="normal"
              name="galleryOutputPath"
              label="Gallery Output Path"
              type="text"
              validators={["required"]}
              errorMessages={["this field is required"]}
              onChange={this.handleChange}
              value={formData.galleryOutputPath}
            />
            <TextValidator
              style={{
                border: this.state.validationObject.javaPath
                  ? "none"
                  : "5px solid red",
              }}
              onClick={() => this.removeErrorStyle("javaPath")}
              fullWidth
              margin="normal"
              name="javaPath"
              label="Java Path"
              type="text"
              validators={["required"]}
              errorMessages={["this field is required"]}
              onChange={this.handleChange}
              value={formData.javaPath}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Localhost?</InputLabel>
              <Select
                label="Localhost?"
                value={formData.localhost}
                onChange={this.handleChange}
                inputProps={{
                  name: "localhost",
                  id: "localhost-native-helper",
                }}
              >
                <MenuItem value={false}>False</MenuItem>
                <MenuItem value={true}>True</MenuItem>
              </Select>
              <FormHelperText>Set True to use remotely</FormHelperText>
            </FormControl>
            <TextValidator
              style={{
                border:
                  this.state.licenseStatus === "VALID"
                    ? "none"
                    : "5px solid red",
              }}
              fullWidth
              margin="normal"
              name="licenseKey"
              label="License Key"
              type="text"
              validators={["required"]}
              errorMessages={["this field is required"]}
              onChange={this.handleChange}
              value={formData.licenseKey}
              variant="outlined"
              multiline
              rowsmax={8}
            />

            <Button
              className={classes.submit}
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </ValidatorForm>
          {this.state.licenseStatus !== "VALID" && this.state.os !== "Windows" && (
            <div>
              <strong>To Update LicenseKey do following steps: </strong>
              <br />
              <ol>
                <li>Get Valid License Key</li>
                <li>Open env.list</li>
                <li>Paste Valid License Key to HSA_LICENSE_KEY</li>
                <li>Shutdown docker container</li>
                <li>
                  Restart Container with <code>docker-compose up</code>
                </li>
              </ol>
            </div>
          )}
        </Paper>
      </main>
    );
  }
}

LicensingPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LicensingPage);

import React, { Component } from "react";
import PropTypes from "prop-types";

import { Route } from "react-router-dom";

import withStyles from "@mui/styles/withStyles";
import { withSnackbar } from "notistack";

import Viewer from "./viewer/Viewer";
import ProteomViewer from "./proteomViewer/ProteomViewer";
import SpectraViewer from "./spectraViewer/SpectraViewer";
import ScanViewer from "./scanViewer/ScanViewer";

import Report from "./home/Report";

import { Role } from "./common/utils";
//import { authenticationService } from "./common/services";
import { PrivateRoute } from "./common/components";
import AdminPage from "./admin/AdminPage";
import LoginPage from "./account/LoginPage";
import LicensingPage from "./licensing/LicensingPage";
import HomePage from "./home/HomePage";
import CasesPage from "./cases/CasesPage";
import PredictionPage from "./prediction/PredictionPage";
import SettingsPage from "./settings/SettingsPage";
import NavigationBar from "./common/components/NavigationBar";
import AboutPage from "./about/AboutPage";
import PersistentStorageProvider from "./viewer/contexts/PersistentStorageContext";
import ProjectHistoryProvider from "./viewer/contexts/ProjectHistoryContext";
import ProjectProvider from "./viewer/contexts/ProjectContext";
import ResultTabProvider from "./viewer/contexts/ResultTabContext";
import { TilesProvider } from "./viewer/contexts/TilesContext";
import SpectraViewerProvider from "./spectraViewer/contexts/SpectraViewerContext";
import ScanProvider from "./scanViewer/contexts/ScanViewerContext";
import "./css/global.css";
import CustomDialog from "./common/components/CustomDialog";

const styles = (theme) => ({
  root: {
    height: "100vh",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    height: 50,
  },
  appbarPlaceholder: theme.mixins.toolbar,
});

class App extends Component {
  static displayName = App.name;

  render() {
    const { classes } = this.props;

    window.showSuccessSnackbar = (str) => {
      this.props.enqueueSnackbar(str, {
        variant: "success",
      });
    };

    window.showWarningSnackbar = (str) => {
      this.props.enqueueSnackbar(str, {
        variant: "warning",
      });
    };

    window.showErrorSnackbar = (str) => {
      this.props.enqueueSnackbar(str, {
        variant: "error",
      });
    };

    return (
      <div className={classes.root}>
        <div className={classes.appbarPlaceholder} />
        <PrivateRoute
          path="/view/:id"
          component={({ match }) => (
            <PersistentStorageProvider
              projectId={match.params.id ? match.params.id : "001"}
            >
              <ProjectProvider>
                <ProjectHistoryProvider historyDepth={20}>
                  <ResultTabProvider>
                    <TilesProvider>
                      <Viewer id={match.params.id ? match.params.id : "001"} />
                    </TilesProvider>
                  </ResultTabProvider>
                </ProjectHistoryProvider>
              </ProjectProvider>
            </PersistentStorageProvider>
          )}
        />
        <PrivateRoute
          path="/proteome_view/:id"
          component={({ match }) => (
            <ProteomViewer id={match.params.id ? match.params.id : "001"} />
          )}
        />
        <PrivateRoute
          path="/esr_view/:id"
          component={({ match }) => (
            <SpectraViewerProvider>
              <SpectraViewer id={match.params.id ? match.params.id : "001"} />
            </SpectraViewerProvider>
          )}
        />
        <PrivateRoute
          path="/scan_view"
          component={() => (
            <ScanProvider>
              <ScanViewer />
            </ScanProvider>
          )}
        />
        <PrivateRoute path="/cases" component={() => <CasesPage />} />
        <PrivateRoute path="/prediction" component={() => <PredictionPage />} />
        <PrivateRoute
          path="/report/:id"
          component={({ match }) => <Report id={match.params.id} />}
        />
        <Route path="/login" component={() => <LoginPage />} />
        <Route path="/licensing" component={() => <LicensingPage />} />
        <PrivateRoute exact path="/" component={() => <HomePage />} />
        <PrivateRoute path="/settings" component={() => <SettingsPage />} />
        <PrivateRoute path="/about" component={() => <AboutPage />} />
        <PrivateRoute
          path="/admin"
          roles={[Role.Admin]}
          component={AdminPage}
        />

        <NavigationBar />
        <CustomDialog />
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func,
};

export default withSnackbar(withStyles(styles)(App));

import { IconButton, Tooltip } from "@mui/material";
import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  faRuler,
  faStopwatch,
  faLayerGroup,
  faInfo,
  faTable,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UnfoldMore, LinearScale, Map } from "@mui/icons-material";

class ToggleButton extends Component {
  static defaultProps = {
    disabled: false,
  };

  render() {
    const { title, name, value, icon, onToggle, classes, disabled } =
      this.props;

    return (
      <Tooltip
        disableInteractive
        title={disabled ? `${title}: Currently not available` : title}
      >
        <span>
          <IconButton
            className={classNames(
              classes.toolbarButton,
              value && classes.toolbarButtonChecked
            )}
            onClick={() => (disabled ? {} : onToggle(name))}
            size="small"
            disabled={disabled}
          >
            {icon === "MapIcon" && (
              <Map className={classes.toolbarButtonIcon} />
            )}
            {icon === "faInfoCircle" && <FontAwesomeIcon icon={faInfoCircle} />}
            {icon === "faRuler" && <FontAwesomeIcon icon={faRuler} />}
            {icon === "faStopwatch" && <FontAwesomeIcon icon={faStopwatch} />}
            {icon === "faLayerGroup" && <FontAwesomeIcon icon={faLayerGroup} />}
            {icon === "faInfo" && <FontAwesomeIcon icon={faInfo} />}
            {icon === "LinearScaleIcon" && (
              <LinearScale
                className={classes.toolbarButtonIcon}
                style={{ transform: "rotate(90deg)" }}
              />
            )}
            {icon === "faTable" && <FontAwesomeIcon icon={faTable} />}
            {icon === "UnfoldMore" && (
              <UnfoldMore
                style={{ marginTop: -5, transform: "rotate(90deg)" }}
              />
            )}
          </IconButton>
        </span>
      </Tooltip>
    );
  }
}

ToggleButton.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  key: PropTypes.string,
  value: PropTypes.bool,
  icon: PropTypes.string,
  onToggle: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ToggleButton;

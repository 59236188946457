import React from "react";

import {
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import { inv } from "mathjs";
import { CommentROI } from "../../utils/ROI";
import Tool from "./Tool";

class LandmarkTool extends Tool {
  name = "Landmark registration";
  noConfig = false;
  mouseActionState = "free";
  commentState = {
    commentValue: "",
    color: "#D0021B",
    selectedTool: "rectangle",
  };
  selectedLandmarkIdx = 0;
  gripIdx = null;
  radioValue = "#00FF00";
  deletedNumberQ = {};
  baseId = null;

  setLayer(obj) {
    this.ome = obj.ome;
    this.isBrightfield = obj.ome.channels[0].type === "brightfield";
    this.lineColor = this.isBrightfield ? "#000000" : "#ffffff";
    this.selectionColor = this.lineColor;
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    this.selectedLayer = obj.selectedLayer;
    this.structures = obj.structures;
    this.drawLayer = obj.drawLayer;
    this.commentLayer = obj.commentLayer
      ? obj.commentLayer
      : { commentRois: [] };
    this.landmarkLayer = obj.landmarkLayer
      ? obj.landmarkLayer
      : { landmarkRois: [] };
    this.fileId = obj.fileId;
    this.landmarkLayers = obj.landmarkLayers;
    this.rendererDict = obj.rendererDict;
    this.splitscreenFileIds = obj.splitscreenFileIds;
  }

  //return index if mousepoint is inside a mark
  checkBoundingBox(layer, scale, mousePoint) {
    let linelength = 15 / scale;
    let p = mousePoint;
    for (let i = 0; i < layer.length; i++) {
      let point = layer[i].regions;
      let topLeft = [point[0] - linelength, point[1] - linelength];
      let bottomRight = [point[0] + linelength, point[1] + linelength];
      if (
        p.x > topLeft[0] &&
        p.x < bottomRight[0] &&
        p.y > topLeft[1] &&
        p.y < bottomRight[1]
      ) {
        return i;
      }
    }
  }

  mouse(params) {
    let { event, p, scale } = params;
    let mouseP = p;
    let roiColor = this.radioValue === "#00FF00" ? "#00FF00" : "#FF0000";
    let selectedLandmarkIdx = this.landmarkLayer["landmarkRois"].length;
    let markNumber = selectedLandmarkIdx;

    if (typeof this.deletedNumberQ[this.fileId] === "undefined") {
      this.deletedNumberQ[this.fileId] = [];
    }

    if (event.type === "mousedown") {
      this.mouseActionState = "down";
      if (selectedLandmarkIdx > 0 && event.button === 0) {
        let tag = this.landmarkLayer["landmarkRois"][0].color;
        if (tag === "#00FF00") {
          this.baseId = this.fileId;
          console.log("tagged as base");
        }
        if (tag !== this.radioValue) {
          this.radioValue = tag;
          return;
        }
        roiColor = tag;
      } else if (selectedLandmarkIdx === 0 && event.button === 0) {
        for (const value of Object.values(this.splitscreenFileIds)) {
          if (this.landmarkLayers[value]["landmarkRois"].length > 0) {
            if (
              this.landmarkLayers[value]["landmarkRois"][0].color === "#00FF00"
            ) {
              this.radioValue = "#FF0000";
              roiColor = "#FF0000";
              this.baseId = value;
            }
          }
        }
        if (this.radioValue === "#00FF00") {
          this.baseId = this.fileId;
          roiColor = "#00FF00";
        }
      }
    } else if (event.type === "mousemove" && this.mouseActionState === "down") {
      this.mouseActionState = "drag";
    } else if (event.type === "mouseup") {
      this.mouseActionState = "free";
    }

    if (this.mouseActionState === "down" && event.button === 0) {
      if (
        this.radioValue === "#00FF00" &&
        this.splitscreenFileIds.includes(this.baseId) &&
        this.fileId !== this.baseId
      ) {
        console.log("this file is not tagged as base");
        this.radioValue = "#FF0000";
        return;
      }
      if (selectedLandmarkIdx > 0) {
        let idx = this.checkBoundingBox(
          this.landmarkLayer["landmarkRois"],
          scale,
          mouseP
        );
        if (!idx && idx !== 0) {
          if (this.deletedNumberQ[this.fileId].length !== 0) {
            markNumber = this.deletedNumberQ[this.fileId].pop() - 1;
          }
          this.landmarkLayer["landmarkRois"][selectedLandmarkIdx] =
            new CommentROI(
              [mouseP.x, mouseP.y],
              roiColor,
              "landmark",
              markNumber + 1,
              0
            );
        } else if (typeof idx === "number") {
          this.gripIdx = idx;
        }
      } else if (selectedLandmarkIdx === 0) {
        this.landmarkLayer["landmarkRois"][selectedLandmarkIdx] =
          new CommentROI(
            [mouseP.x, mouseP.y],
            roiColor,
            "landmark",
            markNumber + 1,
            0
          );
      }
    } else if (this.mouseActionState === "free" && event.type === "mouseup") {
      //set new location of landmark after dragging it
      if (typeof this.gripIdx === "number") {
        this.landmarkLayer["landmarkRois"][this.gripIdx].regions = [
          mouseP.x,
          mouseP.y,
        ];
        this.gripIdx = null;
      }
    }

    // delete specific mark
    if (this.mouseActionState === "down" && event.button === 2) {
      let idx = this.checkBoundingBox(
        this.landmarkLayer["landmarkRois"],
        scale,
        mouseP
      );
      if (!idx && idx !== 0) {
        return;
      } else if (typeof idx === "number") {
        let number = this.landmarkLayer["landmarkRois"][idx].commentValue;
        this.landmarkLayer.landmarkRois.splice(idx, 1);
        this.deletedNumberQ[this.fileId].push(number);
        if (this.landmarkLayer.landmarkRois.length === 0) {
          this.deletedNumberQ[this.fileId] = [];
          this.baseId = null;
        }
      }
    }

    //disable custom mouse cursor when dragging with middle mouse button
    if (event.button === 1) {
      this.mouseActionState = "dragImage";
    } else if (event.button === 0 && this.mouseActionState === "dragImage") {
      this.mouseActionState = "free";
    }
  }

  drawCustomCursor() {}

  onClickRemoveTransformation = () => {
    let r = this.rendererDict[this.fileId];
    let a = r.props.tiles.getTransformationMatrix(this.fileId);
    if (Array.isArray(a)) {
      let m = [
        [a[0], a[1], a[2]],
        [a[3], a[4], a[5]],
        [a[6], a[7], a[8]],
      ];
      let h = inv(m);
      let matrix = [
        h[0][0],
        h[0][1],
        h[0][2],
        h[1][0],
        h[1][1],
        h[1][2],
        h[2][0],
        h[2][1],
        h[2][2],
      ];
      r.transformLandmark(matrix, 1);
      r.transformAnnotation(matrix, 1);
      r.props.tiles.removeTransformationMatrix(this.fileId);
      r.reset();
      this.rendererDict[this.fileId].props.onChangeChain(
        false,
        this.rendererDict[this.fileId].props.splitscreenIdx,
        this.fileId
      );
      window.showWarningSnackbar("Alignment removed");
    }
  };

  reallocateLandmarkNumbers = (bMarks) => {
    for (let [id, layer] of Object.entries(this.landmarkLayers)) {
      let l = layer.landmarkRois;
      if (bMarks.length === l.length) {
        for (let i = 0; i < l.length; i++) {
          if (i + 1 !== l[i].commentValue) {
            if (l.length > 0) {
              l.sort((a, b) => (a.commentValue > b.commentValue ? 1 : -1));
              for (let i = 0; i < l.length; i++) {
                l[i].commentValue = i + 1;
              }
              this.deletedNumberQ[id] = [];
            }
            break;
          }
        }
      }
    }
  };

  onClickApplyTransformation = () => {
    if (this.splitscreenFileIds.length < 2) {
      window.showWarningSnackbar("No base is present");
      return;
    }
    let baseMarks = [];
    let targetMarks = {};
    this.baseId = null;
    for (let [id, layer] of Object.entries(this.landmarkLayers)) {
      if (layer.landmarkRois.length > 0) {
        let l = layer.landmarkRois;
        if (l[0].color === "#00FF00") {
          //sort computed value
          if (
            this.rendererDict[this.fileId].props.splitscreenFileIds.includes(id)
          ) {
            if (this.baseId !== null) {
              window.showWarningSnackbar("More than one base is present");
              return;
            }
            this.baseId = id;
            let j = 1;
            while (baseMarks.length < l.length) {
              for (let i = 0; i < l.length; i++) {
                if (Number(l[i].commentValue) === j) {
                  baseMarks.push(l[i].regions);
                }
              }
              j++;
            }
          }
        } else if (l[0].color === "#FF0000") {
          if (
            this.rendererDict[this.fileId].props.splitscreenFileIds.includes(id)
          ) {
            //sort computed Value
            targetMarks[id] = [];
            let j = 1;
            while (targetMarks[id].length < l.length) {
              for (let i = 0; i < l.length; i++) {
                if (Number(l[i].commentValue) === j) {
                  targetMarks[id].push(l[i].regions);
                }
              }
              j++;
            }
          }
        }
      }
    }
    this.reallocateLandmarkNumbers(baseMarks);
    this.rendererDict[this.baseId].props.onChangeChain(
      true,
      this.rendererDict[this.baseId].props.splitscreenIdx,
      this.baseId
    );
    for (let [id, points] of Object.entries(targetMarks)) {
      let a = this.rendererDict[id].props.tiles.getTransformationMatrix(
        this.fileId
      );
      if (baseMarks.length !== points.length && baseMarks.length < 4) {
        window.showWarningSnackbar("Unequal amount of base and target marks");
        window.showWarningSnackbar("Please set at least 4 base marks");
        break;
      } else if (baseMarks.length < 4) {
        window.showWarningSnackbar("Please set at least 4 base marks");
        break;
      } else if (baseMarks.length !== points.length) {
        window.showWarningSnackbar("Unequal amount of base and target marks");
        break;
      } else if (Array.isArray(a)) {
        window.showWarningSnackbar(
          "Target already aligned. Remove alignment first"
        );
        break;
      } else {
        this.rendererDict[id].props.onChangeChain(
          true,
          this.rendererDict[id].props.splitscreenIdx,
          id
        );
        this.rendererDict[id].generateLandmarkTransformation(
          baseMarks,
          targetMarks[id],
          this.baseId,
          id,
          this.rendererDict[this.baseId].props.ome
        );
      }
    }
  };

  handleRadioChange = (event) => {
    this.radioValue = event.target.value;
    window.forceSidebarUpdate();
  };

  onClickRemoveAllMarks = () => {
    this.landmarkLayer.landmarkRois = [];
    this.deletedNumberQ[this.fileId] = [];
    if (this.fileId === this.baseId) {
      this.baseId = null;
    }
  };

  exit() {}

  renderConfiguration = () => {
    return (
      <div>
        <Typography variant="h6">{this.name}:</Typography>
        <FormControl component="fieldset">
          <RadioGroup value={this.radioValue} onChange={this.handleRadioChange}>
            <FormControlLabel
              value={"#00FF00"}
              control={<Radio />}
              label="Set landmarks on base"
            />
            <FormControlLabel
              value={"#FF0000"}
              control={<Radio />}
              label="Set landmarks on target"
            />
          </RadioGroup>
        </FormControl>
        <Button
          style={{ marginTop: "5px" }}
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => {
            this.onClickApplyTransformation();
          }}
        >
          Align Image
        </Button>

        <Button
          style={{ marginTop: "5px" }}
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => {
            this.onClickRemoveTransformation();
          }}
        >
          Remove Alignment
        </Button>
        <Button
          style={{ marginTop: "5px" }}
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => {
            this.onClickRemoveAllMarks();
          }}
        >
          Remove landmarks
        </Button>
      </div>
    );
  };
}

export default LandmarkTool;

import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";

import { getParentIndexLayer } from "../../../viewer/utils/StructuresUtils";

import {
  TextField,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Tooltip,
  FormHelperText,
  Slider,
  IconButton,
} from "@mui/material";

import {
  Delete,
  VisibilityOff,
  GetApp,
  Publish,
  Visibility,
  Add,
} from "@mui/icons-material";
import { withAllViewerContexts } from "../../contexts/AllViewerContexts";
import { withTiles } from "../../contexts/TilesContext";
import ListItemSubType from "../ListItemSubType";
import ListItemStructure from "../ListItemStructure";

// define the component's styling
const styles = () => ({
  roiContainer: {
    padding: 20,
    overflowY: "auto",
    width: 355,
  },
  flexVerticalContainer: {
    height: "100%",
    display: "grid",
    gridTemplateRows: "1fr auto",
  },
  flexRowContentHeight: {
    padding: 10,
  },
  flexRowRemainingHeight: {
    overflowY: "auto",
    overflowX: "hidden",
  },
  spacing: {
    padding: 10,
    paddingBottom: 0,
    paddingTop: 0,
  },
});

class SideBarTabRois extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicStructureText: "",
      structuresListHeight: 300,
    };
    this.textInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);
    this.structureRefs = [];
  }

  componentWillUnmount = () => {
    if (this.structureScrollList) {
      this.structureScrollList.removeEventListener(
        "scroll",
        this.handlestructureListScroll
      );
    }
  };

  handlestructureListScroll = () => {
    this.forceUpdate();
  };

  focusTextInput() {
    setTimeout(() => {
      if (this.textInput) {
        this.textInput.select();
      }
    }, 100);
  }

  countVisibleStructures = () => {
    return this.props.structures.reduce(
      (acc, cur) => acc + (cur.visible ? 1 : 0),
      0
    );
  };

  checkToolInConfig(toolName) {
    if (this.props.viewerConfig) {
      if (this.props.viewerConfig.project.toolsInProject[toolName]) {
        return true;
      } else {
        return false;
      }
    }
  }

  addStructure = () => {
    const { roiLayers, projectContext } = this.props;
    const { dynamicStructureText } = this.state;
    // add dynamic structure
    if (dynamicStructureText !== "") {
      projectContext.addStructure(dynamicStructureText);
      this.props.onSelectLayer(roiLayers.length - 1);
      this.focusTextInput();
      this.setState({ dynamicStructureText: "" });
    }
  };

  isInView = (el) => {
    if (
      !this.structureScrollList ||
      this.structureScrollList.getBoundingClientRect().height === 0
    ) {
      this.structureScrollList = document.getElementById(
        "StructuresListContainer"
      );
      if (
        this.structureScrollList &&
        this.structureScrollList.getAttribute("listener") !== "true"
      ) {
        this.structureScrollList.addEventListener(
          "scroll",
          this.handlestructureListScroll
        );
      }
    }
    let result = true;
    if (this.structureScrollList && el) {
      let parentTop = this.structureScrollList.getBoundingClientRect().top;
      let parentHeight =
        this.structureScrollList.getBoundingClientRect().height;
      let elTop = el.getBoundingClientRect().top;
      let elHeight = el.getBoundingClientRect().height;

      result = elTop + elHeight > parentTop && elTop < parentTop + parentHeight;
    }
    return result;
  };

  render() {
    const {
      ome,
      rois,
      activeTool,
      roiLayers,
      selectedLayer,
      structures,
      visible,
      isAdmin,
    } = this.props;
    const { classes, ...propsWithoutClasses } = this.props;

    const { dynamicStructureText } = this.state;

    if (!visible) return null;

    return (
      <Grid
        className={`${classes.tabContent} ${classes.flexRowRemainingHeight} ${classes.flexVerticalContainer}`}
      >
        {activeTool !== "comment" && (
          <div
            style={{
              overflowY: "auto",
              display: "grid",
              gridTemplateRows: "auto 1fr",
              height: "100%",
            }}
          >
            <Grid className={classes.flexRowContentHeight} item>
              <Typography variant="h6">
                {ome ? "Structures (" + roiLayers.length + "):" : "Channels:"}
                <Tooltip disableInteractive title="Clear selected structures">
                  <IconButton
                    disabled={selectedLayer < 0}
                    onClick={() => {
                      let selectedStructure = structures[selectedLayer];
                      let historyItem = roiLayers[
                        selectedLayer
                      ].layer.regionRois.map((roi) => {
                        return {
                          add: false,
                          id: structures[selectedLayer].id,
                          roi: roi,
                        };
                      });
                      window.projectHistory.add(historyItem);
                      roiLayers[selectedLayer].layer.regionRois = [];
                      roiLayers[selectedLayer].tree.clear();
                      if (selectedStructure.isSubtype) {
                        let parentIdx = getParentIndexLayer(
                          selectedStructure,
                          structures
                        );
                        roiLayers[parentIdx].layer.regionRois = roiLayers[
                          parentIdx
                        ].layer.regionRois.filter((regionRoi) => {
                          if (
                            regionRoi.subtypeName === selectedStructure.label
                          ) {
                            roiLayers[parentIdx].tree.remove(
                              regionRoi.treeItem
                            );
                            return false;
                          } else {
                            return true;
                          }
                        });
                      }
                    }}
                    size="large"
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
                {/* {selectedLayer === 0 && (
                  <Tooltip disableInteractive title="Clear selected structures for all scenes">
                    <IconButton
                      disabled={selectedLayer < 0}
                      onClick={() => {
                        this.props.deleteforAllScenes(selectedLayer);
                      }}
                    >
                      <DeleteSweepIcon />
                    </IconButton>
                  </Tooltip>
                )} */}
                <Tooltip
                  disableInteractive
                  title={
                    this.countVisibleStructures() > 0
                      ? "Hide all structures [Shift] + [R]"
                      : "Show all structures [Shift] + [R]"
                  }
                >
                  <IconButton
                    id="toggleAllStructuresBtn"
                    disabled={selectedLayer < 0}
                    onClick={() => {
                      if (this.countVisibleStructures() > 0) {
                        for (let roiLayer of structures) {
                          roiLayer.visible = false;
                        }
                      } else {
                        for (let roiLayer of structures) {
                          roiLayer.visible = true;
                        }
                      }
                      this.forceUpdate();
                    }}
                    size="large"
                  >
                    {this.countVisibleStructures() === 0 ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip disableInteractive title="Export structure tree">
                  <IconButton
                    onClick={this.props.onExportParameters}
                    size="large"
                  >
                    <GetApp />
                  </IconButton>
                </Tooltip>
                <Tooltip disableInteractive title="Import structure tree">
                  <IconButton
                    onClick={() =>
                      document.getElementById("selectFiles").click()
                    }
                    size="large"
                  >
                    <Publish />
                  </IconButton>
                </Tooltip>
              </Typography>
              <input
                type="file"
                id="selectFiles"
                style={{ position: "absolute", top: "-150px" }}
                accept=".json, .strhsa"
                onChange={this.props.onImportParameters}
              />
            </Grid>
            <Grid
              className={classes.flexRowRemainingHeight}
              style={{
                overflowY: "hidden",
                display: "grid",
                gridTemplateRows: "1fr auto",
              }}
            >
              <Grid style={{ overflow: "auto" }}>
                <List
                  id="StructuresListContainer"
                  style={{
                    overflowX: "hidden",
                  }}
                >
                  {structures.map((structure, index) => (
                    <div key={index + 600}>
                      {!structure.isSubtype && (
                        <div
                          ref={(el) => {
                            this.structureRefs[index] = el;
                          }}
                          style={{
                            height: 48,
                            width: "100%",
                          }}
                        >
                          {this.isInView(this.structureRefs[index]) && (
                            <ListItemStructure
                              {...propsWithoutClasses}
                              key={structure.id}
                              structure={structure}
                              index={index}
                              setTextInput={(el) => (this.textInput = el)}
                              isAdmin={isAdmin}
                              renderLocation={"SideBarTabRois"}
                            />
                          )}
                        </div>
                      )}

                      {structure.showSubtypes &&
                        !structure.isSubtype &&
                        structures
                          .filter(
                            (element) =>
                              element.isSubtype && element.subtypeLevel > 0
                          )
                          .map((subType, i) => (
                            <ListItemSubType
                              {...propsWithoutClasses}
                              isInView={this.isInView}
                              key={structure.id + "-" + subType.id}
                              subType={subType}
                              parent={structure}
                              isAdmin={isAdmin}
                              i={i}
                              renderLocation={"SideBarTabRois"}
                            />
                          ))}
                    </div>
                  ))}

                  {this.props.viewerConfig.project.dynamicStructure && (
                    <ListItem
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        paddingLeft: 15,
                      }}
                      key={500 + 400}
                    >
                      <TextField
                        variant="standard"
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                          paddingLeft: 0,
                          width: 375,
                        }}
                        value={dynamicStructureText}
                        onChange={(e) =>
                          this.setState({
                            dynamicStructureText: e.target.value,
                          })
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.addStructure();
                          }
                        }}
                      />
                      <Tooltip disableInteractive title="Add new structure">
                        <IconButton onClick={this.addStructure} size="large">
                          <Add />
                        </IconButton>
                      </Tooltip>
                    </ListItem>
                  )}
                </List>

                <List>
                  {rois.map((roi, index) => (
                    <ListItem
                      button
                      key={index}
                      onClick={() => this.props.onCenterROI(roi)}
                      onMouseEnter={() => this.props.onHoverROI(roi, true)}
                      onMouseLeave={() => this.props.onHoverROI(roi, false)}
                    >
                      <ListItemText
                        primary={"ROI " + index}
                        secondary={roi.summary()}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>

              <Grid className={classes.spacing}>
                <FormHelperText>Opacity:</FormHelperText>
                <Slider
                  style={{ width: "calc(100% - 12px)" }}
                  min={0}
                  max={180}
                  value={this.props.opacity * 100}
                  onChange={(event, newValue) =>
                    this.props.onChangeOpacity(newValue / 100)
                  }
                />
              </Grid>
            </Grid>
          </div>
        )}
      </Grid>
    );
  }
}

// define the component's interface
SideBarTabRois.propTypes = {
  classes: PropTypes.object.isRequired,
  // sidebar tab properties
  visible: PropTypes.bool,
  isAdmin: PropTypes.bool,
  // general properties
  viewerConfig: PropTypes.object,
  id: PropTypes.string.isRequired,
  ome: PropTypes.object,
  // roiLayers
  roiLayers: PropTypes.array,
  selectedLayer: PropTypes.number,
  onSelectLayer: PropTypes.func,
  deleteforAllScenes: PropTypes.func,
  onCenterROI: PropTypes.func,
  onHoverROI: PropTypes.func,
  // tools
  activeTool: PropTypes.string,
  // opacity slider
  opacity: PropTypes.number,
  onChangeOpacity: PropTypes.func,
  // Parameterset Export Import
  onExportParameters: PropTypes.func,
  onImportParameters: PropTypes.func,
  // not ordered
  structures: PropTypes.array,
  projectContext: PropTypes.object,
  rois: PropTypes.array,
};

export default withTiles(
  withAllViewerContexts(withStyles(styles)(SideBarTabRois))
);

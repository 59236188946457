import React, { Component } from "react";
import PropTypes from "prop-types";

import { IconButton, Tooltip, SvgIcon } from "@mui/material";
import { Save } from "@mui/icons-material";

import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
  root: {
    background: "white",
    borderRight: "5px solid  #EBEBEB",
    height: "100%",
    display: "grid",
    gridTemplateRows: "1fr auto",
  },
  toolbarButton: {
    width: 40,
  },
  toolbarButtonChecked: {
    width: 40,
    color: theme.palette.primary.main,
  },
});

// Only the bottom tools, top tools are part of the chart
class SpectraToolBar extends Component {
  render() {
    const { classes, showResultTable } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.topContent}>
          <Tooltip disableInteractive title="Open Result">
            <IconButton
              className={
                showResultTable
                  ? classes.toolbarButtonChecked
                  : classes.toolbarButton
              }
              onClick={this.props.toggleLeftTableContainer}
              size="large"
            >
              <SvgIcon>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="table"
                  className="svg-inline--fa fa-table fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z"
                  ></path>
                </svg>
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </div>
        <div className={classes.bottomContent}>
          <Tooltip disableInteractive title="Save [Ctrl]+[S]">
            <IconButton
              className={classes.toolbarButtonChecked}
              onClick={this.props.onSave}
              size="large"
            >
              <Save />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  }
}

SpectraToolBar.propTypes = {
  classes: PropTypes.object.isRequired,
  showResultTable: PropTypes.bool,
  onSave: PropTypes.func,
  toggleLeftTableContainer: PropTypes.func,
};

export default withStyles(styles)(SpectraToolBar);

import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";

import ScanRenderer from "./components/ScanRenderer";
import ScanToolBar from "./components/ScanToolBar";
import ScanMicroscopeSettings from "./components/ScanMicroscopeSettings";
import { withScanViewerContext } from "./contexts/ScanViewerContext";
import ScanFluorescence from "./components/ScanFluorescence";

const styles = {
  root: {
    overflow: "hidden",
    position: "relative",
    width: "100%",
    height: "calc(100% - 64px)",
    display: "grid",
  },
  rendererContainer: {
    background: "d3d3d3",
  },
  toolBarContainer: {
    background: "white",
    borderLeft: "5px solid #ebebeb",
    borderRight: "5px solid #ebebeb",
  },
  sideBarContainer: {
    background: "#fffff",
    overflow: "hidden",
  },
};

class ScanViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarVisible: true,
    };
  }

  onToggleSidebar = () => {
    if (this.state.sidebarVisible) {
      const w = this.props.scanViewerContext.w + 436;
      const h = this.props.scanViewerContext.h;
      this.props.scanViewerContext.setScanRendererSize(w, h);
    } else {
      const w = this.props.scanViewerContext.w - 436;
      const h = this.props.scanViewerContext.h;
      this.props.scanViewerContext.setScanRendererSize(w, h);
    }
    this.setState({ sidebarVisible: !this.state.sidebarVisible });
  };

  render() {
    const { classes } = this.props;
    const { streamReady, sideBarContent } = this.props.scanViewerContext;
    return (
      <div
        className={classes.root}
        style={{
          gridTemplateColumns: this.state.sidebarVisible
            ? "1fr 50px 436px"
            : "1fr 50px 0px",
        }}
      >
        <div className={classes.rendererContainer}>
          {streamReady && <ScanRenderer />}
        </div>
        <div className={classes.toolBarContainer}>
          <ScanToolBar
            onToggleSidebar={this.onToggleSidebar}
            sidebarVisible={this.state.sidebarVisible}
          />
        </div>
        <div className={classes.sideBarContainer}>
          {sideBarContent === "fluorescence" ? (
            <ScanFluorescence />
          ) : (
            <ScanMicroscopeSettings />
          )}
        </div>
      </div>
    );
  }
}

ScanViewer.propTypes = {
  classes: PropTypes.object.isRequired,
  scanViewerContext: PropTypes.object.isRequired,
};

export default withScanViewerContext(withStyles(styles)(ScanViewer));

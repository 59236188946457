import {
  updateDrawLayer,
  updateLayer,
  findSiblingRoiLayers,
  findSameLayer,
  findClickedRoi,
  lineArrayBuffer,
  distance,
} from "../../utils/PolygonUtil";
import { Button } from "@mui/material";
import { Typography, FormControl, FormLabel, Slider } from "@mui/material";
import Tool from "./Tool";
import React from "react";
import OverlapConfigForm from "./ConfigForms/OverlapConfigForm";

class RegionGrabCutTool extends Tool {
  toolName = "Region Grab Cut";
  noConfig = false;
  flag = false;
  removeOverlap = null;
  removeOverlapSame = null;
  tempRemoveOverlap = null;
  tempRemoveOverlapSame = null;
  threshold = 30;
  penRadius = 5;
  penOpacity = 1;
  minPenRadius = 1;
  maxPenRadius = 50;
  line = [];
  lines = [];
  linePoly = {};
  matline = [];

  setLayer(obj) {
    this.ome = obj.ome;
    this.isBrightfield = obj.ome.channels[0].type === "brightfield";
    this.ctx = obj.ctx; //context
    this.canvas = obj.ctx.canvas; //canvas
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    let layerResults = findSameLayer(obj.structures, obj.selectedLayer);
    this.selectedLayer = layerResults[0];
    this.parentLayer = layerResults[1];
    this.originalSelectedLayer = obj.selectedLayer;
    this.structures = obj.structures;
    this.drawLayer = obj.drawLayer;

    const project = obj.viewerConfig_project;
    if (this.removeOverlap === null) {
      this.removeOverlap = project.projectProperties["PreventOverlap"];
    }
    if (this.tempRemoveOverlap === null) {
      this.tempRemoveOverlap = project.projectProperties["PreventOverlap"];
    }
    if (this.removeOverlapSame === null) {
      this.removeOverlapSame = project.projectProperties["PreventOverlapSame"];
    }

    // set removeOverlap
    // save current settings (in tempRemoveOverlap) if structure gets changed
    if (obj.selectedLayer === 0) {
      if (!this.noConfig) {
        this.noConfig = true;
        this.tempRemoveOverlap = this.removeOverlap;
        this.tempRemoveOverlapSame = this.removeOverlapSame;
        this.removeOverlap = false;
        this.removeOverlapSame = false;
        window.forceSidebarUpdate();
      }
    } else {
      if (this.noConfig) {
        this.noConfig = false;
        this.removeOverlap = this.tempRemoveOverlap;
        this.removeOverlapSame = this.tempRemoveOverlapSame;
        window.forceSidebarUpdate();
      }
    }
  }

  setPreviewRect() {}

  getPosition = () => {
    return {
      x: -this.ctx.getTransform().e,
      y: -this.ctx.getTransform().f,
    };
  };

  fitPointToImage = (p) => {
    if (this.ctx) {
      this.scale = this.ctx.getTransform().a;
    }
    return {
      x: parseInt(p.x * this.scale - this.getPosition().x, 10),
      y: parseInt(p.y * this.scale - this.getPosition().y, 10),
    };
  };

  getPolygonsWithOpenCV = () => {
    let p1 = this.fitPointToImage(this.startPoint);
    let p2 = this.fitPointToImage(this.endPoint);
    let tlp = { x: Math.min(p1.x, p2.x), y: Math.min(p1.y, p2.y) };

    let w = Math.abs(p1.x - p2.x);
    let h = Math.abs(p1.y - p2.y);

    const cv = window.cv;
    let ctx = this.canvas.getContext("2d");
    let imageData = ctx.getImageData(
      0,
      0,
      this.canvas.width,
      this.canvas.height
    );

    let resultImageData = imageData;
    let src = cv.matFromImageData(resultImageData);
    let rect1 = new cv.Rect(tlp.x, tlp.y, w, h);
    src = src.roi(rect1);
    let resizeMultiplier = 200 / w;
    if (resizeMultiplier > 1) {
      resizeMultiplier = 1;
    }
    let originalSize = new cv.Size(w, h);
    w = parseInt(w * resizeMultiplier, 10);
    h = parseInt(h * resizeMultiplier, 10);
    let workingSize = new cv.Size(w, h);
    cv.resize(src, src, workingSize, 0, 0, cv.INTER_AREA);

    let rect = new cv.Rect(2, 2, w - 4, h - 4);
    let point1 = new cv.Point(rect.x, rect.y);
    let point2 = new cv.Point(rect.x + rect.width, rect.y + rect.height);

    cv.cvtColor(src, src, cv.COLOR_RGBA2RGB, 0);
    let mask = cv.Mat.zeros(h, w, cv.CV_8U);
    let obvFgColor = new cv.Scalar(cv.GC_FGD);
    let obvBgColor = new cv.Scalar(cv.GC_BGD);
    let posFgColor = new cv.Scalar(cv.GC_PR_FGD);
    let fgP1 = new cv.Point(parseInt(w / 2, 10) - 10, parseInt(h / 2, 10) - 10);
    let fgP2 = new cv.Point(parseInt(w / 2, 10) + 10, parseInt(h / 2, 10) + 10);
    //todo: another rect with obv bg - spectated rect
    cv.rectangle(mask, point1, point2, posFgColor, -1); // whole image (todo: drawn  rect)
    cv.rectangle(mask, fgP1, fgP2, obvFgColor, -1); //small rectangle in the middle
    //todo draw obv bg / draw obv fg

    let wDrawn = Math.abs(this.startPoint.x - this.endPoint.x);
    let scale = wDrawn / w;

    for (let p of this.matline) {
      let point = this.fitPointToImage({ x: p.x, y: p.y });
      let center = new cv.Point(
        (point.x - tlp.x) * resizeMultiplier,
        (point.y - tlp.y) * resizeMultiplier
      );
      let color = p.color == "#ff0000" ? obvBgColor : obvFgColor;
      cv.circle(mask, center, parseInt(this.penRadius / scale, 10), color, -1);
    }

    let bgdModel = new cv.Mat();
    let fgdModel = new cv.Mat();
    cv.grabCut(src, mask, rect, bgdModel, fgdModel, 1, cv.GC_INIT_WITH_MASK);
    // // draw foreground
    for (let i = 0; i < src.rows; i++) {
      for (let j = 0; j < src.cols; j++) {
        if (mask.ucharPtr(i, j)[0] == 0 || mask.ucharPtr(i, j)[0] == 2) {
          src.ucharPtr(i, j)[0] = 0;
          src.ucharPtr(i, j)[1] = 0;
          src.ucharPtr(i, j)[2] = 0;
        } else {
          src.ucharPtr(i, j)[0] = 255;
          src.ucharPtr(i, j)[1] = 255;
          src.ucharPtr(i, j)[2] = 255;
        }
      }
    }

    // // show mask for debuggins
    // cv.imshow("outputCanvas", testmask);

    cv.resize(src, src, originalSize, 0, 0, cv.INTER_AREA);
    cv.cvtColor(src, src, cv.COLOR_RGBA2GRAY, 0);

    cv.threshold(src, src, 0, 200, cv.THRESH_BINARY);
    let contours = new cv.MatVector();
    let hierarchy = new cv.Mat();

    cv.findContours(
      src,
      contours,
      hierarchy,
      cv.RETR_CCOMP,
      cv.CHAIN_APPROX_SIMPLE
    );

    let polygons = [];
    if (this.ctx) {
      this.scale = this.ctx.getTransform().a;
    }
    for (let i = 0; i < contours.size(); ++i) {
      let cnt = contours.get(i);
      if (cv.contourArea(cnt) < 100) continue;
      let polygon = [];
      // console.log(cnt);
      // let counter = 0;
      for (let i = 0; i < cnt.rows; i++) {
        let point = new cv.Point(cnt.data32S[i * 2], cnt.data32S[i * 2 + 1]);
        polygon.push([
          (tlp.x + point.x + this.getPosition().x) / this.scale,
          (tlp.y + point.y + this.getPosition().y) / this.scale,
        ]);
      }
      polygons.push(polygon);
    }

    // cleanup
    mask.delete();
    bgdModel.delete();
    fgdModel.delete();
    src.delete();

    return polygons;
    // return [];
  };

  removeCenteredObject = () => {
    if (this.startPoint === null || this.endPoint === null) return;
    let centerPoint = {
      x: parseInt((this.startPoint.x + this.endPoint.x) / 2),
      y: parseInt((this.startPoint.y + this.endPoint.y) / 2),
    };
    let centeredRoi = findClickedRoi(
      centerPoint,
      this.selectedLayer,
      this.structures,
      this.roiLayers,
      this.includeBaseROI
    );
    let roiIdx = this.roiLayers[this.selectedLayer].layer.regionRois.findIndex(
      (roi) => roi.uuid === centeredRoi.uuid
    );
    if (roiIdx >= 0) {
      this.roiLayers[this.selectedLayer].layer.regionRois.splice(roiIdx, 1);
      this.roiLayers[this.selectedLayer].tree.clear();
      this.roiLayers[this.selectedLayer].tree.load(
        this.roiLayers[this.selectedLayer].layer.regionRois.map(
          (item) => item.treeItem
        )
      );
    }
  };

  addPoint = (event, p) => {
    let eventPoint = { x: event.clientX, y: event.clientY };
    if (this.line.length < 3) {
      this.line.push([p.x, p.y]);
      this.lastEventPoint = eventPoint;
    } else {
      const dist = distance(this.lastEventPoint, eventPoint);
      if (dist < 10) {
        this.line[this.line.length - 1] = [p.x, p.y];
      } else {
        this.line.push([p.x, p.y]);
        this.lastEventPoint = eventPoint;
      }
    }
  };

  updateDrawing = () => {
    this.renderer.drawVisibleImage();

    this.polygons = this.getPolygonsWithOpenCV();

    this.drawLayer.regionRois = []; //only one region will be drawn to the draw layer
    this.drawRegion = {
      regions: this.polygons.map((polygon) => {
        return polygon;
      }),
      inverted: false,
    };
    this.drawLayer.clear = this.clear;
    updateDrawLayer(
      this.drawLayer,
      this.drawRegion,
      false,
      this.color,
      this.subtype,
      this.name
    );
  };

  getTopLeftPoint = () => {
    return {
      x: Math.min(this.startPoint.x, this.endPoint.x),
      y: Math.min(this.startPoint.y, this.endPoint.y),
    };
  };

  getBottomRightPoint = () => {
    return {
      x: Math.max(this.startPoint.x, this.endPoint.x),
      y: Math.max(this.startPoint.y, this.endPoint.y),
    };
  };

  mouse(params) {
    try {
      let {
        event,
        p,
        color,
        subtype,
        name,
        positionInRoiLayer,
        fullyLoaded,
        renderer,
      } = params;
      this.color = color;
      this.subtype = subtype;
      this.name = name;
      this.positionInRoiLayer = positionInRoiLayer;
      this.fullyLoaded = fullyLoaded;
      this.renderer = renderer;
      if (
        event.type === "mousedown" &&
        (event.button === 0 || event.button === 2)
      ) {
        if (this.isPointOutsideRect(p)) {
          this.flag = true;
          this.startPoint = p;
          this.endPoint = p;
          this.lines = [];
        } else if (!this.flag) {
          this.lineFlag = true;
          this.obvColor = event.button === 0 ? "#00ff00" : "#ff0000";
          this.line = [[p.x, p.y]];
        }
      } else if (event.type === "mousemove" && (this.flag || this.lineFlag)) {
        if (this.isDrawingpointInsideRect(p) && this.lineFlag) {
          this.matline.push({
            x: p.x,
            y: p.y,
            color: this.obvColor,
            rad: this.penRadius,
          });
          this.addPoint(event, p);
          this.linePoly = {
            l: lineArrayBuffer(
              this.line,
              this.penRadius,
              this.ome.sizeX,
              this.ome.sizeY
            ),
            color: this.obvColor,
          };
        } else if (!this.lineFlag) {
          this.endPoint = p;
        }
      } else if (
        event.type === "mouseup" ||
        ((this.flag || this.lineFlag) && event.type === "mouseleave")
      ) {
        if (this.isPointOutsideRect(p) && this.lineFlag) {
          this.lineFlag = false;
          this.linePoly = {};
        } else if (this.isDrawingpointInsideRect(p)) {
          this.lineFlag = false;
          this.addPoint(event, p);
          this.linePoly = {
            l: lineArrayBuffer(
              this.line,
              this.penRadius,
              this.ome.sizeX,
              this.ome.sizeY
            ),
            color: this.obvColor,
          };
          this.lines.push(this.linePoly);
          this.linePoly = {};
          this.line = [];
          try {
            this.updateDrawing();
          } catch (e) {
            console.log("error:", e);
            window.showWarningSnackbar(
              "Error, To Close! Rectangle Not Visible"
            );
            let tlp = this.getTopLeftPoint();
            let w = Math.abs(this.startPoint.x - this.endPoint.x);
            let h = Math.abs(this.startPoint.y - this.endPoint.y);
            let rect = {
              left: tlp.x + w * 0.25,
              top: tlp.y + h * 0.25,
              right: tlp.x + w - w * 0.25,
              bottom: tlp.y + h - h * 0.25,
            };
            window.zoomToRect(rect);
            window.forceSidebarUpdate();
          }
        } else if (this.flag) {
          this.clear = event.button === 2;
          this.endPoint = p;
          this.flag = false;
          try {
            this.updateDrawing();
          } catch (e) {
            console.log("error:", e);
            window.showWarningSnackbar("Error, Rectangle To Small");
            this.drawLayer.regionRois = [];
          }
        }
      }
    } catch (e) {
      console.log("error:", e);
      window.showWarningSnackbar("Error, try again!");
    }
  }

  isPointOutsideRect = (p) => {
    let isOutside = true;
    if (this.startPoint && this.endPoint) {
      let tlp = this.getTopLeftPoint();
      let brp = this.getBottomRightPoint();
      isOutside = p.x < tlp.x || p.x > brp.x || p.y < tlp.y || p.y > brp.y;
    }
    return isOutside;
  };

  isDrawingpointInsideRect = (p) => {
    let isInside = false;
    if (this.startPoint && this.endPoint) {
      let tlp = this.getTopLeftPoint();
      let brp = this.getBottomRightPoint();
      isInside =
        p.x - this.penRadius > tlp.x &&
        p.x + this.penRadius < brp.x &&
        p.y - this.penRadius > tlp.y &&
        p.y + this.penRadius < brp.y;
    }
    return isInside;
  };

  drawCustomCursor(ctx, mousePosition) {
    if (this.startPoint && this.endPoint) {
      const ctx = this.ctx;
      let topLeftPoint = this.getTopLeftPoint();
      let w = Math.abs(this.startPoint.x - this.endPoint.x);
      let h = Math.abs(this.startPoint.y - this.endPoint.y);
      if (ctx) {
        let scale = ctx.getTransform().a;
        ctx.beginPath();
        ctx.globalAlpha = 1.0;
        ctx.strokeStyle = "#0673C1";
        ctx.lineWidth = 4 / scale;
        ctx.rect(topLeftPoint.x, topLeftPoint.y, w, h);
        ctx.stroke();
        ctx.strokeStyle = "#ffffff";
        ctx.lineWidth = 2 / scale;
        ctx.rect(topLeftPoint.x, topLeftPoint.y, w, h);
        ctx.stroke();
        ctx.closePath();

        ctx.beginPath();
        if (Object.keys(this.linePoly).length > 0) {
          for (let points of this.linePoly.l.geometry.coordinates) {
            ctx.fillStyle = this.linePoly.color;
            ctx.globalAlpha = 1.0;
            if (points[points.length - 1]) {
              ctx.moveTo(
                points[points.length - 1][0],
                points[points.length - 1][1]
              );
              for (let i = 0; i < points.length; i++) {
                ctx.lineTo(points[i][0], points[i][1]);
              }
            }
          }
        }
        ctx.fill();
        ctx.closePath();

        if (this.lines.length > 0) {
          for (let line of this.lines) {
            ctx.beginPath();
            for (let points of line.l.geometry.coordinates) {
              ctx.fillStyle = line.color;
              ctx.globalAlpha = this.penOpacity;
              if (points[points.length - 1]) {
                ctx.moveTo(
                  points[points.length - 1][0],
                  points[points.length - 1][1]
                );
                for (let i = 0; i < points.length; i++) {
                  ctx.lineTo(points[i][0], points[i][1]);
                }
              }
            }
            ctx.fill("evenodd");
            ctx.closePath();
          }
        }

        ctx.globalAlpha = 1.0;
      }
    }

    if (this.isPointOutsideRect(mousePosition)) {
      ctx.beginPath();
      ctx.globalAlpha = 1.0;
      ctx.strokeStyle = this.isBrightfield ? "#000000" : "#ffffff";
      ctx.beginPath();
      ctx.moveTo(mousePosition.x, 0);
      ctx.lineTo(mousePosition.x, 1000000);
      ctx.moveTo(0, mousePosition.y);
      ctx.lineTo(1000000, mousePosition.y);
      ctx.stroke();
      ctx.closePath();
    } else if (this.isDrawingpointInsideRect(mousePosition)) {
      ctx.beginPath();
      ctx.globalAlpha = 1.0;
      ctx.strokeStyle = this.isBrightfield ? "#000000" : "#ffffff";
      ctx.beginPath();
      ctx.arc(mousePosition.x, mousePosition.y, this.penRadius, 0, 2 * Math.PI);
      ctx.stroke();
      ctx.closePath();
    }
  }

  onKeyDown(e) {
    // enter key
    if (e.code === "Enter") {
      this.onApply();
    }
    let delta = Math.max(1, parseInt(this.penRadius * 0.05, 10));
    if (e.code === "KeyS") {
      this.penRadius -= delta;
    } else if (e.code === "KeyW") {
      this.penRadius += delta;
    }
    if (this.penRadius < this.minPenRadius) {
      this.penRadius = this.minPenRadius;
    }
    if (this.penRadius > this.maxPenRadius) {
      this.penRadius = this.maxPenRadius;
    }
    window.forceSidebarUpdate();
  }

  onApply = () => {
    if (!this.clear) {
      this.removeCenteredObject();
    }

    this.drawRegion.regions = [];
    if (this.drawLayer.regionRois.length > 0) {
      this.drawRegion.regions = this.drawLayer.regionRois;

      // soll der selected layer sein
      let overlapRoiLayers = [];
      if (this.removeOverlapSame) {
        overlapRoiLayers.push(this.roiLayers[this.selectedLayer]);
      }
      if (this.removeOverlap) {
        let siblingRoiLayers = findSiblingRoiLayers(
          this.structures,
          this.selectedLayer,
          this.roiLayers
        );
        siblingRoiLayers.map((layer) => overlapRoiLayers.push(layer));
      }

      updateLayer(
        this.layer,
        this.drawRegion,
        this.clear,
        this.color,
        this.subtype,
        this.name,
        this.roiLayers[this.selectedLayer].tree,
        this.positionInRoiLayer,
        this.fullyLoaded,
        false,
        this.roiLayers[this.parentLayer],
        this.structures[this.originalSelectedLayer].id,
        overlapRoiLayers,
        this.clickedOnRoi
      );
      this.drawLayer.regionRois = [];
    }
    this.resetViuals();
  };

  resetViuals = () => {
    this.lines = [];
    this.linePoly = {};
    this.matline = [];
    this.startPoint = null;
    this.endPoint = null;
  };

  exit() {}

  renderConfiguration() {
    return (
      <div style={{ margin: "12px" }}>
        {/* canvas for debugging */}
        {/* <canvas id="outputCanvas"></canvas> */}
        <Typography variant="h6">{this.toolName}:</Typography>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">
            {"Threshold: " + this.threshold}
          </FormLabel>
          <Slider
            min={1}
            max={50}
            value={this.threshold}
            onChange={(e, v) => {
              this.threshold = v;
              window.forceSidebarUpdate();
            }}
            onChangeCommitted={() => {
              this.updateDrawing();
            }}
          />
        </FormControl>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">
            {"Line thickness = " + this.penRadius + " (smaller: S, wider: W)"}
          </FormLabel>
          <Slider
            min={this.minPenRadius}
            max={this.maxPenRadius}
            value={this.penRadius}
            onChange={(e, v) => {
              this.penRadius = v;
              window.forceSidebarUpdate();
            }}
          />
        </FormControl>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">
            {"Line opacity = " + this.penOpacity}
          </FormLabel>
          <Slider
            min={1}
            max={10}
            value={this.penOpacity * 10}
            onChange={(e, v) => {
              this.penOpacity = v / 10;
              window.forceSidebarUpdate();
            }}
          />
        </FormControl>
        <OverlapConfigForm
          removeOverlap={this.removeOverlap}
          removeOverlapSame={this.removeOverlapSame}
          onChangeRemoveOverlap={(e) => (this.removeOverlap = e)}
          onChangeRemoveOverlapSame={(e) => (this.removeOverlapSame = e)}
        />
        <Button
          disabled={this.drawLayer.regionRois.length === 0}
          style={{ marginTop: "5px" }}
          fullWidth
          variant="contained"
          color="primary"
          onClick={this.onApply}
        >
          Apply [Enter]
        </Button>
      </div>
    );
  }
}

export default RegionGrabCutTool;

// ... App.js

import React, { Component } from "react";

import PropTypes from "prop-types";
import { withSpinloader } from "../../common/components/Spinloader";

// 3D-Viewer Libraries
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import ViewHelper from "./3DViewHelper";

import withStyles from "@mui/styles/withStyles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "@mui/material";

// Custom Components
import Backend from "../../common/utils/Backend";

const styles = {
  root: {
    pointerEvents: "none",
    width: "auto",
    maxWidth: "600px",
    height: 46,

    position: "absolute",
    top: 10,
    left: 5,
    color: "#ffffff",

    padding: 0,
    "& :active": {
      outline: "",
    },
    background: "#000000AA",
    border: "2px solid rgb(85, 85, 85)",
    borderRadius: 10,
  },
  barIcon: {
    position: "absolute",
    top: 2,
    left: 0,
    height: 36,
    width: "36px!important",
    padding: 8,
    color: "#ffffff",
  },
  infoArea: {
    paddingLeft: "40px",
    paddingRight: "10px",
    right: 0,
    top: 0,
    bottom: 0,
    color: "#fff",
  },
};

class Viewer3D extends Component {
  constructor(props) {
    super(props);

    if (props.componentRef) props.componentRef(this);
    this.state = {
      width: 800,
      height: 800,
      viewHelperSize: 128,
      mode: "Loading",
      status: "Loading",
    };
    this.scene = new THREE.Scene();
    this.renderer3D = new THREE.WebGLRenderer({ antialias: true });
    this.camera = new THREE.PerspectiveCamera(75, 800 / 800, 0.1, 1000);
  }

  componentDidMount = () => {
    const container = document.getElementById("3dViewerContainer");
    let width = container.offsetWidth;
    let height = container.offsetHeight;
    this.setState({ width });
    this.setState({ height });

    this.props.spinloader.show(20);

    //Config Renderer
    this.renderer3D.setClearColor("#263238");
    this.renderer3D.setSize(width, height);
    this.renderer3D.outputEncoding = THREE.sRGBEncoding;
    this.renderer3D.toneMappingExposure = 1.0;
    this.mount.appendChild(this.renderer3D.domElement);
    //Config Camera
    this.camera.position.set(0, 0, 500);
    this.camera.lookAt(0, 0, 0);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    // add Light to camera and scene
    let color = 0xffffff;
    let intensity = 1;
    let light = new THREE.DirectionalLight(color, intensity);
    light.position.set(0.5, 0, 0.866);
    this.camera.add(light);
    this.scene.add(this.camera);

    color = 0x222222;
    light = new THREE.AmbientLight(color, intensity);
    this.scene.add(light);

    //Camera Controls
    this.controls = new OrbitControls(this.camera, this.renderer3D.domElement);
    //LIGHTS

    // add GridHelper
    // const size = 100;
    // const divisions = 20;
    // const gridHelper = new THREE.GridHelper(size, divisions);
    // this.scene.add(gridHelper);

    // Get variables for analysis
    const fileId = this.props.fileId;
    const projectId = this.props.projectId;
    const minZ = this.props.minZ;
    const maxZ = this.props.maxZ;
    const zRange = minZ + "," + maxZ;
    const showPointCloud = this.props.showPointCloud;

    const data = {
      fileId,
      projectId,
      zRange,
      showPointCloud,
    };

    // Render Scene
    this.renderScene();
    //start animation
    this.start();

    // Adding 3D-Models
    let res = Backend.get3dObjects(data);
    const gltfLoader = new GLTFLoader();

    gltfLoader.load(
      // "./assets3d/Horse.glb",
      res,
      (gltf) => {
        const box = new THREE.Box3().setFromObject(gltf.scene);
        const center = box.getCenter(new THREE.Vector3());

        gltf.scene.position.x += gltf.scene.position.x - center.x;
        gltf.scene.position.y += gltf.scene.position.y - center.y;
        gltf.scene.position.z += gltf.scene.position.z - center.z;

        gltf.scene.name = "object_scene";

        this.scene.add(gltf.scene);
        this.renderScene();
        this.setState({ status: "" });
        this.props.spinloader.hide();
      },
      // called while loading is progressing
      () => {},
      // called when loading has errors
      (error) => {
        console.log("An error happened");
        console.log(error);
        this.setState({ status: "ERROR", mode: "ERROR" });
        this.props.spinloader.hide();
      }
    );

    Backend.get3DAllObjectsExists(data, (res) => {
      if (res === "True") {
        this.setState({ mode: "Job results" });
      } else {
        this.setState({ mode: "Histogram Preview" });
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.showPointCloud !== this.props.showPointCloud) {
      this.load3DObject(this.props.showPointCloud);
    }
  }

  load3DObject = (showPointCloud) => {
    this.props.spinloader.show(20);

    let selectedObject = this.scene.getObjectByName("object_scene");
    this.scene.remove(selectedObject);
    console.log("showProintCloud", showPointCloud);

    // Get variables for analysis
    const fileId = this.props.fileId;
    const projectId = this.props.projectId;
    const minZ = this.props.minZ;
    const maxZ = this.props.maxZ;
    const zRange = minZ + "," + maxZ;

    const data = {
      fileId,
      projectId,
      zRange,
      showPointCloud,
    };

    // Adding 3D-Models
    let res = Backend.get3dObjects(data);
    const gltfLoader = new GLTFLoader();

    gltfLoader.load(
      // "./assets3d/Horse.glb",
      res,
      (gltf) => {
        const box = new THREE.Box3().setFromObject(gltf.scene);
        const center = box.getCenter(new THREE.Vector3());

        gltf.scene.position.x += gltf.scene.position.x - center.x;
        gltf.scene.position.y += gltf.scene.position.y - center.y;
        gltf.scene.position.z += gltf.scene.position.z - center.z;

        gltf.scene.name = "object_scene";

        this.scene.add(gltf.scene);
        this.renderScene();
        this.setState({ status: "" });
        this.props.spinloader.hide();
      },
      // called while loading is progressing
      () => {},
      // called when loading has errors
      (error) => {
        console.log("An error happened");
        console.log(error);
        this.setState({ status: "ERROR", mode: "ERROR" });
        this.props.spinloader.hide();
      }
    );
  };

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  };

  stop = () => {
    cancelAnimationFrame(this.frameId);
  };

  animate = () => {
    //Animate Models Here
    //ReDraw Scene with Camera and Scene Object
    this.renderScene();
    this.frameId = window.requestAnimationFrame(this.animate);
  };

  renderScene = () => {
    if (this.renderer3D) {
      this.renderer3D.render(this.scene, this.camera);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div id="3dViewerContainer" style={{ width: "100%", height: "100%" }}>
        <ViewHelper
          viewHelperSize={this.state.viewHelperSize}
          camera={this.camera}
        />

        <div className={classes.root}>
          <FontAwesomeIcon className={classes.barIcon} icon={faInfo} />
          <div className={classes.infoArea}>
            <Typography color="inherit" noWrap={true}>
              {this.state.mode}
            </Typography>
            <Typography color="inherit" noWrap={true} style={{ fontSize: 8 }}>
              {this.state.status}
            </Typography>
          </div>
        </div>

        <div ref={(ref) => (this.mount = ref)} />
      </div>
    );
  }
}

// define the component's interface
Viewer3D.propTypes = {
  classes: PropTypes.object.isRequired,
  fileId: PropTypes.string,
  ome: PropTypes.object,
  projectId: PropTypes.string,
  minZ: PropTypes.number,
  maxZ: PropTypes.number,
  showPointCloud: PropTypes.bool,
  componentRef: PropTypes.object,
  spinloader: PropTypes.object,
};

// const rootElement = document.getElementById("root")
// ReactDOM.render(<Viewer3D />, rootElement);
export default withSpinloader(withStyles(styles)(Viewer3D));

import React, { Component } from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

import withStyles from "@mui/styles/withStyles";
import withTheme from "@mui/styles/withTheme";

import { trackTransforms } from "../utils/CanvasUtil";
import DragIndicator from "@mui/icons-material/DragIndicator";
import Draggable from "react-draggable";

import { withTiles } from "../contexts/TilesContext";

const styles = {
  root: {
    position: "absolute",
    left: 5,
    height: 170,
    width: 220,
    border: "2px solid rgb(85, 85, 85)",
    opacity: 0.8,
  },
  rootsidebar: {
    height: 170,
    width: 220,
    border: "2px solid rgb(85, 85, 85)",
    opacity: 0.8,
  },
  canvas: {
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: "#000000",
  },
  dragIndicator: {
    color: "#fff",
    position: "absolute",
    top: 5,
    right: 5,
    cursor: "grab",
  },
  grabbing: {
    cursor: "grabbing",
  },
};

class MiniMap extends Component {
  constructor(props) {
    super(props);
    if (props.componentRef) props.componentRef(this);

    this.state = {
      grabbing: false,
    };
  }

  componentDidMount = () => {
    this.initialized = false;
    // add zoom with mouse wheel
    this.canvas.addEventListener("mousewheel", (e) => this.mousewheel(e), {
      passive: false,
    });

    // get drawing context from canvas
    this.ctx = this.canvas.getContext("2d");

    // extend context with some fancy additional transformation methods
    trackTransforms(this.ctx);

    // converts the relative size css information into an absolute size which we can access
    this.width = this.canvas.width = this.canvas.offsetWidth;
    this.height = this.canvas.height = this.canvas.offsetHeight;

    // get bounds for correct minimap positionining
    let bounds = this.root.getBoundingClientRect();
    if (this.props.inSideBar) {
      bounds.x = 0;
      bounds.y = 0;
    }
    this.setState({
      x: bounds.x,
      y: bounds.y,
    });
  };

  componentWillUnmount() {
    this.canvas.removeEventListener("mousewheel", (e) => this.mousewheel(e));
  }

  componentDidUpdate() {
    // redraw image when the component updates
    if (!this.initialized) {
      setTimeout(() => {
        this.draw();
      }, 500);
    } else if (this.initialized) {
      this.draw();
    }
    this.draw();
  }

  // zoom out to show the whole image
  zoomOut() {
    // center image first
    let pt = this.ctx.transformedPoint(
      (this.canvas.width - this.vw) / 2,
      (this.canvas.height - this.vh) / 2
    );
    this.ctx.translate(pt.x, pt.y);
    // calc our transformation offset
    pt = this.ctx.transformedPoint(
      this.canvas.width / 2,
      this.canvas.height / 2
    );
    // move to origin
    this.ctx.translate(pt.x, pt.y);
    // calc scale factor so we can fit the whole image on our screen
    var factor = Math.min(
      this.canvas.height / this.vh,
      this.canvas.width / this.vw
    );
    // perform scaling
    this.ctx.scale(factor, factor);
    // restore our offset
    this.ctx.translate(-pt.x, -pt.y);
  }

  draw() {
    // nothing to draw if we haven't loaded our meta data yet
    if (!this.props.ome || this.canvas === null) return;

    // Clear the entire canvas
    var p1 = this.ctx.transformedPoint(0, 0);
    var p2 = this.ctx.transformedPoint(this.canvas.width, this.canvas.height);
    this.ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
    this.ctx.save();
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.restore();

    let lv = 0,
      x = 0,
      y = 0;
    for (let c = 0; c < this.props.histogramConfig.channels.length; c++) {
      let channel = this.props.histogramConfig.channels[c];
      // skip disabled channels
      if (!channel.enabled) continue;

      let page = this.props.getPageForChannel(c)
        ? this.props.getPageForChannel(c)
        : 0;

      // load image first if missing
      let tileId =
        page + "," + lv + "," + x + "," + y + "," + this.props.fileId;
      let img = this.props.tiles.getVisibleImage(tileId);
      if (img) {
        // image is loaded -> draw it
        let img = this.props.tiles.getVisibleImage(tileId);
        this.imgLoaded = img.width > 0;
        if (this.imgLoaded) {
          this.vw = this.props.ome.sizeX;
          this.vh = this.props.ome.sizeY;

          let coloredImg = this.props.tiles.getColoredImage(tileId);

          if (coloredImg) {
            // composition should be difference for fluorescence
            // see https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/globalCompositeOperation
            this.ctx.globalCompositeOperation = "screen";
            // draw colored image layer
            if (
              Object.prototype.toString.call(coloredImg) ===
                "[object HTMLCanvasElement]" &&
              this.props.tiles.getColoredImage(tileId)
            ) {
              this.ctx.drawImage(
                coloredImg,
                x * this.vw,
                y * this.vh,
                this.vw,
                this.vh
              );
            }
          }
        }
        this.ctx.setTransform(1, 0, 0, 1, 0, 0);
        this.zoomOut();
        this.initialized = true;
      }
    }

    let mainCanvas = this.props.canvas;

    let screenX = this.props.position.x / this.props.zoom;
    let screenY = this.props.position.y / this.props.zoom;
    let screenW = mainCanvas.width / this.props.zoom;
    let screenH = mainCanvas.height / this.props.zoom;

    // offset of minimap in view
    let offsetX = -this.ctx.getTransform().e / this.ctx.getTransform().a;
    let offsetY = -this.ctx.getTransform().f / this.ctx.getTransform().a;

    // back to default overlay composition operation
    // see https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/globalCompositeOperation
    this.ctx.globalCompositeOperation = "source-over";

    // draw screen region
    this.ctx.lineWidth = 2 / this.ctx.getTransform().a;
    this.ctx.strokeStyle = this.props.theme.palette.primary.main;
    this.ctx.beginPath();
    this.ctx.rect(screenX, screenY, screenW, screenH);
    this.ctx.moveTo(screenX + screenW / 2, screenY);
    this.ctx.lineTo(screenX + screenW / 2, offsetY);
    this.ctx.moveTo(screenX + screenW / 2, screenY + screenH);
    this.ctx.lineTo(
      screenX + screenW / 2,
      this.canvas.height / this.ctx.getTransform().a
    );
    this.ctx.moveTo(offsetX, screenY + screenH / 2);
    this.ctx.lineTo(screenX, screenY + screenH / 2);
    this.ctx.moveTo(screenX + screenW, screenY + screenH / 2);
    this.ctx.lineTo(
      this.canvas.width / this.ctx.getTransform().a,
      screenY + screenH / 2
    );
    this.ctx.stroke();
  }

  // returns the current zoom scale
  getScale() {
    return this.ctx.getTransform().a;
  }

  // returns position of our image
  getPosition() {
    return {
      x: -this.ctx.getTransform().e,
      y: -this.ctx.getTransform().f,
    };
  }

  getCtx = () => {
    let c = this.ctx;
    return c;
  };

  miniMapMouseEvent = (event) => {
    // transform mouse coordinates on screen to relative to minimap componenent coordinates
    let x = event.pageX - this.state.x;
    let y = event.pageY - this.state.y;
    // transform coordinates into world space
    let pt = this.ctx.transformedPoint(x, y);
    // let renderer navigate to clicked point
    this.props.onMoveTo({ x: pt.x, y: pt.y });
    this.props.chainMouseMove(x, y, event);
  };

  // handle dragging of the minimap region rectangle
  mouseDown = (event) => {
    this.miniMapMouseEvent(event);
    this.dragging = true;
  };

  // handle dragging of the minimap region rectangle
  mouseMove = (event) => {
    if (this.dragging) {
      this.miniMapMouseEvent(event);
    }
  };

  mouseUp = () => {
    this.dragging = false;
  };

  // zoom event in minimap
  mousewheel = (event) => {
    let x = event.pageX - this.state.x;
    let y = event.pageY - this.state.y;
    this.props.zoomMouseWheel(event, x, y);
  };

  // drag stop event of minimap
  stopEvent = () => {
    let bounds = this.root.getBoundingClientRect();
    if (this.props.inSideBar) {
      bounds.x = 0;
      bounds.y = 0;
    }
    this.setState({ grabbing: false, x: bounds.x, y: bounds.y });
  };

  render() {
    const { classes, pointerEvents } = this.props;
    const { grabbing } = this.state;

    let classToUse = this.props.inSideBar ? classes.rootsideBar : classes.root;

    return (
      <Draggable
        handle=".handle"
        onStart={() => this.setState({ grabbing: true })}
        onStop={this.stopEvent}
      >
        <div
          ref={(c) => (this.root = c)}
          className={classNames(classToUse, grabbing && classes.grabbing)}
          style={{
            pointerEvents: pointerEvents ? "all" : "none",
            top: this.props.canvas.height - 175,
            zIndex: 9999,
          }}
        >
          <canvas
            ref={(c) => {
              this.canvas = c;
            }}
            className={classes.canvas}
            onMouseDown={this.mouseDown}
            onMouseMove={this.mouseMove}
            onMouseUp={this.mouseUp}
          />
          {!this.props.inSideBar && (
            <DragIndicator
              style={{ zIndex: 10000 }}
              className={classNames(
                "handle",
                classes.dragIndicator,
                grabbing && classes.grabbing
              )}
            />
          )}
        </div>
      </Draggable>
    );
  }
}

// define the component's interface
MiniMap.propTypes = {
  classes: PropTypes.object.isRequired,
  ome: PropTypes.object,
  theme: PropTypes.object,
  zoom: PropTypes.number,
  onMoveTo: PropTypes.func,
  onScaleOnly: PropTypes.func,
  position: PropTypes.object,
  histogramConfig: PropTypes.object,
  canvas: PropTypes.object,
  pointerEvents: PropTypes.bool,
  inSideBar: PropTypes.bool,
  zoomMouseWheel: PropTypes.func,
  chainMouseMove: PropTypes.func,
  componentRef: PropTypes.func,
  // from withTiles
  tiles: PropTypes.object,
  fileId: PropTypes.string,
  getPageForChannel: PropTypes.func,
};

export default withTiles(withTheme(withStyles(styles)(MiniMap)));

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import ScoringMatrixHistoClassification from "../ScoringMatrixHistoClassification";
import ScoringMatrixHistoPointCounting from "../ScoringMatrixHistoPointCounting";
import MiniMap from "../MiniMap";

import { Add, ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

import withStyles from "@mui/styles/withStyles";

import {
  Grid,
  Typography,
  Button,
  Tooltip,
  IconButton,
  ListItem,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { withAllViewerContexts } from "../../contexts/AllViewerContexts";
import { withTiles } from "../../contexts/TilesContext";
import { withResultTab } from "../../contexts/ResultTabContext";

import Backend from "../../../common/utils/Backend";

// define the component's styling
const styles = () => ({
  flexRowRemainingHeight: {
    flex: "1 1 auto",
    overflowY: "auto",
  },
  spacing: {
    padding: 10,
    paddingBottom: 0,
    paddingTop: 0,
  },
});

// all possible substructures for module "Histo-Klassifikation"
const HistoClassififcationLabels = [
  "Skelettmuskulatur [Skm]",
  "Brätstrukturen [Brät]",
  "Bindegewebe kollagenes  [kBdg]",
  "Bindegewebe elastisches [eBdg]",
  "Fettgewebe [Fett]",
  "Pflanzliches Fett  [pFett]",
  "Gewürze [Gewü]",
  "Gefäße [Gefä]",
  "Drüsengewebe [Drüs]",
  "Haut äußere Schwarten [Schwa]",
  "Lebergewebe [Leber]",
  "Lymphatisches Gewebe [Lymph]",
  "Muskeleiweiß strukturlos (teils granuliert) [Mus]",
  "Brät mit Hülle wiederverarbeitetes [ReMit]",
  "Brät ohne Hülle wiederverarbeitetes [ReOhne]",
  "Gelatineartige fremde Eiweißstrukturen [Gelat]",
  "Stärkepartikel [Stärke]",
  "Haut Geflügel [GHaut]",
  "Speicheldrüse [Speidrü]",
  "Knorpelpartikel [Knorpel]",
  "Knochenpartikel [Knochen]",
  "Pflanzliches Protein [pProt]",
  "Pflanzliche Partikel [pPart]",
  "Muskelabrieb, strukturlose Masse (teils granuliert, auch Vakuolen) [Mgranu]",
  "Muskelabrieb, feinbrätartig (>drei Vakuolen auf einer Stelle) [Mbrät]",
  "Muskelabrieb in Form amorpher Proteinstrukturen [Mamorph]",
  "Deutlich von umgebender Muskulatur abgegrenzte straßenartige Bahnen aus Muskeleiweiß wabig-schaumig vernetzt großporig [MeatIn1]",
  "Deutlich von umgebender Muskulatur abgegrenzte straßenartige Bahnen aus Muskeleiweiß wabig-schaumig vernetzt kleinporig [MeatIn2]",
  "Deutlich von umgebender Muskulatur abgegrenzte straßenartige Bahnen aus Muskeleiweiß in Form von Detritus [MeatIn3]",
  "Strukturen wie durch Transglutaminase entstanden inter-/intramuskulär [Transgl]",
  "Tierisches Fremdprotein Plasmaeiweiß [tProt]",
  "Schilddrüse [SchiDrü]",
  "Skelettmuskulatur, zusammengefügt [SkmZus]",
  "unbekannte Strukturen [US]",
];

class SideBarTabResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
      dynamicStructureText: "",
      dynamicStructureAbbrText: "",
      // shows if grid from last use uf project exists
      gridExists: this.getGridExists(),
      structuresToAdd: [],
      showLabelsToAdd: false,
    };
  }

  componentDidMount = () => {
    this.setState({ isMounted: true });
    this.props.resultTab.setZoomLevelFixed(false); // Re-enable zoom on customer request
    if (!this.props.tiles.getHsitoClassificationStarted()) {
      this.props.onChangeTool("gridtool");
    }
  };

  UNSAFE_componentWillMount = () => {
    this.setSelectedSampleFromFilename();
  };

  componentWillUnmount = () => {
    this.props.onChangeTool("none");
  };

  setSelectedSampleFromFilename = () => {
    // set selected sample according to file name (hint in filename about sample)
    if (
      this.props.project &&
      this.props.project.name &&
      !this.props.resultTab.getSelSampleSet()
    ) {
      let res = this.props.project.name.split("-");
      let sampleAbbr = res[res.length - 2];
      let sampleLabel = "";
      switch (sampleAbbr) {
        case "KP": // Kochpökelware
          sampleLabel = "Kochpökelware";
          break;
        case "BR": // Brühwurst
          sampleLabel = "Brühwurst";
          break;
        case "HA": // Hackfleisch
          sampleLabel = "Hackfleisch";
          break;
        case "KO": // Kochwurst
          sampleLabel = "Kochwurst";
          break;
        case "RO": // Rohwurst
          sampleLabel = "Rohwurst";
          break;
        case "VP": // VegFleischersatz
          sampleLabel = "VegFleischersatz";
          break;
        default:
          break;
      }

      // get index of sample to select
      let idxSelectedStructure = this.props.structures.findIndex(
        (element) => element.label === sampleLabel
      );

      if (idxSelectedStructure >= 0) {
        this.props.onSelectLayer(idxSelectedStructure);
        let selId = this.props.structures[idxSelectedStructure].id;

        // get all parent structures
        let parentStructures = this.props.structures.filter(
          (element) => element.subtypeLevel === 0
        );

        // get Index of sample in parent structures
        let parentIdxSelStructure = parentStructures.findIndex(
          (element) => element.id === selId
        );
        this.props.resultTab.setSelectedStructure(parentIdxSelStructure);
        // save selectedLayer in local storage
        this.props.persistentStorage.save(
          "selLayerSample",
          idxSelectedStructure
        );
      }
      this.props.resultTab.setSelSampleSet(true);
    }
  };

  getGridExists = () => {
    const { roiLayers } = this.props;
    // check if grid was created in one roilayer
    let exists = false;
    roiLayers.forEach((l) => {
      if (l.layer.regionRois.length > 0) {
        exists = true;
      }
    });
    this.props.resultTab.setGridExists(exists);
    return exists;
  };

  findChilds = (subType) => {
    const { structures } = this.props;
    // return direct classification subtypes
    return structures.filter(
      (element) =>
        element.subtypeLevel === subType.subtypeLevel + 1 &&
        element.parentId === subType.id &&
        element.classificationSubtype
    );
  };

  resetStructures = (changeSample) => {
    const {
      structures,
      roiLayers,
      selectedLayer,
      tools,
      onChangeTool,
      project,
    } = this.props;
    // reset properties for each structure
    structures.forEach((structure) => {
      structure.avgClassFrequency = 0;
      for (const key of Object.keys(structure.classFrequencies)) {
        structure.classFrequencies[key] = 0;
      }
    });
    this.props.resultTab.setSelectedRoi(0);
    this.props.resultTab.setSelectedChildIndex(1);

    // set resetPressed to true
    this.props.resultTab.setResetPressed(true);

    // set hundredTiles to false
    this.props.resultTab.setHundredTiles(false);

    if (project.type.includes("HistoClassification")) {
      // set started to false
      this.props.tiles.setHsitoClassificationStarted(false);
    }

    // save selectedRoi in local storage
    this.props.persistentStorage.save(
      "selectedRoi",
      this.props.resultTab.getSelectedRoi()
    );

    if (changeSample) {
      // delete roiLayers
      roiLayers.forEach((roiLayer) => {
        roiLayer.layer.regionRois = [];
      });
      //if condistion so that onChangeTool("none") doesnt affect Histo Modules, but otherwise no new bugs occur
      if (
        !(
          project.type.includes("HistoClassification") ||
          project.type.includes("HistoPointCounting")
        )
      ) {
        onChangeTool("none");
      }
    } else {
      // make new grid
      // if no grid finish here
      if (roiLayers[selectedLayer].layer.regionRois.length === 0) {
        return;
      }

      // make new grid
      // calculate gridsize
      let size = Math.sqrt(roiLayers[selectedLayer].layer.regionRois.length);
      if (project.type.includes("HistoClassification")) {
        tools["gridtool"].createGrid(size);
      } else if (project.type.includes("HistoPointCounting")) {
        tools["gridtool"].createGrid(size, true);
      }

      // make tilesClassificationTool active
      let roiItem = {
        maxX: roiLayers[selectedLayer].layer.regionRois[0].bounds.left,
        maxY: roiLayers[selectedLayer].layer.regionRois[0].bounds.top,
        minX: roiLayers[selectedLayer].layer.regionRois[0].bounds.right,
        minY: roiLayers[selectedLayer].layer.regionRois[0].bounds.bottom,
        roi: roiLayers[selectedLayer].layer.regionRois[0],
      };

      if (project.type.includes("HistoClassification")) {
        onChangeTool("selectiontile");
        tools["selectiontile"].setNextTile(roiItem);
      } else if (project.type.includes("HistoPointCounting")) {
        onChangeTool("pointcountingtile");
        tools["pointcountingtile"].setNextTile(roiItem);
      }
    }
  };

  resetRoiLayers = () => {
    const { allRoiLayers } = this.props;
    // make all roiLayers in all files empty
    for (const value of Object.entries(allRoiLayers)) {
      value[1].forEach((element) => {
        element.layer.regionRois = [];
      });
    }
  };

  onStart = () => {
    const {
      tools,
      onChangeTool,
      roiLayers,
      selectedLayer,
      project,
      fileId,
      onSelectFile,
    } = this.props;

    // check if in first file for starting
    let inxCurrentFile = project.files.findIndex(
      (element) => element.id === fileId
    );
    if (
      inxCurrentFile !== 0 &&
      !this.props.tiles.getHsitoClassificationStarted() &&
      !this.state.gridExists
    ) {
      window.showWarningSnackbar("Please start in file 1.");
      window.showWarningSnackbar("Changing to file 1 ...");
      onSelectFile(project.files[0].id);
      return;
    }

    // if no grid
    if (
      roiLayers[selectedLayer].layer.regionRois.length === 0 &&
      project.type.includes("HistoClassification")
    ) {
      window.showWarningSnackbar("Please create a grid before annotating");
      this.props.onChangeTool("gridtool");
      return;
    }

    // reset
    if (this.props.tiles.getHsitoClassificationStarted()) {
      // initialize gridtool if not initialized yet
      onChangeTool("gridtool");
      setTimeout(() => this.resetStructures(), 100);
      // set started to false
      this.props.tiles.setHsitoClassificationStarted(false);

      if (
        fileId !== project.files[0].id &&
        project.type.includes("HistoClassification")
      ) {
        // reset roiLayers of all files
        this.resetRoiLayers();
        window.showWarningSnackbar("Changing to file 1 ...");
        onSelectFile(project.files[0].id);
      }
      return;
    }

    // set corresponding zoom level for grid size
    let gridSize = Math.sqrt(roiLayers[selectedLayer].layer.regionRois.length);
    if (project.type.includes("HistoClassification")) {
      window.setZoomLevelForGridSize(gridSize);
    }

    if (project.type.includes("HistoClassification")) {
      // get selectedRoi from previous use
      let savedRoi = this.props.persistentStorage.load("selectedRoi");
      if (savedRoi && savedRoi !== null) {
        this.props.resultTab.setSelectedRoi(savedRoi);
      } else {
        savedRoi = 0;
        this.props.resultTab.setSelectedRoi(savedRoi);
      }

      // make tilesClassificationTool active
      let roiItem = {
        maxX: roiLayers[selectedLayer].layer.regionRois[savedRoi].bounds.left,
        maxY: roiLayers[selectedLayer].layer.regionRois[savedRoi].bounds.top,
        minX: roiLayers[selectedLayer].layer.regionRois[savedRoi].bounds.right,
        minY: roiLayers[selectedLayer].layer.regionRois[savedRoi].bounds.bottom,
        roi: roiLayers[selectedLayer].layer.regionRois[savedRoi],
      };

      tools["selectiontile"].setNextTile(roiItem);
      onChangeTool("selectiontile");
      // On Start set Labels to 0
      this.checkIfSkipped();
      this.setClassToZero();
    } else if (project.type.includes("HistoPointCounting")) {
      onChangeTool("gridtool");
      // make small delay to initialize grid tool
      setTimeout(() => this.startHistoPointCounting(), 100);
    }

    // set session started true
    this.props.tiles.setHsitoClassificationStarted(true);

    // set resetPressed to false
    this.props.resultTab.setResetPressed(false);

    // set gridsize if start old project
    let size = Math.sqrt(roiLayers[selectedLayer].layer.regionRois.length);
    window.setGridSize(size);
  };

  startHistoPointCounting = () => {
    const { tools, onChangeTool, roiLayers, selectedLayer } = this.props;

    // only create grid if not exists
    if (roiLayers[selectedLayer].layer.regionRois.length === 0) {
      tools["gridtool"].createGrid(20, true);
    }

    window.setZoomLevelForGridSize(20);

    // get selectedRoi from previous use
    let savedRoi = this.props.persistentStorage.load("selectedRoi");
    if (savedRoi && savedRoi !== null) {
      this.props.resultTab.setSelectedRoi(savedRoi);
    } else {
      savedRoi = 0;
      this.props.resultTab.setSelectedRoi(savedRoi);
    }

    // make tilesClassificationTool active
    let roiItem = {
      maxX: roiLayers[selectedLayer].layer.regionRois[savedRoi].bounds.left,
      maxY: roiLayers[selectedLayer].layer.regionRois[savedRoi].bounds.top,
      minX: roiLayers[selectedLayer].layer.regionRois[savedRoi].bounds.right,
      minY: roiLayers[selectedLayer].layer.regionRois[savedRoi].bounds.bottom,
      roi: roiLayers[selectedLayer].layer.regionRois[savedRoi],
    };

    onChangeTool("pointcountingtile");
    tools["pointcountingtile"].setNextTile(roiItem);

    // check if 100 tiles already classified
    let totalCount = this.getTotalCount();
    if (totalCount >= 100) {
      this.props.resultTab.setHundredTiles(true);
    }
  };

  onPause = () => {
    // save project
    this.props.onSave();
  };

  onPrevious = () => {
    const { tools, roiLayers, selectedLayer, onChangeTool, project } =
      this.props;

    // if no grid
    if (roiLayers[selectedLayer].layer.regionRois.length === 0) {
      window.showWarningSnackbar("Please create a grid before annotating");
      this.props.onChangeTool("gridtool");
      return;
    }

    // set new selectedRoi
    if (project.type.includes("HistoClassification")) {
      this.previousHistoClassification();
    } else if (project.type.includes("HistoPointCounting")) {
      this.previousHistoPointCounting();
    }

    // roi to select as custom curser in tool
    let selectedRoi = this.props.resultTab.getSelectedRoi();
    let roiItem = {
      maxX: roiLayers[selectedLayer].layer.regionRois[selectedRoi].bounds.left,
      maxY: roiLayers[selectedLayer].layer.regionRois[selectedRoi].bounds.top,
      minX: roiLayers[selectedLayer].layer.regionRois[selectedRoi].bounds.right,
      minY: roiLayers[selectedLayer].layer.regionRois[selectedRoi].bounds
        .bottom,
      roi: roiLayers[selectedLayer].layer.regionRois[selectedRoi],
    };

    // select tile with tool
    if (project.type.includes("HistoClassification")) {
      tools["selectiontile"].setNextTile(roiItem);
      onChangeTool("selectiontile");
    } else if (project.type.includes("HistoPointCounting")) {
      tools["pointcountingtile"].setNextTile(roiItem);
      onChangeTool("pointcountingtile");
    }

    // save selectedRoi in local storage
    this.props.persistentStorage.save(
      "selectedRoi",
      this.props.resultTab.getSelectedRoi()
    );
  };

  previousHistoClassification = () => {
    const { roiLayers, selectedLayer } = this.props;

    this.checkIfSkipped();
    // if first tile of scene go to previous file last tile
    if (this.props.resultTab.getSelectedRoi() === 0) {
      // change file
      let prevFileId = this.getPrevFileId();
      if (prevFileId) {
        let numberTiles = roiLayers[selectedLayer].layer.regionRois.length;
        this.props.resultTab.setSelectedRoi(numberTiles - 1);
        this.props.resultTab.setSelectedChildIndex(1);
        window.showWarningSnackbar("Please wait, loading previous file ...");
        this.props.onSelectFile(prevFileId);
        this.props.resultTab.setZoomLevelFixed(false);
        this.setClassToZero();
      } else {
        return;
      }
    } else {
      let nextRoi = this.props.resultTab.getSelectedRoi() - 1;
      this.props.resultTab.setSelectedRoi(nextRoi);
      this.props.resultTab.setSelectedChildIndex(1);
      this.setClassToZero();
    }
  };

  previousHistoPointCounting = () => {
    // if first tile of scene do nothing
    if (this.props.resultTab.getSelectedRoi() === 0) {
      // change file
      let prevFileId = this.getPrevFileId();
      if (prevFileId) {
        // get tile index from previous file
        let lastTileIndices = this.props.persistentStorage.load("tileIndices");
        let roiFile = lastTileIndices ? lastTileIndices[prevFileId] : 0;
        this.props.resultTab.setSelectedRoi(roiFile);
        this.props.resultTab.setSelectedChildIndex(1);
        this.props.resultTab.setZoomLevelFixed(false);
        this.props.resultTab.setHundredTiles(false);
        window.showWarningSnackbar("Please wait, loading previous file ...");
        this.props.onSelectFile(prevFileId);
      } else {
        return;
      }
    } else {
      // if first tile of blue tiles from specification
      if (this.props.resultTab.getSelectedRoi() === 21) {
        // set last red tile from specification
        this.props.resultTab.setSelectedRoi(378);
        return;
      }

      let selectedRoi = this.props.resultTab.getSelectedRoi();
      // check if last tile in row --> then skip next row
      let nextRoi = 0;
      if (selectedRoi % 20 === 0 || selectedRoi % 20 === 1) {
        nextRoi = selectedRoi - 22;
      } else {
        nextRoi = selectedRoi - 2;
      }

      this.props.resultTab.setSelectedRoi(nextRoi);
    }
  };

  onNext = () => {
    const { tools, roiLayers, selectedLayer, onChangeTool, project } =
      this.props;

    // if no grid
    if (roiLayers[selectedLayer].layer.regionRois.length === 0) {
      window.showWarningSnackbar("Please create a grid before annotating");
      this.props.onChangeTool("gridtool");
      return;
    }

    // if file is changing no next possible for a few seconds
    if (this.props.resultTab.getFileChange()) {
      return;
    }

    // if last tile in last file stop
    if (this.nextButtonDisabled() && !this.props.resultTab.getFileChange()) {
      window.showWarningSnackbar("Finished!");
      return;
    }

    // set new selectedRoi
    if (project.type.includes("HistoClassification")) {
      this.nextHistoClassification();
    } else if (project.type.includes("HistoPointCounting")) {
      this.nextHistoPointCounting();
    }

    // roi to select as custom curser in tool
    let selectedRoi = this.props.resultTab.getSelectedRoi();
    let roiItem = {
      maxX: roiLayers[selectedLayer].layer.regionRois[selectedRoi].bounds.left,
      maxY: roiLayers[selectedLayer].layer.regionRois[selectedRoi].bounds.top,
      minX: roiLayers[selectedLayer].layer.regionRois[selectedRoi].bounds.right,
      minY: roiLayers[selectedLayer].layer.regionRois[selectedRoi].bounds
        .bottom,
      roi: roiLayers[selectedLayer].layer.regionRois[selectedRoi],
    };

    // select tile with tool
    if (project.type.includes("HistoClassification")) {
      tools["selectiontile"].setNextTile(roiItem);
      onChangeTool("selectiontile");
    } else if (project.type.includes("HistoPointCounting")) {
      tools["pointcountingtile"].setNextTile(roiItem);
      onChangeTool("pointcountingtile");
    }

    // save selectedRoi in local storage
    this.props.persistentStorage.save(
      "selectedRoi",
      this.props.resultTab.getSelectedRoi()
    );
  };

  nextHistoClassification = () => {
    const { roiLayers, selectedLayer, onSelectFile } = this.props;
    let selectedRoi = this.props.resultTab.getSelectedRoi();

    this.checkIfSkipped();

    let numberTiles = roiLayers[selectedLayer].layer.regionRois.length;
    if (selectedRoi === numberTiles - 1) {
      // change file
      let nextFileId = this.getNextFileId();
      if (nextFileId) {
        window.showWarningSnackbar("Please wait, loading next file ...");
        onSelectFile(nextFileId);
        this.props.resultTab.setZoomLevelFixed(false);
        this.props.resultTab.setSelectedRoi(0);
      } else {
        return;
      }
    } else {
      let nextRoi = this.props.resultTab.getSelectedRoi() + 1;
      this.props.resultTab.setSelectedRoi(nextRoi);
    }

    this.setClassToZero();
    this.props.resultTab.setSelectedChildIndex(1);
  };

  checkIfSkipped = () => {
    const { structures, roiLayers } = this.props;
    const selectedRoi = this.props.resultTab.getSelectedRoi();

    let id_range = this.getRoiLayersIdRange();

    // check if some layer was classified, if so return
    for (let id in id_range) {
      if (
        roiLayers[id_range[id]].layer.regionRois[selectedRoi].frequencyClass !==
        0
      ) {
        return;
      }
    }

    // set Class to -1 and reduce counter by one for all Rois
    for (let id in id_range) {
      roiLayers[id_range[id]].layer.regionRois[selectedRoi].frequencyClass = -1;
      for (let e in structures) {
        if (structures[e].id === roiLayers[id_range[id]].id) {
          structures[e].classFrequencies.class_0 -= 1;
          break;
        }
      }
    }
  };

  setClassToZero = () => {
    const { structures, roiLayers } = this.props;
    const selectedRoi = this.props.resultTab.getSelectedRoi();

    let id_range = this.getRoiLayersIdRange();

    // set Class to 0 and increase the according counter
    for (let id in id_range) {
      if (
        roiLayers[id_range[id]].layer.regionRois[selectedRoi].frequencyClass ===
        -1
      ) {
        roiLayers[id_range[id]].layer.regionRois[
          selectedRoi
        ].frequencyClass = 0;
        for (let e in structures) {
          if (structures[e].id === roiLayers[id_range[id]].id) {
            structures[e].classFrequencies.class_0 += 1;
            break;
          }
        }
      }
    }
  };

  getRoiLayersIdRange = () => {
    const { structures, roiLayers, selectedLayer } = this.props;

    // get ids of RoiLayers that should be set to zero
    const tmp = structures.filter(
      (element) =>
        element.subtypeLevel === structures[selectedLayer].subtypeLevel + 1 &&
        element.parentId === structures[selectedLayer].id
    );

    // get min and max id
    let roi_min = 99999;
    let roi_max = -1;
    roiLayers.filter((element, idx) => {
      for (let e in tmp) {
        if (tmp[e].id === element.id) {
          if (roi_min > idx) {
            roi_min = idx;
          }
          if (roi_max < idx) {
            roi_max = idx;
          }
          return true;
        }
      }
      return false;
    });

    // create a range of ids
    let id_range = [...Array(roi_max - roi_min + 1).keys()]; //+roi_min
    for (let e in id_range) {
      id_range[e] += roi_min;
    }

    return id_range;
  };

  nextHistoPointCounting = () => {
    const { roiLayers, selectedLayer, onSelectFile, fileId } = this.props;
    let selectedRoi = this.props.resultTab.getSelectedRoi();

    // if 100 classified tiles --> change file
    // if last tile of scene change file
    let numberTiles = roiLayers[selectedLayer].layer.regionRois.length;
    let totalCount = this.getTotalCount();
    if (totalCount >= 100) {
      this.props.resultTab.setHundredTiles(true);
    } else {
      this.props.resultTab.setHundredTiles(false);
    }

    if (
      (this.props.resultTab.getHundredTiles() && this.isLastClassifiedTile()) ||
      selectedRoi === numberTiles - 1
    ) {
      // change selected file
      let nextFileId = this.getNextFileId();
      if (nextFileId) {
        // save last tileindex of current file
        let lastTileIndices = this.props.persistentStorage.load("tileIndices");
        if (!lastTileIndices || lastTileIndices.length === 0) {
          let indicesObject = {};
          indicesObject[fileId] = selectedRoi;
          this.props.persistentStorage.save("tileIndices", indicesObject);
        } else {
          lastTileIndices[fileId] = selectedRoi;
          this.props.persistentStorage.save("tileIndices", lastTileIndices);
        }

        // change file
        window.showWarningSnackbar("Please wait, loading next file ...");
        onSelectFile(nextFileId);
        this.props.resultTab.setFileChange(true);
        this.props.resultTab.setZoomLevelFixed(false);
        this.props.resultTab.setSelectedRoi(0);
        this.props.resultTab.setHundredTiles(false);
        return;
      }
    }

    // check if last tile in row --> then skip next row
    let nextRoi = 0;
    if (selectedRoi % 20 === 18 || selectedRoi % 20 === 19) {
      nextRoi = selectedRoi + 22;
    } else {
      nextRoi = selectedRoi + 2;
    }

    // check if nextRoi exists
    let lenRois = roiLayers[selectedLayer].layer.regionRois.length;
    if (nextRoi >= lenRois) {
      // roi does not exist --> start with "blue" tiles from specifications
      nextRoi = 21;
    }

    this.props.resultTab.setSelectedRoi(nextRoi);
  };

  isLastClassifiedTile = () => {
    const { structures, roiLayers, selectedLayer } = this.props;
    let selectedRoi = this.props.resultTab.getSelectedRoi();

    // get id of "leer" child
    let emptyId = 0;
    this.findChilds(structures[selectedLayer]).forEach((child) => {
      if (child.label === "leer [leer]") {
        emptyId = child.id;
      }
    });

    // get all classified tiles
    // if selectedRoi is even --> red tile (see specifications)
    let classifiedTiles;
    if (selectedRoi % 2 === 0) {
      classifiedTiles = roiLayers[selectedLayer].layer.regionRois.filter(
        (roi, idx) =>
          roi.isAnnotated && roi.structureId !== emptyId && idx % 2 === 0
      );
    } else {
      // if selectedRoi is uneven --> blue tile (see specifications)
      classifiedTiles = roiLayers[selectedLayer].layer.regionRois.filter(
        (roi, idx) =>
          roi.isAnnotated && roi.structureId !== emptyId && idx % 2 === 1
      );
    }

    // get index of last classified tile
    let idxLastTile = roiLayers[selectedLayer].layer.regionRois.findIndex(
      (element) => element === classifiedTiles[classifiedTiles.length - 1]
    );

    // check if blue tiles classified
    let blueTiles = roiLayers[selectedLayer].layer.regionRois.filter(
      (roi, idx) =>
        roi.isAnnotated && roi.structureId !== emptyId && idx % 2 === 1
    );

    let blueTilesClassified = blueTiles.length > 0 ? true : false;

    // if selectedRoi has same Index as last classified tile return true
    if (idxLastTile === selectedRoi) {
      if (selectedRoi === 378 && blueTilesClassified) {
        // if last red tile but blue tiles are already classified
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  getNextFileId = () => {
    const { project, fileId } = this.props;
    let inxCurrentFile = project.files.findIndex(
      (element) => element.id === fileId
    );

    if (project.files[inxCurrentFile + 1]) {
      return project.files[inxCurrentFile + 1].id;
    } else {
      // if last file
      window.showWarningSnackbar("Last file.");
      return null;
    }
  };

  getPrevFileId = () => {
    const { project, fileId } = this.props;
    let inxCurrentFile = project.files.findIndex(
      (element) => element.id === fileId
    );

    if (project.files[inxCurrentFile - 1]) {
      return project.files[inxCurrentFile - 1].id;
    } else {
      // if first file
      window.showWarningSnackbar("First file.");
      return null;
    }
  };

  onEnd = () => {
    // save project
    if (this.props.project.type.includes("HistoClassification")) {
      this.checkIfSkipped();
    }
    this.props.onSave();
    // show warning if not 100 tiles classified
    if (
      this.getTotalCount() < 100 &&
      this.props.project.type.includes("HistoPointCounting")
    ) {
      window.showWarningSnackbar("Not 100 classified tiles in each file");
    }

    // make excel table with results
    Backend.setProjectsPending([this.props.project.id], () => {
      console.log("project state changed to pending:", this.props.project.id);
    });
    // return to Home Screen

    let { history } = this.props;

    setTimeout(
      function () {
        history.push("/");
      },
      3000,
      history
    );
  };

  getTotalCount = () => {
    const { structures, roiLayers, selectedLayer } = this.props;

    // get id of "leer" child
    let emptyId = 0;
    this.findChilds(structures[selectedLayer]).forEach((child) => {
      if (child.label === "leer [leer]") {
        emptyId = child.id;
      }
    });

    // get number of rois that are not of class "leer"
    let numberRois = roiLayers[selectedLayer].layer.regionRois.filter(
      (roi) => roi.isAnnotated && roi.structureId !== emptyId
    ).length;

    return numberRois;
  };

  renderGridTool = () => {
    const { tool } = this.props;
    return (
      <div>
        {tool && !tool.noConfig && <Grid>{tool.renderConfiguration()}</Grid>}
      </div>
    );
  };

  addStructure = () => {
    const { projectContext, structures, selectedLayer, project } = this.props;
    const { dynamicStructureText, dynamicStructureAbbrText } = this.state;

    if (dynamicStructureText === "") {
      window.showWarningSnackbar("Please add label of new structure.");
      return;
    } else if (dynamicStructureAbbrText === "") {
      window.showWarningSnackbar("Please add abbreviation for new structure.");
      return;
    }

    // put label and abbreviation together for label of new structure
    let newSubstructureLabel =
      dynamicStructureText + " [" + dynamicStructureAbbrText + "]";

    if (project.type.includes("HistoPointCounting")) {
      // add new subtype
      projectContext.addSubType(
        structures[selectedLayer],
        newSubstructureLabel,
        true
      );
    }

    this.setState({ dynamicStructureText: "", dynamicStructureAbbrText: "" });
  };

  addSelectedStructures = () => {
    const { projectContext, structures, selectedLayer, project } = this.props;
    const { structuresToAdd } = this.state;

    if (project.type.includes("HistoClassification")) {
      // add new substructures
      structuresToAdd.forEach((newSubstructureLabel) => {
        projectContext.addSubStructure(
          structures[selectedLayer],
          newSubstructureLabel,
          true
        );
      });
    }

    this.setState({ structuresToAdd: [], showLabelsToAdd: false });
  };

  getCurrentFileInx = () => {
    const { project, fileId } = this.props;
    return project.files.findIndex((element) => element.id === fileId) + 1;
  };

  startOrContinueText = () => {
    if (!this.props.tiles.getHsitoClassificationStarted()) {
      return "RUN";
    } else {
      if (this.props.project.type.includes("HistoClassification")) {
        return "RESET ALL FILES";
      } else if (this.props.project.type.includes("HistoPointCounting")) {
        return "RESET CURRENT FILE";
      } else {
        return "RESET";
      }
    }
  };

  nextOrSkipText = () => {
    if (this.props.project.type.includes("HistoClassification")) {
      return "NEXT";
    }

    if (!this.getGridExists()) {
      return "NEXT";
    }
    const { roiLayers } = this.props;
    const selectedRoi = this.props.resultTab.getSelectedRoi();

    let id_range = this.getRoiLayersIdRange();

    // set label to "NEXT" if Roi has classifications
    for (let id in id_range) {
      if (roiLayers[id_range[id]].layer.regionRois[selectedRoi] === undefined) {
        return "NEXT";
      }
      if (
        roiLayers[id_range[id]].layer.regionRois[selectedRoi].frequencyClass !==
        0
      ) {
        return "NEXT";
      }
    }
    return "SKIP TILE";
  };

  isLastFile = () => {
    const { project, fileId } = this.props;
    let inxCurrentFile = project.files.findIndex(
      (element) => element.id === fileId
    );

    if (inxCurrentFile === project.files.length - 1) {
      return true;
    } else {
      return false;
    }
  };

  nextButtonDisabled = () => {
    const { roiLayers, selectedLayer } = this.props;

    // if file change disable next button for three seconds
    if (this.props.resultTab.getFileChange()) {
      return true;
    }

    let disabled;
    if (this.props.tiles.getHsitoClassificationStarted()) {
      disabled = false;
    } else {
      disabled = true;
    }

    let selectedRoi = this.props.resultTab.getSelectedRoi();
    let numberTiles = roiLayers[selectedLayer].layer.regionRois.length;
    if (!disabled && this.props.project.type.includes("HistoClassification")) {
      // if last file and last tile
      let lastTileClassification =
        selectedRoi === numberTiles - 1 ? true : false;
      if (this.isLastFile() && lastTileClassification) {
        return true;
      }
    } else if (
      !disabled &&
      this.props.project.type.includes("HistoPointCounting")
    ) {
      // last file and last tile or last file and 100 annotated
      let lastTileCounting = selectedRoi === numberTiles - 1 ? true : false;
      if (
        (this.isLastFile() && lastTileCounting) ||
        (this.isLastFile() &&
          this.props.resultTab.getHundredTiles() &&
          this.isLastClassifiedTile())
      ) {
        return true;
      }
    }

    return disabled;
  };

  getAbbreviationOfStructure = (label) => {
    let labelWithBracket = label.split("[")[1];
    if (!labelWithBracket) {
      labelWithBracket = label;
    }
    let abbreviation = labelWithBracket.substring(
      0,
      labelWithBracket.length - 1
    );
    return abbreviation;
  };

  getOtherLabels = () => {
    const { structures, selectedLayer } = this.props;

    // get all childs of selected sample
    let sampleChilds = structures.filter(
      (structure) => structure.parentId === structures[selectedLayer].id
    );

    // get all labels sampleChilds
    let sampleChildsLabels = [];
    sampleChilds.forEach((child) => {
      sampleChildsLabels.push(child.label);
    });

    // get all labels of histoclassificationlabels that are not in samplechilds
    let newLabels = [];
    HistoClassififcationLabels.forEach((histoLabel) => {
      if (!sampleChildsLabels.includes(histoLabel)) {
        newLabels.push(histoLabel);
      }
    });

    return newLabels;
  };

  handleChangeNewStructures = (e, label) => {
    // check if add ort remove structure
    let updatedStructures = this.state.structuresToAdd;
    if (e.target.checked) {
      // add structure
      updatedStructures.push(label);
    } else {
      // remove structure
      // find index of label and remove it
      let idx = updatedStructures.findIndex((labelStr) => labelStr === label);
      updatedStructures.splice(idx, 1);
    }

    this.setState({ structuresToAdd: updatedStructures });
  };

  render() {
    const { classes, visible, ...propsWithoutClasses } = this.props;

    if (!visible) return null;

    let numberTilesFile =
      this.props.roiLayers[this.props.selectedLayer].layer.regionRois.length;

    let showAddSubstructure = !this.props.tiles.getHsitoClassificationStarted();

    return (
      <Grid
        style={{ overflowX: "hidden", overflowY: "auto" }}
        container
        className={classes.flexRowRemainingHeight}
      >
        <Grid className={classes.spacing} item xs={12}>
          <Typography
            variant="h6"
            style={{ fontSize: "18px", marginBottom: "20px" }}
          >
            File {this.getCurrentFileInx()} / {this.props.project.files.length}
          </Typography>
          {this.props.project.type.includes("HistoClassification") && (
            <ScoringMatrixHistoClassification
              selectedRoi={this.props.resultTab.getSelectedRoi()}
              resetStructures={this.resetStructures}
              zoomLevelFixed={this.props.resultTab.getZoomLevelFixed()}
              {...propsWithoutClasses}
            ></ScoringMatrixHistoClassification>
          )}
          {this.props.project.type.includes("HistoPointCounting") && (
            <ScoringMatrixHistoPointCounting
              selectedRoi={this.props.resultTab.getSelectedRoi()}
              onNext={this.onNext}
              resetStructures={this.resetStructures}
              zoomLevelFixed={this.props.resultTab.getZoomLevelFixed()}
              {...propsWithoutClasses}
            ></ScoringMatrixHistoPointCounting>
          )}
          {this.props.project.type.includes("HistoPointCounting") &&
            showAddSubstructure && (
              <ListItem
                style={{
                  paddingBottom: 0,
                  paddingTop: 15,
                  paddingLeft: 0,
                }}
                key={1111}
              >
                <TextField
                  style={{
                    paddingBottom: 0,
                    paddingTop: 0,
                    paddingLeft: 0,
                    paddingRight: 5,
                    width: 180,
                  }}
                  placeholder="Label"
                  label="Label"
                  value={this.state.dynamicStructureText}
                  onChange={(e) =>
                    this.setState({
                      dynamicStructureText: e.target.value,
                    })
                  }
                />
                <TextField
                  style={{
                    paddingBottom: 0,
                    paddingTop: 0,
                    paddingLeft: 0,
                    width: 120,
                  }}
                  placeholder="Abbreviation"
                  label="Abbreviation"
                  value={this.state.dynamicStructureAbbrText}
                  onChange={(e) =>
                    this.setState({
                      dynamicStructureAbbrText: e.target.value,
                    })
                  }
                />
                <Tooltip
                  disableInteractive
                  title="Add new structure (label [abbreviation])"
                >
                  <IconButton onClick={this.addStructure} size="large">
                    <Add />
                  </IconButton>
                </Tooltip>
              </ListItem>
            )}

          {this.props.project.type.includes("HistoClassification") &&
            showAddSubstructure && (
              <div style={{ marginTop: "30px" }}>
                <Typography style={{ float: "left" }}>
                  Add structures:
                </Typography>
                <Tooltip
                  disableInteractive
                  title="Add new structures"
                  style={{ display: "inline-block", marginTop: "-12px" }}
                >
                  <IconButton
                    onClick={() => {
                      this.setState({
                        showLabelsToAdd: !this.state.showLabelsToAdd,
                      });
                    }}
                    size="large"
                  >
                    {this.state.showLabelsToAdd ? (
                      <ArrowDropUp />
                    ) : (
                      <ArrowDropDown />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
            )}

          {this.props.project.type.includes("HistoClassification") &&
            showAddSubstructure &&
            this.state.showLabelsToAdd && (
              <div>
                {this.getOtherLabels().map((histoLabel, idx) => {
                  return (
                    <Tooltip disableInteractive key={idx} title={histoLabel}>
                      <FormControlLabel
                        style={{ width: "160px" }}
                        control={
                          <Checkbox
                            onChange={(e) => {
                              this.handleChangeNewStructures(e, histoLabel);
                            }}
                          />
                        }
                        label={this.getAbbreviationOfStructure(histoLabel)}
                      />
                    </Tooltip>
                  );
                })}
              </div>
            )}

          {this.props.project.type.includes("HistoClassification") &&
            showAddSubstructure &&
            this.state.showLabelsToAdd && (
              <Tooltip disableInteractive title="Add selected structures">
                <Button
                  style={{ marginTop: "5px" }}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => this.addSelectedStructures()}
                >
                  Add selected structures
                </Button>
              </Tooltip>
            )}

          <Grid container spacing={3} style={{ marginTop: "20px" }}>
            <Grid item xs={4}>
              {this.props.resultTab.getRendererInitialized() &&
                this.props.histogramConfig && (
                  <MiniMap
                    inSideBar={true}
                    ome={this.props.ome}
                    histogramConfig={
                      this.props.histogramConfig[this.props.fileId]
                    }
                    visibleImage={this.props.tiles.getVisibleImages()}
                    coloredImages={this.props.tiles.getColoredImages()}
                    position={this.props.resultTab.getPosition()}
                    zoom={this.props.resultTab.getScale()}
                    canvas={this.props.resultTab.getRendererCanvas()}
                    getPageForChannel={(c) => c}
                    fileId={this.props.fileId}
                  />
                )}
            </Grid>
            <Grid item xs={8}>
              <div>
                <Grid container spacing={1}>
                  <Grid item xs>
                    <Button
                      style={{ marginTop: "5px" }}
                      size="small"
                      disabled={this.nextButtonDisabled()}
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => this.onNext()}
                    >
                      {this.nextOrSkipText()}
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button
                      style={{ marginTop: "5px" }}
                      size="small"
                      disabled={
                        this.props.tiles.getHsitoClassificationStarted()
                          ? false
                          : true
                      }
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => this.onPrevious()}
                    >
                      Previous
                    </Button>
                  </Grid>
                  <Grid item xs style={{ marginTop: "11px" }}>
                    <Typography variant="body1" style={{ textAlign: "center" }}>
                      Tile {this.props.resultTab.getSelectedRoi() + 1} /{" "}
                      {numberTilesFile}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs>
                    <Button
                      style={{ marginTop: "5px" }}
                      size="small"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => this.onStart()}
                    >
                      {this.startOrContinueText()}
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button
                      style={{ marginTop: "5px" }}
                      size="small"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => this.onPause()}
                    >
                      Pause
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button
                      style={{ marginTop: "5px" }}
                      size="small"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => this.onEnd()}
                    >
                      End
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

// define the component's interface
SideBarTabResults.propTypes = {
  classes: PropTypes.object.isRequired,
  // sidebar tab properties
  sideBarWidth: PropTypes.number,
  visible: PropTypes.bool,
  // general properties
  id: PropTypes.string.isRequired,
  viewerConfig: PropTypes.object,
  // historgram
  ome: PropTypes.object,
  histogramConfig: PropTypes.object,
  onChangeChannels: PropTypes.func,
  // file management
  onSelectFile: PropTypes.func,
  onExcludeFilesToggle: PropTypes.func,
  //not ordered
  project: PropTypes.object,
  resultTab: PropTypes.object,
  structures: PropTypes.array,
  roiLayers: PropTypes.array,
  onSelectLayer: PropTypes.func,
  persistentStorage: PropTypes.object,
  selectedLayer: PropTypes.number,
  tools: PropTypes.array,
  onChangeTool: PropTypes.func,
  tiles: PropTypes.object,
  allRoiLayers: PropTypes.object,
  fileId: PropTypes.string,
  onSave: PropTypes.func,
  history: PropTypes.object,
  tool: PropTypes.object,
  projectContext: PropTypes.object,
};

export default withRouter(
  withAllViewerContexts(
    withTiles(withResultTab(withStyles(styles)(SideBarTabResults)))
  )
);

import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { IconButton, Slider, TextField, Tooltip } from "@mui/material";

import {
  PlayCircleOutline,
  PauseCircleOutline,
  ArrowDropUp,
  ArrowDropDown,
} from "@mui/icons-material";

const styles = {
  root: {
    width: 40,
    position: "absolute",
    top: 70,
    bottom: 185,
    left: 5,
    color: "#ffffff",

    padding: 0,
    "& :active": {
      outline: "",
    },
    background: "#000000AA",
    border: "2px solid rgb(85, 85, 85)",
    borderRadius: 10,
  },
  barIcon: {
    position: "absolute",
    left: 0,
    top: 4,
    height: 26,
    width: "36px!important",
    color: "#fff",
  },
  stepUp: {
    position: "absolute",
    top: 174,
    left: 5,
    padding: 0,
    color: "#fff",
  },
  stepDown: {
    position: "absolute",
    bottom: 32,
    left: 5,
    padding: 0,
    color: "#fff",
  },
  slider: {
    position: "absolute",
    top: 200,
    left: 2,
    bottom: 58,
  },
  verticalTextfield: {
    position: "absolute",
    left: 0,
    color: "#fff",

    "& div": {
      color: "#fff",
      marginTop: 4,
      width: "100%",
    },
    "& label": {
      color: "#fff !important",
      fontSize: 11,
      lineHeight: "14px",
      padding: 0,
      width: "100%",
      textAlign: "center",
      margin: 0,
      left: "-5px",
    },
    "& input": {
      marginTop: 4,
      padding: "0px 0 0px",
      textAlign: "center",
    },
    "& .MuiOutlinedInput-input": {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
    },
  },
  playPauseDown: {
    position: "absolute",
    top: 142,
    padding: 5,
    color: "#fff",
  },
  playPauseUp: {
    position: "absolute",
    bottom: 0,
    padding: 5,
    color: "#fff",
  },
};

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip
      disableInteractive
      style={{ marginTop: -5 }}
      open={open}
      enterTouchDelay={0}
      placement="right"
      title={Math.round(value)}
    >
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

class ZStackBar extends Component {
  render() {
    const {
      classes,
      ome,
      z,
      minZ,
      maxZ,
      zsr,
      onStep,
      onChangeZ,
      onChangeZValue,
      onChangeZSr,
      playing,
      playDirection,
      onPlayPause,
      pointerEvents,
    } = this.props;

    return (
      <div
        className={classes.root}
        style={{ pointerEvents: pointerEvents ? "all" : "none" }}
      >
        <FontAwesomeIcon className={classes.barIcon} icon={faLayerGroup} />
        <Tooltip disableInteractive title={"Z position: " + z}>
          <TextField
            className={classes.verticalTextfield}
            style={{ top: 50 }}
            label="Z"
            value={Math.round(z)}
            min={0}
            max={ome.sizeZ - 1}
            type="number"
            onChange={(e) =>
              onChangeZValue(
                Math.max(
                  0,
                  Math.min(ome.sizeZ - 1, parseInt(e.target.value, 10))
                )
              )
            }
          />
        </Tooltip>
        <Tooltip
          disableInteractive
          title={"Frames per minute: " + Math.round(zsr)}
        >
          <TextField
            className={classes.verticalTextfield}
            style={{ top: 100 }}
            label="FPM"
            value={zsr}
            type="number"
            onChange={(e) => {
              onChangeZSr(Math.max(1, parseInt(e.target.value, 10)));
            }}
          />
        </Tooltip>
        <IconButton
          className={classes.playPauseDown}
          style={{
            transform:
              playing && playDirection < 0 ? "rotate(0deg)" : "rotate(90deg)",
          }}
          onClick={() =>
            playDirection > 0
              ? onPlayPause(true, -1)
              : onPlayPause(!playing, -1)
          }
          size="large"
        >
          {playing && playDirection < 0 ? (
            <PauseCircleOutline color="inherit" />
          ) : (
            <PlayCircleOutline color="inherit" />
          )}
        </IconButton>
        <IconButton
          className={classes.stepUp}
          onClick={() => onStep(1)}
          size="large"
        >
          <ArrowDropUp color="inherit" />
        </IconButton>
        <div className={classes.slider}>
          <Slider
            orientation="vertical"
            min={0}
            max={ome.sizeZ - 1}
            ValueLabelComponent={ValueLabelComponent}
            valueLabelDisplay="auto"
            value={[minZ, z, maxZ]}
            onChange={onChangeZ}
            color="primary"
          />
        </div>
        <IconButton
          className={classes.stepDown}
          onClick={() => onStep(-1)}
          size="large"
        >
          <ArrowDropDown color="inherit" />
        </IconButton>
        <IconButton
          className={classes.playPauseUp}
          style={{
            transform:
              playing && playDirection > 0 ? "rotate(0deg)" : "rotate(-90deg)",
          }}
          onClick={() =>
            playDirection > 0 ? onPlayPause(!playing, 1) : onPlayPause(true, 1)
          }
          size="large"
        >
          {playing && playDirection > 0 ? (
            <PauseCircleOutline color="inherit" />
          ) : (
            <PlayCircleOutline color="inherit" />
          )}
        </IconButton>
      </div>
    );
  }
}

// define the component's interface
ZStackBar.propTypes = {
  classes: PropTypes.object.isRequired,
  ome: PropTypes.object,
  playing: PropTypes.bool,
  playDirection: PropTypes.number,
  z: PropTypes.number,
  minZ: PropTypes.number,
  maxZ: PropTypes.number,
  onStep: PropTypes.func,
  onChangeZSr: PropTypes.func,
  onChangeZValue: PropTypes.func,
  zsr: PropTypes.number,
  onChangeZ: PropTypes.func,
  onPlayPause: PropTypes.func,
  pointerEvents: PropTypes.bool,
};

export default withStyles(styles)(ZStackBar);

import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";

import {
  DialogContent,
  DialogContentText,
  List,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
} from "@mui/material";

// import { getContainedRegionRois } from "../../../viewer/utils/StructuresUtils";
import ListItemStructure from "../../../viewer/components/ListItemStructure";
import ListItemSubType from "../../../viewer/components/ListItemSubType";

const styles = () => ({});

class TrainModelStep1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allStructuresEnabled: false,
    };
    let allStructures = props.structures.map((item) => {
      return item;
    });
    allStructures.forEach((item, index) => (item.index = index));
    this.structures = allStructures;
  }

  render() {
    const { classes, ...propsWithoutClasses } = this.props;

    return (
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Grid item xs={10}>
            <DialogContentText>
              Select all structures that should be detected/classified by your
              model:
            </DialogContentText>
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              style={{ marginLeft: "25px" }}
              control={
                <Checkbox
                  checked={this.state.allStructuresEnabled}
                  onChange={(e) =>
                    this.setState({ allStructuresEnabled: e.target.checked })
                  }
                />
              }
              label={
                <Typography variant="caption">Enable all structures</Typography>
              }
            />
          </Grid>
        </Grid>
        <List>
          {this.structures.map((structure, index) => {
            return structure.isSubtype ? (
              <ListItemSubType
                {...propsWithoutClasses}
                isInView={null}
                key={structure.id + "-" + structure.id}
                subType={structure}
                parent={null}
                isAdmin={false}
                i={index}
                renderLocation={"AI-Cockpit"}
                allStructuresEnabled={this.state.allStructuresEnabled}
                updateFormDataStructures={this.props.updateFormDataStructures}
              />
            ) : (
              <ListItemStructure
                {...propsWithoutClasses}
                key={structure.id}
                structure={structure}
                index={index}
                isAdmin={false}
                renderLocation={"AI-Cockpit"}
                allStructuresEnabled={this.state.allStructuresEnabled}
                updateFormDataStructures={this.props.updateFormDataStructures}
              />
            );
          })}
        </List>
      </DialogContent>
    );
  }
}

TrainModelStep1.propTypes = {
  classes: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  projectProperties: PropTypes.object,
  projectStringProperties: PropTypes.object,
  onChangeMetaData: PropTypes.func,
  structures: PropTypes.array,
  roiLayers: PropTypes.array,
  updateFormDataStructures: PropTypes.func,
};

export default withStyles(styles)(TrainModelStep1);

import React, { Component } from "react";
import PropTypes from "prop-types";

import { withAllViewerContexts } from "../contexts/AllViewerContexts";
import { withTiles } from "../contexts/TilesContext";
import { withResultTab } from "../contexts/ResultTabContext";

import {
  Grid,
  Typography,
  Button,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import withStyles from "@mui/styles/withStyles";

const styles = {};

class ScoringMatrixHistoClassification extends Component {
  constructor(props) {
    super(props);

    window.addFrequencyClass = this.addFrequencyClass;
  }

  componentDidMount = () => {};

  componentDidUpdate() {}

  UNSAFE_componentWillMount = () => {
    // get all parent structures
    let selSample = this.props.persistentStorage.load("selLayerSample");
    if (selSample) {
      this.props.onSelectLayer(selSample);
      let selId = this.props.structures[selSample].id;

      // get all parent structures
      let parentStructures = this.props.structures.filter(
        (element) => element.subtypeLevel === 0
      );

      // get Index of sample in parent structures
      let parentIdxSelStructure = parentStructures.findIndex(
        (element) => element.id === selId
      );
      this.props.resultTab.setSelectedStructure(parentIdxSelStructure);
    }
  };

  findChilds = (subType) => {
    const { structures } = this.props;
    // return direct childs of structure
    return structures.filter(
      (element) =>
        element.subtypeLevel === subType.subtypeLevel + 1 &&
        element.parentId === subType.id
    );
  };

  getAbbreviationOfStructure = (structure) => {
    // get abbreviation of structure --> label [abbreviation]
    let strWithBracket = structure.label.split("[")[1];
    if (!strWithBracket) {
      strWithBracket = structure.label;
    }
    let abbreviation = strWithBracket.substring(0, strWithBracket.length - 1);
    return abbreviation;
  };

  isCriticalOrSelected = (structure) => {
    const { structures, selectedLayer } = this.props;
    // check if structure is selected (color dark grey) or critical (color red)
    let selectedChildIndex = this.props.resultTab.getSelectedChildIndex();
    if (this.isStructureCritical(structure)) {
      // if structure has critical value
      return "#ff0000";
    } else if (
      structure.id === structures[selectedLayer + selectedChildIndex].id
    ) {
      // if structure is selected
      return "#d8d8d8";
    } else {
      return "#ffffff";
    }
  };

  isStructureCritical = (structure) => {
    const { selectedLayer, roiLayers, project } = this.props;
    // calculate arithmetic mean (look at specifications)
    let numberTiles =
      project.files.length * roiLayers[selectedLayer].layer.regionRois.length;

    let totalClassFrequencies = 0;
    let idx = 0;
    for (const [key, value] of Object.entries(structure.classFrequencies)) {
      if (key !== "class_0") {
        totalClassFrequencies = totalClassFrequencies + value * idx;
      }
      idx = idx + 1;
    }

    let mean = Math.round(totalClassFrequencies / numberTiles);
    this.setAvgClassFrequ(structure, mean);

    // if mean is greater or equal than critical class of structure return true
    if (mean >= structure.criticalClass && structure.criticalClass !== 0) {
      return true;
    } else {
      return false;
    }
  };

  setAvgClassFrequ = (structure, mean) => {
    // set average frequency class
    if (!Number.isNaN(mean) && mean !== null) {
      structure.avgClassFrequency = mean;
    }
  };

  subtractFrequencyClass = (x) => {
    const { structures, selectedLayer } = this.props;
    // subtract frequency class (is structure gert reclassified)

    let selectedChildIndex = this.props.resultTab.getSelectedChildIndex();
    let selectedStructure = selectedLayer + selectedChildIndex;

    switch (x) {
      case 0:
        structures[selectedStructure].classFrequencies.class_0 =
          structures[selectedStructure].classFrequencies.class_0 - 1;
        break;
      case 1:
        structures[selectedStructure].classFrequencies.class_1 =
          structures[selectedStructure].classFrequencies.class_1 - 1;
        break;
      case 2:
        structures[selectedStructure].classFrequencies.class_2 =
          structures[selectedStructure].classFrequencies.class_2 - 1;
        break;
      case 3:
        structures[selectedStructure].classFrequencies.class_3 =
          structures[selectedStructure].classFrequencies.class_3 - 1;
        break;
      case 4:
        structures[selectedStructure].classFrequencies.class_4 =
          structures[selectedStructure].classFrequencies.class_4 - 1;
        break;
      case 5:
        structures[selectedStructure].classFrequencies.class_5 =
          structures[selectedStructure].classFrequencies.class_5 - 1;
        break;
      case 6:
        structures[selectedStructure].classFrequencies.class_6 =
          structures[selectedStructure].classFrequencies.class_6 - 1;
        break;
      default:
    }
  };

  addFrequencyClass = (x) => {
    const { structures, selectedLayer, roiLayers, selectedRoi } = this.props;
    // add frequency class when classifying tile

    let selectedChildIndex = this.props.resultTab.getSelectedChildIndex();

    // if not started no classification possible
    if (!this.props.tiles.getHsitoClassificationStarted()) {
      window.showWarningSnackbar("Please click 'RUN' to classify.");
      return;
    }

    // if no grid
    if (roiLayers[selectedLayer].layer.regionRois.length === 0) {
      window.showWarningSnackbar("Please create a grid before annotating");
      this.props.onChangeTool("gridtool");
      return;
    }

    /*// zoomLevel is not fixed yet
    if (!this.props.zoomLevelFixed) {
      window.showWarningSnackbar("Plese fix zoom level before annotating");
      return;
    }*/

    let selectedStructure = selectedLayer + selectedChildIndex;

    // check if tile was classified before
    let fClass =
      roiLayers[selectedStructure].layer.regionRois[selectedRoi].frequencyClass;
    if (fClass !== -1) {
      // if tile was classified before undo previous classification
      this.subtractFrequencyClass(fClass);
    }

    switch (x) {
      case 0:
        structures[selectedStructure].classFrequencies.class_0 =
          structures[selectedStructure].classFrequencies.class_0 + 1;
        break;
      case 1:
        structures[selectedStructure].classFrequencies.class_1 =
          structures[selectedStructure].classFrequencies.class_1 + 1;
        break;
      case 2:
        structures[selectedStructure].classFrequencies.class_2 =
          structures[selectedStructure].classFrequencies.class_2 + 1;
        break;
      case 3:
        structures[selectedStructure].classFrequencies.class_3 =
          structures[selectedStructure].classFrequencies.class_3 + 1;
        break;
      case 4:
        structures[selectedStructure].classFrequencies.class_4 =
          structures[selectedStructure].classFrequencies.class_4 + 1;
        break;
      case 5:
        structures[selectedStructure].classFrequencies.class_5 =
          structures[selectedStructure].classFrequencies.class_5 + 1;
        break;
      case 6:
        structures[selectedStructure].classFrequencies.class_6 =
          structures[selectedStructure].classFrequencies.class_6 + 1;
        break;
      default:
    }

    // assign frequencyClass to tile
    roiLayers[selectedStructure].layer.regionRois[
      this.props.selectedRoi
    ].frequencyClass = x;

    if (
      selectedChildIndex < this.findChilds(structures[selectedLayer]).length
    ) {
      // not last substructure of tile
      let newSelChildIdx = selectedChildIndex + 1;
      this.props.resultTab.setSelectedChildIndex(newSelChildIdx);
    } else {
      // last substructure of tile
      this.props.resultTab.setSelectedChildIndex(1);
    }

    if (
      structures[selectedStructure] &&
      this.isStructureCritical(structures[selectedStructure]) &&
      !structures[selectedStructure].warningWasShown
    ) {
      // show warning that sample is critical
      let warningStr =
        "Histologische Beurteilung auffällig (" +
        structures[selectedStructure].label +
        " reached average class " +
        structures[selectedStructure].criticalClass +
        ").";

      window.showErrorSnackbar(warningStr);
      structures[selectedStructure].warningWasShown = true;
    }

    this.forceUpdate();
  };

  handleChangeSelectedStructure = (e) => {
    // get all parent structures
    let parentStructures = this.props.structures.filter(
      (element) => element.subtypeLevel === 0
    );

    let idxNewSelectedStructure = this.props.structures.findIndex(
      (element) => element.id === parentStructures[e.target.value].id
    );

    if (idxNewSelectedStructure >= 0) {
      this.props.onSelectLayer(idxNewSelectedStructure);
    }

    this.props.resultTab.setSelectedChildIndex(1);
    this.props.resultTab.setSelectedStructure(e.target.value);

    // reset structures
    this.props.resetStructures(true);

    // save selectedLayer in local storage
    this.props.persistentStorage.save(
      "selLayerSample",
      idxNewSelectedStructure
    );
  };

  selectRow = (structure) => {
    const { structures, selectedLayer } = this.props;
    // select row which was clicked
    // get childindex of selected structure
    let childIdx = this.findChilds(structures[selectedLayer]).findIndex(
      (element) => element.id === structure.id
    );
    this.props.resultTab.setSelectedChildIndex(childIdx + 1);
    this.forceUpdate();
  };

  renderSubtypeRow = (structure, idx) => {
    const { structures, roiLayers } = this.props;

    // if it is critical make row red
    let backgroundColor = this.isCriticalOrSelected(structure);

    // get frequencyClass of selected Tile for row
    let structureIndex = structures.findIndex(
      (element) => element.id === structure.id
    );
    let rowFrequencyClass = 0;
    if (
      structureIndex > -1 &&
      roiLayers[structureIndex].layer.regionRois.length > 0 &&
      roiLayers[structureIndex].layer.regionRois[this.props.selectedRoi]
    ) {
      rowFrequencyClass =
        roiLayers[structureIndex].layer.regionRois[this.props.selectedRoi]
          .frequencyClass;
    }

    if (!structure.avgClassFrequency) {
      structure.avgClassFrequency = 0;
    }

    return (
      <Grid
        key={idx}
        container
        spacing={0}
        style={{
          background: backgroundColor,
        }}
        onClick={() => this.selectRow(structure)}
      >
        <Grid item xs>
          <Tooltip disableInteractive title={structure.label}>
            <Typography>
              {this.getAbbreviationOfStructure(structure)}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs>
          <Typography
            style={{
              background:
                (rowFrequencyClass === 0 || rowFrequencyClass === -1) &&
                "#A4A4A4",
              textAlign: "center",
            }}
          >
            {structure.classFrequencies.class_0}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography
            style={{
              background: rowFrequencyClass === 1 && "#A4A4A4",
              textAlign: "center",
            }}
          >
            {structure.classFrequencies.class_1}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography
            style={{
              background: rowFrequencyClass === 2 && "#A4A4A4",
              textAlign: "center",
            }}
          >
            {structure.classFrequencies.class_2}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography
            style={{
              background: rowFrequencyClass === 3 && "#A4A4A4",
              textAlign: "center",
            }}
          >
            {structure.classFrequencies.class_3}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography
            style={{
              background: rowFrequencyClass === 4 && "#A4A4A4",
              textAlign: "center",
            }}
          >
            {structure.classFrequencies.class_4}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography
            style={{
              background: rowFrequencyClass === 5 && "#A4A4A4",
              textAlign: "center",
            }}
          >
            {structure.classFrequencies.class_5}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography
            style={{
              background: rowFrequencyClass === 6 && "#A4A4A4",
              textAlign: "center",
            }}
          >
            {structure.classFrequencies.class_6}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography
            style={{
              textAlign: "center",
            }}
          >
            {structure.avgClassFrequency}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  renderFrequencyClasses = () => {
    return (
      <Grid container spacing={0}>
        <Grid item xs>
          <Typography
            style={{
              textAlign: "center",
              color: "#ffffff",
            }}
          >
            0
          </Typography>
          <Typography
            style={{
              background: "#ffffff",
              color: "#ffffff",
              textAlign: "center",
            }}
          >
            0
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography
            style={{
              textAlign: "center",
              color: "#ffffff",
            }}
          >
            0
          </Typography>
          <Tooltip
            disableInteractive
            title="Classify with key [0]"
            placement="top"
          >
            <Button
              style={{
                background: "#ffffff",
                textAlign: "center",
                width: "100%",
              }}
              onClick={() => this.addFrequencyClass(0)}
            >
              0
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs>
          <Typography
            style={{
              textAlign: "center",
              width: "100%",
            }}
          >
            1x
          </Typography>
          <Tooltip
            disableInteractive
            title="Classify with key [1]"
            placement="top"
          >
            <Button
              style={{
                background: "#d9eeff",
                textAlign: "center",
                width: "100%",
              }}
              onClick={() => this.addFrequencyClass(1)}
            >
              1
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs>
          <Typography
            style={{
              textAlign: "center",
              width: "100%",
            }}
          >
            {"<5%"}
          </Typography>
          <Tooltip
            disableInteractive
            title="Classify with key [2]"
            placement="top"
          >
            <Button
              style={{
                background: "#b9dfff",
                textAlign: "center",
                width: "100%",
              }}
              onClick={() => this.addFrequencyClass(2)}
            >
              2
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs>
          <Typography
            style={{
              textAlign: "center",
              width: "100%",
            }}
          >
            5-20%
          </Typography>
          <Tooltip
            disableInteractive
            title="Classify with key [3]"
            placement="top"
          >
            <Button
              style={{
                background: "#9fd4ff",
                textAlign: "center",
                width: "100%",
              }}
              onClick={() => this.addFrequencyClass(3)}
            >
              3
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs>
          <Typography
            style={{
              textAlign: "center",
              width: "100%",
            }}
          >
            20-35%
          </Typography>
          <Tooltip
            disableInteractive
            title="Classify with key [4]"
            placement="top"
          >
            <Button
              style={{
                background: "#79c2ff",
                textAlign: "center",
                width: "100%",
              }}
              onClick={() => this.addFrequencyClass(4)}
            >
              4
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs>
          <Typography
            style={{
              textAlign: "center",
              width: "100%",
            }}
          >
            35-50%
          </Typography>
          <Tooltip
            disableInteractive
            title="Classify with key [5]"
            placement="top"
          >
            <Button
              style={{
                background: "#5db6ff",
                textAlign: "center",
                width: "100%",
              }}
              onClick={() => this.addFrequencyClass(5)}
            >
              5
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs>
          <Typography
            style={{
              textAlign: "center",
              width: "100%",
            }}
          >
            {">50%"}
          </Typography>
          <Tooltip
            disableInteractive
            title="Classify with key [6]"
            placement="top"
          >
            <Button
              style={{
                background: "#37a5ff",
                textAlign: "center",
                width: "100%",
              }}
              onClick={() => this.addFrequencyClass(6)}
            >
              6
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={1}>
          <Typography
            style={{
              textAlign: "center",
              color: "#ffffff",
            }}
          >
            Ø
          </Typography>
          <Tooltip
            disableInteractive
            title="Average frequency class"
            placement="top"
          >
            <Typography
              style={{
                background: "#ffffff",
                textAlign: "center",
                width: "100%",
                marginTop: "7px",
              }}
            >
              Ø
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  isSelSampleSet = () => {
    // disable dropdown if sample is set and classification started
    let dropdownDisabled = this.props.tiles.getHsitoClassificationStarted();
    return dropdownDisabled;
  };

  render() {
    const { structures, selectedLayer } = this.props;
    return (
      <div>
        <FormControl>
          <InputLabel>Sample</InputLabel>
          <Select
            label="Sample"
            variant="standard"
            value={this.props.resultTab.getSelectedStructure()}
            onChange={this.handleChangeSelectedStructure}
            disabled={this.isSelSampleSet()}
          >
            {this.props.structures
              .filter((element) => element.subtypeLevel === 0)
              .map((structure, idx) => (
                <MenuItem key={idx} value={idx}>
                  {structure.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <div style={{ overflowX: "auto" }}>
          <div style={{ minWidth: "550px" }}>
            {this.renderFrequencyClasses()}
            {this.findChilds(structures[selectedLayer]).map((structure, idx) =>
              this.renderSubtypeRow(structure, idx)
            )}
          </div>
        </div>
      </div>
    );
  }
}

// define the component's interface
ScoringMatrixHistoClassification.propTypes = {
  persistentStorage: PropTypes.object,
  structures: PropTypes.array,
  roiLayers: PropTypes.array,
  resultTab: PropTypes.object,
  onSelectLayer: PropTypes.func,
  selectedLayer: PropTypes.number,
  project: PropTypes.object,
  selectedRoi: PropTypes.number,
  tiles: PropTypes.object,
  onChangeTool: PropTypes.func,
  resetStructures: PropTypes.func,
};

export default withAllViewerContexts(
  withTiles(withResultTab(withStyles(styles)(ScoringMatrixHistoClassification)))
);

import React, { Component } from "react";
import PropTypes from "prop-types";

import Pagination from "@mui/material/Pagination";

import {
  getContainedRegionRois,
  getParentIndexLayer,
} from "../../utils/StructuresUtils";
import { pointInside } from "../../utils/PolygonUtil";

import {
  Tooltip,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";

import { Delete } from "@mui/icons-material";
import Tool from "./Tool";
import RBush from "rbush";

class FindSmallestROITool extends Tool {
  name = "FindSmallestROITool";
  selection = null;
  selectionIdx = 0;
  sortedTreeItems = [];
  checkJumps = true;
  regionRois = [];
  tree = new RBush();
  structure = null;
  parentIndex = 0;

  setLayer(obj) {
    this.structures = obj.structures;
    this.layer = obj.layer;
    this.roiLayers = obj.roiLayers;
    this.selectedLayer = obj.selectedLayer;
    this.structure = obj.structures[obj.selectedLayer];
    this.regionRois = getContainedRegionRois(
      this.structure,
      this.structures,
      this.roiLayers
    );
    this.parentIndex = getParentIndexLayer(this.structure, this.structures);
    this.tree.clear();
    this.tree.load(this.regionRois.map((item) => item.treeItem));
    this.updateSortedTreeItems();
  }

  setPreviewRect() {}

  findSmallestTreeItem(p) {
    let minArea = Number.MAX_SAFE_INTEGER;
    let resultItem;
    if (this.structures[this.selectedLayer].visible) {
      let treeItems = this.tree.search({
        minX: p.x,
        minY: p.y,
        maxX: p.x,
        maxY: p.y,
      });
      for (let treeItem of treeItems) {
        let b = treeItem.roi.bounds;

        if (pointInside(p, treeItem.roi)) {
          let bArea = (b.right - b.left) * (b.bottom - b.top);
          if (bArea < minArea) {
            minArea = bArea;
            resultItem = treeItem;
          }
        }
      }
    }
    return resultItem;
  }

  deleteSelection() {
    let historyItem = [];
    let histId = this.structures[this.parentIndex].id;
    this.roiLayers[this.parentIndex].tree.remove(this.selection);
    this.tree.remove(this.selection);
    historyItem.push({ add: false, id: histId, roi: this.selection.roi });
    this.regionRois = this.tree.all().map((treeItem) => treeItem.roi);
    this.roiLayers[this.parentIndex].layer.regionRois = this.roiLayers[
      this.parentIndex
    ].tree
      .all()
      .map((treeItem) => treeItem.roi);

    this.updateSortedTreeItems();

    if (this.selectionIdx < this.sortedTreeItems.length) {
      this.selection = this.sortedTreeItems[this.selectionIdx];
      if (this.checkJumps) window.zoomToRect(this.selection.roi.bounds);
    } else if (this.sortedTreeItems.length > 0) {
      this.selectionIdx = this.sortedTreeItems.length - 1;
      this.selection = this.sortedTreeItems[this.selectionIdx];
      if (this.checkJumps) window.zoomToRect(this.selection.roi.bounds);
    } else {
      this.selection = null;
    }
    window.forceSidebarUpdate();
    window.projectHistory.add(historyItem);
  }
  /**
   * handle shortcuts
   * @param {ActionEvent} event Event when keyboard button is pressed
   */
  onKeyDown(event) {
    if (event.key === "Delete" && this.selection) {
      this.deleteSelection();
    }
  }

  mouse(params) {
    let { event, p } = params;
    this.curX = p.x;
    this.curY = p.y;

    // mouse middle button
    if (event.button === 1) {
      return;
    }

    if (event.type === "mousedown") {
      let treeItem = this.findSmallestTreeItem(p);
      this.selection = treeItem;
      this.updateSortedTreeItems();
      if (this.selection) {
        this.selectionIdx = this.selection.sortIdx;
        window.forceSidebarUpdate();
      }
    }
  }

  /**
   * Draw a custom cursor
   */
  drawCustomCursor(ctx) {
    if (this.selection) {
      ctx.beginPath();
      ctx.globalAlpha = 1.0;
      ctx.strokeStyle = "#ffffff";
      let b = this.selection.roi.bounds;
      // no custom cursor
      ctx.rect(b.left, b.top, b.right - b.left, b.bottom - b.top);
      ctx.stroke();
      ctx.closePath();
    }
  }

  /**
   * Sorts all rois of the selected structure by area, should they not already be sorted.
   */
  updateSortedTreeItems = () => {
    if (this.sortedTreeItems !== this.regionRois) {
      this.sortedTreeItems = this.tree.all().sort((treeItem1, treeItem2) => {
        return treeItem1.roi.area - treeItem2.roi.area;
      });
      for (let i = 0; i < this.sortedTreeItems.length; i++) {
        this.sortedTreeItems[i]["sortIdx"] = i;
      }
    }
  };

  selectRegion = (v) => {
    this.updateSortedTreeItems();
    this.selectionIdx = v - 1;
    this.selection = this.sortedTreeItems[this.selectionIdx];
    if (this.selection && this.selection.roi) {
      if (this.checkJumps) window.zoomToRect(this.selection.roi.bounds);
      window.forceSidebarUpdate();
    }
  };

  toggleJumps = () => {
    this.checkJumps = !this.checkJumps;
    window.forceSidebarUpdate();
  };

  exit() {}

  renderConfiguration = () => {
    return (
      <div>
        <Typography variant="h6">{this.name}:</Typography>
        <ConfigForm
          selectionIdx={this.selectionIdx}
          selectRegion={(v) => this.selectRegion(v)}
          delete={() => this.deleteSelection()}
          regionRois={this.regionRois}
          selection={this.selection}
          checkJumps={this.checkJumps}
          toggleJumps={() => this.toggleJumps()}
        />
      </div>
    );
  };
}

class ConfigForm extends Component {
  render() {
    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={this.props.checkJumps}
              onChange={() => {
                this.props.toggleJumps();
              }}
              value={this.props.checkJumps}
            />
          }
          label="Jump to selection"
        />
        <br />
        <TextField
          fullWidth
          label="Sorted object number:"
          type="number"
          value={this.props.selectionIdx + 1}
          margin="normal"
          size="small"
          onChange={(e) => {
            this.props.selectRegion(e.target.value);
          }}
          variant="outlined"
          InputProps={{
            inputProps: { min: 1, max: this.props.regionRois.length },
          }}
        />
        <br />
        <Pagination
          page={this.props.selectionIdx + 1}
          style={{ display: "inline-block" }}
          variant="outlined"
          shape="rounded"
          onChange={(e, v) => {
            this.props.selectRegion(v);
          }}
          count={this.props.regionRois.length}
        />
        <Tooltip disableInteractive title="Delete selected Structure (Del)">
          <IconButton
            onClick={() => {
              this.props.delete();
            }}
            size="large"
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

ConfigForm.propTypes = {
  checkJumps: PropTypes.bool,
  toggleJumps: PropTypes.func,
  selectionIdx: PropTypes.number,
  selectRegion: PropTypes.func,
  regionRois: PropTypes.array,
  delete: PropTypes.func,
};

export default FindSmallestROITool;

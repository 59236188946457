import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import PropTypes from "prop-types";

import { withScanViewerContext } from "../contexts/ScanViewerContext";
import CustomSlider from "../../globalComponents/CustomSlider";
import CustomSelector from "../../globalComponents/CustomSelector";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  // TextField, // for DEBUG
} from "@mui/material";

import { PlayArrow, Stop, Info } from "@mui/icons-material";

const styles = {
  root: {
    padding: 20,
    height: "100%",
    overflowY: "auto",
  },
  OpticsRow: {
    display: "grid",
    gridTemplateColumns: "1fr auto auto",
    width: "100%",
    marginTop: 10,
  },
  OpticsSelector: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "100%",
  },
  vignetteRow: {
    display: "grid",
    gridTemplateColumns: "1fr auto auto",
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
  },
  recordButton: {
    lineHeight: "30px",
  },
  checkboxLabel: {
    margin: "0px !important",
  },
  textfieldNumber: {
    width: "90px",
  },
  featurePointRow: {
    display: "grid",
    gridTemplateColumns: "1fr auto auto",
    width: "100%",
    marginTop: 15,
  },
  infoButton: {
    height: 40,
    marginTop: 16,
  },
};

/**
 * Class to render Camera Settings, read them and send them to backend
 */
class ScanMicroscopeSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      vignetteState: "",
      // checkedFeaturePoint: false, // for DEBUG
      // numFeaturePoints: 3, // for DEBUG
    };

    // sets the colors (RGB) to the chosen white balance
    this.lightSourceParams = {
      label: "Light Source",
      selectedValue: "Daylight6500K",
      info: [
        "Sets the light source preset.",
        "The colors in the image will be corrected so that they are appropriate for the selected light source.",
      ],
      options: [
        {
          name: "Off",
          label: "Off",
        },
        {
          name: "Daylight5000K",
          label: "Daylight (5000 Kelvin)",
        },
        {
          name: "Daylight6500K",
          label: "Daylight (6500 Kelvin)",
        },
        {
          name: "Tungsten2800K",
          label: "Tungsten (2800 Kelvin)",
        },
      ],
      onChange: (value) => {
        this.lightSourceParams.selectedValue = value;
        if (value === "Off") {
          this.sliderParams.red.value = 1.0;
          this.sliderParams.green.value = 1.0;
          this.sliderParams.blue.value = 1.0;
        } else if (value === "Daylight5000K") {
          this.sliderParams.red.value = 1.57349;
          this.sliderParams.green.value = 1.0;
          this.sliderParams.blue.value = 2.2749;
        } else if (value === "Daylight6500K") {
          this.sliderParams.red.value = 1.82056;
          this.sliderParams.green.value = 1.0;
          this.sliderParams.blue.value = 1.94189;
        } else if (value === "Tungsten2800K") {
          this.sliderParams.red.value = 1.06372;
          this.sliderParams.green.value = 1.0;
          this.sliderParams.blue.value = 4.17261;
        }
        this.forceUpdate();
        this.sendParams();
      },
    };

    this.lenseParams = {
      label: "Lense Type",
      selectedValue: "40x",
      options: [
        {
          name: "4x",
          label: "4x",
        },
        {
          name: "10x",
          label: "10x",
        },
        {
          name: "20x",
          label: "20x",
        },
        {
          name: "40x",
          label: "40x",
        },
        {
          name: "100x",
          label: "100x",
        },
      ],
      onChange: (value) => {
        this.lenseParams.selectedValue = value;
        this.forceUpdate();
        this.sendParams();
      },
    };
    this.adapterParams = {
      label: "Adapter Type",
      selectedValue: "0.65x",
      options: [
        {
          name: "0.65x",
          label: "0.65x",
        },
        {
          name: "1x",
          label: "None",
        },
      ],
      onChange: (value) => {
        this.adapterParams.selectedValue = value;
        this.forceUpdate();
        this.sendParams();
      },
    };
    this.sliderParams = {
      frameRate: {
        type: "Slider",
        label: "Frame Rate",
        info: [
          "Acquisition frame rate of the camera in frames per second.",
          "Double click to reset value to default.",
        ],
        value: 30,
        minKey: "cameraMinFramerate",
        maxKey: "cameraMaxFramerate",
        step: 1,
        standardValue: 30,
      },
      exposureTime: {
        type: "Slider",
        label: "Exposure Time",
        info: [
          "Value of the shutter speed the camera uses.",
          "The higher the shutter speed, the darker the image ",
        ],
        value: 250,
        minKey: "cameraMinExposureTime",
        maxKey: "cameraMaxExposureTime",
        standardValue: 250,
      },
      gain: {
        type: "Slider",
        label: "Gain",
        info: [
          "Value of the currently selected gain in dB.",
          "Double click to reset value to default.",
        ],
        value: 0.1,
        minKey: "cameraMinGain",
        maxKey: "cameraMaxGain",
        step: 0.1,
        standardValue: 0.1,
      },
      gamma: {
        type: "Slider",
        label: "Gamma",
        info: [
          "Gamma correction to be applied.",
          "Gamma correction allows you to optimize the brightness of acquired images for display on a monitor.",
          "Double click to reset value to default.",
        ],
        value: 1.0,
        minKey: "cameraMinGamma",
        maxKey: "cameraMaxGamma",
        step: 0.01,
        standardValue: 1.0,
      },
      blackValue: {
        type: "Slider",
        label: "Black Value",
        info: [
          "Black level value to be applied to the currently selected sensor tap.",
          "Double click to reset value to default.",
        ],
        value: 0,
        minKey: "cameraMinBlackValue",
        maxKey: "cameraMaxBlackValue",
        step: 0.1,
        standardValue: 0,
      },
      red: {
        type: "Slider",
        label: "Red",
        info: [
          "Sets the light source preset.",
          "The colors in the image will be corrected so that they are appropriate for the red light source.",
          "Double click to reset value to default.",
        ],
        value: 1.82,
        minKey: "cameraColorMin",
        maxKey: "cameraColorMax",
        step: 0.01,
      },
      green: {
        type: "Slider",
        label: "Green",
        info: [
          "Sets the light source preset.",
          "The colors in the image will be corrected so that they are appropriate for the green light source.",
          "Double click to reset value to default.",
        ],
        value: 1.0,
        minKey: "cameraColorMin",
        maxKey: "cameraColorMax",
        step: 0.01,
      },
      blue: {
        type: "Slider",
        label: "Blue",
        info: [
          "Sets the light source preset.",
          "The colors in the image will be corrected so that they are appropriate for the blue light source.",
          "Double click to reset value to default.",
        ],
        value: 1.94,
        minKey: "cameraColorMin",
        maxKey: "cameraColorMax",
        step: 0.01,
      },
    };

    this.sharpnessBenchmark = {
      type: "Slider",
      label: "Image Sharpness Benchmark",
      info: [
        "Sets the benchmark for evaluating how much in focus the image is.",
        "If the sharpness value is below the benchmark the image will not be stitched.",
        "It is possible that the image cannot be stitched when the sharpness is too low.",
        "So do not set the benchmark too low!",
      ],
      value: 80,
      min: 0,
      max: 100,
      standardValue: 80,
      onChange: (value) => {
        this.sharpnessBenchmark.value = value;
        this.props.scanViewerContext.setState({
          sharpnessBenchmark: this.sharpnessBenchmark.value,
        });
        this.forceUpdate();
        this.sendParams();
      },
      onSliderChange: (value) => {
        this.sharpnessBenchmark.value = value;
        this.props.scanViewerContext.setState({
          sharpnessBenchmark: this.sharpnessBenchmark.value,
        });
        this.forceUpdate();
        this.sendParams();
      },
      onDoubleClick: () => {
        this.sharpnessBenchmark.value = this.sharpnessBenchmark.standardValue;
        this.props.scanViewerContext.setState({
          sharpnessBenchmark: this.sharpnessBenchmark.value,
        });
        this.forceUpdate();
        this.sendParams();
      },
    };

    this.sendParams();
  }
  /**
   * create json with important values and send to backend
   */
  sendParams() {
    let paramJson = {
      lightSource: this.lightSourceParams.selectedValue,
      lense: this.lenseParams.selectedValue,
      adapter: this.adapterParams.selectedValue,
      sharpnessBenchmark: this.sharpnessBenchmark.value,
      pixelSizeCamera: this.props.scanViewerContext.pixelSizeCamera,
    };
    for (const [key, value] of Object.entries(this.sliderParams)) {
      paramJson[key] = value.value;
    }
    // Backend.configureMicroscopeCamera(paramJson);
    this.props.scanViewerContext.changeMicroscopeParams(paramJson);
  }

  render() {
    const { classes, scanViewerContext } = this.props;
    const {
      vignetteState,
      // checkedFeaturePoint, numFeaturePoints // for DEBUG
    } = this.state;
    return (
      <div className={classes.root}>
        <h2>Settings</h2>
        {/* not in use at the moment */}
        {/* <CustomSelector params={this.lightSourceParams} /> */}
        {Object.entries(this.sliderParams).map(([key, value], idx) => {
          let params = value;
          params.onChange = (value) => {
            this.sliderParams[key].value = value;
            this.forceUpdate();
            if (
              value >= scanViewerContext[params.minKey] &&
              value <= scanViewerContext[params.maxKey]
            ) {
              params.error = false;
              this.sendParams();
            } else {
              params.error = true;
            }
          };
          params.onSliderChange = (value) => {
            this.sliderParams[key].value = value;
            this.forceUpdate();
            this.sendParams();
          };
          params.onDoubleClick = () => {
            if (this.sliderParams[key].label === "Red") {
              switch (this.lightSourceParams.selectedValue) {
                case "Off":
                  this.sliderParams[key].value = 1.0;
                  break;
                case "Daylight5000K":
                  this.sliderParams[key].value = 1.57349;
                  break;
                case "Daylight6500K":
                  this.sliderParams[key].value = 1.82056;
                  break;
                case "Tungsten2800K":
                  this.sliderParams[key].value = 1.06372;
                  break;
              }
            } else if (this.sliderParams[key].label === "Green") {
              this.sliderParams[key].value = 1.0;
            } else if (this.sliderParams[key].label === "Blue") {
              switch (this.lightSourceParams.selectedValue) {
                case "Off":
                  this.sliderParams[key].value = 1.0;
                  break;
                case "Daylight5000K":
                  this.sliderParams[key].value = 2.2749;
                  break;
                case "Daylight6500K":
                  this.sliderParams[key].value = 1.94189;
                  break;
                case "Tungsten2800K":
                  this.sliderParams[key].value = 4.17261;
                  break;
              }
            } else {
              this.sliderParams[key].value =
                this.sliderParams[key].standardValue;
            }
            this.forceUpdate();
            this.sendParams();
          };
          return (
            <CustomSlider
              key={idx}
              params={params}
              min={this.props.scanViewerContext[params.minKey]}
              max={this.props.scanViewerContext[params.maxKey]}
            />
          );
        })}
        <div className={classes.OpticsRow}>
          <div className={classes.OpticsSelector}>
            <CustomSelector params={this.lenseParams} />
            <CustomSelector params={this.adapterParams} />
          </div>
          <IconButton
            className={classes.infoButton}
            onClick={() =>
              window.openWarningDialog([
                "Set the used lense and adapter magnification.",
                "These values are important for the Scanner to behave correctly.",
              ])
            }
          >
            <Info />
          </IconButton>
        </div>

        <div className={classes.vignetteRow}>
          <FormGroup>
            <FormControlLabel
              className={classes.checkboxLabel}
              control={
                <Checkbox
                  disabled={
                    !(
                      vignetteState === "ready" || vignetteState === "filtering"
                    )
                  }
                  checked={vignetteState === "filtering"}
                  onChange={() => {
                    const value =
                      vignetteState === "ready" ? "filtering" : "ready";
                    scanViewerContext.setVignetteState(value);
                    this.setState({ vignetteState: value });
                  }}
                />
              }
              label="Filter Vignette"
            />
          </FormGroup>
          <Button
            className={classes.recordButton}
            disabled={vignetteState === "filtering"}
            onClick={() => {
              const value =
                vignetteState === "" || vignetteState === "ready"
                  ? "recording"
                  : "ready";
              scanViewerContext.setVignetteState(value);
              this.setState({ vignetteState: value });
            }}
          >
            {vignetteState === "recording" ? <Stop /> : <PlayArrow />}{" "}
            {vignetteState === "recording"
              ? "Stop Recording"
              : "Record Vignette"}
          </Button>
          <IconButton
            className={classes.iconButton}
            onClick={() =>
              window.openWarningDialog([
                "To filter the vignette, a vignette must be recorded first:",
                "1. Navigate to a mostly white position.",
                "2. Record Vignette.",
                "3. Navigate around until shown vignette is not changing anymore.",
                "4. Stop Recording.",
                "5. Filter Vignette is now possible.",
              ])
            }
          >
            <Info />
          </IconButton>
        </div>
        <div>
          <CustomSlider
            params={this.sharpnessBenchmark}
            min={this.sharpnessBenchmark.min}
            max={this.sharpnessBenchmark.max}
          ></CustomSlider>
        </div>
        {/* for DEBUG use: */}
        {/* <div className={classes.featurePointRow}>
          <FormGroup row={true}>
            <FormControlLabel
              className={classes.checkboxLabel}
              control={
                <Checkbox
                  value={checkedFeaturePoint}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    this.setState({ checkedFeaturePoint: checked });
                    scanViewerContext.setFeaturePointsState(
                      checked,
                      numFeaturePoints
                    );
                  }}
                />
              }
              label="Show Feature Points"
            />
          </FormGroup>

          <TextField
            className={classes.textfieldNumber}
            label="Point Count"
            type="number"
            size="small"
            value={numFeaturePoints}
            InputProps={{ inputProps: { max: 5, min: 1 } }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              const numberFeaturePoints = e.target.value;
              this.setState({ numFeaturePoints: numberFeaturePoints });
              scanViewerContext.setFeaturePointsState(
                checkedFeaturePoint,
                numberFeaturePoints
              );
            }}
          />

          <IconButton
            className={classes.iconButton}
            onClick={() =>
              window.openWarningDialog([
                "Activates feature points and sets the number of feature points to be displayed.",
                "Feature points are points, that the computer uses for orientation. The Scanner needs them to compare the position of different images.",
                "This function is mainly for development use.",
              ])
            }
          >
            <Info />
          </IconButton>
        </div> */}
      </div>
    );
  }
}

ScanMicroscopeSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  scanViewerContext: PropTypes.object.isRequired,
};

export default withScanViewerContext(
  withStyles(styles)(ScanMicroscopeSettings)
);

import React from "react";
import PropTypes from "prop-types";
//import { userService } from "../../common/services";
import Backend from "../../common/utils/Backend";
import {
  IconButton,
  TableRow,
  TableCell,
  TableHead,
  Table,
  TableBody,
  Button,
  Card,
  CardContent,
  Typography,
  CardActions,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  MenuItem,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { Delete, Edit } from "@mui/icons-material";

const styles = {
  paper: {
    padding: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

class UserDetailsDialog extends React.Component {
  state = { user: null };
  componentDidUpdate(prevProps) {
    // dialog open
    if (this.props.open !== prevProps.open && this.props.open) {
      this.setState({ user: JSON.parse(JSON.stringify(this.props.user)) });
    }
  }

  onChange = (e) => {
    const { user } = this.state;
    user[e.target.name] = e.target.value;
    this.setState({ user });
  };

  render() {
    const { open, onClose, onApply, create } = this.props;
    const { user } = this.state;
    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">User Details</DialogTitle>
        {user && (
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="fullName"
              label="Full Name"
              fullWidth
              value={user.fullName}
              onChange={this.onChange}
            />
            <TextField
              disabled={!create}
              margin="dense"
              name="email"
              label="Email"
              type="email"
              fullWidth
              value={user.email}
              onChange={this.onChange}
            />
            <TextField
              margin="dense"
              name="password"
              label="Password"
              type="password"
              fullWidth
              value={user.password}
              onChange={this.onChange}
            />
            <TextField
              label="Role"
              select
              fullWidth
              value={user.role}
              onChange={this.onChange}
              inputProps={{
                name: "role",
              }}
            >
              <MenuItem value={"User"}>Standard User</MenuItem>
              <MenuItem value={"Admin"}>Admin</MenuItem>
            </TextField>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onApply(user)} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

UserDetailsDialog.propTypes = {
  open: PropTypes.bool,
  user: PropTypes.string,
  onClose: PropTypes.func,
  onApply: PropTypes.func,
  create: PropTypes.bool,
};

class UsersContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: null,
      userDetails: null,
      create: false,
    };
  }

  componentDidMount() {
    Backend.loadUserList((data) => {
      this.setState({ users: data });
    });
  }

  onUserEdit = (e) => {
    if (this.state.create) {
      Backend.createUser(e, () => {
        Backend.loadUserList((data) => {
          this.setState({ users: data });
        });
      });
    } else {
      Backend.updateUser(e, () => {
        Backend.loadUserList((data) => {
          this.setState({ users: data });
        });
      });
    }
    this.setState({ userDetails: null, create: false });
  };

  deleteUser = (id) => {
    window.openResponseDialog("Delete user forever?", (response) => {
      if (response) {
        Backend.deleteUser(id, () => {
          Backend.loadUserList((data) => {
            this.setState({ users: data });
          });
        });
      }
    });
  };

  newUserClick = () => {
    this.setState({
      userDetails: {
        email: "",
        fullName: "",
        role: "User",
        password: "",
      },
      create: true,
    });
  };

  render() {
    const { classes } = this.props;
    const { users, userDetails, create } = this.state;
    return (
      <Card>
        <CardContent>
          <Typography variant="h6">Users</Typography>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Full Name</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Role</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users &&
                users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell component="th" scope="row">
                      {user.fullName}
                    </TableCell>
                    <TableCell align="right">{user.email}</TableCell>
                    <TableCell align="right">{user.role}</TableCell>
                    <TableCell align="right">
                      <Tooltip disableInteractive title="Edit user details">
                        <IconButton
                          onClick={() => this.setState({ userDetails: user })}
                          size="large"
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                      <Tooltip disableInteractive title="Delete user forever">
                        <IconButton
                          onClick={() => this.deleteUser(user.id)}
                          size="large"
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" onClick={this.newUserClick}>
            Add new User
          </Button>
        </CardActions>
        <UserDetailsDialog
          open={Boolean(userDetails)}
          create={create}
          user={userDetails}
          onApply={this.onUserEdit}
          onClose={() => this.setState({ userDetails: null, create: false })}
        />
      </Card>
    );
  }
}

UsersContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

export default withStyles(styles)(UsersContainer);

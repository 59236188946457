import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import SwapVertIcon from "@mui/icons-material/SwapVert";

import { v4 as uuidv4 } from "uuid";

import {
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Slider,
  Checkbox,
} from "@mui/material";

import { withSpectraViewer } from "../../contexts/SpectraViewerContext";

const criteriaLowerBound = 0.0;
const criteriaUpperBound = 100.0;

const xValueRangeMin = 300.0;
const xValueRangeMax = 400.0;

const styles = {
  paddingContainer: {
    padding: 10,
  },
  applyButton: {
    width: "100%",
  },
  applyContainer: {
    display: "grid",
    overflow: "hidden",
  },
  applyBottomContent: {
    borderTop: "3px solid #EBEBEB",
    padding: 10,
    "& button:first-child": {
      marginBottom: 10,
    },
  },
  selectionContainer: {
    overflow: "auto",
    borderTop: "2px solid #EBEBEB",
  },
  minMaxInput: {
    marginTop: 10,
  },
};

const TooltipTable = withStyles({
  tooltip: {
    background: "#FFFFFF",
    border: "#EBEBEB solid 2px",
  },
})(Tooltip);

class ModelTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabScrollPosition: 0,
      scrollContainerHeight: 1000,
    };
    this.selectionContainerRef = React.createRef();
  }

  handleScroll = (e) => {
    const element = this.selectionContainerRef.current;
    this.setState({
      tabScrollPosition: e.target.scrollTop,
      scrollContainerHeight: element.clientHeight,
    });
  };

  /**
   * Rendering only visible models/pcas
   * @param {int} idx Index of option to check
   * @returns {bool} Whether or not the given index is in view
   */
  isInView = (idx) => {
    const pos = 91 + idx * 74; // Pixel offset
    return (
      pos + 75 >= this.state.tabScrollPosition &&
      pos <= this.state.tabScrollPosition + this.state.scrollContainerHeight
    );
  };

  componentDidMount = () => {
    if (this.selectionContainerRef.current) {
      this.selectionContainerRef.current.addEventListener(
        "scroll",
        this.handleScroll
      );
    }
  };

  componentWillUnmount = () => {
    if (this.selectionContainerRef.current) {
      this.selectionContainerRef.current.removeEventListener(
        "scroll",
        this.handleScroll
      );
    }
  };

  /**
   * Select a pca. Deselect previously selected pca and/or models.
   * @param {Clickenvent} event Clickevent when selecting pca.
   * @param {int} idx Index of selected pca in list of pcas.
   */
  onPCASelection = (event, idx) => {
    // Must be adjusted together with backend
    const passingCriteria = [
      "r2",
      "mse",
      "rmse",
      "expl_var",
      "max_err",
      "mean_abs_err",
      "mape",
      "med_abs_err",
    ];

    if (event.target.value) {
      this.props.spectraViewer.setState({
        selectedPca: idx,
        selectedPcaId: this.props.spectraViewer.pcas[idx].id,
        selectedModelIdx: null,
        selectedModelName: null,
        passingCriteria,
        passingCriteria_idx: 0,
      });
    }
  };

  /**
   * Select a model. Deselect previously selected model and/or pcas.
   * @param {Clickenvent} event Clickevent when selecting model.
   * @param {int} idx Index of selected model in list of models.
   */
  onModelSelection = (event, idx) => {
    const model = this.props.spectraViewer.models[idx];

    // Analysis: Set passing criteria in accordance with selected model
    if (
      model.versions[model.selectedVersionIdx].modeltype === "spectra-analysis"
    ) {
      let passingCriteria = [];
      model.versions[model.selectedVersionIdx].substances?.forEach(
        (substance) => {
          passingCriteria.push(
            substance + " Probability", // Must be adjusted together with backend
            substance + " Pred. Concentration" // Must be adjusted together with backend
          );
        }
      );
      if (event.target.value) {
        this.props.spectraViewer.setState({
          selectedPca: null,
          selectedPcaId: null,
          selectedModelIdx: idx,
          selectedModelName: model.name,
          passingCriteria,
          passingCriteria_idx: 0,
        });
      }
    }
    // Prediction: Set prediction susbtances options
    else if (
      model.versions[model.selectedVersionIdx].modeltype === "spectra-predict"
    ) {
      const oldSettings = this.props.spectraViewer.spectraPredictionSettings;
      let spectraPredictionSettings = model.versions[
        model.selectedVersionIdx
      ].substances.map((substance) => {
        const foundIdx = oldSettings.findIndex(
          (setting) => setting.substance === substance
        );
        return foundIdx >= 0
          ? {
              substance: substance,
              concentration: oldSettings[foundIdx].concentration,
            }
          : { substance: substance, concentration: 0.0 };
      });
      if (event.target.value) {
        this.props.spectraViewer.setState({
          selectedPca: null,
          selectedPcaId: null,
          selectedModelIdx: idx,
          selectedModelName: model.name,
          passingCriteria: [],
          passingCriteria_idx: null,
          spectraPredictionSettings,
        });
      }
    }
  };

  /**
   * Begin PCA analysis for the selected spectrum adn PCA model
   */
  onPcaAnalysis = () => {
    if (
      this.props.spectraViewer.rawSpectra[
        this.props.spectraViewer.selectedSpectrum
      ].isTemporary
    ) {
      window.showWarningSnackbar(
        `Action not possible with temporary spectrum "${
          this.props.spectraViewer.rawSpectra[
            this.props.spectraViewer.selectedSpectrum
          ].name
        }"`
      );
      return;
    }
    this.props.requestData("true_approx_scores");
    this.props.project.type === "ESRTraining" && this.props.setActiveTab(3);
    this.props.project.type === "ESREvaluation" && this.props.setActiveTab(2);
  };

  /**
   * Predict spectrum for the given settings
   */
  onPredictSpectrum = () => {
    this.props.requestData("predict_spectrum");
    this.props.setActiveTab(0);
  };

  /**
   * Generate table with pca
   * @param {JSON} pca PCA object
   * @param {int} idx Index of the pca in the pca list
   * @returns interactive table with pcas
   */
  pcaStats = (pca, idx) => {
    return (
      <TooltipTable
        arrow
        interactive="true"
        placement="left"
        key={idx}
        title={
          <TableContainer>
            <Table>
              {this.isInView(idx) && (
                <TableBody>
                  {/* Substances used for PCA */}
                  <TableRow key="substances">
                    <TableCell>Substances</TableCell>
                    <TableCell>
                      {pca.substances
                        .reduce((result, substance) => {
                          return `${result}${substance.name}, `;
                        }, "")
                        .slice(0, -2)}
                    </TableCell>
                  </TableRow>
                  {/* Operations used for PCA */}
                  <TableRow key="ops">
                    <TableCell>Preprocessing</TableCell>
                    <TableCell>
                      {/* More than the simple scores */}
                      {pca.preprocessing.length === 1
                        ? "none"
                        : pca.preprocessing
                            .slice(0, pca.preprocessing.length - 1)
                            .reduce((result, operation) => {
                              return `${result}${operation[0]}: ${operation[1]}, `;
                            }, "")
                            .slice(0, -2)}
                    </TableCell>
                  </TableRow>
                  <TableRow key="scores">
                    <TableCell>Scores</TableCell>
                    <TableCell>{pca.used_scores}</TableCell>
                  </TableRow>
                  {/* Areas */}
                  <TableRow key="areas">
                    <TableCell>Areas</TableCell>
                    <TableCell>
                      {pca.areas.length !== 0
                        ? pca.areas
                            .reduce((result, area) => {
                              return `${result}[${Number(area[0]).toFixed(
                                2
                              )}, ${Number(area[1]).toFixed(2)}], `;
                            }, "")
                            .slice(0, -2)
                        : "full"}
                    </TableCell>
                  </TableRow>
                  {/* No. of Features used for PCA */}
                  <TableRow key="features">
                    <TableCell>No. of Features</TableCell>
                    <TableCell>{pca.features}</TableCell>
                  </TableRow>
                  {/* Results of PCA */}
                  {Object.entries(pca.result).map(([key, value]) => {
                    return (
                      <TableRow key={key} label={key}>
                        <TableCell>{key}</TableCell>
                        <TableCell>{Number(value).toFixed(6)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        }
      >
        <TableRow key={pca.id} onClick={(e) => this.onPCASelection(e, idx)}>
          {this.isInView(idx) ? (
            <React.Fragment>
              <TableCell component="th" scope="row">
                {/* Radio Button */}
                <FormControlLabel
                  value={idx}
                  control={
                    <Radio
                      checked={
                        pca.id === this.props.spectraViewer.selectedPcaId
                      }
                    />
                  }
                  label=""
                ></FormControlLabel>
              </TableCell>
              <TableCell component="th" scope="row">
                {/* Substances */}
                {pca.substances
                  .reduce((result, substance) => {
                    return `${result} ${substance.name},`;
                  }, "")
                  .slice(0, -1)}
              </TableCell>
              <TableCell component="th" scope="row">
                {/* R^2 Value */}
                {Number(pca.result.r2).toFixed(6)}
              </TableCell>
            </React.Fragment>
          ) : (
            <TableCell
              style={{ height: 74 }}
              component="th"
              scope="row"
            ></TableCell>
          )}
        </TableRow>
      </TooltipTable>
    );
  };

  /**
   * Generate table with models
   * @param {JSON} mode Model object
   * @param {int} idx Index of the model in the model list
   * @returns interactive table with model
   */
  modelStats = (model, idx) => {
    return (
      <TooltipTable
        arrow
        interactive="true"
        placement="left"
        key={uuidv4()}
        title={
          <TableContainer>
            <Table>
              <TableBody>
                {/* Substances used for PCA */}
                <TableRow key="substances">
                  <TableCell>Substances</TableCell>
                  <TableCell>
                    {model.versions[model.selectedVersionIdx].substances?.join(
                      ", "
                    )}
                  </TableCell>
                </TableRow>
                {/* Time created */}
                <TableRow key="time">
                  <TableCell>Creation time</TableCell>
                  <TableCell>
                    {model.versions[model.selectedVersionIdx].datetime}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        }
      >
        <TableRow key={model.id} onClick={(e) => this.onModelSelection(e, idx)}>
          <TableCell component="th" scope="row">
            {/* Radio Button */}
            <FormControlLabel
              value={idx}
              control={
                <Radio
                  checked={
                    model.name === this.props.spectraViewer.selectedModelName
                  }
                />
              }
              label=""
            ></FormControlLabel>
          </TableCell>
          <TableCell component="th" scope="row">
            {/* Substances */}
            {model.versions[model.selectedVersionIdx].substances?.join(", ")}
          </TableCell>
          <TableCell component="th" scope="row">
            {/* Version */}
            {model.versions.length > 1 ? (
              // Version-selection dropdown
              <TextField
                select
                label="Version"
                value={model.versions[model.selectedVersionIdx].label}
                onChange={(event) => {
                  // Update selected version in models
                  let models = this.props.spectraViewer.models;
                  models[idx].selectedVersionIdx = models[
                    idx
                  ].versions.findIndex(
                    (version) => version.label === event.target.value
                  );
                  this.props.spectraViewer.setState({
                    models,
                  });
                  this.onModelSelection(event, idx);
                }}
              >
                {model.versions.map((version, idx) => (
                  <MenuItem key={idx} value={version.label}>
                    {version.label}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              model.versions[model.selectedVersionIdx].label
            )}
          </TableCell>
        </TableRow>
      </TooltipTable>
    );
  };

  /**
   * Save the new upper and lower limits for selection criteria.
   * Will not go under the lower and over the upper bound set above (Usually 0 and 1).
   * @param {Array} newValue The new upper and lower limits to set for the criteria as array [min, max].
   */
  handleChangeCriteriaLimits = (newValue) => {
    this.props.spectraViewer.setState({
      passingCriteria_min: Math.max(Math.min(...newValue), criteriaLowerBound),
      passingCriteria_max: Math.min(Math.max(...newValue), criteriaUpperBound),
    });
  };

  /**
   * Set substances to predict and their respective concentrations.
   * @param {String} substance Name of the substance
   * @param {Number} concentration Concentration as a value between 0 and 100%
   */
  handleChangePredictionSettings = (substance, concentration) => {
    let { spectraPredictionSettings } = this.props.spectraViewer;

    // Limit concentration to allowed values
    concentration = Math.max(
      Math.min(concentration, criteriaUpperBound),
      criteriaLowerBound
    );

    // Check if value already exists, if found, update it, otherwise add it
    const foundIdx = spectraPredictionSettings.findIndex(
      (setting) => setting.substance === substance
    );
    if (foundIdx >= 0) {
      spectraPredictionSettings[foundIdx].concentration = concentration;
    } else {
      spectraPredictionSettings.push({
        substance: substance,
        concentration: concentration,
      });
    }

    // Update settings
    this.props.spectraViewer.setState({ spectraPredictionSettings });
  };

  /**
   * Set min and max values for the x-values range if user wants to set this manually
   * @param {Array} xValueMinMax Array with the minimum and maximum x-value
   */
  handleChangeManualXRange = (xValueMinMax) => {
    // Update settings
    this.props.spectraViewer.setState({ xValueMinMax });
  };

  /**
   * Component of passing criteria settings, moved out fo render for visibility
   * @returns Passing criteria settings element, wrapped in <div/>
   */
  passingCriteriaSettings = () => {
    const { classes, spectraViewer } = this.props;
    return (
      // Set pass/fail parameters for spectra
      <div style={{ maxHeight: 300 }} className={classes.applyBottomContent}>
        <Typography variant="h6">Passing Criteria</Typography>
        {/* Criteria selection | Limit Entry */}
        <Grid className={classes.minMaxInput} container spacing={1}>
          <Grid item xs={6}>
            <TextField
              select
              label="Criteria"
              value={
                spectraViewer.passingCriteria[
                  spectraViewer.passingCriteria_idx
                ] ?? ""
              }
              onChange={(event) => {
                let targetIndex = spectraViewer.passingCriteria.findIndex(
                  (criterium) => criterium === event.target.value
                );
                this.props.spectraViewer.setState({
                  passingCriteria_idx: targetIndex,
                });
              }}
              fullWidth={true}
              style={{ minWidth: 150 }}
            >
              {spectraViewer.passingCriteria.map((criterium) => (
                <MenuItem key={criterium} value={criterium}>
                  {criterium}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={3}>
            {/* Minimum selection */}
            <TextField
              label="Min"
              value={spectraViewer.passingCriteria_min}
              onChange={(event) =>
                this.handleChangeCriteriaLimits([
                  Number(event.target.value),
                  spectraViewer.passingCriteria_max,
                ])
              }
              fullWidth={true}
              inputProps={{
                min: criteriaLowerBound,
                max: criteriaUpperBound,
                type: "number",
                step: 1e-1,
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              label="Max"
              value={spectraViewer.passingCriteria_max}
              onChange={(event) =>
                this.handleChangeCriteriaLimits([
                  spectraViewer.passingCriteria_min,
                  Number(event.target.value),
                ])
              }
              fullWidth={true}
              inputProps={{
                min: criteriaLowerBound,
                max: criteriaUpperBound,
                type: "number",
                step: 1e-1,
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  /**
   * Component containing predition entry settings
   * @returns spectra prediction element, wrapped in <div/>
   */
  spectraPreditionSettings = () => {
    const { classes, spectraViewer } = this.props;
    // const model = spectraViewer.models[spectraViewer.selectedModelIdx];
    const model = spectraViewer.models[0]; // TODO: Change to line above
    const modelVersion = model?.versions[model.selectedVersionIdx];
    return (
      <div
        style={{ maxHeight: 300, overflowY: "auto" }}
        className={classes.applyBottomContent}
      >
        <Typography variant="h6">Prediction Settings</Typography>
        <Grid className={classes.minMaxInput} container spacing={1}>
          {modelVersion?.substances.map((substance) => {
            return (
              <Grid item xs={3} key={substance}>
                <TextField
                  label={substance}
                  fullWidth={true}
                  inputProps={{
                    min: criteriaLowerBound,
                    max: criteriaUpperBound,
                    type: "number",
                    step: 1e-1,
                  }}
                  onChange={(e) => {
                    this.handleChangePredictionSettings(
                      substance,
                      Number(e.target.value)
                    );
                  }}
                  value={
                    spectraViewer.spectraPredictionSettings.find(
                      (setting) => setting.substance === substance
                    )?.concentration ?? 0.0
                  }
                ></TextField>
              </Grid>
            );
          })}
        </Grid>
        {spectraViewer.selectedPca == null &&
          spectraViewer.passingCriteria_idx === null &&
          spectraViewer.selectedModelIdx !== null && (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={spectraViewer.setXRangeManually}
                  onChange={(e) => {
                    spectraViewer.setState({
                      setXRangeManually: e.target.checked,
                    });
                  }}
                />
              }
              label={"set x-value range manually"}
            />
          )}

        {spectraViewer.setXRangeManually && (
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2}>
              <TextField
                variant="standard"
                size="small"
                style={styles.input}
                value={spectraViewer.xValueMinMax[0]}
                margin="dense"
              />
            </Grid>
            <Grid item xs={6}>
              <Slider
                min={xValueRangeMin}
                max={xValueRangeMax}
                value={[
                  spectraViewer.xValueMinMax[0],
                  spectraViewer.xValueMinMax[1],
                ]}
                onChange={(e) => {
                  this.handleChangeManualXRange(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="standard"
                size="small"
                style={styles.input}
                value={spectraViewer.xValueMinMax[1]}
                margin="dense"
              />
            </Grid>
          </Grid>
        )}
      </div>
    );
  };

  render() {
    const { classes, spectraViewer } = this.props;
    return (
      <div
        style={{
          gridTemplateRows:
            this.props.project.type === "ESREvaluation"
              ? "1fr 1fr auto"
              : "1fr auto",
        }}
        className={classes.applyContainer}
      >
        <div
          className={classes.selectionContainer}
          ref={this.selectionContainerRef}
        >
          <Typography variant="h5">PCA Selection</Typography>
          {spectraViewer.pcas?.length !== 0 ? (
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Substances</TableCell>
                    <TableCell>
                      <SwapVertIcon
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.props.spectraViewer.sortPCAs(
                            "r2",
                            spectraViewer.pcaOrder === "asc" ? "desc" : "asc"
                          )
                        }
                      ></SwapVertIcon>
                      R²
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {spectraViewer.pcas.map((pca, idx) => {
                    return this.pcaStats(pca, idx);
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>
              <Typography variant="h6">No PCAs found!</Typography>
              <Typography variant="body1">
                Please use the &quot;ESR Training&quot; module to train PCAs
                with known spectra before validating further unknown ones.
              </Typography>
            </>
          )}
        </div>
        {this.props.project.type === "ESREvaluation" && (
          <div
            className={classes.selectionContainer}
            styles={{ borderTop: "3px solid #EBEBEB" }}
          >
            <Typography variant="h5">Model Selection</Typography>
            {spectraViewer.models?.length !== 0 ? (
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Substances</TableCell>
                      <TableCell>Version</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {spectraViewer.models.map((model, idx) => {
                      return this.modelStats(model, idx);
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <>
                <Typography variant="h6">No AI models found!</Typography>
                <Typography variant="body1">
                  Please import AI models before validating further unknown
                  ones.
                </Typography>
              </>
            )}
          </div>
        )}
        {this.props.initialized &&
          this.props.project.type === "ESREvaluation" &&
          // PCA or analysis model selected
          (spectraViewer.selectedPca !== null ? (
            this.passingCriteriaSettings()
          ) : // AI model selected
          spectraViewer.selectedModelIdx !== null ? (
            spectraViewer.passingCriteria_idx === null ? (
              // Prediction Model
              this.spectraPreditionSettings()
            ) : (
              // Analysis model
              this.passingCriteriaSettings()
            )
          ) : (
            // Nothing or something else selected
            <div />
          ))}
        {(this.props.initialized ||
          this.props.project.type === "ESREvaluation") && (
          <div className={classes.applyBottomContent}>
            <Button
              variant="contained"
              color="secondary"
              style={{ marginBottom: "10px" }}
              className={classes.applyButton}
              disabled={
                // No PCAs selected
                spectraViewer.selectedPca === null &&
                // No Prediction AI Models selected
                (spectraViewer.passingCriteria_idx !== null ||
                  spectraViewer.selectedModelIdx === null)
              }
              onClick={() => {
                // PCA selected
                if (spectraViewer.selectedPca !== null) {
                  this.onPcaAnalysis();
                }
                // Predcition model selected
                else if (
                  spectraViewer.passingCriteria_idx === null &&
                  spectraViewer.selectedModelIdx !== null
                ) {
                  this.onPredictSpectrum();
                }
              }}
            >
              {
                // PCA selected
                spectraViewer.selectedPca !== null
                  ? "Analyse Selected Spectrum with Selected PCA"
                  : // Predcition model selected
                  spectraViewer.passingCriteria_idx === null &&
                    spectraViewer.selectedModelIdx !== null
                  ? "Predict Spectrum"
                  : // Nothing or something else selected
                    "Please select a PCA or prediction model"
              }
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.applyButton}
              disabled={
                // No PCA selected
                spectraViewer.selectedPca === null &&
                // No Analysis model selected
                spectraViewer.passingCriteria_idx === null
              }
              onClick={() => {
                this.props.onSave();
                if (this.props.project.type === "ESREvaluation") {
                  // Check whether to use PCAs or AI models
                  if (spectraViewer.selectedPca !== null) {
                    this.props.requestData("pca_evaluation");
                  } else if (
                    spectraViewer.selectedModelIdx !== null &&
                    spectraViewer.passingCriteria_idx !== null
                  ) {
                    // Analysis ai model
                    this.props.requestData("ai_evaluation");
                  }
                  this.props.setActiveTab(0);
                } else if (this.props.project.type === "ESRTraining") {
                  this.props.requestData("pca_chosen");
                  // Return to home screen
                  this.props.returnHome();
                }
              }}
            >
              Select
            </Button>
          </div>
        )}
      </div>
    );
  }
}

ModelTab.propTypes = {
  classes: PropTypes.object.isRequired,
  spectraViewer: PropTypes.object.isRequired,
  project: PropTypes.object,
  initialized: PropTypes.bool,
  onSave: PropTypes.func,
  requestData: PropTypes.func,
  setActiveTab: PropTypes.func,
  returnHome: PropTypes.func,
};

export default withSpectraViewer(withStyles(styles)(ModelTab));

import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import { v4 as uuidv4 } from "uuid";

import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const rows = [
  {
    id: "actions",
    numeric: false,
    label: "",
  },
  {
    id: "name",
    numeric: false,
    label: "Epitope",
  },
  { id: "score", numeric: true, label: "Score" },
  {
    id: "proteinId",
    numeric: true,
    label: "Protein ID",
  },
  {
    id: "ProteinName",
    numeric: true,
    label: "Protein name",
  },
  { id: "location", numeric: true, label: "Location" },
  { id: "epitope", numeric: true, label: "Epitope" },
];

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

class ProteomResultsHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map(
            (row, idx) => (
              <TableCell
                key={uuidv4()}
                align={row.numeric ? "right" : "left"}
                padding="normal"
                sortDirection={orderBy === row.id ? order : false}
              >
                {idx > 0 && idx < 3 ? (
                  <Tooltip
                    disableInteractive
                    title="Sort"
                    placement={row.numeric ? "bottom-end" : "bottom-start"}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={this.createSortHandler(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  </Tooltip>
                ) : (
                  <React.Fragment>{row.label}</React.Fragment>
                )}
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

ProteomResultsHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const styles = () => ({
  root: {
    width: "100%",
  },
  table: {
    minWidth: 800,
  },
  tableWrapper: {
    overflowX: "auto",
  },
});

class ProteomResults extends React.Component {
  constructor(props) {
    super(props);

    this.counter = 0;
    this.epitopes = {};
    let allRows = [];

    this.state = {
      order: "asc",
      orderBy: "calories",
      selected: [],
      data: allRows,
      epitopes: this.epitopes,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (
      Object.keys(prevProps.annotations).length === 0 &&
      Object.keys(this.props.annotations).length > 0
    ) {
      let allRows = [];
      for (const [key, value] of Object.entries(this.props.annotations)) {
        let name = key;
        //console.log("row", key);
        let score = value.score;
        let proteinId = "";
        let proteinName = "";
        let location = "";
        let epitope = "C";
        for (let row of value.proteins) {
          proteinId = row.id;
          proteinName = row.name;
          location = row.content.indexOf(key, 0);
          allRows.push(
            this.createRow(
              name,
              score,
              proteinId,
              proteinName,
              location,
              epitope
            )
          );
        }
      }
      this.setState({ data: allRows });
    }
  };

  createRow(name, score, proteinId, proteinName, location, epitope) {
    this.counter += 1;
    if (typeof this.epitopes[name] === "undefined")
      this.epitopes[name] = { score: score, collapsed: true };
    return {
      id: this.counter,
      name,
      score,
      proteinId,
      proteinName,
      location,
      epitope,
    };
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleClick = (row) => {
    this.setState({ selected: row.id });
    this.props.setSelectedRow(row);
  };

  handleEpitopeRowClick = (row) => {
    let epitopes = this.state.epitopes;
    epitopes[row.name].collapsed = !epitopes[row.name].collapsed;
    this.setState({ epitopes, selected: null });
    this.props.setSelectedRow(null);
  };

  isSelected = (id) => this.state.selected === id;

  isCollapsed = (name) => {
    if (this.state.epitopes && this.state.epitopes[name]) {
      return this.state.epitopes[name].collapsed;
    } else {
      return true;
    }
  };

  render() {
    const { classes } = this.props;
    const { data, order, orderBy } = this.state;
    let tempRow = null;

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <ProteomResultsHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy)).map((n) => {
                const isSelected = this.isSelected(n.id);
                let epitopeRow = null;
                if (tempRow === null || tempRow.name !== n.name) {
                  epitopeRow = n;
                }
                tempRow = n;
                return (
                  <React.Fragment key={uuidv4()}>
                    {epitopeRow !== null && (
                      <TableRow
                        hover
                        key={uuidv4()}
                        onClick={() => this.handleEpitopeRowClick(n)}
                      >
                        <TableCell>
                          {this.isCollapsed(n.name) ? (
                            <ArrowDropDown />
                          ) : (
                            <ArrowDropUp />
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {n.name}
                        </TableCell>
                        <TableCell align="right">{n.score}</TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    )}
                    {!this.isCollapsed(n.name) && (
                      <TableRow
                        hover
                        onClick={() => this.handleClick(n)}
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={uuidv4()}
                        selected={isSelected}
                      >
                        <TableCell></TableCell>
                        <TableCell component="th" scope="row"></TableCell>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right">{n.proteinId}</TableCell>
                        <TableCell align="right">{n.proteinName}</TableCell>
                        <TableCell align="right">{n.location}</TableCell>
                        <TableCell align="right">{n.epitope}</TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

ProteomResults.propTypes = {
  classes: PropTypes.object.isRequired,
  setSelectedRow: PropTypes.func,
  annotations: PropTypes.object,
};

export default withStyles(styles)(ProteomResults);

import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";

import {
  DialogContent,
  DialogContentText,
  TextField,
  FormControl,
  MenuItem,
} from "@mui/material";
import { withAllViewerContexts } from "../../../viewer/contexts/AllViewerContexts";

const styles = () => ({
  dialogContent: {
    paddingTop: 0,
    maxWidth: 900,
  },
  firstSelectField: {
    marginTop: 10,
  },
});

class TrainModelStep3 extends Component {
  constructor(props) {
    super(props);
  }

  /**
   * Checks if a given modelname already exists.
   * @param {string} valueToCheck The text to be checked.
   * @returns {bool} Whether or not the name already exists.
   */
  checkNameAvailibility = (valueToCheck) => {
    let aiModelRepository = this.props.projectContext.aiModelRepository
      ? this.props.projectContext.aiModelRepository
      : [];
    return (
      aiModelRepository.findIndex((aiModel) => {
        let result = aiModel.name === valueToCheck;
        return result;
      }) >= 0
    );
  };

  /**
   * Checks if input string has forbidden characters.
   * @param {string} input The text to be checked.
   * @returns {bool} Whether or not a forbidden char is present.
   */
  isInvalidInput = (input) => {
    const invalidChars = /[&+#\\/?:*"|<>]/g;
    return invalidChars.test(input);
  };

  render() {
    const { classes, selectedModel, newModelName, formData, projectContext } =
      this.props;
    const { versionName } = this.props.formData.metaData;
    let aiModelRepository = projectContext.aiModelRepository
      ? projectContext.aiModelRepository
      : [];
    let valueToCheck = "Custom - " + newModelName;
    let nameInUse = this.checkNameAvailibility(valueToCheck);
    let invalidChar = this.isInvalidInput(valueToCheck);

    return (
      <DialogContent className={classes.dialogContent}>
        <DialogContentText
          component={"span"}
          style={{ paddingTop: "0px", marginBottom: "20px" }}
        >
          <div>
            You can train a new model from scratch or start training based on an
            existing model:
            <ul>
              <li>
                <strong>Train from scratch:</strong> type in name of your model
              </li>
              <li>
                <strong>Use existing model:</strong> select existing model name
                and version
              </li>
            </ul>
          </div>
        </DialogContentText>
        <FormControl fullWidth>
          <TextField
            className={classes.firstSelectField}
            select
            value={selectedModel.name}
            label="Select Model"
            onChange={(e) => {
              let value = e.target.value;
              let selectedModel = { name: "newModel" };
              if (value === "newModel") {
                this.props.updateFormDataMetaData("newModel", true);
                this.props.updateFormDataAdvSettings("tileSize", 512);
              } else {
                this.props.updateFormDataMetaData("newModel", false);
                let aiModel = aiModelRepository.find(
                  (aiModel) => aiModel.name === value
                );
                if (typeof aiModel !== "undefined" && aiModel.versions) {
                  selectedModel = aiModel;
                  let versions = aiModel.versions;
                  if (aiModel.versions.length > 0) {
                    let selectedVersion = versions[versions.length - 1].label;
                    this.props.updateFormDataMetaData(
                      "versionName",
                      selectedVersion
                    );
                    this.props.updateFormDataAdvSettings(
                      "tileSize",
                      aiModel.versions[0].image_size
                    );
                  }
                }
              }
              this.props.setSelectedModel(selectedModel);
              value = value.replace("Custom - ", "");
              this.props.updateFormDataMetaData("modelName", value);
            }}
          >
            <MenuItem key="newModel" value="newModel">
              New Model
            </MenuItem>
            {aiModelRepository
              .filter(
                (aiModel) =>
                  aiModel.versions.length > 0 &&
                  aiModel.versions[0].modeltype == formData["modelType"]
              )
              .map((aiModel) => {
                return (
                  <MenuItem key={aiModel.name} value={aiModel.name}>
                    {aiModel.label}
                  </MenuItem>
                );
              })}
          </TextField>
        </FormControl>
        <br />
        <br />
        {selectedModel.name === "newModel" ? (
          <TextField
            error={newModelName === "" || nameInUse || invalidChar}
            helperText={
              newModelName && newModelName === ""
                ? "Model name needed!"
                : nameInUse
                ? "Name already used!"
                : invalidChar
                ? 'Must not use the following: & + # \\ / | ? : * " < >'
                : ""
            }
            label="Name for new Model"
            value={newModelName}
            onChange={(e) => {
              valueToCheck = "Custom - " + e.target.value;
              nameInUse = this.checkNameAvailibility(valueToCheck);
              invalidChar = this.isInvalidInput(valueToCheck);
              let uniqueName = !(
                e.target.value === "" ||
                nameInUse ||
                invalidChar
              );

              this.props.updateFormDataMetaData("modelName", e.target.value);
              this.props.updateFormDataMetaData("validChar", !invalidChar);
              this.props.updateFormDataMetaData("uniqueName", uniqueName);
              projectContext.aiStateObject.newModelName = e.target.value;
              this.forceUpdate();
            }}
          />
        ) : (
          <FormControl fullWidth>
            <TextField
              select
              value={versionName}
              label="Select Version"
              onChange={(e) => {
                let value = e.target.value;
                this.props.updateFormDataMetaData("versionName", value);
              }}
            >
              {aiModelRepository.find(
                (aiModel) => aiModel.name === selectedModel.name
              ) &&
                aiModelRepository
                  .find((aiModel) => aiModel.name === selectedModel.name)
                  .versions.map((version) => {
                    return (
                      <MenuItem key={version.label} value={version.label}>
                        {version.label}
                      </MenuItem>
                    );
                  })}
            </TextField>
          </FormControl>
        )}
      </DialogContent>
    );
  }
}

TrainModelStep3.propTypes = {
  classes: PropTypes.object.isRequired,
  projectContext: PropTypes.object,
  project: PropTypes.object,
  files: PropTypes.array,
  onChangeFiles: PropTypes.func,
  updateFormDataMetaData: PropTypes.func,
  formData: PropTypes.object,
  selectedModel: PropTypes.object,
  newModelName: PropTypes.string,
  setSelectedModel: PropTypes.func,
  updateFormDataAdvSettings: PropTypes.func,
};

export default withAllViewerContexts(withStyles(styles)(TrainModelStep3));

import React, { Component } from "react";
import PropTypes from "prop-types";

import Draggable from "react-draggable";
import { Resizable } from "react-resizable";
import classNames from "classnames";

import {
  Typography,
  Checkbox,
  Select,
  MenuItem,
  Tooltip,
  TextField,
  Card,
  Slider,
  List,
  ListItem,
  ListItemText,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  Tabs,
  Tab,
  Button,
} from "@mui/material";

import {
  ChatBubbleOutline,
  DragIndicator,
  Build,
  Keyboard,
  Mouse,
} from "@mui/icons-material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/free-solid-svg-icons";

import { withTiles } from "../contexts/TilesContext";
import { withPersistentStorage } from "../contexts/PersistentStorageContext";
import { withAllViewerContexts } from "../contexts/AllViewerContexts";
import AccuracyGraph from "./AccuracyGraph";
import SketchColorPicker from "./SketchColorPicker";
import withStyles from "@mui/styles/withStyles";

const styles = () => ({
  tabsContainer: {
    width: "100%",
  },
  tab: {
    "& *": {
      display: "inline-block",
      fontSize: "12px",
    },
    "& svg": {
      marginRight: "5px",
      position: "relative",
      top: "2px",
    },
  },
  flexVerticalContainer: {
    display: "flex",
    flexFlow: "column",
    height: "100%",
  },
  flexRowContentHeight: {
    flex: "0 1 auto",
    padding: "10px",
  },
  flexRowRemainingHeight: {
    flex: "1 1 auto",
    overflowY: "auto",
  },
  spacing: {
    padding: "10px",
    paddingBottom: 0,
  },
  dragIndicator: {
    position: "absolute",
    color: "#000000",
    cursor: "grab",
    bottom: 5,
    right: 5,
  },
  grabbing: {
    cursor: "grabbing",
  },
  toolBox: {
    marginLeft: "5px",
    marginRight: "10px",
    marginTop: "7px",
    display: "inline-block",
  },
  aiLabel: {
    display: "block",
    position: "absolute",
    bottom: "11px",
    right: "4px",
    background: "black",
    color: "white",
    zIndex: 99999,
    padding: "0 5px",
    margin: 0,
  },
  imgOuter: {
    "&:hover .aiLabel": {
      display: "none",
    },
  },
});

class GalleryToolBoxes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      grabbing: false,
      toolbarSize: {
        width: 300,
        height:
          this.props.project &&
          !this.props.project.type.includes("HistoPointCounting")
            ? 355
            : 525,
      },
      originalToolbarHeight: 355,
      comboBoxSizeOptions: [
        "small (200px)",
        "medium (300px)",
        "large (600px)",
        "full width",
      ],
      showContour: this.getShowContour(),
      showFileNames: true,
      drawCrosshair: this.props.showGallery ? false : true,
      crosshairColor: "#FF0000",
      crosshairOpacity: 1.0,
      crosshairLineWidth: 1,
      activeTab: 0,
      automaticTraining: false,
    };
  }

  getShowContour = () => {
    // checks default value for draw contour checkbox for specific modules
    const { setDrawContour } = this.props;
    if (this.props.showGallery) {
      if (this.getProjectLabel() === "Polarity - Gallery") {
        setDrawContour(false);
        return false;
      } else if (this.getProjectLabel() === "Active Learning - Gallery") {
        setDrawContour(false);
        return false;
      } else if (this.props.project.type.includes("HistoPointCounting")) {
        setDrawContour(false);
        return false;
      } else {
        setDrawContour(true);
        return true;
      }
    } else {
      setDrawContour(false);
      return false;
    }
  };

  getProjectLabel = () => {
    // returns project label form viewerconfig
    if (this.props.viewerConfig) {
      return this.props.viewerConfig.project.label;
    }
  };

  isScrollbarVisible = () => {
    let el = document.getElementById("subtypesDiv");
    if (el !== null) {
      return el.scrollHeight > el.clientHeight;
    } else {
      return false;
    }
  };

  onResize = (event, { size }) => {
    let minSizeHeight = 200;
    size.height = size.height < minSizeHeight ? minSizeHeight : size.height;
    this.setState({ toolbarSize: size });
  };

  handleChangeContour = (e) => {
    const { setDrawContour } = this.props;
    setDrawContour(e.target.checked ? true : false);
    this.setState({ showContour: e.target.checked ? true : false });
  };

  handleChangeShowFileNames = (e) => {
    const { setShowFileNames } = this.props;
    setShowFileNames(e.target.checked ? true : false);
    this.setState({ showFileNames: e.target.checked ? true : false });
  };

  handleChangeCombo = (e) => {
    // change canvas size
    if (e.target.value === 1) {
      this.props.projectContext.setState({ galleryImageSize: 200 });
    } else if (e.target.value === 2) {
      this.props.projectContext.setState({ galleryImageSize: 300 });
    } else if (e.target.value === 3) {
      this.props.projectContext.setState({ galleryImageSize: 600 });
    } else {
      this.props.projectContext.setState({ galleryImageSize: -1 });
    }
    this.setFirstTimeGallery();
    this.forceUpdate();
  };

  setFirstTimeGallery = () => {
    // firstTimeGallery = true --> load new tiles
    this.props.roiLayers[this.props.selectedLayer].layer.regionRois.forEach(
      function (element) {
        element.firstTimeGallery = true;
      }
    );
  };

  getStructuresForDropDown = () => {
    const { structures, selectedLayer } = this.props;

    let parentIndex = selectedLayer;
    if (structures[selectedLayer].classificationSubtype) {
      parentIndex = this.findParentIndex();
    }

    let childs = structures.filter(
      (element) =>
        element.parentId === structures[parentIndex].id &&
        element.label !== "leer [leer]"
    );
    childs.unshift(structures[parentIndex]);
    return childs;
  };

  findChilds = (subType) => {
    const { structures } = this.props;
    // return direct classification subtypes of selected structure
    return structures.filter(
      (element) =>
        element.subtypeLevel === subType.subtypeLevel + 1 &&
        element.parentId === subType.id &&
        element.classificationSubtype
    );
  };

  findParentIndex = () => {
    const { structures, selectedLayer } = this.props;
    // returns index of parentstructure in all structures
    let parentIndex = structures.findIndex(
      (element) => element.id === structures[selectedLayer].parentId
    );
    return parentIndex;
  };

  handleChangeFilteredStructure = (e) => {
    const { structures, selectedLayer } = this.props;

    let parentIndex = selectedLayer;
    if (structures[selectedLayer].classificationSubtype) {
      parentIndex = this.findParentIndex();
    }

    // find index of leer --> add 1 of all childs after leer
    // get index of class leer in childs
    let childs = this.findChilds(structures[parentIndex]);
    let emptyIdx = childs.findIndex(
      (element) => element.label === "leer [leer]"
    );
    let idx = e.target.value;
    if (emptyIdx >= 0 && e.target.value >= emptyIdx + 1) {
      idx = idx + 1;
    }

    this.props.onSelectLayer(parentIndex + idx);
    this.props.tiles.setStructure(structures[parentIndex + idx]);
  };

  handleChangeCrosshair = (e, fromKeyPress) => {
    const { setDrawCrosshair } = this.props;
    if (fromKeyPress) {
      this.setState({ drawCrosshair: this.state.drawCrosshair ? false : true });
      setDrawCrosshair(this.state.drawCrosshair ? false : true);
    } else {
      this.setState({ drawCrosshair: e.target.checked ? true : false });
      setDrawCrosshair(e.target.checked ? true : false);
    }
  };

  handleChangeActiveTab = (event, value) => {
    const { setAutomaticTraining } = this.props;
    this.setState({ activeTab: value });
    setAutomaticTraining(value === 1 ? true : false);
  };

  startTraining = (activeLearning) => {
    const { structures, selectedLayer } = this.props;
    // start training for dl model

    // check if selectedLayer is parent
    if (structures[selectedLayer].classificationSubtype) {
      this.props.trainingWarning("subtype");
      return;
    }

    // check if structure has childs
    // check if selectedLayer is parent
    if (!structures[selectedLayer].hasChild) {
      this.props.trainingWarning("hasNoChilds");
      return;
    }

    // check if enough objects per class
    let childs = this.findClassificationChilds();
    let enoughAnnos = this.enoughAnnotations(childs);
    if (!enoughAnnos) {
      this.props.trainingWarning("tooFewAnnotations");
      return;
    }

    this.props.saveChangesGallery();
    this.props.setAlruns(true);
    let passiveLearning = activeLearning ? false : true;
    this.props.applyDL(
      this.props.tools["alm_gallery_tool"],
      this.props.projectId,
      this.props.fileId,
      passiveLearning
    );
  };

  findClassificationChilds = () => {
    const { structures, selectedLayer } = this.props;
    // return direct classification subtypes
    return structures.filter(
      (element) =>
        element.parentId === structures[selectedLayer].id &&
        element.classificationSubtype
    );
  };

  enoughAnnotations = (childs) => {
    const { roiLayers, selectedLayer } = this.props;
    // check if enough annotations for training of dl model (at least one class with 5 annotations and one other class with 1 annotation)
    let minFive = false;
    let twoAnnotated = 0;
    childs.forEach((element) => {
      // get number of elements for child
      let numberElements = roiLayers[selectedLayer].layer.regionRois.filter(
        (roi) => roi.structureId === element.id
      ).length;

      // check if minimum of five in one class
      if (numberElements >= 5) {
        minFive = true;
      }
      // check if two classes with at least one element
      if (numberElements >= 1) {
        twoAnnotated = twoAnnotated + 1;
      }
    });

    // if one class with at least five and one other class with at least one annotation --> enough annotations for training
    let enough = false;
    if (minFive && twoAnnotated >= 2) {
      enough = true;
    }
    return enough;
  };

  applyModel = () => {
    const { structures, selectedLayer } = this.props;
    // apply trained dl model to selected structure unlabeled images

    // check if selectedLayer is parent
    if (structures[selectedLayer].classificationSubtype) {
      this.props.trainingWarning("subtype");
      return;
    }

    // check if structure has childs
    // check if selectedLayer is parent
    if (!structures[selectedLayer].hasChild) {
      this.props.trainingWarning("hasNoChilds");
      return;
    }

    // make warning that not changing layer while applying model
    window.showWarningSnackbar(
      "Please do not change structure while applying the model."
    );

    this.props.saveChangesGallery();
    this.props.setAlruns(true);
    let passiveLearning = true;
    let applyModel = true;
    this.props.applyDL(
      this.props.tools["alm_gallery_tool"],
      this.props.projectId,
      this.props.fileId,
      passiveLearning,
      applyModel
    );
  };

  handleChangeMakePredictions = (e) => {
    const { setApplyModelAfterTraining } = this.props;
    // change state of make predictions after training
    setApplyModelAfterTraining(e.target.checked ? true : false);
  };

  checkToolInConfig = (toolName) => {
    // checks if tool exists in viewerconfig
    if (this.props.viewerConfig) {
      if (this.props.viewerConfig.project.toolsInProject[toolName]) {
        return true;
      } else {
        return false;
      }
    }
  };

  render() {
    const {
      classes,
      selectedLayer,
      structures,
      roiLayers,
      childsGallery,
      childs,
      classificationId,
      showGallery,
      page,
      setCrosshairColor,
      setCrosshairOpacity,
      setCrosshairLineWidth,
      isFilesGallery,
    } = this.props;
    const { grabbing, activeTab, comboBoxSizeOptions } = this.state;

    let comboBoxSizeValue = comboBoxSizeOptions.filter((option) =>
      option.includes(String(this.props.projectContext.galleryImageSize))
    )[0];

    if (this.props.projectContext.galleryImageSize == -1) {
      comboBoxSizeValue = "full width";
    }

    return (
      <div>
        {showGallery && (
          <Draggable
            handle=".handle"
            onStart={() => this.setState({ grabbing: true })}
            onStop={() => this.setState({ grabbing: false })}
          >
            <Resizable
              className={classes.resizableContainer}
              height={this.state.toolbarSize.height}
              width={this.state.toolbarSize.width}
              onResize={this.onResize}
              resizeHandles={["s"]}
            >
              <Card
                style={{
                  position: "absolute",
                  left: "20px",
                  top: "10px",
                  width: this.state.toolbarSize.width,
                  height: this.state.toolbarSize.height,
                  backgroundColor: "#EBEBEB",
                  opacity: 0.8,
                  marginRight: "10px",
                  zIndex: 20,
                }}
              >
                <Tooltip disableInteractive title="Tools">
                  <Build
                    style={{ float: "right", margin: "5px 5px 0px 0px" }}
                  />
                </Tooltip>

                <FormGroup
                  row
                  style={{
                    marginTop: "12px",
                    marginLeft: "10px",
                    marginBottom: "5px",
                  }}
                >
                  <Typography style={{ width: "100px" }}>
                    Preview size
                  </Typography>
                  <Select
                    variant="standard"
                    style={{ marginLeft: "5px", marginTop: "-5px" }}
                    value={comboBoxSizeValue}
                    onChange={this.handleChangeCombo}
                    inputProps={{
                      name: "Image size",
                    }}
                  >
                    <MenuItem value={comboBoxSizeValue}>
                      <em>{comboBoxSizeValue}</em>
                    </MenuItem>
                    <MenuItem value={1}>small (200px)</MenuItem>
                    <MenuItem value={2}>medium (300px)</MenuItem>
                    <MenuItem value={3}>large (600px)</MenuItem>
                    {isFilesGallery && (
                      <MenuItem value={4}>full width</MenuItem>
                    )}
                  </Select>
                </FormGroup>

                {this.props.project.type.includes("HistoPointCounting") && (
                  <FormGroup
                    row
                    style={{
                      marginTop: "20px",
                      marginLeft: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    <Typography>Filter structure:</Typography>
                    <Select
                      variant="standard"
                      style={{ marginLeft: "5px", marginTop: "-5px" }}
                      value={this.getStructuresForDropDown().findIndex(
                        (element) => element.id === structures[selectedLayer].id
                      )}
                      onChange={this.handleChangeFilteredStructure}
                    >
                      {this.getStructuresForDropDown().map((structure, idx) => (
                        <MenuItem key={idx} value={idx}>
                          {structure.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormGroup>
                )}

                {!this.props.project.type.includes("HistoPointCounting") &&
                  !isFilesGallery && (
                    <FormControlLabel
                      className={classes.toolBox}
                      label="Show contour"
                      control={
                        <Checkbox
                          checked={this.state.showContour}
                          onChange={this.handleChangeContour}
                        />
                      }
                    />
                  )}

                <List
                  style={{
                    marginTop: "-10px",
                    marginBottom: "-20px",
                    width: "284px",
                    marginRight: this.isScrollbarVisible() ? "20px" : "0px",
                  }}
                >
                  <ListItem>
                    <ListItemText primary="Subtype name" />
                    {!this.props.project.type.includes(
                      "HistoPointCounting"
                    ) && (
                      <Tooltip
                        disableInteractive
                        title="Left (L) or right (R) click./nLeft or right click on item to set subtypes."
                      >
                        <Mouse style={{ marginRight: "50px" }} />
                      </Tooltip>
                    )}
                    <Tooltip
                      disableInteractive
                      title="Shortcut number on keyboard"
                    >
                      <Keyboard
                        style={{
                          marginRight: !this.isScrollbarVisible()
                            ? "-17px"
                            : "0px",
                        }}
                      />
                    </Tooltip>
                  </ListItem>
                </List>

                <div
                  style={{
                    maxHeight:
                      210 +
                      (this.state.toolbarSize.height -
                        this.state.originalToolbarHeight),
                    overflow: "auto",
                  }}
                  id="subtypesDiv"
                >
                  <List>
                    {childsGallery.map((child, index) => (
                      <ListItem
                        key={index}
                        onMouseUp={(e) => {
                          let x = {
                            name: child.label,
                            color: child.color,
                            id: child.id,
                          };
                          let structureId = structures[selectedLayer].id;
                          if (
                            e.nativeEvent.which === 3 &&
                            classificationId[1] !== child.id
                          ) {
                            // right click
                            this.props.tiles.setSingleSubtype(
                              structureId,
                              x,
                              1
                            );
                            classificationId[2] = child.id;
                          } else if (
                            e.nativeEvent.which === 1 &&
                            classificationId[2] !== child.id
                          ) {
                            // left click
                            this.props.tiles.setSingleSubtype(
                              structureId,
                              x,
                              0
                            );
                            classificationId[1] = child.id;
                          }
                          this.forceUpdate();
                        }}
                      >
                        <ListItemText
                          style={{ color: child.color }}
                          primary={child.label}
                        />
                        {!this.props.project.type.includes(
                          "HistoPointCounting"
                        ) &&
                          child.id === classificationId[1] && (
                            <Typography style={{ marginRight: "62px" }}>
                              L
                            </Typography>
                          )}
                        {!this.props.project.type.includes(
                          "HistoPointCounting"
                        ) &&
                          child.id === classificationId[2] && (
                            <Typography style={{ marginRight: "62px" }}>
                              R
                            </Typography>
                          )}
                        <Typography style={{ marginRight: "8px" }}>
                          {!this.props.project.type.includes(
                            "HistoPointCounting"
                          )
                            ? index
                            : index + 1}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </div>

                {!this.props.project.type.includes("HistoPointCounting") &&
                  isFilesGallery && (
                    <FormControlLabel
                      className={classes.toolBox}
                      label="Show file names"
                      control={
                        <Checkbox
                          checked={this.state.showFileNames}
                          onChange={this.handleChangeShowFileNames}
                        />
                      }
                    />
                  )}

                <DragIndicator
                  className={classNames(
                    "handle",
                    classes.dragIndicator,
                    grabbing && classes.grabbing
                  )}
                />
              </Card>
            </Resizable>
          </Draggable>
        )}

        {showGallery && this.checkToolInConfig("GalleryTrainingTool") && (
          <Draggable
            handle=".handle"
            onStart={() => this.setState({ grabbing: true })}
            onStop={() => this.setState({ grabbing: false })}
          >
            <Card
              style={{
                position: "absolute",
                left: "20px",
                top: "375px",
                width: "300px",
                height: "435px",
                backgroundColor: "#EBEBEB",
                opacity: 0.8,
                marginRight: "10px",
                zIndex: 15,
              }}
            >
              <Tooltip disableInteractive title="AI training">
                <div>
                  <FontAwesomeIcon
                    icon={faBrain}
                    style={{ float: "right", margin: "5px 5px 0px 0px" }}
                  />
                </div>
              </Tooltip>

              <Typography
                className={classes.toolBox}
                style={{ fontWeight: "bold" }}
              >
                Training information:
              </Typography>

              <br />

              <Tabs
                className={classes.tabsContainer}
                style={{ minHeight: "30px" }}
                value={activeTab}
                onChange={this.handleChangeActiveTab}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab
                  className={classes.tab}
                  style={{
                    minWidth: "100px",
                    minHeight: "25px",
                    fontWeight: "bold",
                  }}
                  label="passive learning"
                />
                <Tab
                  className={classes.tab}
                  style={{
                    minWidth: "100px",
                    minHeight: "25px",
                    fontWeight: "bold",
                  }}
                  label="active learning"
                />
              </Tabs>

              <Typography
                className={classes.toolBox}
                style={{ marginTop: "15px" }}
              >
                {"Epochs: " + this.props.progressText}
              </Typography>

              <br />

              <Typography className={classes.toolBox}>
                {"Accuracy: " +
                  Math.round(parseFloat(this.props.trainingAcc) * 100) +
                  "%"}
              </Typography>

              <br />

              {activeTab === 1 && (
                <div style={{ marginTop: "10px" }}>
                  <Tooltip
                    disableInteractive
                    title="Number of unlabeled images to classify for next training."
                  >
                    <Typography
                      className={classes.toolBox}
                      style={{ width: "210px" }}
                    >
                      Annotations for next training:
                    </Typography>
                  </Tooltip>
                  <TextField
                    id="nAnnos"
                    className={classes.toolBox}
                    style={{ width: "40px", marginTop: "5px" }}
                    value={this.props.startData}
                    onChange={(e) => {
                      this.props.setStartData(Number(e.target.value));
                    }}
                    onMouseEnter={() => {
                      document.getElementById("nAnnos").disabled = false;
                      this.props.tiles.setOnPageInput(true);
                    }}
                    onMouseLeave={() => {
                      this.props.tiles.setOnPageInput(false);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        //not tested, because not in use
                        this.props.tiles.setOnPageInput(false);
                        document.getElementById("nAnnos").disabled = true;
                      }
                    }}
                  />
                  <br />
                </div>
              )}

              <AccuracyGraph />

              {activeTab === 0 && (
                <div>
                  <FormControlLabel
                    style={{
                      marginTop: "-3px",
                      marginLeft: "-3px",
                      marginBottom: "0px",
                    }}
                    label="Make predictions after training"
                    control={
                      <Checkbox
                        checked={this.props.makePredictions}
                        onChange={this.handleChangeMakePredictions}
                      />
                    }
                  />

                  <Button
                    style={{ width: "46%", margin: "5px" }}
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => this.startTraining()}
                  >
                    Train model
                  </Button>

                  <Button
                    style={{ width: "46%", margin: "5px" }}
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => this.applyModel()}
                  >
                    Apply model
                  </Button>
                </div>
              )}

              {activeTab === 1 && (
                <Button
                  style={{ width: "96%", marginLeft: "6px" }}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => this.startTraining(true)}
                >
                  Train and apply model
                </Button>
              )}

              {this.props.alruns && activeTab === 1 && (
                <div
                  style={{
                    position: "absolute",
                    top: "92px",
                    right: "125px",
                  }}
                >
                  <CircularProgress />
                </div>
              )}

              <DragIndicator
                className={classNames(
                  "handle",
                  classes.dragIndicator,
                  grabbing && classes.grabbing
                )}
              />
            </Card>
          </Draggable>
        )}

        {!this.props.showGallery && (
          <Draggable
            handle=".handle"
            onStart={() => this.setState({ grabbing: true })}
            onStop={() => this.setState({ grabbing: false })}
          >
            <Card
              style={{
                position: "absolute",
                left: "20px",
                top: "20px",
                width: "300px",
                height: "500px",
                backgroundColor: "#EBEBEB",
                opacity: 0.8,
                marginRight: "10px",
                zIndex: 20,
              }}
            >
              <Tooltip disableInteractive title="Tools">
                <Build style={{ float: "right", margin: "5px 5px 0px 0px" }} />
              </Tooltip>

              <Typography className={classes.toolBox}>
                Show crosshair:
              </Typography>
              <Checkbox
                className={classes.toolBox}
                defaultChecked
                onChange={this.handleChangeCrosshair}
              />
              <br />
              <Typography className={classes.toolBox}>
                Crosshair color:
              </Typography>
              <div className={classes.toolBox}>
                <SketchColorPicker
                  color={this.state.crosshairColor}
                  handleChange={(color) => {
                    this.setState({ crosshairColor: color });
                    setCrosshairColor(color);
                  }}
                />
              </div>
              <br />
              <Typography className={classes.toolBox}>
                Opacity crosshair:
              </Typography>
              <Slider
                style={{ width: "100px" }}
                min={0}
                max={100}
                value={this.state.crosshairOpacity * 100}
                onChange={(event, newValue) => {
                  this.setState({ crosshairOpacity: newValue / 100 });
                  setCrosshairOpacity(newValue / 100);
                }}
              />
              <br />
              <Typography
                className={classes.toolBox}
                style={{ width: "150px" }}
              >
                Crosshair linewidth:
              </Typography>
              <TextField
                className={classes.toolBox}
                style={{ width: "40px", marginTop: "5px" }}
                value={this.state.crosshairLineWidth}
                onChange={(e) => {
                  this.setState({ crosshairLineWidth: Number(e.target.value) });
                  setCrosshairLineWidth(Number(e.target.value));
                }}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 30,
                  type: "number",
                }}
              />

              <List
                style={{
                  marginTop: "20px",
                  marginBottom: "-20px",
                  width: "284px",
                }}
              >
                <ListItem>
                  <ListItemText primary="Subtype name" />
                  <Tooltip
                    disableInteractive
                    title="Left (L) or right (R) click./nLeft or right click on item to set subtypes."
                  >
                    <Mouse style={{ marginRight: "50px" }} />
                  </Tooltip>
                  <Tooltip
                    disableInteractive
                    title="Shortcut number on keyboard"
                  >
                    <Keyboard />
                  </Tooltip>
                </ListItem>
              </List>

              <div
                style={{
                  maxHeight: "245px",
                  overflow: "auto",
                }}
              >
                <List>
                  {childs.map((child, index) => (
                    <ListItem
                      key={index}
                      onMouseUp={(e) => {
                        if (
                          e.nativeEvent.which === 3 &&
                          classificationId[1] !== child.id
                        ) {
                          // right click
                          classificationId[2] = child.id;
                        } else if (
                          e.nativeEvent.which === 1 &&
                          classificationId[2] !== child.id
                        ) {
                          // left click
                          classificationId[1] = child.id;
                        }
                        this.forceUpdate();
                      }}
                    >
                      <ListItemText
                        style={{ color: child.color }}
                        primary={child.label}
                      />
                      {child.id === classificationId[1] && (
                        <Typography style={{ marginRight: "62px" }}>
                          L
                        </Typography>
                      )}
                      {child.id === classificationId[2].id && (
                        <Typography style={{ marginRight: "62px" }}>
                          R
                        </Typography>
                      )}
                      <Typography style={{ marginRight: "8px" }}>
                        {index + 1}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </div>

              <DragIndicator
                className={classNames(
                  "handle",
                  classes.dragIndicator,
                  grabbing && classes.grabbing
                )}
              />
            </Card>
          </Draggable>
        )}

        {!this.props.showGallery && (
          <Draggable
            handle=".handle"
            onStart={() => this.setState({ grabbing: true })}
            onStop={() => this.setState({ grabbing: false })}
          >
            <Card
              style={{
                position: "absolute",
                left: "20px",
                top: "540px",
                width: "300px",
                height: "150px",
                backgroundColor: "#EBEBEB",
                opacity: 0.8,
                marginRight: "10px",
                zIndex: 20,
              }}
            >
              <Tooltip disableInteractive title="Add comments to the tile here">
                <ChatBubbleOutline
                  style={{ float: "right", margin: "5px 5px 0px 0px" }}
                />
              </Tooltip>

              <TextField
                id="roiComment"
                style={{
                  margin: "20px 5px 20px 5px",
                  width: "80%",
                  marginLeft: "10px",
                }}
                label="Comment"
                multiline
                rows={4}
                variant="outlined"
                value={
                  roiLayers[selectedLayer].layer.regionRois[page]
                    ? roiLayers[selectedLayer].layer.regionRois[page].comment
                    : ""
                }
                onChange={(e) => {
                  roiLayers[selectedLayer].layer.regionRois[page].comment =
                    e.target.value;
                  this.forceUpdate();
                }}
                onMouseEnter={() => {
                  document.getElementById("roiComment").disabled = false;
                  this.props.tiles.setOnPageInput(true);
                }}
                onMouseLeave={() => {
                  this.props.tiles.setOnPageInput(false);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    // not tested, because not in use
                    this.props.tiles.setOnPageInput(false);
                    document.getElementById("roiComment").disabled = true;
                  }
                }}
              />
              <DragIndicator
                className={classNames(
                  "handle",
                  classes.dragIndicator,
                  grabbing && classes.grabbing
                )}
              />
            </Card>
          </Draggable>
        )}
      </div>
    );
  }
}

GalleryToolBoxes.propTypes = {
  classes: PropTypes.object.isRequired,
  project: PropTypes.object,
  roiLayers: PropTypes.array,
  structures: PropTypes.array,
  showGallery: PropTypes.bool,
  setDrawContour: PropTypes.func,
  setShowFileNames: PropTypes.func,
  viewerConfig: PropTypes.object,
  tiles: PropTypes.object,
  tools: PropTypes.array,
  selectedLayer: PropTypes.number,
  page: PropTypes.number,
  projectId: PropTypes.string,
  fileId: PropTypes.string,
  classificationId: PropTypes.array,
  onSelectLayer: PropTypes.func,
  setDrawCrosshair: PropTypes.func,
  setAutomaticTraining: PropTypes.func,
  trainingWarning: PropTypes.func,
  saveChangesGallery: PropTypes.func,
  setAlruns: PropTypes.func,
  applyDL: PropTypes.func,
  setApplyModelAfterTraining: PropTypes.func,
  setCrosshairColor: PropTypes.func,
  setCrosshairOpacity: PropTypes.func,
  setCrosshairLineWidth: PropTypes.func,
  childsGallery: PropTypes.array,
  childs: PropTypes.array,
  progressText: PropTypes.string,
  trainingAcc: PropTypes.string,
  startData: PropTypes.number,
  setStartData: PropTypes.func,
  makePredictions: PropTypes.bool,
  alruns: PropTypes.bool,
  isFilesGallery: PropTypes.bool,
  projectContext: PropTypes.object,
};

export default withAllViewerContexts(
  withPersistentStorage(withTiles(withStyles(styles)(GalleryToolBoxes)))
);

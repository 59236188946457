import React, { Component } from "react";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

// define the component's styling
const styles = () => ({
  root: {
    background: "white",
    height: "100%",
    marginLeft: 5,
  },
  headline: {
    padding: 16,
    paddingBottom: 8,
  },
  inline: {
    display: "inline",
  },
});

const students = [
  {
    primary: "",
    name: "Ali Conners",
    avatar: "/static/images/avatar/1.jpg",
    comment: " — UKE",
  },
  {
    primary: "",
    name: "Scott",
    avatar: "/static/images/avatar/1.jpg",
    comment: " — KIT",
  },
  {
    primary: "",
    name: "Jennifer",
    avatar: "/static/images/avatar/1.jpg",
    comment: " — KIT",
  },
  {
    primary: "",
    name: "Alex",
    avatar: "/static/images/avatar/1.jpg",
    comment: " — KIT",
  },
  {
    primary: "",
    name: "Sandra Adams",
    avatar: "/static/images/avatar/1.jpg",
    comment: " — Tübingen",
  },
];

class ClassroomChat extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, classRoomChatWidth } = this.props;
    return (
      <div
        style={{
          width: classRoomChatWidth - 5,
          display: classRoomChatWidth > 0 ? "block" : "none",
        }}
        className={classes.root}
      >
        <Typography className={classes.headline} variant="h6" id="tableTitle">
          Classroom
        </Typography>
        <List className={classes.root}>
          {students.map((student, idx) => {
            return (
              <span key={idx}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt={student.name} src={student.avatar} />
                  </ListItemAvatar>
                  <ListItemText
                    primary=""
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          {student.name}
                        </Typography>
                        {student.comment}
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </span>
            );
          })}
        </List>
      </div>
    );
  }
}

// define the component's interface
ClassroomChat.propTypes = {
  classes: PropTypes.object.isRequired,
  classRoomChatWidth: PropTypes.number,
};

export default withStyles(styles)(ClassroomChat);

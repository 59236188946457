import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
// define the component's styling
const styles = () => ({
  resizesBorder: {
    height: "100%",
    width: "100%",
    cursor: "row-resize",
  },
  //invisible over whole screen, so mouse is always tracked
  overlayBorder: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999999,
    cursor: "row-resize",
  },
});

class LocalHorizontalResizeBorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resizeMode: false,
      borderHeight: 8,
      newHeight: props.targetHeight,
    };
  }

  handleMouseDown = (e) => {
    this.setState({ resizeMode: true, lastPageY: e.pageY, initPageY: e.pageY });
  };
  handleMouseMove = (e) => {
    e.preventDefault();
    if (this.state.resizeMode) {
      let deltaY = this.state.lastPageY - e.pageY;
      deltaY = !this.props.bottomBorder ? deltaY : -deltaY;
      let newHeight = this.state.newHeight + deltaY;
      if (newHeight > this.props.min) {
        this.props.resizeHeight(newHeight);
      } else if (this.props.targetHeight > this.props.min) {
        this.props.resizeHeight(this.props.min);
      }
      this.setState({ lastPageY: e.pageY, newHeight });
    }
  };
  handleMouseEnd = () => {
    if (this.state.resizeMode) {
      this.setState({ resizeMode: false });
      this.props.resizeHeight(this.props.targetHeight);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        className={
          this.state.resizeMode ? classes.overlayBorder : classes.resizesBorder
        }
        onMouseDown={(e) => this.handleMouseDown(e)}
        onMouseMove={(e) => this.handleMouseMove(e)}
        onMouseLeave={(e) => this.handleMouseEnd(e)}
        onMouseUp={(e) => this.handleMouseEnd(e)}
      ></div>
    );
  }
}

// define the component's interface
LocalHorizontalResizeBorder.propTypes = {
  classes: PropTypes.object.isRequired,
  min: PropTypes.number,
  targetHeight: PropTypes.number,
  bottomBorder: PropTypes.bool,
  resizeHeight: PropTypes.func,
};

export default withStyles(styles)(LocalHorizontalResizeBorder);

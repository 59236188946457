import React, { Component } from "react";
import PropTypes from "prop-types";

const ResultTabContext = React.createContext();

export const withResultTab = (Component) => {
  const WrappedComponent = ({ ...propsWithoutClasses }) => (
    <ResultTabContext.Consumer>
      {(context) => <Component {...propsWithoutClasses} resultTab={context} />}
    </ResultTabContext.Consumer>
  );

  WrappedComponent.displayName = `withResultTab(${
    Component.displayName || Component.name || "Component"
  })`;

  return WrappedComponent;
};

// this is the main component, it has to be added at the root of the app.
// all components that use withResultTab(...) will have access to it via this.props.resultTab...
class ResultTabProvider extends Component {
  constructor(props) {
    super(props);

    this.selectedRoi = 0;
    this.selectedChildIndex = 1;
    this.selectedStructure = 0;
    this.changeFile = false;
    this.hundredTiles = false;
    this.rendererInitialized = false;
    this.rendererCanvas = null;
    this.rendererCtx = null;
    this.zoomLevelFixed = false;
    this.gridExists = false;
    this.resetPressed = false;
    this.selSampleSet = false;
    this.fileChange = false;
  }

  getSelectedRoi() {
    return this.selectedRoi;
  }

  setSelectedRoi(a) {
    this.selectedRoi = a;
  }

  getSelectedChildIndex() {
    return this.selectedChildIndex;
  }

  setSelectedChildIndex(a) {
    this.selectedChildIndex = a;
  }

  getSelectedStructure() {
    return this.selectedStructure;
  }

  setSelectedStructure(a) {
    this.selectedStructure = a;
  }

  getChangeFile() {
    return this.changeFile;
  }

  setChangeFile(a) {
    this.changeFile = a;
  }

  getHundredTiles() {
    return this.hundredTiles;
  }

  setHundredTiles(a) {
    this.hundredTiles = a;
  }

  getRendererInitialized() {
    return this.rendererInitialized;
  }

  setRendererInitialized(a) {
    this.rendererInitialized = a;
  }

  getRendererCanvas() {
    return this.rendererCanvas;
  }

  setRendererCanvas(a) {
    this.rendererCanvas = a;
  }

  getRendererCtx() {
    return this.rendererCtx;
  }

  setRendererCtx(a) {
    this.rendererCtx = a;
  }

  // returns position of our image
  getPosition = () => {
    return {
      x: -this.rendererCtx.getTransform().e,
      y: -this.rendererCtx.getTransform().f,
    };
  };

  // returns the current zoom scale
  getScale = () => {
    return this.rendererCtx ? this.rendererCtx.getTransform().a : 1;
  };

  /**
   * Is the current zoom level be fixed?
   * @returns {bool} Is the current zoom level fixed?
   */
  getZoomLevelFixed() {
    return this.zoomLevelFixed;
  }

  /**
   * Toggle the state of zoomLevelFixed.
   */
  toggleZoomLevelFixed() {
    this.zoomLevelFixed = !this.zoomLevelFixed;
  }

  /**
   * Set the attribute zoomLevelFixed to true or false.
   * @param {bool} value Should the zoom be fixed?
   */
  setZoomLevelFixed(value) {
    this.zoomLevelFixed = value;
  }

  getGridExists() {
    return this.gridExists;
  }

  setGridExists(a) {
    this.gridExists = a;
  }

  getResetPressed() {
    return this.resetPressed;
  }

  setResetPressed(a) {
    this.resetPressed = a;
  }

  getSelSampleSet() {
    return this.selSampleSet;
  }

  setSelSampleSet(a) {
    this.selSampleSet = a;
  }

  getFileChange() {
    return this.fileChange;
  }

  setFileChange(a) {
    this.fileChange = a;
  }

  render() {
    return (
      <ResultTabContext.Provider
        value={{
          getSelectedRoi: () => this.getSelectedRoi(),
          setSelectedRoi: (a) => this.setSelectedRoi(a),
          getSelectedChildIndex: () => this.getSelectedChildIndex(),
          setSelectedChildIndex: (a) => this.setSelectedChildIndex(a),
          getSelectedStructure: () => this.getSelectedStructure(),
          setSelectedStructure: (a) => this.setSelectedStructure(a),
          getChangeFile: () => this.getChangeFile(),
          setChangeFile: (a) => this.setChangeFile(a),
          getHundredTiles: () => this.getHundredTiles(),
          setHundredTiles: (a) => this.setHundredTiles(a),
          getRendererInitialized: () => this.getRendererInitialized(),
          setRendererInitialized: (a) => this.setRendererInitialized(a),
          getRendererCanvas: () => this.getRendererCanvas(),
          setRendererCanvas: (a) => this.setRendererCanvas(a),
          getRendererCtx: () => this.getRendererCtx(),
          setRendererCtx: (a) => this.setRendererCtx(a),
          getPosition: () => this.getPosition(),
          getScale: () => this.getScale(),
          getZoomLevelFixed: () => this.getZoomLevelFixed(),
          toggleZoomLevelFixed: () => this.toggleZoomLevelFixed(),
          setZoomLevelFixed: (a) => this.setZoomLevelFixed(a),
          getGridExists: () => this.getGridExists(),
          setGridExists: (a) => this.setGridExists(a),
          getResetPressed: () => this.getResetPressed(),
          setResetPressed: (a) => this.setResetPressed(a),
          getSelSampleSet: () => this.getSelSampleSet(),
          setSelSampleSet: (a) => this.setSelSampleSet(a),
          getFileChange: () => this.getFileChange(),
          setFileChange: (a) => this.setFileChange(a),
        }}
      >
        {this.props.children}
      </ResultTabContext.Provider>
    );
  }
}

ResultTabProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ResultTabProvider;

import PolyBool from "polybooljs";

import { RegionROI } from "./ROI";
import * as turf from "@turf/turf";
//import { booleanIntersects, difference, simplify } from "@turf/turf";
// use old turf library, since new turf lib breaks GeoJSON coordinates when buffering
import { buffer } from "turf";

//default:       0.0000000001
PolyBool.epsilon(0.0000000001);

export function lineArrayBuffer(lineArray, radius, vw, vh) {
  if (lineArray.length === 1) {
    lineArray = [lineArray[0], lineArray[0]];
  }
  let feature =
    lineArray.length > 1 ? turf.lineString(lineArray) : turf.point(lineArray);
  let buffered = buffer(feature, radius * 110);
  let viewRect = turf.bboxPolygon([0, 0, vw, vh]);
  let result = null;
  try {
    result = turf.intersect(buffered, viewRect);
  } catch {
    console.log("pen tool error:", buffered, viewRect);
    return null;
  }
  return result;
}

export function circleToPolygon(center, radius, numberOfSegments) {
  let n = numberOfSegments ? numberOfSegments : 32;
  let coordinates = [];

  for (let i = 0; i < n; ++i) {
    coordinates.push([
      center[0] + radius * Math.cos((2 * Math.PI * i) / n),
      center[1] + radius * Math.sin((2 * Math.PI * i) / n),
    ]);
  }

  return {
    regions: [coordinates],
    inverted: false,
  };
}

export function distance(p1, p2) {
  let deltaX = p1.x - p2.x;
  let deltaY = p1.y - p2.y;
  return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
}

export function pointsToCirclePolygon(p1, p2, numberOfSegments, vw, vh) {
  let n = numberOfSegments ? numberOfSegments : 32;
  let center = {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  };
  let dist = distance(p1, center);
  let coordinates = [];
  let x = center.x - dist;
  let y = center.y - dist;
  let w = 2 * dist;
  let h = 2 * dist;

  for (let i = 0; i < n; ++i) {
    let point = [
      x + w / 2 + (w / 2) * Math.cos((2 * Math.PI * i) / n),
      y + h / 2 + (h / 2) * Math.sin((2 * Math.PI * i) / n),
    ];
    point[0] = Math.max(0, point[0]);
    point[1] = Math.max(0, point[1]);
    point[0] = Math.min(vw, point[0]);
    point[1] = Math.min(vh, point[1]);
    coordinates.push(point);
  }

  return {
    regions: [coordinates],
    inverted: false,
  };
}

export function rectangleToEllipyePolygon(x, y, w, h, numberOfSegments) {
  let n = numberOfSegments ? numberOfSegments : 32;
  let coordinates = [];

  for (let i = 0; i < n; ++i) {
    coordinates.push([
      x + w / 2 + (w / 2) * Math.cos((2 * Math.PI * i) / n),
      y + h / 2 + (h / 2) * Math.sin((2 * Math.PI * i) / n),
    ]);
  }

  return {
    regions: [coordinates],
    inverted: false,
  };
}

export function lineToPolygon(start, stop, width) {
  let coordinates = [];

  let normalVector = [-(stop[1] - start[1]), stop[0] - start[0]];

  let normalVectorLength = Math.sqrt(
    normalVector[0] * normalVector[0] + normalVector[1] * normalVector[1]
  );
  normalVector[0] /= normalVectorLength;
  normalVector[1] /= normalVectorLength;

  coordinates.push([
    start[0] + (normalVector[0] * width) / 2,
    start[1] + (normalVector[1] * width) / 2,
  ]);
  coordinates.push([
    stop[0] + (normalVector[0] * width) / 2,
    stop[1] + (normalVector[1] * width) / 2,
  ]);
  coordinates.push([
    stop[0] - (normalVector[0] * width) / 2,
    stop[1] - (normalVector[1] * width) / 2,
  ]);
  coordinates.push([
    start[0] - (normalVector[0] * width) / 2,
    start[1] - (normalVector[1] * width) / 2,
  ]);

  return {
    regions: [coordinates],
    inverted: false,
  };
}

export function simplifyRegions(regions, tolerance) {
  let poly = turf.polygon(regions);
  let simplifiedPoly = regions;
  try {
    simplifiedPoly = turf.simplify(poly, {
      tolerance: tolerance,
      highQuality: false,
    });
  } catch (e) {
    return regions;
  }

  return simplifiedPoly.geometry.coordinates;
}

export function bufferSimplifyRegions(regions) {
  let poly = turf.polygon(regions);
  poly = buffer(poly, -5);
  if (poly.geometry.coordinates[0].length === 0) return regions;
  let simplifiedPoly = turf.simplify(poly, {
    tolerance: 0.05,
    highQuality: false,
  });
  return simplifiedPoly.geometry.coordinates;
}

export function calcBoundingBox(region) {
  if (region.length === 0) {
    // region is empty
    return { left: 0, top: 0, right: 0, bottom: 0 };
  }
  let rect = {
    left: region[0][region[0].length - 1][0],
    top: region[0][region[0].length - 1][1],
    right: region[0][region[0].length - 1][0],
    bottom: region[0][region[0].length - 1][1],
  };
  for (let p of region[0]) {
    if (p[0] < rect.left) rect.left = p[0];
    if (p[0] > rect.right) rect.right = p[0];
    if (p[1] < rect.top) rect.top = p[1];
    if (p[1] > rect.bottom) rect.bottom = p[1];
  }
  return rect;
}

export function getTreeItem(regions) {
  let bounds = calcBoundingBox(regions);
  return {
    minX: bounds.left,
    minY: bounds.top,
    maxX: bounds.right,
    maxY: bounds.bottom,
    used: false,
    regions: regions[0],
  };
}

export function calcBoundingBoxes(layer) {
  let rects = [];
  for (let roi of layer.regionRois) {
    let region = roi.region;
    if (region[region.length - 1]) {
      rects.push(calcBoundingBox(region));
    }
  }
  return rects;
}

export function intersectRect(r1, r2) {
  return !(
    r2.left >= r1.right ||
    r2.right <= r1.left ||
    r2.top >= r1.bottom ||
    r2.bottom <= r1.top
  );
}

export function pointInsidePoly(p, polyPoints) {
  let x = p.x,
    y = p.y;
  let intersections = 0;
  let ss = "";
  for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
    let xi = polyPoints[i][0],
      yi = polyPoints[i][1];
    let xj = polyPoints[j][0],
      yj = polyPoints[j][1];
    if (yj === yi && yj === y && x > Math.min(xj, xi) && x < Math.max(xj, xi)) {
      // Check if point is on an horizontal polygon boundary
      return true;
    }

    if (
      y > Math.min(yj, yi) &&
      y <= Math.max(yj, yi) &&
      x <= Math.max(xj, xi) &&
      yj !== yi
    ) {
      ss = ((y - yj) * (xi - xj)) / (yi - yj) + xj;
      if (ss === x) {
        // Check if point is on the polygon boundary (other than horizontal)
        return true;
      }
      if (xj === xi || x <= ss) {
        intersections++;
      }
    }
  }
  // If the number of edges we passed through is odd, then it’s in the polygon.
  if (intersections % 2 !== 0) {
    return true;
  } else {
    return false;
  }
}

export function pointInsideRegion(p, region) {
  let turfPoint = turf.point([p.x, p.y]);
  let turfPoly = turf.polygon([region]);
  return !turf.booleanDisjoint(turfPoly, turfPoint);
}

export function pointInside(p, regionRoi) {
  let turfPoint = turf.point([p.x, p.y]);
  let turfPoly = turf.polygon(regionRoi.regions);
  return !turf.booleanDisjoint(turfPoly, turfPoint);
}

export function boundsInside(b, regionRoi) {
  let points = [
    { x: b.left, y: b.top },
    { x: b.right, y: b.top },
    { x: b.right, y: b.bottom },
    { x: b.left, y: b.bottom },
  ];
  let returnValue = true;
  for (let p of points) {
    if (!pointInside(p, regionRoi)) returnValue = false;
  }
  return returnValue;
}

// if one poly intersects or is inside other poly
export function hasIntersection(regionRoi1, regionRoi2) {
  let poly1 = turf.polygon(regionRoi1.regions);
  let poly2 = turf.polygon(regionRoi2.regions);
  return !turf.booleanDisjoint(poly1, poly2);
}

export function intersects(regionRoi1, regionRoi2) {
  let poly1 = turf.polygon(regionRoi1.regions[0]);
  let poly2 = turf.polygon(regionRoi2.regions[0]);
  return turf.booleanIntersects(poly1, poly2);
}

export function filterInvalidRegions(layer) {
  let result = layer.regionRois.filter(
    (roi) => roi.regions[roi.regions.length - 1]
  );
  layer.regionRois = result;
}

export function getIntersections(regionRois1, regionRois2) {
  let regions1 = regionRois1.map((roi) => roi.regions);
  let regions2 = regionRois2.map((roi) => roi.regions);

  let poly1 = turf.multiPolygon(regions1);
  let poly2 = turf.multiPolygon(regions2);
  let intersection = turf.intersect(poly1, poly2);
  return intersection;
}
function findIndexById(structures, id) {
  return structures.findIndex((structure) => structure.id === id);
}
export function findSameLayer(structures, selectedLayer) {
  let defaultParentIndex = selectedLayer === 0 ? -1 : 0;
  let parentLayerId = structures[selectedLayer].parentId;
  while (structures[selectedLayer].classificationSubtype) {
    selectedLayer = findIndexById(structures, parentLayerId);
    parentLayerId = structures[selectedLayer].parentId;
  }
  let parentIndex = structures.findIndex(
    (structure) => structure.id === structures[selectedLayer].parentId
  );
  return [selectedLayer, parentIndex < 0 ? defaultParentIndex : parentIndex];
}

export function findSiblingRoiLayers(structures, selectedLayer, roiLayers) {
  let overlapRoiLayers = [];
  let parentLayerId = structures[selectedLayer].parentId;
  for (let i = 1; i < structures.length; i++) {
    if (
      selectedLayer !== i &&
      structures[i].parentId === parentLayerId &&
      structures[i].visible
    ) {
      if (roiLayers[i].layer.regionRois.length > 0) {
        overlapRoiLayers.push(roiLayers[i]);
      }
    }
  }
  return overlapRoiLayers;
}

/**
 * Check if structure is hidden when starting drawing and if so unhide it
 * @param {*} structures reference on this.structures needed! No copy!
 * @param {*} selectedLayer index of selected structure
 * @param {*}
 */
export function checkIfStructureHidden(
  structures,
  selectedLayer,
  isSubtype,
  name,
  color
) {
  // set baseRoi to visible
  structures[0].visible = true;

  // if subtype get correct selectedLayer
  if (isSubtype) {
    selectedLayer = structures.findIndex(
      (structure) => structure.label === name && structure.color === color
    );
  }
  if (!structures[selectedLayer].visible) {
    window.showWarningSnackbar("Unhiding drawing structure");
    structures[selectedLayer].visible = true;
  }
  let parentId = structures[selectedLayer].parentId;
  while (parentId !== 0) {
    // unhide all subtypes with same parent structure
    for (let idx in structures) {
      if (structures[idx].parentId === parentId) {
        if (!structures[idx].visible) {
          structures[idx].visible = true;
        }
      }
    }
    // unhide parent structure
    for (let structure of structures) {
      if (structure.id === parentId) {
        if (!structure.visible) {
          structure.visible = true;
        }
        parentId = structure.parentId;
        break;
      }
    }
  }
}

/**
 * Filter only relevant Regions from given rois
 * @param {*} tree search tree
 * @param {*} rois ROI used as filter using Bounding Box
 * @returns [largestROI, reducedLayerRegions]
 */
export function getTreeRegionsFromRois(tree, rois) {
  let reducedLayerRois = [];
  for (let regionRoi of rois) {
    tree
      .search(regionRoi.treeItem)
      .filter((treeItem) => treeItem.roi.isObject === false)
      .forEach((treeItem) => {
        const index = reducedLayerRois.findIndex(
          (roi) => roi.uuid === treeItem.roi.uuid
        );
        if (index === -1) {
          reducedLayerRois.push(treeItem.roi);
        }
      });
  }
  return [
    reducedLayerRois[0],
    reducedLayerRois.map((roi) => roi.regions),
    reducedLayerRois,
  ];
}

/**
 * find smallest region
 * @param {*} p mouse position
 * @param {*} selectedLayer this.selectedLayer
 * @param {*} structures this.structures
 * @param {*} roiLayers this.roiLayers
 * @param {boolean} includeBaseROI this.includeBaseROI
 * @returns {[]} region polygon or false if none was found
 */
export function findRegion(
  p,
  selectedLayer,
  structures,
  roiLayers,
  includeBaseROI
) {
  if (
    structures[selectedLayer].visible &&
    (includeBaseROI || !structures[selectedLayer].inversed)
  ) {
    let smallestRoi = null;
    for (let roi of roiLayers[selectedLayer].layer.regionRois) {
      if (pointInside(p, roi)) {
        if (smallestRoi === null || roi.area < smallestRoi.area) {
          smallestRoi = roi;
        }
      }
    }
    if (smallestRoi !== null) return smallestRoi;
  }
  return false;
}

/**
 * find clicked on ROI
 * @param {*} p mouse position
 * @param {*} selectedLayer this.selectedLayer
 * @param {*} structures this.structures
 * @param {*} roiLayers this.roiLayers
 * @param {boolean} includeBaseROI this.includeBaseROI
 * @returns {[]} region polygon or false if none was found
 */
export function findClickedRoi(
  p,
  selectedLayer,
  structures,
  roiLayers,
  includeBaseROI
) {
  if (
    structures[selectedLayer].visible &&
    (includeBaseROI || !structures[selectedLayer].inversed)
  ) {
    let overlapTreeItems = roiLayers[selectedLayer].tree.search({
      minX: parseInt(p.x, 10),
      minY: parseInt(p.y, 10),
      maxX: parseInt(p.x, 10),
      maxY: parseInt(p.y, 10),
    });
    for (let treeItem of overlapTreeItems) {
      if (pointInside(p, treeItem.roi)) {
        return treeItem.roi;
      }
    }
  }
  return false;
}

/** Find all ROIs of a layer present at a certain location.
 *
 * @param {Object} p (Mouse) position {x: , y: }
 * @param {Int} selectedLayer The id of the selected layer.
 * @param {Array} structures Structures of the project.
 * @param {Array} roiLayers The layers containing all ROIs.
 * @param {Bool} includeBaseROI Whether or not to include the Base ROI.
 * @returns {Object} The ROI object found.
 * @returns {Bool} If no ROI is found, returns false.
 */
export function findClickedBounds(
  p,
  selectedLayer,
  structures,
  roiLayers,
  includeBaseROI
) {
  if (
    structures[selectedLayer].visible &&
    (includeBaseROI || !structures[selectedLayer].inversed)
  ) {
    let overlapTreeItems = roiLayers[selectedLayer].tree.search({
      minX: parseInt(p.x, 10),
      minY: parseInt(p.y, 10),
      maxX: parseInt(p.x, 10),
      maxY: parseInt(p.y, 10),
    });
    for (let treeItem of overlapTreeItems) {
      return treeItem.roi;
    }
  }
  return false;
}

/**
 * Check if layer.regionRoi corresponse to Roi in overlapRegionRoi
 * @param {*} overlapRegionRois Regions that are overlapping with draw region
 * @param {ROI} checkRegion ROI of object.layer to be checked if the same as overlapRegionRois
 * @returns {boolean} true if is Hole, false if is outside
 */
export function isInRegionRois(overlapRegionRois, checkRegion) {
  let inRegionRois = false;

  for (let region of overlapRegionRois.regions) {
    if (
      region.find(
        (point) =>
          point[0] === checkRegion.regions[0][0] &&
          point[1] === checkRegion.regions[0][1]
      )
    ) {
      if (
        region.find(
          (point) =>
            point[0] === checkRegion.regions[1][0] &&
            point[1] === checkRegion.regions[1][1]
        )
      ) {
        inRegionRois = true;
        return inRegionRois;
      }
    }
  }

  return inRegionRois;
}

/**
 * generate overlapRegionRois based on GeoJson logic without the region (incl holes) that was clicked on
 * @param {*} reducedLayerRegionsGeoJson geoJson polygon/multipolygon
 * @param {*} clickedOnRegion (opt.) regions of clicked on ROI
 * @returns [overlapPolygon, clickedOnPolygon]
 */
export function generateOverlapRegionRois(
  reducedLayerRegionsGeoJson,
  clickedOnRegion
) {
  let overlapPolygon = {
    inverted: false,
    regions: [],
  };
  let clickedOnPolygon = {
    inverted: false,
    regions: [],
  };

  if (reducedLayerRegionsGeoJson.coordinates.length > 0) {
    if (reducedLayerRegionsGeoJson["type"] === "Polygon") {
      let clickIsOnPolygon = false;
      if (clickedOnRegion) {
        if (
          reducedLayerRegionsGeoJson["coordinates"][0].find(
            (point) =>
              point[0] === clickedOnRegion[0][0] &&
              point[1] === clickedOnRegion[0][1]
          )
        ) {
          if (
            reducedLayerRegionsGeoJson["coordinates"][0].find(
              (point) =>
                point[0] === clickedOnRegion[1][0] &&
                point[1] === clickedOnRegion[1][1]
            )
          ) {
            clickIsOnPolygon = true;
          }
        }
      }
      let tmpPoly = {
        inverted: false,
        regions: reducedLayerRegionsGeoJson["coordinates"],
      };
      if (clickIsOnPolygon) {
        clickedOnPolygon = tmpPoly;
      } else {
        overlapPolygon = tmpPoly;
      }
    } else {
      for (let polygon of reducedLayerRegionsGeoJson["coordinates"]) {
        let clickIsOnPolygon = false;
        if (clickedOnRegion) {
          if (
            polygon[0].find(
              (point) =>
                point[0] === clickedOnRegion[0][0] &&
                point[1] === clickedOnRegion[0][1]
            )
          ) {
            if (
              polygon[0].find(
                (point) =>
                  point[0] === clickedOnRegion[1][0] &&
                  point[1] === clickedOnRegion[1][1]
              )
            ) {
              clickIsOnPolygon = true;
            }
          }
        }
        if (clickIsOnPolygon) {
          clickedOnPolygon.regions = [...clickedOnPolygon.regions, ...polygon];
        } else {
          overlapPolygon.regions = [...overlapPolygon.regions, ...polygon];
        }
      }
    }
  }
  return [overlapPolygon, clickedOnPolygon];
}

/**
 * generate regions that intersect with drawRegion based on GeoJson logic
 * @param {{ type: String, coordinates: any }} reducedLayerRegionsGeoJson geoJson polygon/multipolygon
 * @param {[]} drawRegion region others have to intersect with
 * @returns overlapRegionRois
 */
export function getIntersectingRegions(reducedLayerRegionsGeoJson, drawRegion) {
  let poly = [];
  if (drawRegion.length === 1) drawRegion = drawRegion[0];
  let poly1 = turf.polygon([drawRegion]);

  if (reducedLayerRegionsGeoJson["type"] === "Polygon") {
    if (reducedLayerRegionsGeoJson["coordinates"].length > 0) {
      let poly2 = turf.polygon(reducedLayerRegionsGeoJson.coordinates[0]);

      if (turf.booleanIntersects(poly1, poly2)) {
        poly.push({
          regions: reducedLayerRegionsGeoJson.coordinates
            ? reducedLayerRegionsGeoJson.coordinates
            : [],
          inverted: false,
        });
      }
    } else {
      poly.push({
        regions: reducedLayerRegionsGeoJson.coordinates
          ? reducedLayerRegionsGeoJson.coordinates
          : [],
        inverted: false,
      });
    }
  } else {
    for (let regions of reducedLayerRegionsGeoJson.coordinates) {
      let poly2 = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: regions[0],
        },
      };
      if (turf.booleanIntersects(poly1, poly2)) {
        poly.push({
          regions: regions,
          inverted: false,
        });
      }
    }
  }
  return poly;
}

/**
 * before further analysis remove holes from drawRegion
 * -> uses buffer of 1
 * @param {*} drawRegion in GeoJson format
 * @returns drawRegion without holes
 */
export function getExterior(drawRegion) {
  // custom index filter to get duplicated points
  function getIndexOf(a, arr) {
    for (let i = 0; i < arr.length; i++) {
      let el = arr[i];
      if (el[0] === a[0] && el[1] === a[1]) return i;
    }
    return -1;
  }

  try {
    drawRegion.regions[0] = drawRegion.regions[0].filter(
      (a, i, arr) => getIndexOf(a, arr) === i
    );
    let drawRegionGeoJson = PolyBool.polygonToGeoJSON(drawRegion);

    if (drawRegionGeoJson.coordinates.length !== 0) {
      drawRegionGeoJson = buffer(drawRegionGeoJson, 1).geometry;
      let drawRegionWithoutHoles = {
        inverted: false,
        regions: [],
      };
      if (drawRegionGeoJson.type === "Polygon") {
        drawRegionWithoutHoles.regions.push(drawRegionGeoJson.coordinates[0]);
      } else {
        for (let regions of drawRegionGeoJson.coordinates) {
          drawRegionWithoutHoles.regions.push(regions[0]);
        }
      }
      drawRegion = drawRegionWithoutHoles;
    }
  } catch {
    console.log("Error in getExterior, returning empty regions instead!");
    drawRegion = {
      regions: [],
      inverted: false,
    };
  }

  return drawRegion;
}

// /**
//  * Get all regions and Rois that overlap with drawing region and the largest included area based on the provided rTree
//  * @param {*} tree rTree with all relevant ROIs
//  * @param {*} drawRegionRois drawn region that should be used as baseline
//  * @returns [reducedLayerRegions, reducedLayerRegionRois] which contain all regions/ROIs that are relevant for the drawn region
//  */
// function getOverlapRegionsAndRois(tree, drawRegionRois) {
//   let reducedLayerRegions = [];
//   let reducedLayerRegionRois = [];
//   if (tree) {
//     // get all Regions that overlap with drawRegion
//     let tmpResult = getTreeRegionsFromRois(tree, drawRegionRois);
//     reducedLayerRegions = tmpResult[1];
//     reducedLayerRegionRois = tmpResult[2];
//   }
//   return [reducedLayerRegions, reducedLayerRegionRois];
// }

function getTreeItemsFromRois(tree, regionRois) {
  let reducedLayerRegionRois = [];
  if (tree) {
    for (let roi of regionRois) {
      tree.search(roi.treeItem).forEach((treeItem) => {
        let idx = reducedLayerRegionRois.findIndex(
          (r) => r.uuid === treeItem.roi.uuid
        );
        if (idx < 0) reducedLayerRegionRois.push(treeItem.roi);
      });
    }
  }
  return reducedLayerRegionRois;
}

/**
 * Generates difference Regions and Informations to account for subtypes when deleting (using turf)
 * @param {*} overlapItems overlapping tree items
 * @param {*} transformResult turf.difference() result
 * @returns differenceRegionsInfo - corresponding Info
 */
function differenceRegionsAndInfo(overlapItems, transformResult) {
  let differenceRegionsInfo = [];

  transformResult = transformResult ? transformResult : turf.multiPolygon([]);
  if (transformResult.geometry.type === "Polygon") {
    transformResult = turf.multiPolygon([transformResult.geometry.coordinates]);
  }

  for (let poly of transformResult.geometry.coordinates) {
    differenceRegionsInfo.push({
      name: null,
      color: null,
      subtype: null,
      structureId: null,
    });
    let segDiff = turf.polygon(poly);
    for (let idxRoi in overlapItems) {
      let segGeoJSON = turf.polygon(overlapItems[idxRoi].regions);
      if (!turf.booleanDisjoint(segDiff, segGeoJSON)) {
        let idxRegionsInfo = differenceRegionsInfo.length - 1;
        differenceRegionsInfo[idxRegionsInfo].name =
          overlapItems[idxRoi].subtypeName;
        differenceRegionsInfo[idxRegionsInfo].color =
          overlapItems[idxRoi].color;
        differenceRegionsInfo[idxRegionsInfo].subtype =
          overlapItems[idxRoi].isSubtype;
        differenceRegionsInfo[idxRegionsInfo].structureId =
          overlapItems[idxRoi].structureId;
        break;
      }
    }
  }
  return differenceRegionsInfo;
}

// /**
//  * custom GeoJSON converter to throw that sees them all as outer Polygons
//  * TODO: Add efficient way to detect wholes
//  * @param {*} reducedLayerRegions
//  * @returns
//  */
// function customGeoJsonConverter(reducedLayerRegions) {
//   let resultGeoJSON = {
//     coordinates: [],
//   };
//   if (reducedLayerRegions.length === 0) {
//     resultGeoJSON.type = "Polygon";
//   } else if (reducedLayerRegions.length === 1) {
//     resultGeoJSON.type = "Polygon";
//     resultGeoJSON.coordinates = reducedLayerRegions;
//   } else {
//     resultGeoJSON.type = "MultiPolygon";
//     for (let reducedLayerRegion of reducedLayerRegions) {
//       resultGeoJSON.coordinates.push([reducedLayerRegion]);
//     }
//   }
//   return resultGeoJSON;
// }

// function checkIfExists(value) {
//   return value ? value : null;
// }

// /**
//  * checks object for needed parameters and if not exists, set to null
//  * @param {*} object original object to check
//  * @returns object with all entries
//  */
// function checkObject(object) {
//   object = {
//     layer: checkIfExists(object.layer),
//     drawRegion: checkIfExists(object.drawRegion),
//     clear: checkIfExists(object.clear),
//     color: checkIfExists(object.color),
//     subtype: checkIfExists(object.subtype),
//     name: checkIfExists(object.name),
//     tree: checkIfExists(object.tree),
//     positionInRoiLayer: checkIfExists(object.positionInRoiLayer),
//     fullyLoaded: checkIfExists(object.fullyLoaded),
//     overlap: checkIfExists(object.overlap),
//     parentLayer: checkIfExists(object.parentLayer),
//     structureId: checkIfExists(object.structureId),
//     overlapRoiLayers: checkIfExists(object.overlapRoiLayers),
//     clickedOnRegion: checkIfExists(object.clickedOnRegion),
//     convert: checkIfExists(object.convert),
//   };
//   return object;
// }

/** Updates the objects currently being drawn.
 *
 * @param {Object} layer Structure being edited {regionRois: [], inverted: Bool}
 * @param {Object} drawRegion Objects inside the structure: {regions: [[x:, y: ], ...], inverted: Bool}
 * @param {Bool} clear Delete mode?
 * @param {String} color Color of the currently selected structure (#012DEF)
 * @param {Bool} subtype Whether or not the annotation is a subtype.
 * @param {String} name Name of the structure.
 */
export function updateDrawLayer(
  layer,
  drawRegion,
  clear,
  color,
  subtype,
  name
) {
  // drawRegionRois -> region that was clicked on
  let drawRegionRois = drawRegion.regions.map((regions) => {
    return new RegionROI({
      regions: regions,
      color1: color,
      subtype: subtype,
      name: name,
    });
  });
  let multiLayerRegions = layer.regionRois.map((roi) => roi.regions);
  let multiDrawRegions = drawRegionRois.map((roi) => roi.regions);
  let transformResult = turf.union(
    turf.multiPolygon(multiDrawRegions),
    turf.multiPolygon(multiLayerRegions)
  );

  layer.regionRois = [];
  let coordArray = [];
  if (transformResult === null) {
    coordArray = [];
  } else if (transformResult.geometry.type === "Polygon") {
    coordArray = [transformResult.geometry.coordinates];
  } else {
    coordArray = transformResult.geometry.coordinates;
  }
  for (let coordinates of coordArray) {
    let roiToAdd = createRegionRoi(
      coordinates,
      color,
      subtype,
      name,
      null,
      null
    );
    if (roiToAdd.area > 2) layer.regionRois.push(roiToAdd);
  }
}

/** Creates a ROI object.
 *
 * @param {Array} regions Array of coordninate arrays: [[x,y], ..., [x,y]]
 * @param {String} color #012DEF
 * @param {Bool} subtype Is the ROI a subtype or not?
 * @param {String} name Name of the object structure, e.g. "Base ROI"
 * @param {Bool} fullyLoaded
 * @param {Int} structureId Id of the objects structure.
 * @param {Bool} isObject Is the ROI an object for object detection? Defaults to false.
 * @returns
 */
export function createRegionRoi(
  regions,
  color,
  isSubtype,
  name,
  fullyLoaded,
  structureId,
  isObject = false
) {
  return new RegionROI({
    regions: regions,
    color1: color,
    subtype: isSubtype,
    name: name,
    fullyLoaded: fullyLoaded,
    structureId: structureId,
    isObject: isObject,
  });
}

function turfPolyToRegionRois(
  poly,
  color,
  isSubtype,
  name,
  fullyLoaded,
  structureId
) {
  let coordArray = [];
  if (poly === null) {
    coordArray = [];
  } else if (poly.geometry.type === "Polygon") {
    coordArray = [poly.geometry.coordinates];
  } else {
    coordArray = poly.geometry.coordinates;
  }
  return coordArray.map((coordinates) => {
    return createRegionRoi(
      coordinates,
      color,
      isSubtype,
      name,
      fullyLoaded,
      structureId
    );
  });
}

/**
 * Checks if a ROI is inside its parentlayer,
 * calculates resulting polygon for overlapping polygons.
 *
 * @param {Object} layer Structure being edited {regionRois: [], inverted: Bool}
 * @param {Object} drawRegion Objects inside the structure: {regions: [RegionROI, ...], inverted: Bool}
 * @param {Bool} clear Delete mode?
 * @param {String} color Color of the currently selected structure (#012DEF)
 * @param {Bool} isSubtype Whether or not the annotation is a subtype.
 * @param {String} name Name of the structure.
 * @param {rTree} tree R Tree containing structure information of the selected layer
 * @param {Bool} positionInRoiLayer Is the object inside a ROI Layer?
 * @param {Bool} fullyLoaded
 * @param {Bool} overlap
 * @param {Object} parentLayer The parent structure as object. Prevents annotations outside of paranet layer.
 * @param {Int} structureId The id of the structure.
 * @param {Array} overlapRoiLayers List of all ROI Layers, 1 for each struct, substruct and subtypes of the same parent. Depends on the checkboxes set in remove overlaps.
 * @param {Object} clickedOnRoi The ROI the user clicked on to begin with, if any.
 * @param {Bool} isObject Is this an object detection annotation? Defaults to false.
 * @param {Bool} convert (Not used). Whether or not to convert. Defaults to false.
 */
export function updateLayer(
  layer,
  drawRegion,
  clear,
  color,
  isSubtype,
  name,
  tree,
  positionInRoiLayer,
  fullyLoaded,
  overlap,
  parentLayer,
  structureId,
  overlapRoiLayers,
  clickedOnRoi,
  isObject = false
) {
  let historyItem = [];
  let histId = structureId;
  // drawRegionRois -> region that was clicked on
  // Creates a Region ROI for the freshly created ROI
  let drawRegionRois = [];
  for (let regionRoi of drawRegion.regions) {
    drawRegionRois.push(regionRoi);
  }

  // Extract the regions of the freshly created ROI
  let multiDrawRegions = drawRegionRois.map((roi) => roi.regions);

  // Filter with Base ROI and Parent Annotations
  if (parentLayer && parentLayer.tree && !clear) {
    let parentRegionRois = getTreeItemsFromRois(
      parentLayer.tree,
      drawRegionRois
    );
    // Extract the regions of parent regions
    let multiParentRegions = parentRegionRois.map((roi) => roi.regions);

    // Check if in parent region
    let poly1 = turf.multiPolygon(multiDrawRegions);
    poly1 = turf.simplify(poly1, {
      tolerance: 0.05,
      highQuality: false,
    });
    let poly2 = turf.multiPolygon(multiParentRegions);
    let intersection = turf.intersect(poly1, poly2);
    if (intersection) {
      multiDrawRegions = turfPolyToRegionRois(
        intersection,
        color,
        isSubtype,
        name,
        fullyLoaded,
        structureId
      ).map((roi) => roi.regions);
    } else {
      multiDrawRegions = [];
      window.showWarningSnackbar("Can only be drawn inside parent regions.");
      return;
    }
  }

  // Remove Overlaps, if checkbox is active
  if (!clear && overlapRoiLayers && overlapRoiLayers.length > 0) {
    for (let overlapRoiLayer of overlapRoiLayers) {
      if (overlapRoiLayer.layer.regionRois.length > 0) {
        // get all Regions that overlap with drawRegion
        if (!isObject && overlapRoiLayer.tree) {
          let overlapItems = [];
          for (let drawRoi of drawRegionRois) {
            let drawPoly = turf.polygon(drawRoi.regions);
            overlapRoiLayer.tree
              .search(drawRoi.treeItem)
              .filter((treeItem) => treeItem.roi.isObject === isObject)
              .forEach((treeItem) => {
                if (clickedOnRoi && treeItem.roi.uuid === clickedOnRoi.uuid)
                  return;
                let poly = turf.polygon(treeItem.roi.regions);
                let isIntersecting = true;
                try {
                  isIntersecting = !turf.booleanDisjoint(poly, drawPoly);
                } catch (e) {
                  console.log(
                    "intersection check failed, treeting polygon as intersecting!"
                  );
                  isIntersecting = true;
                }
                if (isIntersecting) {
                  overlapItems.push(treeItem.roi);
                }
              });
          }

          let multiLayerRegions = overlapItems.map((roi) => roi.regions);
          let transformResult = null;
          let poly1 = turf.multiPolygon(multiDrawRegions);
          let poly2 = turf.multiPolygon(multiLayerRegions);
          transformResult = turf.difference(poly1, poly2);
          if (transformResult) {
            // Reduce size of ROIs for good measure
            transformResult = buffer(transformResult, -10);
            if (transformResult.geometry.coordinates[0].length > 0) {
              transformResult = turf.simplify(transformResult, {
                tolerance: 0.05,
                highQuality: false,
              });
            }
          }

          let coordArray = [];
          if (transformResult === null) {
            coordArray = [];
          } else if (transformResult.geometry.type === "Polygon") {
            coordArray = [transformResult.geometry.coordinates];
          } else {
            coordArray = transformResult.geometry.coordinates;
          }
          drawRegionRois = [];
          for (let coordinates of coordArray) {
            if (coordinates[0].length < 1) continue;
            let roiToAdd = createRegionRoi(
              coordinates,
              color,
              isSubtype,
              name,
              fullyLoaded,
              structureId,
              isObject
            );
            if (roiToAdd.area > 2) drawRegionRois.push(roiToAdd);
          }
          multiDrawRegions = drawRegionRois.map((roi) => roi.regions);
        }
      }
    }
  }

  // Add objects to structure trees
  if (tree) {
    let overlapItems = [];
    for (let drawRoi of drawRegionRois) {
      let drawPoly = turf.polygon(drawRoi.regions);
      tree
        .search(drawRoi.treeItem)
        .filter((treeItem) => treeItem.roi.isObject === isObject)
        .forEach((treeItem) => {
          // if drawPoly is subtype, filter all annotations that aren't of the same subtype
          if (isSubtype && clear) {
            overlapItems.push(treeItem.roi);
            treeItem.roi.comment = "";
            tree.remove(treeItem);
            historyItem.push({
              add: false,
              id: histId,
              roi: treeItem.roi.copy(),
            });

            return;
          }
          let poly = turf.polygon(treeItem.roi.regions);
          let isIntersecting = true;
          try {
            isIntersecting = !turf.booleanDisjoint(poly, drawPoly);
          } catch (e) {
            console.log(
              "intersection check failed, treating polygon as intersecting!"
            );
            isIntersecting = true;
          }
          if (isIntersecting) {
            overlapItems.push(treeItem.roi);
            treeItem.roi.comment = "";
            tree.remove(treeItem);
            historyItem.push({
              add: false,
              id: structureId,
              roi: treeItem.roi.copy(),
            });
          }
        });
      //overlapItems.push(drawRoi);
    }
    let multiLayerRegions = overlapItems.map((roi) => roi.regions);
    let transformResult = null;
    let transformResultInfo = null;

    // Add a polygon
    if (!clear) {
      if (isObject) {
        // Ensure both the new and the old ROI are included.
        // No transformation
        multiLayerRegions.forEach((region) => {
          multiDrawRegions.push(region);
        });
        transformResult = turf.multiPolygon(multiDrawRegions);
      }
      // Non-Object ROI was clicked
      // Transform according to settings
      else {
        let poly1 = turf.multiPolygon(multiDrawRegions);
        // No overlapping regions
        if (multiLayerRegions.length === 0 && !overlap) {
          transformResult = poly1;
        }
        // Overlapping regions exist
        else {
          let poly2 = turf.multiPolygon(multiLayerRegions);
          try {
            transformResult = overlap
              ? turf.intersect(poly1, poly2)
              : turf.union(poly1, poly2);
          } catch (e) {
            console.debug("Overlap error:", e);
            window.openErrorDialog(
              "Overlap Error!\n Probably too many objects to compute:\n" + e
            );
          }
        }
      }
    }
    // Remove a polygon
    else {
      if (!isObject) {
        if (overlapItems.length <= 100) {
          transformResult = turf.difference(
            turf.multiPolygon(multiLayerRegions),
            turf.multiPolygon(multiDrawRegions)
          );
          transformResultInfo = differenceRegionsAndInfo(
            overlapItems,
            transformResult
          );
        } else {
          window.showWarningSnackbar(
            "Too many intersections, " +
              overlapItems.length +
              " objects deleted whole!"
          );
        }
      }
    }

    // The new coordinates of the transformed object
    let coordArray = [];
    if (transformResult === null) {
      coordArray = [];
    } else if (transformResult.geometry.type === "Polygon") {
      coordArray = [transformResult.geometry.coordinates];
    } else {
      coordArray = transformResult.geometry.coordinates;
    }
    let firstRoiToAdd = null;
    for (let idx in coordArray) {
      let roiToAdd = createRegionRoi(
        coordArray[idx],
        transformResultInfo ? transformResultInfo[idx].color : color,
        transformResultInfo ? transformResultInfo[idx].subtype : isSubtype,
        transformResultInfo ? transformResultInfo[idx].name : name,
        fullyLoaded,
        transformResultInfo
          ? transformResultInfo[idx].structureId
          : structureId,
        isObject
      );
      if (roiToAdd.area > 2) {
        if (idx === "0") {
          firstRoiToAdd = roiToAdd;
        }

        tree.insert(roiToAdd.treeItem);
        historyItem.push({ add: true, id: histId, roi: roiToAdd });
      }
    }
    layer.regionRois = tree.all().map((treeItem) => treeItem.roi);
    if (firstRoiToAdd !== null && positionInRoiLayer >= 0) {
      let fromIndex = layer.regionRois.findIndex(
        (item) => item.uuid === firstRoiToAdd.uuid
      );
      arraymove(layer.regionRois, fromIndex, positionInRoiLayer);
    }
  }
  window.projectHistory.add(historyItem);
}

/**
 * Special findRegion function for CopyTools, since they require additional information of the layer
 * @param {Object} p mouse position {x:, y: }
 * @param {Array} roiLayers The layers containing all ROIs.
 * @param {Array} structures Structures of the project.
 * @param {Bool} includeBaseROI Whether or not to include the Base ROI.
 * @returns {[*, []]} Array with index of layer where region was found in and array with region in polygon form
 */
export function findRoi(p, roiLayers, structures, includeBaseROI) {
  let resultRoi = null;
  let targetLayerIndex = -1;

  for (let i = 0; i < roiLayers.length; i++) {
    if (
      structures[i].visible &&
      (includeBaseROI || !structures[i].inversed) &&
      roiLayers[i].layer.regionRois.length > 0
    ) {
      let overlapTreeItems = roiLayers[i].tree.search({
        minX: parseInt(p.x, 10),
        minY: parseInt(p.y, 10),
        maxX: parseInt(p.x, 10),
        maxY: parseInt(p.y, 10),
      });
      for (let treeItem of overlapTreeItems) {
        if (pointInside(p, treeItem.roi)) {
          if (resultRoi) {
            if (resultRoi.area > treeItem.roi.area) {
              resultRoi = treeItem.roi;
              targetLayerIndex = i;
            }
          } else {
            resultRoi = treeItem.roi;
            targetLayerIndex = i;
          }
        }
      }
    }
  }

  return [targetLayerIndex, resultRoi];
}

function arraymove(arr, fromIndex, toIndex) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}

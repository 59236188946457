import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import ScanMinimap from "./ScanMinimap";
import ScanComments from "./ScanComments";
import LinearProgress from "@mui/material/LinearProgress";

import { withScanViewerContext } from "../contexts/ScanViewerContext";

const styles = () => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: "#D3D3D3",
    display: "grid",
    overflow: "hidden",
    gridTemplateRows: "auto 1fr",
  },
  mainStream: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  imgStyle: {
    maxWidth: "100%",
    maxHeight: "100%",
    pointerEvents: "none",
  },
  minimapStream: {
    position: "absolute",
    left: 5,
    bottom: 5,
    border: "3px solid white",
  },
  progressBar: {
    height: 10,
  },
});

class ScanRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageHash: Date.now(), // to reload stream,
    };

    this.mouseState = "idle";

    this.mainStreamRef = React.createRef();
  }

  /**
   * Handle mousewheel event
   * @param {ActionEvent} e Event when mousewheel is used
   */
  onMouseWheel = (e) => {
    if (e.deltaY > 0) {
      this.props.scanViewerContext.zoomOut();
    } else if (e.deltaY < 0) {
      this.props.scanViewerContext.zoomIn();
    }
  };
  /**
   * add eventlistener and initialize renderer size
   */
  componentDidMount() {
    window.onbeforeunload = () => {
      this.cleanup();
    };
    window.addEventListener("resize", () => this.computeRendererSize());
    this.computeRendererSize();
    document
      .getElementById("scanMainStream")
      .addEventListener("mousewheel", this.onMouseWheel, true);
  }

  /**
   * cleanup and remove EventListener
   */
  componentWillUnmount() {
    this.cleanup();
    document
      .getElementById("scanMainStream")
      .removeEventListener("mousewheel", this.onMouseWheel, true);
  }

  cleanup() {
    window.removeEventListener("resize", this.computeRendererSize);
  }

  /**
   * calculates height and width of renderer
   */
  computeRendererSize() {
    const w = this.mainStreamRef.current.clientWidth;
    const h = window.innerHeight - 64;
    this.props.scanViewerContext.setScanRendererSize(w, h);
  }

  /**
   * delete tile with right mouse click
   * @param {MouseEvent} e Mouse click
   */
  deleteAtMousePosition = (e) => {
    const parentRect = this.mainStreamRef.current.getBoundingClientRect();
    const x = e.clientX - parentRect.x;
    const y = e.clientY - parentRect.y;
    const center = [
      parseInt(parentRect.width / 2, 10),
      parseInt(parentRect.height / 2, 10),
    ];
    this.props.scanViewerContext.deleteTile(x - center[0], y - center[1]);
  };

  /**
   * if main (left) button and auxiliary (wheel) button pressed: move picture.
   * on secondary (right) button: delete tile
   * @param {MouseEvent} e When mouse is down
   */
  onMouseDown = (e) => {
    if (e.button === 0 || e.button === 1) {
      this.mouseState = "translate";
    } else if (e.button === 2) {
      this.mouseState = "delete";
      this.deleteAtMousePosition(e);
    }
  };

  /**
   * on mouse up: change mouseState to "idle"
   */
  onMouseUp = () => {
    this.mouseState = "idle";
  };

  /**
   * on mouse move: delete tiles or move Center of viewer
   * @param {ActionEvent} event
   */
  onMouseMove = (event) => {
    if (this.mouseState === "translate") {
      this.props.scanViewerContext.moveCenter(event.movementX, event.movementY);
    } else if (this.mouseState === "delete") {
      this.deleteAtMousePosition(event);
    }
    event.preventDefault(); // prevent image from being marked
  };

  render() {
    const { classes } = this.props;
    const { sharpness, sharpnessBenchmark } = this.props.scanViewerContext;
    const { imageHash } = this.state;
    const mainStreamSrc =
      "http://127.0.0.1:8051/api/scan/stream?stream_type=main";
    const minimapStreamSrc =
      "http://127.0.0.1:8051/api/scan/stream?stream_type=minimap";
    return (
      <div className={classes.root} ref={this.mainStreamRef}>
        <LinearProgress
          className={classes.progressBar}
          color={
            sharpness < sharpnessBenchmark / 2
              ? "error"
              : sharpness < sharpnessBenchmark
              ? "warning"
              : "success"
          }
          variant="determinate"
          value={sharpness}
        />
        <div
          id="scanMainStream"
          onContextMenu={(e) => e.preventDefault()}
          className={classes.mainStream}
          onMouseDown={this.onMouseDown}
          onMouseMove={this.onMouseMove}
          onMouseUp={this.onMouseUp}
          onMouseLeave={this.onMouseUp}
        >
          <img
            className={classes.imgStyle}
            src={`${mainStreamSrc}&${imageHash}`}
            alt="stream medium"
          />
        </div>
        <ScanMinimap src={`${minimapStreamSrc}&${imageHash}`} />
        <ScanComments />
      </div>
    );
  }
}

ScanRenderer.propTypes = {
  classes: PropTypes.object.isRequired,
  scanViewerContext: PropTypes.object.isRequired,
};

export default withScanViewerContext(withStyles(styles)(ScanRenderer));

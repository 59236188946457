

function getDist(pointA, pointB) {
	const dx = pointA[0] - pointB[0];
	const dy = pointA[1] - pointB[1];
	return Math.sqrt(dx*dx + dy*dy);
}

function getCurveDistances(startIndex, points) {
	const distAr = [];
	for (let i = 0; i < points.length; i++) {
		const indexA = (startIndex + i) % points.length;
		const indexB = (startIndex + i + 1) % points.length;
		const pointA = points[indexA];
		const pointB = points[indexB];
		const dist = getDist(pointA, pointB);
		distAr.push(dist);
	}
	return distAr;
}

function getStartInterpolPoint(distAr, absPlace) {
	let accLen = 0;
	for (let i = 0; i < distAr.length; i++) {
		accLen += distAr[i];
		if (absPlace <= accLen) {
			return {
				distIndex: i,
				absPlaceDist: absPlace - (accLen - distAr[i]),
			};
		}
	}
	return {
		distIndex: distAr.length - 1,
		absPlaceDist: distAr[distAr.length - 1],
	};
}

function getPointByPlaceInCurve(startIndex, points, place) {
	const distAr = getCurveDistances(startIndex, points);
	const distSum = distAr.reduce((a, b) => a + b, 0);
	const absPlace = place * distSum;
	const { distIndex, absPlaceDist } = getStartInterpolPoint(distAr, absPlace);
	const indA = (startIndex + distIndex) % points.length;
	const indB = (startIndex + distIndex + 1) % points.length;
	const pA = points[indA];
	const pB = points[indB];
	const k = absPlaceDist / distAr[distIndex];
	return [
		pA[0] + (pB[0] - pA[0]) * k,
		pA[1] + (pB[1] - pA[1]) * k,
	];
}


function linearInterpol(pStart, pEnd, tStart, tEnd, t) {
  const k = (t - tStart) / (tEnd - tStart);
  return [
    pStart[0] + (pEnd[0] - pStart[0]) * k,
    pStart[1] + (pEnd[1] - pStart[1]) * k,
  ]
}

export function getFigure(figureA, figureB, startPointIndA, startPointIndB, 
	figAIsClockwise, figBIsClockwise, pointsCount, t1, t2, t) {
  if (t > t2) {
    t = t2;
  }
  if (t < t1) {
    t = t1;
  }
  const delta = 1/pointsCount;
  const figure = [];
  const clockwiseSignA = figAIsClockwise ? -1 : 1;
  const clockwiseSignB = figBIsClockwise ? -1 : 1;
  for (let xInd = 0; xInd < pointsCount; xInd++) {
	const xAInd = (startPointIndA + clockwiseSignA * xInd + pointsCount) % pointsCount; 
	const xBInd = (startPointIndB + clockwiseSignB * xInd + pointsCount) % pointsCount; 
	const xA = xAInd * delta;
	const xB = xBInd * delta;
    const pointFrom = getPointByPlaceInCurve(0, figureA, xA);
    const pointTo = getPointByPlaceInCurve(0, figureB, xB);
    const point = linearInterpol(pointFrom, pointTo, t1, t2, t);
    figure.push(point);
  }
  return figure;
}

export function predictStartPointIndex(figure, pointsCount) {
	const delta = 1/pointsCount;
	//const figure = [];
	let xMin = Infinity;
	let xMax = -Infinity;
	let yMin = Infinity;
	let yMax = -Infinity;
	for (let pInd = 0; pInd < pointsCount; pInd++) {
		const d = pInd * delta;
		const p = getPointByPlaceInCurve(0, figure, d);
		if (p[0] < xMin) {
			xMin = p[0];
		}
		if (p[0] > xMax) {
			xMax = p[0];
		}
		if (p[1] < yMin) {
			yMin = p[1];
		}
		if (p[1] > yMax) {
			yMax = p[1];
		}
	}

	let minDist = Infinity;
	let startPointIndex = 0;
	const anchorPoint = [(xMin + xMax) / 2, yMin];
	for (let pInd = 0; pInd < pointsCount; pInd++) {
		const d = pInd * delta;
		const p = getPointByPlaceInCurve(0, figure, d);
		const dist = getDist(anchorPoint, p);
		if (dist < minDist) {
			minDist = dist;
			startPointIndex = pInd;
		}
	}
	return startPointIndex;
}

function getVec(pointA, pointB) {
	return [pointB[0] - pointA[0], pointB[1] - pointA[1]];
}

export function traversalPolylineClockwise(fig) {
	let a = theMostLeftPointIndex(fig);
	let aNext = (a + 1) % fig.length;
	let aPrev = (a - 1 + fig.length) % fig.length;
	const vPrev = getVec(fig[a], fig[aPrev]);
	const vNext = getVec(fig[a], fig[aNext]);
	return vPrev[0] * vNext[1] - vPrev[1] * vNext[0] < 0;
}

function theMostLeftPointIndex(fig) {
	let ind = 0;
	let xMin = Infinity;
	for (let i = 0; i < fig.length; i++) {
		if (fig[ind][0] < xMin) {
			xMin = fig[ind][0];
			ind = i;
		}	
	}	
	return ind;
}

import React, { Component } from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

import withStyles from "@mui/styles/withStyles";
import Draggable from "react-draggable";

const maxScaleWidth = 200;
const maxScaleBarWidth = maxScaleWidth * 1.5;
const scaleUnitLabels = ["km", "m", "mm", "µm", "nm", "pm", "fm", "am", "zm"];
const scaleUnits = [3, 1, -3, -6, -9, -12, -15, -18, -21];

const styles = {
  root: {
    width: maxScaleBarWidth,
    height: 30,

    position: "absolute",
    bottom: 4,
    right: 6,
    color: "#ffffff",

    textAlign: "center",
    padding: 4,
    cursor: "grab",
  },
};

/**
 * computing data to let renderer render the scalebar as a canvas element, so it can be printed with screenshot tool
 */
class ScaleBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grabbing: false,
      x: 0,
      y: 0,
      newWidth: 0,
    };
  }

  componentDidUpdate() {
    const { ome, zoom } = this.props;
    let scalebarMeters = ome.physicalSizeX * (maxScaleWidth / zoom);

    // hide if no scale information is given
    if (!ome.physicalSizeX) return null;

    let exponent = parseFloat(scalebarMeters.toExponential().split("e")[1]);

    // find best fitting exponennt
    let unitExponent = 1;
    let unitExponentIndex = 1;
    for (
      unitExponentIndex = 0;
      unitExponentIndex < scaleUnits.length;
      unitExponentIndex++
    ) {
      if (scaleUnits[unitExponentIndex] <= exponent) {
        unitExponent = scaleUnits[unitExponentIndex];
        break;
      }
    }

    let mantissa = scalebarMeters / Math.pow(10, unitExponent);

    let roundedMantissa = mantissa;
    if (mantissa < 1.5) {
      roundedMantissa = 1;
    } else if (mantissa < 3.5) {
      roundedMantissa = 2;
    } else if (mantissa < 7.5) {
      roundedMantissa = 5;
    } else if (mantissa < 15) {
      roundedMantissa = 10;
    } else if (mantissa < 35) {
      roundedMantissa = 20;
    } else if (mantissa < 75) {
      roundedMantissa = 50;
    } else if (mantissa < 150) {
      roundedMantissa = 100;
    } else if (mantissa < 350) {
      roundedMantissa = 200;
    } else if (mantissa < 750) {
      roundedMantissa = 500;
    } else {
      roundedMantissa = 1;
      unitExponentIndex--;
      unitExponent = scaleUnits[unitExponentIndex];
      mantissa = scalebarMeters / Math.pow(10, unitExponent);
    }

    // round finer without skipped steps
    // if (mantissa < 10) {
    //   roundedMantissa = Math.floor(mantissa / 1) * 1;
    // } else if (mantissa < 100) {
    //   roundedMantissa = Math.floor(mantissa / 10) * 10;
    // } else if (mantissa < 1000) {
    //   roundedMantissa = Math.floor(mantissa / 100) * 100;
    // }

    let newWidth = (maxScaleWidth * roundedMantissa) / mantissa;

    const fluorescence = ome.channels[0].type !== "brightfield";
    //console.log("setting scaleBarData1");
    this.props.setScaleBarData({
      x: this.state.x,
      y: this.state.y,
      width: newWidth,
      label: roundedMantissa + " " + scaleUnitLabels[unitExponentIndex],
      color: fluorescence ? "#fff" : "#000",
      maxScaleWidth: maxScaleBarWidth,
    });
  }

  render() {
    const { classes, setScaleBarData } = this.props;
    const { grabbing } = this.state;

    return (
      <Draggable
        onStart={(e) => {
          this.parentObj = e.target.offsetParent.getBoundingClientRect();
          this.setState({ grabbing: true });
        }}
        onStop={() => this.setState({ grabbing: false })}
        onDrag={(e) => {
          let rect = e.target.getBoundingClientRect();
          if (this.parentObj.right !== rect.right) {
            this.setState({
              x: rect.right - this.parentObj.right,
              y: rect.bottom - this.parentObj.bottom,
            });
          }
        }}
      >
        <div
          className={classNames(classes.root, grabbing && classes.grabbing)}
          style={{
            width: setScaleBarData.width,
            right: "calc(105px - " + setScaleBarData.width / 2 + "px)",
            pointerEvents: this.props.pointerEvents ? "all" : "none",
          }}
        ></div>
      </Draggable>
    );
  }
}

// define the component's interface
ScaleBar.propTypes = {
  classes: PropTypes.object.isRequired,
  ome: PropTypes.object,
  zoom: PropTypes.number,
  pointerEvents: PropTypes.bool,
  setScaleBarData: PropTypes.func,
};

export default withStyles(styles)(ScaleBar);

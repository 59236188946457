import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
// define the component's styling
const styles = () => ({
  resizesBorder: {
    position: "absolute",
    zIndex: 9999999,
    height: "100%",
    right: -8,
    width: 12,
    top: 0,
    cursor: "col-resize",
  },
  overlayBorder: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999999,
    cursor: "col-resize",
  },
});

class VerticalResizeBorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resizeMode: false,
      borderWidth: 8,
    };
  }

  handleMouseDown = (e) => {
    this.setState({ resizeMode: true, lastPageX: e.pageX });
  };
  handleMouseMove = (e) => {
    e.preventDefault();
    if (this.state.resizeMode) {
      let deltaX = this.state.lastPageX - e.pageX;
      this.props.resizeSideBar(deltaX, false);
      this.setState({ lastPageX: e.pageX });
    }
  };
  handleMouseEnd = () => {
    if (this.state.resizeMode) {
      this.setState({ resizeMode: false });
      this.props.resizeSideBar(0, true);
    }
  };

  render() {
    const { classes } = this.props;
    //const { borderWidth } = this.state;
    return (
      <div
        className={
          this.state.resizeMode ? classes.overlayBorder : classes.resizesBorder
        }
        onMouseDown={(e) => this.handleMouseDown(e)}
        onMouseMove={(e) => this.handleMouseMove(e)}
        onMouseLeave={(e) => this.handleMouseEnd(e)}
        onMouseUp={(e) => this.handleMouseEnd(e)}
      ></div>
    );
  }
}

// define the component's interface
VerticalResizeBorder.propTypes = {
  classes: PropTypes.object.isRequired,
  resizeSideBar: PropTypes.func,
};

export default withStyles(styles)(VerticalResizeBorder);
